import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{yield}}", {"contents":"<GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{yield}}","moduleName":"backoffice/components/route-components/affiliates/clients/profile/sb-profile.hbs","parseOptions":{"srcName":"backoffice/components/route-components/affiliates/clients/profile/sb-profile.hbs"}});
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';

export default class RouteComponentsAffiliatesClientsProfileSbProfileComponent extends Component {
  @service ajax;
  @service store;
  @service router;
  @service navigation;
  @service currentUser;

  @tracked count;


  get menuItems() {
    return [
      {
        name: 'Stats & Profile',
        definer: 'affiliates_clients',
        linkTo: {
          path: 'affiliates.clients.profile.stats-profile'
        }
      },
      {
        name: 'Bet History',
        definer: 'affiliates_clients',
        linkTo: {
          path: 'affiliates.clients.profile.bet-history',
        }
      },
      {
        name: 'Transaction',
        definer: 'affiliates_clients',
        linkTo: {
          path: 'affiliates.clients.profile.transaction',
        }
      },
    ];
  }
}
