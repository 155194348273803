import Model, { attr } from '@ember-data/model';

export default class DepositButtonSegmentModel extends Model {
  @attr('string') name;
  @attr('number') value1;
  @attr('number') value2;
  @attr('number') value3;
  @attr('number') value4;

  get isValid() {
    return this.isValidValue1 && this.isValidValue2 && this.isValidValue3 && this.isValidValue4;
  }

  get isValidValue1() {
    return this.value1 > 0;
  }

  get isValidValue2() {
    return this.value2 > this.value1;
  }

  get isValidValue3() {
    return this.value3 > this.value2;
  }

  get isValidValue4() {
    return this.value4 > this.value3;
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      value1: this.value1,
      value2: this.value2,
      value3: this.value3,
      value4: this.value4,
    };
  }
}
