import ApplicationAdapter from './application';

export default class CasinoGameListAdapter extends ApplicationAdapter {
  urlForQuery(params) {
    if(params.is_bonus) {
      return this.host + '/bonusTemplates/bonuses/casino-game-list';
    }
    if (params.on_wheel_bonus) {
      params.withFreeSpin = true;
      return this.host + '/get-casino-game-lists'
    }
    return this.host + '/casino-game-lists'
  }
}
