import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\">\n  <form onsubmit={{action \"createWithdraw\"}} class=\"form-inline pull-right\">\n    <div class=\"field-box\">\n      <Input type=\"number\" placeholder=\"{{t 'Amount'}}\" @value={{this.amount}}/>\n    </div>\n    {{#unless this.createWithdrawTask.isRunning}}\n      <button class=\"btn btn-basic btn-full\" disabled={{false}} type=\"submit\">{{t 'Save'}}</button>\n    {{else}}\n      <button class=\"btn btn-basic btn-full loading dark\" type=\"submit\"></button>\n    {{/unless}}\n  </form>\n</div>\n{{yield}}", {"contents":"<div class=\"add-new-managers-fields\">\n  <form onsubmit={{action \"createWithdraw\"}} class=\"form-inline pull-right\">\n    <div class=\"field-box\">\n      <Input type=\"number\" placeholder=\"{{t 'Amount'}}\" @value={{this.amount}}/>\n    </div>\n    {{#unless this.createWithdrawTask.isRunning}}\n      <button class=\"btn btn-basic btn-full\" disabled={{false}} type=\"submit\">{{t 'Save'}}</button>\n    {{else}}\n      <button class=\"btn btn-basic btn-full loading dark\" type=\"submit\"></button>\n    {{/unless}}\n  </form>\n</div>\n{{yield}}","moduleName":"backoffice/components/modals/retail/withdraw/create-withdraw.hbs","parseOptions":{"srcName":"backoffice/components/modals/retail/withdraw/create-withdraw.hbs"}});
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {task} from "ember-concurrency";
import {action} from "@ember/object";

export default class ModalsRetailWithdrawCreateWithdrawComponent extends Component {
  @service store;
  @service ajax;
  @service modalWindow;
  @service notifications;
  @service currentUser;

  @tracked amount;

  @action
  createWithdraw(e) {
    e.preventDefault();
    this.createWithdrawTask.perform()
  }

  @(task(function* () {
    yield this.ajax.post(`retail/create-withdraw`, {data: {amount: this.amount, currency_id: this.modalWindow.data.currency_id}}).then(() => {
      this.modalWindow.closeModal();
      this.notifications.success('Withdraw successfully executed', {
        autoClear: true,
        clearDuration: 3200
      });
    }).catch(({response}) => {
      let errors = response.errors;
      if (errors) {
        errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true,
            clearDuration: 3200
          });
        })
      }
    });
  })) createWithdrawTask;
}
