import {tracked} from "@glimmer/tracking";
import AuthenticatedRoute from '../../authenticated';
import {inject as service} from '@ember/service';

export default class RetailCashiersCashierRoute extends AuthenticatedRoute {
  @service store;
  @service navigation;

  @tracked link;

  definer = 'retail_cashiers'

  beforeModel(transition) {
    super.beforeModel(transition);
    this.link = transition.to.name
  }

  model({cashier_id}) {
    let parent = this.store.peekAll('site-content').find(i => i.definer === this.definer);
    let link = null;
    this.store.peekAll('site-content').filter(i => i.parent_id === parseInt(parent.id)).forEach((item) => {
      let withLink = this.navigation.retail_cashiers_details.find((content) => {
        return content.linkTo === this.link
      });
      let val = this.navigation.retail_cashiers_details.find(i => i.definer === item.definer);
      if (withLink) {
        link = withLink.linkTo;
      }
      if (!link && val) {
        link = val.linkTo
      }
    })
    this.router.transitionTo(link);
    this.navigation.selected_cashier_id = cashier_id;
    return cashier_id;
  }

  deactivate() {
    this.navigation.selected_cashier_id = null;
  }
}
