import Controller from '@ember/controller';

export default class RiskManagementOddsFeedEventsController extends Controller {
  queryParams = [
    'page',
    'perPage',
    'lng',
    'market_id',
    'name',
    'event_id',
    'market_id',
  ];

  page = 1;
  perPage = 10;
  lng = 'en';
  market_id;
  event_id;
  name;
  market_id;
}
