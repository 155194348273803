import Model, {attr, belongsTo, hasMany} from '@ember-data/model';
import {inject as service} from '@ember/service';

export default class BetEventModel extends Model {
  @service intl;

  @attr('number') bet_id;
  @attr('number') market_id;
  @attr('number') event_id;
  @attr('number') local_game_id;
  @attr('number') additional_game_id;
  @attr('number') status_id;
  @attr odd;
  @attr type;
  @attr start_result;
  @attr result;
  @attr invariant;
  @attr is_main_game;
  @attr created_at;

  @belongsTo('bet', { async: false, inverse: null }) bet;
  @belongsTo('bet-system', { async: false, inverse: null }) betSystem;
  @belongsTo('bet-status', { async: false, inverse: null }) betStatus;
  @belongsTo('local-game', { async: false, inverse: null }) localGame;
  @belongsTo('local-event', { async: false, inverse: null }) localEvent;
  @belongsTo('local-market', { async: false, inverse: null }) localMarket;

  @hasMany('site-edit-log', { async: false, inverse: null }) editLogs;

  get eventName() {
    let item = this.intl.eventNames[this.event_id];

    if (item && item.name) {
      let name = item.name;
      if ([1096].includes(this.market_id)) {
        let first = this.invariant.split('.')[0];
        let second = this.invariant.split('.')[1];
        let n = Number(second).toString();
        second = Number(second) / Math.pow(10, second.length - n.length);
        name = name.replace('()', first).replace('()', second)
      }

      if ([1018, 9788].includes(this.market_id)) {
        let chunk1 = this.invariant.split('~')[0];
        let chunk2 = this.invariant.split('~')[1];

        chunk1.split('/').forEach(k => {
          name = name.replace('[]', k);
        })

        chunk2.split('.')[0];
        name = name.replace('()', +chunk2.split('.')[0] / 100);

        let second = `0.${chunk2.split('.')[1]}`;
        name = name.replace('()', +second * 1000);
      }

      if ([10357, 10358].includes(this.market_id)) {
        let first = this.invariant.split('.')[0];
        let second = this.invariant.split('.')[1];
        Number(second).toString();
        second = second.length === 4 ? Number(second) / 10 : second;

        name = name.replace('()', first).replace('()', second)
      }

      if ([10355, 10356, 10490, 10491].includes(this.market_id)) {
        let first = this.invariant.split('.')[0];
        let second = this.invariant.split('.')[1];
        second = Number(second) / 10;

        name = name.replace('()', first).replace('()', second)
      }

      if ([1012].includes(this.market_id)) {
        // 301.0015
        let arr = [];
        let first = this.invariant.split('.')[0];
        arr = first.split('0');

        let second = this.invariant.split('.')[1];
        arr.push(+second / 10);
        arr = arr.filter(k => !!k);

        arr.forEach(k => {
          name = name.replace('()', k);
        })
      }

      if ([10524, 998, 2516].includes(this.market_id)) {
        let arr = [];
        let first = this.invariant.split('.')[0];
        arr = first.split('00');

        let second = this.invariant.split('.')[1];
        arr.push(+second / 10);
        arr = arr.filter(k => !!k);

        arr.forEach(k => {
          name = name.replace('()', k);
        })
      }

      if ([2316, 2812, 3555, 9181, 9182, 2506, 1002, 1176, 8443, 8445, 8447, 8449, 8589, 9783, 9941, 7957, 10186, 10185, 10334, 10335, 10336, 10337, 10338, 10339, 10340].includes(this.market_id)) {
        name = name.replace('[]', this.invariant.split('~')[0])
          .replace('()', this.invariant.split('~')[1])
      }

      if ([2508, 2806, 2816, 8905, 10350, 1083, 2502, 2514, 2808, 6630, 8921, 10349, 17, 10525, 8861, 9436, 62, 15, 1006, 1007, 1016, 10351, 10352, 10490, 10491, 1044, 9931, 8957, 9636, 2802, 2, 2346, 1020].includes(this.market_id)) {
        name = name.replace('()', this.invariant).replace('[]', this.invariant);
      }

      if ([9088, 9908, 9911, 9921, 9912].includes(this.market_id)) {
        let chunk1 = this.invariant.split('~')[0];
        let chunk2 = this.invariant.split('~')[1];

        chunk1.split('/').forEach(k => {
          name = name.replace('[]', k);
        })

        chunk2.split('.')[0];
        name = name.replace('()', +chunk2.split('.')[0] / 100);

        let second = chunk2.split('.')[1];
        if (second) {
          name = name.replace('()', +second / 10);
        }
      }

      if ([1054, 1062, 1052, 1014].includes(this.market_id)) {
        let first = this.invariant.split('.')[0];
        name = name.replace('()', Math.abs(+first));

        let second = `0.${this.invariant.split('.')[1]}`;
        if (+first < 0) second = +second * -1;
        name = name.replace('()', +((+second * 100).toFixed(2)));
      }

      if ([9516].includes(this.market_id)) {
        let first = this.invariant.split('.')[0];
        name = name.replace('()', Math.abs(+first));

        let second = `0.${this.invariant.split('.')[1]}`;
        if (+first < 0) second = +second * -1;
        name = name.replace('()', +((+second * 1000).toFixed(2)));
      }

      if ([1074].includes(this.market_id)) {
        let first = this.invariant.split('.')[0];
        name = name.replace('()', +first / 100);

        let second = `0.${this.invariant.split('.')[1]}`;
        name = name.replace('()', +((+second * 100).toFixed(2)));
      }

      if ([9513, 9514, 9784, 9829, 1130, 10129, 10128, 10127].includes(this.market_id)) {
        let first = this.invariant.split('.')[0];
        name = name.replace('()', +first);

        let second = `0.${this.invariant.split('.')[1]}`;
        name = name.replace('()', (+((+second * 1000).toFixed(2))) || 0);
      }

      if ([3209].includes(this.market_id)) {
        let first = this.invariant.split('.')[0];
        let second = this.invariant.split('.')[1];
        second = Number(second).toString();

        name = name.replace('()', first).replace('()', second);
      }

      return name.replace('()-()', this.invariant)
        .replace('()', this.invariant)
        .replaceAll('^1^', this.get('localGame.team1_name'))
        .replaceAll('^2^', this.get('localGame.team2_name'));
    }

    return '';
  }

  get marketName() {
    let item = this.intl.marketNames[this.market_id];

    return item ? item.name : ''
  }

  get isReturn() {
    return this.betStatus.get('title') === 'return';
  }
}
