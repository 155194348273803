import MainTransactionHistory from '../../main-transaction-history';

export default class RetailCashiersCashierTransactionHistoryRoute extends MainTransactionHistory {
  definer = 'retail_cashiers_key_transaction_history'

  model(params) {
    params['user_id'] = this.paramsFor('retail.cashiers.cashier')['cashier_id']
    return params
  }
}
