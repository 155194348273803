import Model, {attr, belongsTo} from '@ember-data/model';
import {tracked} from '@glimmer/tracking';

export default class CasinoBannerModel extends Model {
  @attr partner_id;
  @attr language_id;
  @attr title;
  @attr subtitle;
  @attr button_title;
  @attr background_color;
  @attr('image') image;
  @attr expiration_date;
  @attr('number', {defaultValue: 10}) type
  @attr status;
  @attr('boolean') is_dark
  @attr template_category_id;
  @attr template_game_list_id;
  @attr('number') link_id
  @attr link_type_id;
  @attr updated_at;
  @attr created_at;
  @attr created_by;
  @attr updated_by;
  @attr('order', {defaultValue: 999}) order;

  @belongsTo('partner-link', { async: false, inverse: null }) link;

  @tracked checked = false;

  get style() {
    return 'height: 313px';
  }
}
