import AuthenticatedRoute from '../authenticated';

export default class SportsbookRightSectionBannersRoute extends AuthenticatedRoute {
	definer = 'right_section_banners'

  activate() {
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerCurrencySelect = true;
  }
}
