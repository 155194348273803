import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class AccountSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    currency: {embedded: 'always'},
    accountOrder: {embedded: 'always'},
    playersConfig: {embedded: 'always'},
    accountType: {embedded: 'always'}
  }
}
