import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\">\n  <div class=\"field-box\">\n    <h3 class=\"label\">{{t 'Do you really want to delete?'}}</h3>\n  </div>\n  <button class=\"btn btn-basic btn-full\" type=\"button\" {{on 'click' this.delete}}>{{t 'Yes'}}</button>\n</div>\n{{yield}}", {"contents":"<div class=\"add-new-managers-fields\">\n  <div class=\"field-box\">\n    <h3 class=\"label\">{{t 'Do you really want to delete?'}}</h3>\n  </div>\n  <button class=\"btn btn-basic btn-full\" type=\"button\" {{on 'click' this.delete}}>{{t 'Yes'}}</button>\n</div>\n{{yield}}","moduleName":"backoffice/components/modals/retail/delete.hbs","parseOptions":{"srcName":"backoffice/components/modals/retail/delete.hbs"}});
import Component from '@glimmer/component';
import {task} from "ember-concurrency";
import {inject as service} from '@ember/service';
import {action} from "@ember/object";

export default class ModalsRetailDeleteComponent extends Component {
  @service ajax;
  @service store;
  @service modalWindow;
  @service notifications;

  @action
  delete() {
    this.destroyUser.perform()
  }

  @(task(function* () {
    let user = this.modalWindow.data.user;
    user['retail_'+this.modalWindow.data.decider] = true;
    yield user.destroyRecord().then(() => {
      user.unloadRecord()
      this.modalWindow.closeModal()
    }).catch((payload) => {
      user.rollbackAttributes()
      if (payload && payload.errors) {
        payload.errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true,
            clearDuration: 3200
          });
        })
      }
    });
  }))destroyUser
}
