import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class MaxBetCurrencyGroupSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    partnersConfiguration: {embedded: 'always'},
    defaultConfiguration: {embedded: 'always'},
  };

  normalizeQueryRecordResponse(store, primaryModelClass, payload, id, requestType) {
    let payloadConfig = payload['max-bet-currency-group'];

    if (!payloadConfig.partnersConfiguration || payloadConfig.partnersConfiguration.length === 0) {
      payloadConfig.partnersConfiguration = payloadConfig.defaultConfiguration;
    }

    return super.normalizeQueryRecordResponse(store, primaryModelClass, payload, id, requestType);
  }
}
