import AuthenticatedRoute from '../authenticated';

export default class BonusesBonusTemplatesRoute extends AuthenticatedRoute {
  definer = 'bonuses_bonus_templates';

  activate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = false;
    this.partner.showIsEur = false;
  }


  model() {
     this.store.query('bonus', { type: 10, partner_id: this.partner.selected_id });
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
    this.partner.showIsEur = false;
  }
}
