import Controller from '@ember/controller';
import moment from 'moment';

export default class CasinoFreeSpinsUsageController extends Controller {
  queryParams = [
    'page',
    'perPage',
    'start',
    'end',
    'status',
    'game_code',
    'player_id',
    'sort_name',
    'sort_type'
  ];

  page = 1;
  perPage = 10;
  status;
  game_code;
  player_id;
  sort_name;
  sort_type;
  start = moment().startOf('month').unix();
  end = moment().endOf('day').unix();
}
