import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class PlayerDocumentSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    playerDocumentType: {embedded: 'always'}
  }

  normalizeUpdateRecordResponse(store, primaryModelClass, payload) {
    delete payload['player-documents'].playerDocumentType;

    return this.normalizeSaveResponse(...arguments);
  }
}
