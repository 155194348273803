import AuthenticatedRoute from '../authenticated';

export default class BonusesDashboardRoute extends AuthenticatedRoute {
  definer = 'bonuses_dashboard'

  queryParams = {
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    dateMode: {
      refreshModel: true
    },
    graph_start: {
      refreshModel: true
    },
    graph_end: {
      refreshModel: true
    },
    start: {
      refreshModel: true
    },
    end: {
      refreshModel: true
    },
    product: {
      refreshModel: true
    },
    sort_name: {
      refreshModel: true
    },
    sort_type: {
      refreshModel: true
    },
    bonus_action_id: {
      refreshModel: true
    },
    title: {
      refreshModel: true
    }
  };

  model(params) {
    return params
  }

  activate() {
    this.partner.showPartnerSelect = true;
    this.partner.optionSystem = false;
    this.partner.showPartnerCurrencySelect = true;
  }

  deactivate() {
    this.partner.showPartnerSelect = false;
    this.partner.optionSystem = false;
    this.partner.showPartnerCurrencySelect = false;
  }
}
