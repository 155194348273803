import Controller from '@ember/controller';
import moment from "moment";

export default class AffiliatesClientsProfileTransactionController extends Controller {
  queryParams = [
    'end',
    'page',
    'start',
    'perPage',
    'type_id',
    'currency_id',
    'sort_name',
    'account_type',
    'sort_type',
  ];

  perPage = 10;
  currency_id;
  sort_name;
  sort_type;
  account_type = 'Main';
  page = 1;
  type_id;
  start = moment().startOf('day').unix();
  end = moment().endOf('day').unix();
}
