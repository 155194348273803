import ApplicationAdapter from './application';

export default class PartnerLinkAdapter extends ApplicationAdapter {
  urlForUpdateRecord(id) {
    return this.host + `/partners/links/${id}`;
  }

  urlForCreateRecord() {
    return this.host + `/partners/links`;
  }

  urlForQuery() {
    return this.host + `/partners/links`;
  }

  urlForDeleteRecord(id) {
    return this.host + `/partners/links/${id}`;
  }
}
