import Model, {attr, belongsTo, hasMany} from '@ember-data/model';

export default class AffiliateLinkModel extends Model {
  @attr link
  @attr('number') user_id
  @attr('number') bonus_id
  @attr promo_code
  @attr name
  @attr('number') status
  @attr players_count
  @attr created_at

  @belongsTo('bonus', { async: false, inverse: null }) bonus
  @hasMany('affiliate-banner', { async: false, inverse: null }) affiliateBanners
}
