import AuthenticatedRoute from "../authenticated";
import {inject as service} from '@ember/service';

export default class PaymentDashboardRoute extends AuthenticatedRoute {
	@service partner;
  
  definer = 'payment'

  activate() {
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerCurrencySelect = true;
  }
}
