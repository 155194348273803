import {tracked} from '@glimmer/tracking';
import {action, set, computed} from '@ember/object';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency';
import MainFilter from "../../main-filter";

export default class MethodTemplate extends MainFilter {
  @service modalWindow;
  @service router;
  @service store;
  @service notifications;

  @tracked checkedAll = false;
  @tracked categories = [];
  @tracked types = [];
  @tracked providers = [];
  @tracked showOnWebsite = false;
  @tracked integrations = [];
  @tracked method;
  @tracked methods = [];
  @tracked countres = [];
  @tracked exchange_currency_ids = [];

  @tracked countryFilterConfig = {
    title: 'Available countries',
    key: 'country_ids',
    items: [],
    isMultiselect: true,
    visible: true,
    selectedItems: this.filters.country_ids ? this.filters.country_ids.map(id => this.store.peekRecord('country', id)) : [],
    placeholder: 'All currencies are selected',
    getItems: this.getCountries
  }

  @tracked currencyFilterConfig = {
    title: 'Currencies to exchange',
    key: 'exchange_currency_ids',
    items: [],
    isMultiselect: true,
    visible: true,
    placeholder: 'All currencies are selected',
    getItems: this.getCurrencies
  }

  @tracked filters = {
    template_id: this.router.currentRoute.parent.params.template_id,
    page: this.router.currentRoute.queryParams.page || 1,
    perPage: this.router.currentRoute.queryParams.perPage || 10,
    template_category_id: this.router.currentRoute.queryParams.template_category_id,
    integration_id: undefined,
    country_ids: this.router.currentRoute.queryParams.country_ids ? this.router.currentRoute.queryParams.country_ids.split(',') : [],
    currency_id: this.router.currentRoute.queryParams.currency_id,
    name: this.router.currentRoute.queryParams.name,
    include: 'integration'
  }

  @computed('filters.country_ids.length', 'countres.length')
  get selectedFilterCountries() {
    if (!this.filters.country_ids) return [];
    return this.store.peekAll('country').filter(country => this.filters.country_ids.includes(country.id))
  }

  get checkedMethods() {
    return this.store.peekAll(this.method).filter(i => i.checked);
  }

  get baseTask() {
    return this.getMethodsTask;
  }

  get currencies() {
    return this.store.peekAll('currency');
  }

  constructor() {
    super(...arguments);
    this.getCountries.perform(this.countryFilterConfig).then(() => {
      this.getCurrencies.perform(this.currencyFilterConfig).then(() => {
        this.getIntegrationsTask.perform().then((integrations) => {
          this.integrations = integrations;
          this.getMethodsTask.perform();
        })
      })
    })
    this.getCategories();
  }

  getCategories() {
    this.store.query('payment-template-category', {
      template_id: this.filters.template_id
    }).then(categories => this.categories = categories);
  }

  @action
  openPopup() {
    this.modalWindow.open('payment/settings/add-payment-method/sb-add-payment-method', {
      test: '',
      title: 'Add New Method'
    });
  }

  @action
  openPopupActions() {
    this.modalWindow.open('payment/template/actions/sb-actions', {
      test: '',
      title: `${this.checkedMethods.length} items Selected`,
      model: this.method
    }, 'casino-game-actions');
  }

  @action
  setImageUrl(method, imageUrl) {
    method.image = imageUrl;
  }

  @action
  edit(method) {
    this.editTask.perform(method);
  }

  @action
  delete(method) {
    this.deleteTask.perform(method);
  }

  @action
  toggleSwitcher(method, key) {
    method[key] = !method[key];
  }

  @action
  checkAll() {
    this.checkedAll = !this.checkedAll;

    this.methods.forEach(method => method.checked = this.checkedAll);
  }

  @action
  toggleChecked(method) {
    method.checked = !method.checked;
  }

  @action
  changeFilter(key, {target}) {
    set(this.filters, key, target.value ? target.value : undefined);
    this.setPage(1);
  }

  @action
  setExchangeId(method, {target}) {
    method.exchange_currency_id = target.value ? target.value : undefined
  }

  @action
  setCountryFilter(items) {
    let config = this.countryFilterConfig
    set(this, `filters.country_ids`, items.length ? items.map(i => i.id) : undefined);
    // set(config, `selectedItems`, items);
    if (config.getItems) config.getItems.perform(config);
    this.setPage(1);
  }

  @(task(function* (r) {
    this.countres = yield this.store.findAll('country')
    set(r, 'items', this.countres);
  }))getCountries

  // eslint-disable-next-line
  @(task(function* (r) {
    set(r, 'items', this.store.peekAll('currency').slice());
  }))getCurrencies

  @(task(function* () {
    this.checkedAll = false;
    this.methods.forEach(method => {
      method.rollbackAttributes();
      method.checked = false;
    });
    yield this.store.query(this.method, this.filters).then(methods => {
      this.methods = methods;
    })
  })) getMethodsTask;

  @(task(function* () {
    return yield this.store.query('payment-integration', {})
  })) getIntegrationsTask;

  @(task(function* (method) {
    yield method.save().then(() => {
        this.notifications.success('Successfully Changed', {
          autoClear: true
        })
      }
    ).catch((payload) => {
      if (payload.errors) {
        payload.errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true,
            clearDuration: 3200
          });
        })
      }
    });
  })) editTask;

  @(task(function* (method) {
    yield method.destroyRecord().then(() => {
        this.notifications.success('Successfully Changed', {
          autoClear: true
        })
      }
    ).catch((payload) => {
      if (payload.errors) {
        payload.errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true,
            clearDuration: 3200
          });
        })
      }
    });
  })) deleteTask;
}
