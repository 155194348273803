import Model, {attr, belongsTo} from '@ember-data/model';

export default class InterkassaAccountModel extends Model {
  @attr auth_id;
  @attr auth_key;
  @attr secret_key;
  @attr partner_id;
  @attr integration_id;
  @attr external_cashbox_id;
  @attr status;

  @belongsTo('partner', { async: false, inverse: null }) partner;
  @belongsTo('payment-integration', { async: false, inverse: null }) paymentIntegration;
}
