import Helper from '@ember/component/helper';

export default class NumberDelimiter extends Helper {
  compute([number]) {
    if (number) {
      return parseFloat(number).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return number === null ? 0.00 : number;
  }
}
