import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div {{did-insert this.onDidInsert}}>\n  {{#if this.getCodeInjection.isIdle}}\n    <RouteComponents::Settings::CodeInjection::CodeInjectionItem::SbCodeInjectionItem @codeInjection={{this.codeInjection}} />\n  {{else}}\n    <GenericComponents::Loading::SbLoading />\n  {{/if}}\n</div>", {"contents":"<div {{did-insert this.onDidInsert}}>\n  {{#if this.getCodeInjection.isIdle}}\n    <RouteComponents::Settings::CodeInjection::CodeInjectionItem::SbCodeInjectionItem @codeInjection={{this.codeInjection}} />\n  {{else}}\n    <GenericComponents::Loading::SbLoading />\n  {{/if}}\n</div>","moduleName":"backoffice/components/route-components/settings/code-injection/desktop/sb-desktop.hbs","parseOptions":{"srcName":"backoffice/components/route-components/settings/code-injection/desktop/sb-desktop.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {task} from 'ember-concurrency';
import { action } from '@ember/object';

export default class RouteComponentsSettingsCodeInjectionDesktopSbDesktopComponent extends Component {
  @service store;
  @service partner;

  @tracked codeInjection;

  @action
  onDidInsert() {
    this.getCodeInjection.perform();
  }

  @(task(function* (){
    yield this.store.unloadAll('code-injection');
    yield this.store.query('code-injection',{partner_id: this.partner.selected_id, type: 'desktop'}).then((data) => {
      if(!data.length){
        this.codeInjection = this.store.createRecord('code-injection');
        this.codeInjection.partner_id = this.partner.selected_id
        this.codeInjection.type = 'desktop'
      }else{
        this.codeInjection = data.find(i => i.type === 'desktop');
      }
    })
  }))getCodeInjection
}
