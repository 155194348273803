import AuthenticatedRoute from '../authenticated';

export default class RiskManagementManualWithdrawTricksRoute extends AuthenticatedRoute {
  definer = 'risk_management_manual_withdraw_tricks'

  queryParams = {
    status: {
      refreshModel: true
    },
    player_id: {
      refreshModel: true
    },
    partner_id: {
      refreshModel: true
    },
    transaction_id: {
      refreshModel: true
    },
    currency_id: {
      refreshModel: true
    },
    start: {
      refreshModel: true
    },
    end: {
      refreshModel: true
    },
    type: {
      refreshModel: true
    },
    amount_range_start: {
      refreshModel: true
    },
    amount_range_end: {
      refreshModel: true
    }
  };

  model(params) {
    if(!params.status) {
      params.status = 20;
    }
    return params;
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }
}
