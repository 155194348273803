import Model, {attr, belongsTo, hasMany} from '@ember-data/model';
import {tracked} from '@glimmer/tracking';
import {buildValidations, validator} from "ember-cp-validations";

const Validations = buildValidations(
  {
    service_id: {
      description: 'Attribute',
      // disabled: computed('model.isPeculiar', function () {
      //   return this.model.isPeculiar;
      // }),
      validators: [
        // validator('presence', true),
        validator('format', {regex: /^[a-zA-Z]+$/g, message: '{description} must be only letters'}),
        validator('length', {
          min: 6,
          max: 35
        })
      ]
    },
    description: {
      description: 'Description',
      validators: [
        validator('presence', true),
      ]
    },
    permissions: {
      description: 'Description',
      validators: [
        validator('presence', true),
      ]
    },
  }
);
export default class PaymentWithdrawMethodModel extends Model.extend(Validations) {
  @attr service_id;
  @attr alias;
  @attr name;
  @attr description;
  @attr status;
  @attr min;
  @attr max;
  @attr fee_in;
  @attr fee_out;
  @attr payment_cashbox_id;
  @attr currency_id;
  @attr integration_setting_id;
  @attr('number') integration_id;
  @attr('json-parse') permissions
  @attr('image') image;
  @attr is_peculiar;
  @attr partner_id;

  @belongsTo('payment-currency', { async: false, inverse: null }) paymentCurrency;
  @belongsTo('payment-integration-setting', { async: false, inverse: null }) integrationSetting;
  @belongsTo('payment-integration-setting', { async: false, inverse: null }) paymentIntegrationSetting;
  @hasMany('payment-peculiar-attribute', { async: false, inverse: null }) peculiarAttributes;

  @tracked checked = false;

  @tracked isCash = this.integrationSetting.get('integration.title') === 'cash';
  @tracked isCrypto = this.integrationSetting.get('integration.title') === 'crypto currency'
    || this.integrationSetting.get('integration.title') === 'xrpapi crypto currency' || this.integrationSetting.get('integration.title') === 'Ethereum crypto currency'
    || this.integrationSetting.get('integration.title') === 'bitcoin cash crypto currency' || this.integrationSetting.get('integration.title') === 'dogecoin crypto currency' || this.integrationSetting.get('integration.title') === 'litecoin crypto currency';
  @tracked isInterKassa = this.integrationSetting.get('integration.title') === 'interkassa';
  @tracked isWallettec = this.integrationSetting.get('integration.title') === 'wallettec';
  @tracked isPraxis = this.integrationSetting.get('integration.title') === 'praxis';
  @tracked isFlutterwave = this.integrationSetting.get('integration.title') === 'flutterwave';
  @tracked isPeculiar = this.integrationSetting.get('integration.title') === 'peculiar' || this.integration_id === 20;
  @tracked isAmlNode = this.integrationSetting.get('integration.title') === 'amlnode' || this.integration_id === 19;

  modelName = 'payment-withdraw-method';
}
