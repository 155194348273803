import Model, {attr} from '@ember-data/model';

export default class WheelBonusesListModel extends Model {
    @attr() partner_id; 
    @attr() user_id; 
    @attr() type_id; 
    @attr() status_id; 
    @attr() sector_id; 
    @attr() created_at; 
    @attr() use_period; 
}
