import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"wheel-container\" {{did-insert this.onDidInsert}}>\n</div>\n{{yield}}\n", {"contents":"<div class=\"wheel-container\" {{did-insert this.onDidInsert}}>\n</div>\n{{yield}}\n","moduleName":"backoffice/components/route-components/bonuses/wheel-templates/wheel-settings/sb-wheel-settings.hbs","parseOptions":{"srcName":"backoffice/components/route-components/bonuses/wheel-templates/wheel-settings/sb-wheel-settings.hbs"}});
import Component from '@glimmer/component';
import { SVG } from '@svgdotjs/svg.js';
import { action } from '@ember/object';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';

export default class RouteComponentsBonusesWheelTemplatesWheelSettingsSbWheelSettingsComponent extends Component {
  size = 500;
  padding = 50;


  spinTime = 4 * 1000; // X seconds
  secspinDeg = this.sectors.length * 360; // wheel 8 times
  borderColor = '#253337';
  borderWidth = 2;
  arrowColor = '#FFCC00'; //#c792ea
  wheelBorderWidth = 25;


  @tracked draw = null;
  @tracked svgSectorsGroup = null;
  @tracked svgSpinBtn = null;
  @tracked spinBtnGroup = null;

  @service store;
  @service modalWindow;
  @service eventTrigger;

  get sectors() {
    return this.args.sectors.filter(i => i.wheel_template_id === Number(this.wheel.id));
  }

  constructor() {
    super(...arguments);
    this.onChangeWheelTemplate = this.changeWheelTemplate.bind(this);
    this.onUpdateWheelTemplate = this.updateWheelTemplate.bind(this);
    this.onUpdateSectors = this.updateSectors.bind(this);

    this.eventTrigger.on('change-template', this.onChangeWheelTemplate);
    this.eventTrigger.on('update-template', this.onUpdateWheelTemplate);
    this.eventTrigger.on('update-sector', this.onUpdateSectors);
  }


  willDestroy() {
super.willDestroy(...arguments);
    this.eventTrigger.off('change-template', this.onChangeWheelTemplate);
    this.eventTrigger.off('update-template', this.onUpdateWheelTemplate);
    this.eventTrigger.off('update-sector', this.onUpdateSectors);
  }

  get wheel() {
      return this.args.wheelSettings;
  }

  runAddSector = () => {
    let sector = this.store.createRecord('wheel-sector');
    sector.background_color = '#455B66';
    sector.color = '#E3EFF4';
    sector.wheel_template_id = this.wheel.id;
    sector.title = 'example';
    this.modalWindow.open('bonuses/wheel-sectors/create-edit-wheel-sector/sb-create-edit-wheel-sector', {
      title: "Set Sector params",
      sectorParams: sector
    });
  }

  get viewBoxSize() {
    return this.size + this.padding * 2;
  }

  get center() {
    const centerPoint = this.viewBoxSize / 2;
    return {
      x: centerPoint,
      y: centerPoint,
    };
  }

  get sectorAngle() {
    return 360 / this.sectors.length;
  }

  getDegreeCoordinates(cx, cy, radius, angle) {
    let rAngle = ((angle - 90) * Math.PI) / 180;
    return {
      x: cx + radius * Math.cos(rAngle),
      y: cy + radius * Math.sin(rAngle),
    };
  }

  drawArc(x, y, r, startAngle, endAngle, isLine) {
    const start = this.getDegreeCoordinates(x, y, r, startAngle);
    const end = this.getDegreeCoordinates(x, y, r, endAngle);
    const isLarge = Math.abs(endAngle - startAngle) >= 180;
    const large = isLarge ? 1 : 0;
    const alter = endAngle > startAngle;
    const isAlter = alter ? 1 : 0;

    return isLine
      ? `L${start.x} ${start.y} A${r},${r},0,${large},${isAlter},${end.x},${end.y}`
      : `M${start.x} ${start.y} A${r},${r},0,${large},${isAlter},${end.x},${end.y}`;
  }

  drawSector(x, y, r, r2, startAngle, endAngle) {
    const firstArc = this.drawArc(x, y, r, startAngle, endAngle);
    const secondArc = this.drawArc(x, y, r2, endAngle, startAngle, true);

    return `${firstArc} ${secondArc}Z`;
  }

  drawSectorDelBtn(sectorNumber) {
    const startPoint = this.getDegreeCoordinates(
      this.center.x,
      this.center.y,
      50,
      sectorNumber * this.sectorAngle + this.sectorAngle / 2
    );

    const endPoint = this.getDegreeCoordinates(
      this.center.x,
      this.center.y,
      230,
      sectorNumber * this.sectorAngle + this.sectorAngle / 2
    );

    const text = this.draw.text("X");
    text.font({
      leading: '0',
      'dominant-baseline': 'middle',
      size: 18,
    });

    text.path(`M${startPoint.x} ${startPoint.y}, ${endPoint.x} ${endPoint.y}`);

    text.textPath().attr({
      startOffset: '100%',
      'text-anchor': 'end',
      'font-weight': 'bold',
      'font-variant': 'uppwercase',
      'cursor': 'pointer',
    });

    return text;
  }

  drawSectorText(sectorNumber, textContent, drawAndTitle=false) {
    const startPoint = this.getDegreeCoordinates(
      this.center.x,
      this.center.y,
      50,
      sectorNumber * this.sectorAngle + this.sectorAngle / 2
    );

    const endPoint = this.getDegreeCoordinates(
      this.center.x,
      this.center.y,
      230,
      sectorNumber * this.sectorAngle + this.sectorAngle / 2
    );

    const text = this.draw.text(textContent);
    text.font({
      leading: '0',
      'dominant-baseline': 'middle',
      size: 20,
    });

    text.path(`M${startPoint.x} ${startPoint.y}, ${endPoint.x} ${endPoint.y}`);

    text.textPath().attr({
      startOffset: drawAndTitle ? '60%' : '100%',
      'text-anchor': 'end',
      'font-weight': 'bold',
      'font-variant': 'uppwercase',
      'cursor': 'pointer',
    });

    return text;
  }

  drawSectorIcon(sectorNumber, url, drawAndTitle=false) {
    const startPoint = this.getDegreeCoordinates(
      this.center.x - 25 ,
      this.center.y - 25 ,
      drawAndTitle ? 200 : 170,
      sectorNumber * this.sectorAngle + this.sectorAngle / (drawAndTitle ? 50 : 2)
    );

    let img = this.draw.image(url);
    img.on('click', () => { this.editSector(this.sectors.slice()[sectorNumber]) });
    img.size(50,50).move(startPoint.x, startPoint.y);
      return img;
  }

  drawSectorTextIcon(sectorNumber, url, textContent) {
    if (!textContent) {
      return this.drawSectorIcon(sectorNumber, url, false);
    }

    this.drawSectorIcon(sectorNumber, url, true);
    return this.drawSectorText(sectorNumber, textContent, true)

  }

  drawSvg(el) {
    this.draw = SVG()
      .addTo(el)
      .size('100%', '100%')
      .viewbox(`0 0  ${this.viewBoxSize} ${this.viewBoxSize}`);
  }

  drawWheelBorders() {
    const borderWidth = 25;
    const outBorder = this.draw.circle(this.size + borderWidth * 2 + 20);

    outBorder.attr({
      cx: this.center.x,
      cy: this.center.y,
      fill: this.wheel.out_border_color,
      stroke: this.wheel.out_dots_color,
      'stroke-width': 5,
    });
  }

  drawMiniCircles() {
    this.miniCirclesGroup = this.draw.group();
    this.sectors.forEach((_, index) => {
      const firstCirclePosition = this.getDegreeCoordinates(this.center.x, this.center.y, (this.size + this.wheelBorderWidth * 2 - 25) / 2, index * this.sectorAngle + this.sectorAngle / 4);
      const secondCirclePosition = this.getDegreeCoordinates(this.center.x, this.center.y, (this.size + this.wheelBorderWidth * 2 - 25) / 2, index * this.sectorAngle - this.sectorAngle / 4);
      this.generateCircles(firstCirclePosition);
      this.generateCircles(secondCirclePosition);
    });
    SVG().find('.mini-circle-group').addTo(this.miniCirclesGroup);
  }

  generateCircles(startPoint) {
    this.draw.circle(20,20).addClass('mini-circle-group').attr({
      stroke: this.wheel.out_dots_shadow,
      'stroke-opacity': 0.5,
      'stroke-width': 5,
      fill: this.wheel.out_dots_color,
      cx: startPoint.x,
      cy: startPoint.y,
    });
  }

  drawSpinBtn() {
    this.spinBtnGroup = this.draw.group();
    const spinBtnGroup = this.spinBtnGroup;
    spinBtnGroup.attr('class', 'wheel-spin-btn');

    this.svgSpinBtn = this.draw.circle(100).attr({
      cx: this.center.x,
      cy: this.center.y,
      fill: this.wheel.btn_bg_color,
      stroke: this.wheel.have_sector_border ? this.wheel.sector_border_color : null,
      'stroke-width': 2,
    });
    this.svgSpinBtn.addTo(spinBtnGroup);

    const text = this.draw
      .text((add) => {
        add.tspan('Add sector').font({
          'dominant-baseline': 'middle',
          'text-anchor': 'middle',
          'weight': 'bold',
          fill: this.wheel.btn_txt_color,
        });
      })
      .attr({
        x: this.center.x,
        y: this.center.y,
      });

    text.addTo(spinBtnGroup);

    this.btnListener = spinBtnGroup;
    this.btnListener.on('click', this.runAddSector);
  }

  drawSectors() {
    const borderColor = this.wheel.have_sector_border ? this.wheel.sector_border_color : null;

    this.svgSectorsGroup = this.draw.group();
    this.svgSectorsGroup.attr('id', 'wheel-root-group');
    this.sectors.forEach((el, index) => {
      let path = this.draw.path(
        this.drawSector(
          this.center.x,
          this.center.y,
          0,
          235,
          index * this.sectorAngle,
          index * this.sectorAngle + this.sectorAngle
          )
          );

      const sectorGroup = this.draw.group();
      path.addTo(sectorGroup);
      path.stroke({
        color: borderColor,
        width: this.borderWidth,
        linecap: 'round',
        linejoin: 'round',
        'cursor': 'pointer',
      });

      let text;

      if (el.title_or_icon == 'icon') {
        text = this.drawSectorIcon(index, el.icon);
      } else if (el.title_or_icon == 'title_icon') {
        text = this.drawSectorTextIcon(index, el.icon, el.title);
      } else {
        text = this.drawSectorText(index, el.title);
      }

      text.on('click', () => { this.editSector(el) });
      path.on('click', () => { this.editSector(el) });

      text.addTo(sectorGroup);
      sectorGroup.addTo(this.svgSectorsGroup);

      path.fill(el.background_color);
      text.fill(el.color);

      this.svgSectorsGroup.transform({
        rotate: (-1 * this.sectorAngle) / 2,
        origin: 'center',
      });

     sectorGroup.addTo(this.svgSectorsGroup);

    });
  }

  @action
  onDidInsert(rootEl) {
    this.drawSvg(rootEl);
    this.drawWheelBorders();
    this.drawSectors();
    this.drawSpinBtn();
    this.drawMiniCircles();

  }

  editSector(el) {
    this.modalWindow.open('bonuses/wheel-sectors/create-edit-wheel-sector/sb-create-edit-wheel-sector', {
      title: "Edit Sector params",
      sectorParams: el,
      count: this.sectors.length
    });
  }

  changeWheelTemplate() {
    this.draw.remove();
    this.onDidInsert(document.querySelector('.wheel-container'));
  }

  updateSectors() {
    this.svgSectorsGroup.remove();
    this.spinBtnGroup.remove();
    this.drawSectors();
    this.drawSpinBtn();
    this.modalWindow.closeModal();
  }


  updateWheelTemplate()
   {
    this.draw.remove();
    this.onDidInsert(document.querySelector('.wheel-container'));
    this.modalWindow.closeModal();
  }
}
