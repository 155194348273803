import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class PaymentIntegrationSettingSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    integration: {embedded: 'always'}
  }

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (requestType === 'queryRecord') {
      if(payload['PaymentIntegrationSettings']) {
        payload['PaymentIntegrationSetting'] = payload['PaymentIntegrationSettings']?.[0] || null;
        delete payload['PaymentIntegrationSettings'];
      }

      if(payload['payment-integration-settings']) {
        payload['payment-integration-setting'] = payload['payment-integration-settings']?.[0] || null;
        delete payload['payment-integration-settings'];
      }
    }

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
