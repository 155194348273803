import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';

export default class SettingsPartnersConfigsRoute extends Route {
  @service partner;

  model() {}

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }
}
