import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{yield}}", {"contents":"<GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{yield}}","moduleName":"backoffice/components/route-components/affiliates/transactions/sb-transaction.hbs","parseOptions":{"srcName":"backoffice/components/route-components/affiliates/transactions/sb-transaction.hbs"}});
import Component from '@glimmer/component';

export default class RouteComponentsAffiliatesTransactionsSbTransactionComponent extends Component {

  get menuItems() {
    return [
      {
        name: 'History',
        definer: 'affiliates_transactions_history',
        linkTo: {
          path: 'affiliates.transactions.history'
        }
      },
      {
        name: 'Withdraw Request',
        definer: 'affiliates_transactions_withdraw_request',
        linkTo: {
          path: 'affiliates.transactions.withdraw_request',
        }
      },
    ];
  }
}
