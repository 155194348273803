import Model, {attr, belongsTo, hasMany} from '@ember-data/model';
import {tracked} from '@glimmer/tracking';

export default class LocalGameModel extends Model {
  @attr is_line;
  @attr game_id;
  @attr sport_id;
  @attr game_start;
  @attr game_short_id;
  @attr league_id;
  @attr additional_game_id;
  @attr local_additional_game_id;
  @attr team1_name;
  @attr team2_name;
  @attr team1_name_en;
  @attr team2_name_en;
  @attr league_name;
  @attr region_name;
  @attr created_at;
  @attr updated_at;
  @attr('boolean') is_overtime;
  @attr('boolean') is_finished;
  @attr('boolean') is_main_game;
  @attr bet_events_count;
  @attr local_additional_game_name;

  @hasMany('bet-event', { async: false, inverse: null }) betEvents;
  @belongsTo('local-sport', { async: false, inverse: null }) localSport;
  @belongsTo('local-league', { async: false, inverse: null }) localLeague;
  @belongsTo('local-additional-game', { async: false, inverse: null }) localAdditionalGame;

  @tracked isGrouping = false;

  get title() {
    return this.team1_name + '-' + this.team2_name;
  }

  get name() {
    return this.team1_name + '-' + this.team2_name;
  }

  get isCrycet() {
    return this.sport_id == 12406;
  }
}
