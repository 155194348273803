import Service, {inject as service} from '@ember/service';
import {task} from 'ember-concurrency';
import {tracked} from '@glimmer/tracking';

export default class GroupReportService extends Service {
  @service ajax;
  @service headData;
  @service globalVars;
  @service localStorage;
  @service notifications;

  @tracked token;

  defaultUserCreds = {
    email: 'GR_view_txn@netoplay.ltd',
    phone: '+972542110158'
  }

  get reportsUrl() {
    return this.headData.grReportsURL;
  }

  setToken(token) {
    this.localStorage.setItemWithTTL('gr-auth-token', token, 1000 * 60 * 25);
  }

  getToken() {
    return this.localStorage.getItemWithTTL('gr-auth-token');
  }

  removeToken() {
    this.localStorage.removeItem('gr-auth-token')
  }

  @task
  * getIframeTokenTask(displayNotificationError = false) {
    return yield this.ajax.post(`${this.globalVars.url.admin_api}/gr/auth`)
      .then(data => {
        this.setToken(data.token);
        return data;
      })
      .catch(({payload}) => {
        if (displayNotificationError && payload?.errors) {
          payload.errors.forEach((error) => {
            this.notifications.error(error, {
              autoClear: true
            });
          })
        } else if (!displayNotificationError && payload?.errors) {
          payload.errors.forEach((error) => {
            console.error(error);
          })
        }
      });
  }
}
