import ApplicationAdapter from './application';

export default class TournamentAdapter extends ApplicationAdapter {
  urlForCreateRecord() {
    return `${this.host}/tournaments`
  }

  urlForQuery() {
    return `${this.host}/tournaments`
  }

  urlForFindRecord(id) {
    return `${this.host}/tournaments/${id}`
  }

  urlForUpdateRecord(id) {
    return `${this.host}/tournaments/${id}`;
  }

  urlForDeleteRecord(id) {
    return `${this.host}/tournaments/${id}`;
  }
}
