import Store from '@ember-data/store';

export default class StoreService extends Store {
  query(...params) {
    return new Promise((res, rej) => super.query(...params).then(res).catch(rej));
  }

  queryRecord(...params) {
    return new Promise((res, rej) => super.queryRecord(...params).then(res).catch(rej));
  }

  findAll(...params) {
    return new Promise((res, rej) => super.findAll(...params).then(res).catch(rej));
  }

  findRecord(...params) {
    return new Promise((res, rej) => super.findRecord(...params).then(res).catch(rej));
  }
}
