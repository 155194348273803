import Model, {attr, hasMany} from '@ember-data/model';

export default class PaymentMethodModel extends Model {
  @attr('string') provider;
  @attr('string') method;
  @attr('string') type;
  @attr countries;
  @attr tags;
  @attr merchant_id;
  @attr partner_name;
  @attr partner_token;
  @attr active;
  @attr configuration;
  @attr activeTemplate;
  @attr merchantActive;

  @hasMany('payment-method-partner', {async: false, inverse: null}) merchant_configuration;
}
