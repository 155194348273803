import AuthenticatedRoute from '../authenticated';

export default class SettingsUsersRolesRoute extends AuthenticatedRoute {
  definer = 'settings_users_roles'

  activate() {
    this.partner.optionSystem = true;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.optionSystem = false;
    this.partner.showPartnerCurrencySelect = true;
  }
}
