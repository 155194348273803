import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='notifications' onclick={{this.toggleNotifications}}>\n  <i class='sb-icon sb-bell'></i>\n  {{#if this.userNotify.notificationCount}}\n    <span class='count text-center'>{{this.userNotify.notificationCount}}</span>\n  {{/if}}\n</div>\n\n<div class='user-info'>\n  <span class='name text-ellipsis'>{{this.currentUser.user.full_name}}</span>\n  <span class='user-position text-ellipsis'>{{this.currentUser.user.role.title}}</span>\n  <ul class='sub-nav open_menu' {{on 'click' this.logOut}}>\n    <li class='log_out'>{{t 'Log out'}}</li>\n  </ul>\n</div>\n<div class='name-first-letter' {{on 'click' this.profileInfo}}>{{this.firstLetterOfName}}</div>\n\n<MainComponents::Notifications::SbNotifications @closeNotifications={{this.toggleNotifications}} @notificationsShow={{this.notificationsShow}} />\n{{yield}}", {"contents":"<div class='notifications' onclick={{this.toggleNotifications}}>\n  <i class='sb-icon sb-bell'></i>\n  {{#if this.userNotify.notificationCount}}\n    <span class='count text-center'>{{this.userNotify.notificationCount}}</span>\n  {{/if}}\n</div>\n\n<div class='user-info'>\n  <span class='name text-ellipsis'>{{this.currentUser.user.full_name}}</span>\n  <span class='user-position text-ellipsis'>{{this.currentUser.user.role.title}}</span>\n  <ul class='sub-nav open_menu' {{on 'click' this.logOut}}>\n    <li class='log_out'>{{t 'Log out'}}</li>\n  </ul>\n</div>\n<div class='name-first-letter' {{on 'click' this.profileInfo}}>{{this.firstLetterOfName}}</div>\n\n<MainComponents::Notifications::SbNotifications @closeNotifications={{this.toggleNotifications}} @notificationsShow={{this.notificationsShow}} />\n{{yield}}","moduleName":"backoffice/components/main-components/main-header/user-profile/sb-user-profile.hbs","parseOptions":{"srcName":"backoffice/components/main-components/main-header/user-profile/sb-user-profile.hbs"}});
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service'

export default class MainComponentsMainHeaderUserProfileSbUserProfileComponent extends Component {
  @service currentUser;
  @service session;
  @service store;
  @service modalWindow;
  @service userNotify;

  @tracked notificationsShow = false;

  get firstLetterOfName(){
    let str = this.currentUser.user.full_name?this.currentUser.user.full_name:this.currentUser.user.username
    return str.charAt(0)
  }

  @action
  toggleNotifications() {
    this.notificationsShow = !this.notificationsShow;
  }

  @action
  logOut() {
    this.session.invalidate();
  }

  @action
  profileInfo() {
    this.modalWindow.open('settings/user/actions/reset-password/reset-user-password', {
      title: 'Profile Info',
      user_id: this.currentUser.user.id,
      fullName: this.currentUser.user.full_name
    })
  }
}
