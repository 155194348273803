import Controller from '@ember/controller';

export default class AffiliatesSettingsController extends Controller {
  queryParams = [
    'page',
    'perPage',
    'sort_name',
    'sort_type',
  ];

  page = 1;
  perPage = 10;
  sort_name;
  sort_type;
}
