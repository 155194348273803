import ApplicationAdapter from './application';

export default class MaxBetCurrencyGroupAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/maxBet/currency-groups';
  }

  urlForQueryRecord() {
    return this.host + '/maxBet/configuration';
  }
}
