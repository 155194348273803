import Model, {attr} from '@ember-data/model';

export default class RightSectionBannerModel extends Model {
  @attr partner_id;
  @attr language_id;
  @attr title;
  @attr('image') image;
  @attr('boolean') is_dark;
  @attr link;
  @attr status;
  @attr updated_at;
  @attr created_at;
  @attr('order', {defaultValue: 999}) order;
}
