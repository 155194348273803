import ApplicationAdapter from './application';

export default class PartnerStaticPageAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/staticPages/static-pages'
  }

  urlForUpdateRecord(id){
    return this.host+`/staticPages/static-pages/${id}`;
  }

  urlForCreateRecord(){
    return this.host+`/staticPages/static-pages`;
  }

  urlForDeleteRecord(id) {
    return this.host + `/staticPages/static-pages/${id}`;
  }
}
