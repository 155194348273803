import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<LayoutComponents::Table::SbTable\n  @isEmpty={{eq @data.length 0}}\n  @isLoading={{@isLoading}}\n  @titles={{this.tableTitles}}\n>\n  {{#each @data as |rowData|}}\n    <RouteComponents::RiskManagement::Players::Profile::Notes::NotesTable::NotesTableItem::SbNotesTableItem\n      @data={{rowData}}\n    />\n  {{/each}}\n</LayoutComponents::Table::SbTable>\n{{yield}}\n", {"contents":"<LayoutComponents::Table::SbTable\n  @isEmpty={{eq @data.length 0}}\n  @isLoading={{@isLoading}}\n  @titles={{this.tableTitles}}\n>\n  {{#each @data as |rowData|}}\n    <RouteComponents::RiskManagement::Players::Profile::Notes::NotesTable::NotesTableItem::SbNotesTableItem\n      @data={{rowData}}\n    />\n  {{/each}}\n</LayoutComponents::Table::SbTable>\n{{yield}}\n","moduleName":"backoffice/components/route-components/risk-management/players/profile/notes/notes-table/sb-notes-table.hbs","parseOptions":{"srcName":"backoffice/components/route-components/risk-management/players/profile/notes/notes-table/sb-notes-table.hbs"}});
import Component from '@glimmer/component';

export default class RouteComponentsRiskManagementPlayersProfileNotesNotesTableSbNotesTableComponent extends Component {
  tableTitles = [
    'Date',
    'Event Type',
    'Event Name',
    'Event Note',
    'Created By',
  ];
}
