import Model, {attr, belongsTo, hasMany} from '@ember-data/model';
import {tracked} from '@glimmer/tracking'

export default class RegionModel extends Model {
  @attr name

  @belongsTo('sport', { async: false, inverse: null }) sport
  @hasMany('league', { async: false, inverse: null }) leagues

  @tracked order = null;
  @tracked sport_id = this.sport.id;
  @tracked title = this.name;
}
