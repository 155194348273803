import Controller from '@ember/controller';

export default class RetailCashiersIndexController extends Controller {
  queryParams = [
    'page',
    'perPage',
    'administrator_id',
    'parent_id',
    'partner_id',
    'id',
    'name',
    'currency_id',
    'sort_name',
    'sort_type',
    'is_active',
  ];

  page = 1;
  perPage = 10;
  partner_id;
  administrator_id;
  parent_id;
  id;
  name;
  currency_id;
  sort_name;
  sort_type;
  is_active = 1;
}
