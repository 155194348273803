import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class AdminDepositSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    partner: {embedded: 'always'},
    currency: {embedded: 'always'},
    fromUser: {embedded: 'always'},
    user: {embedded: 'always'}
  }

}
