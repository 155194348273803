import AuthenticatedRoute from '../authenticated';

export default class AffiliatesSettingsRoute extends AuthenticatedRoute {
  definer = 'affiliates_settings'

  queryParams = {
    page: {refreshModel: true},
    perPage: {refreshModel: true},
    sort_name: {refreshModel: true},
    sort_type: {refreshModel: true}
  };

  model(params) {
    return params
  }

  activate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }
}
