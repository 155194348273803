import Controller from '@ember/controller';
import moment from "moment";

export default class AffiliatesClientsIndexController extends Controller {
  queryParams = [
    'page',
    'perPage',
    'partner_id',
    'id',
    'name',
    'email',
    'phone',
    'currency_id',
    'affiliate_user_id',
    'affiliate',
    'this_month_income_from',
    'this_month_income_to',
    'income_by_bets_end',
    'income_by_currency_id',
    'income_by_bets_start',
    'income_by_bets_product',
    'balance_product',
    'sort_name',
    'sort_type',
  ];

  page = 1;
  perPage = 10;
  partner_id;
  sub_affiliate_user_id;
  income_by_bets_product = 'Sport';
  income_by_bets_start = moment().startOf('month').unix();
  income_by_bets_end = moment().endOf('month').unix();
  id;
  name;
  email;
  phone;
  affiliate_user_id;
  affiliate=true;
  currency_id;
  this_month_income_from;
  this_month_income_to;
  sort_name;
  sort_type;
  balance_product = 'Sport';
}
