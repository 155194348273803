import Model, {attr, belongsTo} from '@ember-data/model';

export default class CasinoIntegrationSettingModel extends Model {
  @attr integration_id
  @attr partner_id
  @attr currency_id
  @attr secret_key
  @attr api_key
  @attr host
  @attr game_list_url
  @attr office_id
  @attr login
  @attr password

  @belongsTo('casino-integration', { async: false, inverse: null }) integration
}
