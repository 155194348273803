import ApplicationAdapter from './application';

export default class SmsTemplateAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/marketing/sms-templates'
  }

  urlForUpdateRecord(id) {
    return this.host + `/marketing/sms-templates/${id}`;
  }

  urlForCreateRecord() {
    return this.host + `/marketing/sms-templates`;
  }

  urlForDeleteRecord(id) {
    return this.host + `/marketing/sms-templates/${id}`;
  }
}
