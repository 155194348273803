import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class CasinoWithdrawRequestSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    player: {embedded: 'always'},
    withdraw: {embedded: 'always'},
    integration: {embedded: 'always'}
  }
}

