import Service from '@ember/service';
import {tracked} from '@glimmer/tracking'
import {task} from "ember-concurrency";

export default class MailTemplateService extends Service {
  @service ajax;

  statuses = {
    disabled: 20,
    enabled: 10
  }

  endpoints = {
    updateStatus: '/adminapi/marketing/mailchimp/'
  }
}
