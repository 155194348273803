import ApplicationAdapter from './application';

export default class PlayerMessageAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + `/get-players-message`
  }

  urlForCreateRecord() {
    return this.host + '/create-players-message';
  }

  urlForDeleteRecord(id) {
    return this.host + `/delete-players-message/${id}`;
  }
}
