import AuthenticatedRoute from '../../../authenticated';

export default class RetailManagersManagerDetailsRoute extends AuthenticatedRoute {
  definer = 'retail_managers_key_details'

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }
}
