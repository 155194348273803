import Model, {attr, belongsTo, hasMany} from "@ember-data/model";

export default class SearchLeagueModel extends Model {
  @attr('string') alias;
  @attr('string') name;
  @attr('number') live_games_count;
  @attr('number') line_games_count;
  @attr() sport_id;
  @attr() region_id;
  @attr() league_id;
  @belongsTo('search-sport', { async: false, inverse: null }) sport;
  @hasMany('search-game', { async: false, inverse: null }) games;

  get title() {
    return this.name;
  }

  get sport_title() {
    return this.sport.get('name');
  }
}
