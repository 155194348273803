import {helper} from '@ember/component/helper';

export default helper(function calculateSystemStatuses(params) {
  let bets = params[0];
  let currentParent = Number(params[1]);
  let win = 0;
  let lose = 0;
  let pending = 0;
  bets.forEach(bet => {
    if (Number(bet.parent_id) === currentParent) {
      switch (bet.status_id) {
        case '1':
          pending++;
          break;
        case '2':
          win++;
          break;
        case '3':
          lose++;
          break;
      }
    }
  });

  return [
    {class: 'text-green', value: win, title: 'W'},
    {class: 'text-pending', value: pending, title: 'P'},
    {class: 'text-red', value: lose, title: 'L'}
  ];
});
