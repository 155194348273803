import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class WheelTemplateSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
    attrs = {
        wheelSectors: {embedded: 'always'},
        wheelBonuses: {embedded: 'always'},
        wheelPageInfo: {embedded: 'always'},
        currencyLimit: {embedded: 'always'},
    }
}

