import Model, {attr, belongsTo, hasMany} from '@ember-data/model';
import {tracked} from '@glimmer/tracking';

export default class CashboxModel extends Model {
  @attr partner_id;
  @attr manager_id;
  @attr name;
  @attr address;
  @attr phone;
  @attr created_at;
  @attr('boolean', {defaultValue: true}) is_active;

  @attr retail;

  @belongsTo('user', { async: false, inverse: null }) manager
  @hasMany('admin-account', { async: false, inverse: null }) adminAccounts
  @hasMany('admin-account', { async: false, inverse: null }) mainAdminAccounts
  @hasMany('admin-account', { async: false, inverse: null }) freezeAndMainAccounts

  @tracked title = this.name

  toJSON() {
    return {
      name: this.name,
      partner_id: this.partner_id,
      manager_id: this.manager_id,
      address: this.address,
      phone: this.phone,
      created_at: this.created_at,
      is_active: this.is_active,
    }
  }
}
