import AuthenticatedRoute from '../../authenticated';

export default class RetailReportsSoldCreditsRoute extends AuthenticatedRoute {
  definer = 'retail_reports_key_sold_credits'

  queryParams = {
    page: {refreshModel: true},
    perPage: {refreshModel: true},
    system_user_id: {refreshModel: true},
    partner_ids: {refreshModel: true},
    currency_ids: {refreshModel: true},
    start: {refreshModel: true},
    end: {refreshModel: true},
    by: {refreshModel: true},
    sort_name: {refreshModel: true}
  };

  model(params) {
    if(params.currency_ids) {
      params.currency_ids = params.currency_ids.split(',');
    }
    if(params.partner_ids) {
      params.partner_ids = params.partner_ids.split(',');
    }
    return params
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }
}
