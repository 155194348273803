import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"input-file-upload-classic {{this.class}}\">\n  <input\n    type=\"file\"\n    {{on 'change' this.handleFileChange}}\n  />\n  <span class=\"file-name\">\n    {{#if this.fileUpload.singleFile.name}}\n      {{this.fileUpload.singleFile.name}}\n    {{else}}\n      File to upload...\n    {{/if}}\n  </span>\n</div>", {"contents":"<div class=\"input-file-upload-classic {{this.class}}\">\n  <input\n    type=\"file\"\n    {{on 'change' this.handleFileChange}}\n  />\n  <span class=\"file-name\">\n    {{#if this.fileUpload.singleFile.name}}\n      {{this.fileUpload.singleFile.name}}\n    {{else}}\n      File to upload...\n    {{/if}}\n  </span>\n</div>","moduleName":"backoffice/components/generic-components/form-inputs/input-file-upload-classic/sb-input-file-upload-classic.hbs","parseOptions":{"srcName":"backoffice/components/generic-components/form-inputs/input-file-upload-classic/sb-input-file-upload-classic.hbs"}});
import Component from "@glimmer/component";
import {tracked} from "@glimmer/tracking";
import {action} from "@ember/object";
import {inject as service} from "@ember/service";

export default class GenericComponentsFormInputsInputFileUploadClassicSbInputFileUploadClassic extends Component {
  @service fileUpload;

  @tracked file = null;
  class = this.args.class;

  @action
  handleFileChange(event) {
    const file = event.target.files[0];
    if (file) {
      this.fileUpload.addSingleFile(file);
    }
  }
}
