import Controller from '@ember/controller';
import moment from 'moment';

export default class SportsbookDashboardController extends Controller {
  queryParams = [
    'page',
    'perPage',
    'dateMode',
    'graph_start',
    'graph_end',
    'start',
    'end',
    'type',
    'sort_name',
    'sort_type',
    'income_type',
    'income_from',
    'income_to',
    'sport_id',
    'league_id',
    'market_id',
    'local_game_id',
    'isBonus'
  ];

  page = 1;
  perPage = 10;
  dateMode = 'day';
  income_type = 'amount';
  income_from;
  income_to;
  isBonus;
  sport_id;
  league_id;
  market_id;
  local_game_id;
  type = 'sport';
  sort_name;
  sort_type;
  graph_start = moment().startOf('month').unix();
  graph_end = moment().endOf('day').unix();
  start = moment().startOf('month').unix();
  end = moment().endOf('day').unix();
}
