import Transform from '@ember-data/serializer/transform';

export default class ImageTransform extends Transform {
  deserialize(serialized) {
    if(serialized) {
      return serialized.replace('https://betproduction-file-managers.s3.eu-central-1.amazonaws.com', window.GLOBAL_API.media)
    }

    return serialized;
  }

  serialize(deserialized) {
    return deserialized;
  }
}
