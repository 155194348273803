import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"row-item-wrapper\">\n  <div class=\"row-item\">\n    <div class=\"column1\">\n      <div>{{@sourceMessage.message}}</div>\n    </div>\n    <div class=\"column2\">\n      <div>{{@sourceMessage.category}}</div>\n    </div>\n    <div class=\"column3\">\n      <div class=\"field-box\">\n        <Input type=\"text\" @value={{@sourceMessage.messageLang.translation}} />\n      </div>\n      <div class=\"field-box\">\n        <button value=\"btn small\" style=\"background: #e81919b5\" {{on 'click' this.deleteTranslation}}>{{t\n                'Delete'}}</button>\n      </div>\n    </div>\n  </div>\n</div>\n{{yield}}", {"contents":"<div class=\"row-item-wrapper\">\n  <div class=\"row-item\">\n    <div class=\"column1\">\n      <div>{{@sourceMessage.message}}</div>\n    </div>\n    <div class=\"column2\">\n      <div>{{@sourceMessage.category}}</div>\n    </div>\n    <div class=\"column3\">\n      <div class=\"field-box\">\n        <Input type=\"text\" @value={{@sourceMessage.messageLang.translation}} />\n      </div>\n      <div class=\"field-box\">\n        <button value=\"btn small\" style=\"background: #e81919b5\" {{on 'click' this.deleteTranslation}}>{{t\n                'Delete'}}</button>\n      </div>\n    </div>\n  </div>\n</div>\n{{yield}}","moduleName":"backoffice/components/route-components/settings/translations/translation-table/translation-table-item/sb-translation-table-item.hbs","parseOptions":{"srcName":"backoffice/components/route-components/settings/translations/translation-table/translation-table-item/sb-translation-table-item.hbs"}});
import Component from '@glimmer/component';
import {action} from '@ember/object'
import {tracked} from '@glimmer/tracking'
import {task} from 'ember-concurrency'
import {inject as service} from '@ember/service'

export default class RouteComponentsSettingsTranslationsTranslationTableTranslationTableItemSbTranslationTableItemComponent extends Component {
  @service ajax;
  @service store;
  @service notifications;
  @service intl;

  @tracked translatedVal;

  @action
  saveTranslation() {
    this.saveTranslationTask.perform()
  }

  @action
  deleteTranslation() {
    this.deleteTranslationTask.perform()
  }

  @(task(function* () {
    let data = {};
    data.source_message_id = this.args.sourceMessage.id
    data.language = this.args.sourceMessage.get('messageLang.language')
    data.translation = this.args.sourceMessage.get('messageLang.translation')
    yield this.ajax.post('set-translation', {data}).then(() => {
      this.notifications.success(this.intl.t('saved'), {
        autoClear: true
      });
    }).catch(({payload}) => {
      if (payload && payload.errors) {
        this.notifications.error(payload.errors, {
          autoClear: true
        })
      }
    })
  }))saveTranslationTask

  @(task(function* () {
    yield this.args.sourceMessage.destroyRecord('source-message').then(() => {
      this.notifications.success(this.intl.t('Deleted'), {
        autoClear: true
      });
    }).catch(({payload}) => {
      if (payload && payload.errors) {
        this.notifications.error(payload.errors, {
          autoClear: true
        })
      }
    })
  }))deleteTranslationTask
}
