import ApplicationAdapter from './application';

export default class WheelPageInfoAdapter extends ApplicationAdapter {
    urlForQuery() {
		return this.host + '/wheel/wheel-page-infos'
	}
		
	urlForFindAll() {
		return this.host + '/wheel/wheel-page-infos';
	}

	urlForCreateRecord() {
		return this.host + '/wheel/wheel-page-infos';
	}
		
	urlForUpdateRecord(id) {
		return this.host + '/wheel/wheel-page-infos/' + id;
	}

	urlForDeleteRecord(id) {
		return this.host + `/wheel/wheel-page-infos/${id}`;
	}
}
