import Model, { attr } from '@ember-data/model';

export default class UserNotificationConfigModel extends Model {
	@attr('number') user_id;
	@attr('boolean') withdraw_requests_on;
	@attr('boolean') limits_on;
	@attr('boolean') documents_on;
	@attr('number') withdraw_requests_min_amount;
	@attr('number') withdraw_requests_currency_id;
	@attr('boolean') withdraw_requests_retail_on;
	@attr('boolean') withdraw_requests_online_on;
	@attr('number') limits_percent;
}
