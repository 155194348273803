import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"main-info-container settings-partners domain-manager\">\n  {{#if (can 'settings_partners_key_create')}}\n    <div class=\"top-section\">\n      <button class=\"btn btn-secondary small\" {{on 'click'\n                                                   (fn this.modalWindow.open 'settings/partner/details/add-wallet' this.modalData)}}>{{t\n              'Add Wallet'}}</button>\n    </div>\n  {{/if}}\n  {{#if this.wallets}}\n    <RouteComponents::Settings::Partners::Details::DetailsTable::SbDetailsTable @wallets={{this.wallets}}/>\n  {{/if}}\n\n  <div class=\"config-container\">\n  </div>\n</div>\n{{yield}}", {"contents":"<div class=\"main-info-container settings-partners domain-manager\">\n  {{#if (can 'settings_partners_key_create')}}\n    <div class=\"top-section\">\n      <button class=\"btn btn-secondary small\" {{on 'click'\n                                                   (fn this.modalWindow.open 'settings/partner/details/add-wallet' this.modalData)}}>{{t\n              'Add Wallet'}}</button>\n    </div>\n  {{/if}}\n  {{#if this.wallets}}\n    <RouteComponents::Settings::Partners::Details::DetailsTable::SbDetailsTable @wallets={{this.wallets}}/>\n  {{/if}}\n\n  <div class=\"config-container\">\n  </div>\n</div>\n{{yield}}","moduleName":"backoffice/components/route-components/settings/partners/details/sb-details.hbs","parseOptions":{"srcName":"backoffice/components/route-components/settings/partners/details/sb-details.hbs"}});
import Component from '@glimmer/component';
import {task} from 'ember-concurrency';
import {inject as service} from '@ember/service';

export default class RouteComponentsSettingsPartnersDetailsSbDetailsComponent extends Component {
  @service store;
  @service modalWindow;

  modalData = {title: 'Create New Wallet', partner_id: this.args.partner_id}

  constructor() {
    super(...arguments);
    this.getAccountsTask.perform();
  }

  get wallets() {
    return this.store.peekAll('account').filter((account) => parseInt(account.owner_id) === parseInt(this.args.partner_id) && !account.isNew)
  }

  @(task(function* () {
    yield this.store.query('account', {
      partner_id: this.args.partner_id,
      include: 'currency'
    });
  }))getAccountsTask
}
