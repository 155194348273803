import ApplicationAdapter from './application';

export default class NavigationAdapter extends ApplicationAdapter {
  urlForCreateRecord() {
    return this.host + '/navigations/navigations';
  }

  urlForUpdateRecord(id) {
    return this.host + '/navigations/navigations/' + id;
  }

  urlForDeleteRecord(id) {
    return this.host + `/navigations/navigations/${id}`;
  }
}
