import ApplicationAdapter from './application';

export default class BetStatusAdapter extends ApplicationAdapter {
  urlForQuery(params) {
    if(params.affiliate) {
      return this.host + '/affiliate/client/bet-status';
    }
    return this.host + '/get-bet-statuses'
  }
}
