import ApplicationAdapter from './application';

export default class SportsbookTemplateMarketAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/sportsbook/templates/markets'
  }

  urlForCreateRecord() {
    return this.host + '/sportsbook/templates/markets'
  }

  urlForUpdateRecord($id) {
    return this.host + '/sportsbook/templates/markets/' + $id
  }

  urlForDeleteRecord($id) {
    return this.host + '/sportsbook/templates/markets/' + $id
  }
}
 