import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';

export default class LoginRoute extends Route {
  @service session;
  @service store;
  @service router;
  @service navigation;
  @service globalVars;

  beforeModel() {
    if (this.session.isAuthenticated) {
      let definer = this.store.peekAll('site-content').find(i => i.type === this.globalVars.siteContentTypes.subMenu).definer;
      let link = this.navigation.mapping.find(i => i.definer === definer)
      this.router.transitionTo(link.linkTo);
    }
  }
}
