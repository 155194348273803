import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\" {{did-insert this.onDidInsert}}>\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Title'}}</span>\n    <Input type=\"text\" @value={{this.game.name}} />\n  </div>\n\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Code'}}</span>\n    <Input type=\"text\" @value={{this.game.game_code}} />\n  </div>\n\n  {{#if this.saveTask.isIdle}}\n  \t<button class=\"btn btn-full\" type=\"button\" {{action this.save}}>{{t 'Save'}}</button>\n  {{else}}\n  \t<button class=\"btn btn-full loading\" type=\"button\"></button>\n  {{/if}}\n  \n</div>\n", {"contents":"<div class=\"add-new-managers-fields\" {{did-insert this.onDidInsert}}>\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Title'}}</span>\n    <Input type=\"text\" @value={{this.game.name}} />\n  </div>\n\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Code'}}</span>\n    <Input type=\"text\" @value={{this.game.game_code}} />\n  </div>\n\n  {{#if this.saveTask.isIdle}}\n  \t<button class=\"btn btn-full\" type=\"button\" {{action this.save}}>{{t 'Save'}}</button>\n  {{else}}\n  \t<button class=\"btn btn-full loading\" type=\"button\"></button>\n  {{/if}}\n  \n</div>\n","moduleName":"backoffice/components/modals/casino/settings/add-casino-game/sb-add-casino-game.hbs","parseOptions":{"srcName":"backoffice/components/modals/casino/settings/add-casino-game/sb-add-casino-game.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';
import {task} from 'ember-concurrency';

export default class ModalsCasinoSettingsAddCasinoGameSbAddCasinoGameComponent extends Component {
  @service store;
  @service router;
  @service modalWindow;

  @tracked game = null;

  @action
  onDidInsert() {
    this.game = this.store.createRecord('casino-template-game-list', {
      template_id: this.router.currentRoute.parent.params.template_id
    })
  }

  @action
  save() {
    this.saveTask.perform()
  }

  @(task(function* () {
    yield this.game.save();
    this.modalWindow.closeModal();
  }))saveTask
}
