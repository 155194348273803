import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\">\n  <form onsubmit={{action \"save\"}} class=\"form-inline pull-right\">\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Description'}}</span>\n      <Input type=\"text\" @value={{this.partnerSeo.description}}/>\n    </div>\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Keywords'}}</span>\n      <Input type=\"text\" @value={{this.partnerSeo.keywords}}/>\n    </div>\n\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Title'}}</span>\n      <Input type=\"text\" @value={{this.partnerSeo.title}}/>\n    </div>\n\n    <button class=\"btn btn-basic btn-full\">{{t 'Save'}}</button>\n  </form>\n</div>\n", {"contents":"<div class=\"add-new-managers-fields\">\n  <form onsubmit={{action \"save\"}} class=\"form-inline pull-right\">\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Description'}}</span>\n      <Input type=\"text\" @value={{this.partnerSeo.description}}/>\n    </div>\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Keywords'}}</span>\n      <Input type=\"text\" @value={{this.partnerSeo.keywords}}/>\n    </div>\n\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Title'}}</span>\n      <Input type=\"text\" @value={{this.partnerSeo.title}}/>\n    </div>\n\n    <button class=\"btn btn-basic btn-full\">{{t 'Save'}}</button>\n  </form>\n</div>\n","moduleName":"backoffice/components/modals/settings/partner/seo/sb-seo.hbs","parseOptions":{"srcName":"backoffice/components/modals/settings/partner/seo/sb-seo.hbs"}});
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {task} from "ember-concurrency";
import {action} from "@ember/object";

export default class ModalsSettingsPartnerSeoSbSeoComponent extends Component {
  @service store;
  @service modalWindow;
  @service notifications;
  @service intl;

  @tracked partnerSeo;

  constructor() {
    super(...arguments);
    let partnerId = this.modalWindow.data.partner_id;

    this.store.query('partner-seo', {
      partner_id: partnerId,
      language_id: 'en'
    }).then(v => {
      if (v && v.length) {
        this.partnerSeo = v[0];
      } else {
        this.partnerSeo = this.store.createRecord('partner-seo', {
          partner_id: partnerId,
          path: '/*',
          language_id: 'en'
        });
      }
    });
  }

  @action
  setImageUrl(imageUrl) {
    this.partnerSeo.og_image = imageUrl;
  }

  @action
  save(e) {
    e.preventDefault();
    this.saveTask.perform();
  }

  @(task(function* () {
    yield this.partnerSeo.save().then(() => {
      this.notifications.success(this.intl.t('Successfully saved'), {
        autoClear: true
      });
      this.modalWindow.closeModal()
    }).catch((payload) => {
      if (payload && payload.errors) {
        payload.errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true
          });
        })
      }
    })
  })) saveTask;
}
