import AuthenticatedRoute from '../../authenticated';

export default class SettingsPartnersDomainManagerRoute extends AuthenticatedRoute {
  definer = 'settings_partners'

  model({partner_id}) {
    return {
      menuItems: [
        {
          name: 'Main Configs',
          definer: 'settings_partners',
          linkTo: {
            path: 'settings.partners.domain-manager.main-config',
            model: partner_id
          }
        },
        {
          name: 'Mirrors',
          definer: 'settings_partners',
          linkTo: {
            path: 'settings.partners.domain-manager.mirrors',
            model: partner_id
          }
        },
        {
          name: 'Backup Domains',
          definer: 'settings_partners',
          linkTo: {
            path: 'settings.partners.domain-manager.backup-domains',
            model: partner_id
          }
        }
      ]
    }
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }
}
