import AuthenticatedRoute from '../../authenticated';

export default class RiskManagementPlayersIndexRoute extends AuthenticatedRoute {
  definer = 'risk_management_players'

  queryParams = {
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    group_ids: {
      refreshModel: true
    },
    partner_id: {
      refreshModel: true
    },
    id: {
      refreshModel: true
    },
    name: {
      refreshModel: true
    },
    username: {
      refreshModel: true
    },
    email: {
      refreshModel: true
    },
    phone: {
      refreshModel: true
    },
    total_income_from: {
      refreshModel: true
    },
    total_income_to: {
      refreshModel: true
    },
    income_by_bets_start: {
      refreshModel: true
    },
    income_by_bets_end: {
      refreshModel: true
    },
    income_by_currency_id: {
      refreshModel: true
    },
    income_by_bets_from: {
      refreshModel: true
    },
    income_by_bets_product: {
      refreshModel: true
    },
    income_by_bets_to: {
      refreshModel: true
    },
    deposit_quantity_from: {
      refreshModel: true
    },
    deposit_quantity_to: {
      refreshModel: true
    },
    last_deposit_date_from: {
      refreshModel: true
    },
    last_deposit_date_to: {
      refreshModel: true
    },
    balance_from: {
      refreshModel: true
    },
    balance_to: {
      refreshModel: true
    },
    last_login_from: {
      refreshModel: true
    },
    last_login_to: {
      refreshModel: true
    },
    deposit_amount_from: {
      refreshModel: true
    },
    deposit_amount_to: {
      refreshModel: true
    },
    registration_date_from: {
      refreshModel: true
    },
    registration_date_to: {
      refreshModel: true
    },
    balance_product: {
      refreshModel: true
    },
    currency_id: {
      refreshModel: true
    },
    segment_id: {
      refreshModel: true
    },
    active_bonus: {
      refreshModel: true
    },
    free_spins: {
      refreshModel: true
    },
    sort_name: {
      refreshModel: true
    },
    sort_type: {
      refreshModel: true
    }
  };

  model(params) {
    return params
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
    this.partner.showIsEur = false;
    this.partner.showCurrentPlayerCurrencies = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showIsEur = false;
    this.partner.showPartnerCurrencySelect = true;
  }
}
