import Model, {attr, hasMany} from '@ember-data/model';

export default class LanguageModel extends Model {
  @attr('string') language_id;
  @attr locale;
  @attr name;
  @attr default;

  @hasMany('profiles', { async: false, inverse: null }) profiles;
}
