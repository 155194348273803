import Model, {attr, belongsTo} from '@ember-data/model';

export default class AdminAccountModel extends Model {
  @attr() balance;
  @attr('number') type_id;
  @attr() currency_id;
  @attr() owner_id;
  @attr() created_at;
  @attr() updated_at;

  @attr() isAdministrator;
  @attr() isManager;
  @attr() isCashier;

  @belongsTo('admin-account-type', { async: false, inverse: null }) adminAccountType;
  @belongsTo('partner', { async: false, inverse: null }) partner;
  @belongsTo('currency', { async: false, inverse: null }) currency;
}
