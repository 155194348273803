import Model, {attr, hasMany} from '@ember-data/model';

export default class NavigationGroupModel extends Model {
  @attr('string') title;
  @attr partner_id;
  @attr position;
  @attr('boolean') is_active;
  @attr('boolean') is_vertical;
  @attr('order', {defaultValue: 0}) order;

  @hasMany('navigation', { async: false, inverse: null }) navigations;

  toJSON() {
    return {
      title: this.title,
      partner_id: this.partner_id,
      position: this.position,
      is_active: this.is_active,
      is_vertical: this.is_vertical,
      order: this.order,
    }
  }
}
