import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div id=\"reports-container\" {{did-insert this.onDidInsert}}></div>\n", {"contents":"<div id=\"reports-container\" {{did-insert this.onDidInsert}}></div>\n","moduleName":"backoffice/components/generic-components/gr-transactions-iframe/sb-gr-transactions-iframe.hbs","parseOptions":{"srcName":"backoffice/components/generic-components/gr-transactions-iframe/sb-gr-transactions-iframe.hbs"}});
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

export default class GenericComponentsGrTransactionsIframeSbGrTransactionsIframe extends Component {
  @service groupReport;

  @tracked url = null;

  @action
  onDidInsert() {
    window.wcReports = document.createElement('wc-reports');
    window.wcReports.setAttribute('height', 600);
    window.wcReports.setAttribute('host', this.groupReport.reportsUrl);
    window.wcReports.setAttribute('token', this.args.token);
    window.wcReports.setAttribute('tab', this.args.tab);
    document.getElementById('reports-container').appendChild(window.wcReports);
  }
}
