import AuthenticatedRoute from "../authenticated";

export default class CasinoBannersRoute extends AuthenticatedRoute {
  definer = 'casino_banners'

  activate() {
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerCurrencySelect = true;
  }
}
