import Helper from '@ember/component/helper';
import {storageFor} from 'ember-local-storage';

export default class convertToEur extends Helper {
  @storageFor('partner') partner

  compute([currency, carrier = false, attribute = 'amount']) {
    let amount;
    if (typeof carrier === "object") {
      amount = this.partner.get('isEurOn') ? carrier[attribute + '_in_eur'] : carrier[attribute]
    } else {
      amount = carrier
    }
    return this.partner.get('isEurOn') ? (amount ? `<span>${amount}</span>&ensp;<span class="text-green">EUR</span>` : `<span class="text-green">EUR</span>`) : (amount ? `<span>${amount}</span>&ensp;<span class="text-green">${currency}</span>` :
      `<span class="text-green">${currency}</span>`);
  }
}
