import Controller from '@ember/controller';
import moment from 'moment';

export default class RiskManagementPlayersProfileEditLogsController extends Controller {
  queryParams = [
    'end',
    'start',
    'user_id'
  ];

  user_id;
  start = moment().startOf('day').unix();
  end = moment().endOf('day').unix();
}
