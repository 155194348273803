import ApplicationAdapter from './application';

export default class PromotionByLanguageAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/promotionPages/promotionByLanguages'
  }

  urlForUpdateRecord(id) {
    return this.host + `/promotionPages/promotionByLanguages/${id}`;
  }
}
