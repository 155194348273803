import AuthenticatedRoute from '../../../authenticated';
import { inject as service } from '@ember/service';

export default class PaymentSettingTemplateWithdrawMethodRoute extends AuthenticatedRoute {
  @service store;

  definer = 'payment_settings_key_withdraw_methods';
  queryParams = {
    page: {
      refreshModel: true,
    },
    perPage: {
      refreshModel: true,
    },
    template_category_id: {
      refreshModel: true,
    },
    template_integration_id: {
      refreshModel: true,
    },
    name: {
      refreshModel: true,
    },
    country_ids: {
      refreshModel: true,
    },
  };

  model() {
    this.store.unloadAll('payment-template-withdraw-method');
  }
}
