import AuthenticatedRoute from '../authenticated';

export default class RiskManagementLimitsRoute extends AuthenticatedRoute {
  definer = 'risk_management_limits'

  activate() {
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerCurrencySelect = true;
  }
}
