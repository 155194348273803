import Model, {attr, belongsTo} from '@ember-data/model';

export default class ConfigLanguageModel extends Model {
  @attr language_id;
  @attr partner_id;
  @attr is_active;

  @belongsTo('partner', { async: false, inverse: null }) partner;
  @belongsTo('language', { async: false, inverse: null }) language;
}
