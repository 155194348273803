import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';

export default class PaymentWithdrawAvailableCountryModel extends Model {
  @service store;

  @attr template_withdraw_method_id;
  @attr country_id;

  modelName = 'payment-withdraw-available-country';

  get name() {
    return this.store.peekRecord('country', this.country_id).name;
  }
}
