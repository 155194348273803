import Helper from '@ember/component/helper';

export default class toUppercase extends Helper {

  compute([data, separator = null, uppercase = true]) {
    if (Array.isArray(data)) {
      return data.map((name) => {
        if (typeof name !== 'string') return ''
        return name.charAt(0).toUpperCase() + name.slice(1)
      });
    } else if (typeof data === 'string') {
      if(separator) {
        data = this.splitString(data, separator)
        let result = '';
        data.forEach((name) => {
          if (typeof name !== 'string') return ''
          result = result + ' ' + (uppercase? name.charAt(0).toUpperCase() + name.slice(1) : name)
        });
        return result;
      }else {
        return data.charAt(0).toUpperCase() + data.slice(1)
      }
    }
    return '';
  }

  splitString(stringToSplit, separator) {
    return stringToSplit.split(separator);
  }
}
