import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.menuItems}}\n  <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{/if}}\n{{yield}}", {"contents":"{{#if this.menuItems}}\n  <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{/if}}\n{{yield}}","moduleName":"backoffice/components/route-components/settings/marketing/sb-marketing.hbs","parseOptions":{"srcName":"backoffice/components/route-components/settings/marketing/sb-marketing.hbs"}});
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';

export default class RouteComponentsSettingsMarketingSbMarketingComponent extends Component {
  @tracked menuItems = [
    {
      name: 'Email Templates',
      definer: 'settings_marketing_mail',
      linkTo: {
        path: 'settings.marketing.mail-templates',
      }
    },
    {
      name: 'SMTP Mail Templates',
      definer: 'settings_marketing_smtp_mail',
      linkTo: {
        path: 'settings.marketing.smtp-mail-templates',
      }
    },
    {
      name: 'Site Messages',
      definer: 'settings_marketing_site_messages',
      linkTo: {
        path: 'settings.marketing.site-messages',
      }
    },
    {
      name: 'SMS',
      definer: 'settings_marketing_sms',
      linkTo: {
        path: 'settings.marketing.sms',
      }
    }
  ];
}
