import Model, {attr, belongsTo} from '@ember-data/model';
import {buildValidations, validator} from 'ember-cp-validations';

const Validations = buildValidations(
  {
    phone: {
      description: 'Phone',
      validators: [
        validator('presence', true),
        validator('length', {
          min: 9,
          max: 15,
          message: 'Your {description} is too short or too long.'
        }),
        validator('format', {
          regex: /^[+]?[0-9]{9,15}$/,
          message: 'Please enter a valid {description}'
        })
      ]
    },
    town: {
      description: 'City',
      validators: [
        validator('presence', true),
        validator('format', {regex: /^[a-zA-Z\s]+$/g, message: '{description} must be only letters'}),
        validator('length', {
          min: 2,
          max: 35
        })
      ]
    },
    address: {
      description: 'Address',
      validators: [
        validator('presence', true),
        validator('format', {regex: /^[a-zA-Z0-9\s]+$/g, message: '{description} must be only letters or numbers'}),
        validator('length', {
          min: 2,
          max: 255
        })
      ]
    },
    postal_code: {
      description: 'Zip Code',
      validators: [
        validator('presence', true),
        validator('format', {regex: /^[a-zA-Z0-9]+$/g, message: '{description} must be only letters or numbers'}),
        validator('length', {
          min: 2,
          max: 124
        })
      ]
    }
  }
);

export default class ProfileModel extends Model.extend(Validations) {
  @attr gender;
  @attr birthday;
  @attr passport_image;
  @attr phone;
  @attr country_id;
  @attr town;
  @attr postal_code;
  @attr address;
  @attr passport_id;
  @attr created_at;
  @attr updated_at;
  @attr('string') preferred_language;
  @attr preferredLanguage
  @attr('boolean') accept_marketing_emails
  @attr('boolean') email_validation
  @attr('boolean') wants_bonus

  @belongsTo('player', { async: false, inverse: null }) player;
  @belongsTo('country', { async: false, inverse: null }) country;
  @belongsTo('language', { async: false, inverse: null }) language;
}
