import ApplicationAdapter from './application';

export default class InterkassaCashboxAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/payment/settings/cashboxes'
  }

  urlForUpdateRecord(id) {
    return this.host + `/payment/settings/cashboxes/${id}`;
  }
}
