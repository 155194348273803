import ApplicationAdapter from './application';

export default class CasinoTemplateProviderAdapter extends ApplicationAdapter {
  urlForQuery(params) {
    if(params.is_bonus) {
      return this.host + '/bonusTemplates/bonuses/casino-template-provider';
    }
    if (params.wageringContribution) {
      return this.host + `/casino/template-provider-search`;
    }
    return this.host + '/casino/providers'
  }

  urlForCreateRecord() {
    return this.host + '/casino/providers'
  }

  urlForUpdateRecord($id) {
    return this.host + '/casino/providers/' + $id
  }

  urlForDeleteRecord($id) {
    return this.host + '/casino/providers/' + $id
  }
}
