import Model, {attr, hasMany} from '@ember-data/model';

export default class GroupModel extends Model {
  @attr title;
  @attr('number') external_group_id
  @attr description;
  @attr created_at;
  @attr updated_at;

  @hasMany('partner', { async: false, inverse: null }) partners;
}
