import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\">\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Reason'}}</span>\n    <Input type=\"text\" @value={{this.reason}} />\n  </div>\n  {{#if this.save.isRunning}}\n    <div class=\"btn btn-basic btn-full log-in-btn loading dark\"></div>\n  {{else}}\n    <button class=\"btn btn-full\" type=\"button\" {{on 'click' (perform this.save)}}>\n      {{t 'Save'}}\n    </button>\n  {{/if}}\n</div>\n", {"contents":"<div class=\"add-new-managers-fields\">\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Reason'}}</span>\n    <Input type=\"text\" @value={{this.reason}} />\n  </div>\n  {{#if this.save.isRunning}}\n    <div class=\"btn btn-basic btn-full log-in-btn loading dark\"></div>\n  {{else}}\n    <button class=\"btn btn-full\" type=\"button\" {{on 'click' (perform this.save)}}>\n      {{t 'Save'}}\n    </button>\n  {{/if}}\n</div>\n","moduleName":"backoffice/components/modals/payment/payment-method-configuration/sb-configuration-change.hbs","parseOptions":{"srcName":"backoffice/components/modals/payment/payment-method-configuration/sb-configuration-change.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {task} from 'ember-concurrency'

export default class ModalsPaymentMethodConfigurationSbConfigurationChangeComponent extends Component {
  @service modalWindow;
  @tracked reason = '';

  @(task(function* () {
    yield this.modalWindow.data.onTrigger && this.modalWindow.data.onTrigger(this.reason);
    this.modalWindow.closeModal();
  })) save;
}

