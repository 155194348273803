import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{yield}}", {"contents":"<GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{yield}}","moduleName":"backoffice/components/route-components/retail/managers/details/sb-details.hbs","parseOptions":{"srcName":"backoffice/components/route-components/retail/managers/details/sb-details.hbs"}});
import Component from '@glimmer/component';

export default class RouteComponentsRetailManagersDetailsSbDetailsComponent extends Component {
  get menuItems() {
    return [
      {
        name: 'Details',
        definer: 'retail_managers_key_details',
        linkTo: {
          path: 'retail.managers.manager.details',
        }
      },
      {
        name: 'Cashiers',
        definer: 'retail_managers_key_cashiers',
        linkTo: {
          path: 'retail.managers.manager.cashiers'
        }
      },
      {
        name: 'Transaction History',
        definer: 'retail_managers_key_transaction_history',
        linkTo: {
          path: 'retail.managers.manager.transaction-history'
        }
      }
    ];
  }
}
