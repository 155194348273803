import Model, {attr} from '@ember-data/model';

export default class CodeInjectionModel extends Model {
  @attr partner_id;
  @attr type;
  @attr after_head;
  @attr before_head;
  @attr after_body;
  @attr before_body;
  @attr created_at;
  @attr created_by;
}
