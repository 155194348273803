import {task} from 'ember-concurrency';
import {tracked} from '@glimmer/tracking';
import MainPartnerSelect from "./main-partner-select";


export default class MainReports extends MainPartnerSelect {
  @tracked ggrData;

  get baseTask() {
    return this.ggrDataTask;
  }

  @(task(function* () {
    yield this.ajax.request(`/${(this.url)}`, {data: this.filters}).then((data) => {
      this.ggrData = data[Object.keys(data)[0]]
      this.ggrData.forEach(item => {
        if (parseInt(item.integration_id) === 0) {
          item['integration_title'] = "sport"
        }
        if (item.integration_id && typeof item.integration_id === 'number' && parseInt(item.integration_id) !== 0) {
          item['integration_title'] = this.store.peekRecord('casino-integration', item.integration_id).title
        }
      })
    }).catch((payload) => {
      let errors = payload.errors;
      if (errors) {
        errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true
          });
        })
      }
    });
  }))ggrDataTask
}
