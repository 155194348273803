import ApplicationAdapter from './application';

export default class AccountAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/partner-accounts'
  }

  urlForCreateRecord() {
    return this.host + '/create-account';
  }

  urlForUpdateRecord(id) {
    return this.host + `/update-account/${id}`;
  }
}
