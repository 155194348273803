import {inject as service} from '@ember/service';
import AuthenticatedRoute from "../../../authenticated";

export default class CasinoSettingsTemplateWageringContributionRoute extends AuthenticatedRoute {
  definer = 'casino_settings_key_wagering_contribution';

  @service store;

  model() {
    let template_id = this.paramsFor('casino/settings/template').template_id;
  }
}
