import Helper from '@ember/component/helper';

export default class TextColorDefiner extends Helper {

  compute([arg1]) {
    if (typeof arg1 === "number" || (typeof arg1 === "string" && !isNaN(parseInt(arg1)) && typeof parseInt(arg1) === "number")) {
      if (parseInt(arg1) < 0) {
        return 'text-red';
      }
      return 'text-green';
    }
    if (typeof arg1 === "string") {
      for (let item of arg1.split(" ").slice()) {
        if (!isNaN(parseInt(item)) && typeof parseInt(item) === "number") {
          if (parseInt(item) < 0) {
            return 'text-red';
          }
          return 'text-green';
        }
      }
    }
    return '';
  }
}
