import ApplicationAdapter from './application';

export default class PaymentBannerAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/paymentBanner/banners'
  }

  urlForCreateRecord() {
    return this.host + '/paymentBanner/banners'
  }

  urlForUpdateRecord($id) {
    return this.host + '/paymentBanner/banners/' + $id
  }

  urlForDeleteRecord($id) {
    return this.host + '/paymentBanner/banners/' + $id
  }
}
 