import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"main-info-container payment-settings-templates\">\n  {{#if this.menuItems}}\n    <GenericComponents::BasicNavMenu::SbBasicNavMenu @isForIntegrationPage={{true}} @menuItems={{this.menuItems}}/>\n  {{/if}}\n</div>\n{{yield}}", {"contents":"<div class=\"main-info-container payment-settings-templates\">\n  {{#if this.menuItems}}\n    <GenericComponents::BasicNavMenu::SbBasicNavMenu @isForIntegrationPage={{true}} @menuItems={{this.menuItems}}/>\n  {{/if}}\n</div>\n{{yield}}","moduleName":"backoffice/components/route-components/payment/integration/sb-integration.hbs","parseOptions":{"srcName":"backoffice/components/route-components/payment/integration/sb-integration.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency'
import {tracked} from '@glimmer/tracking';
import {A} from "@ember/array"
import {action} from '@ember/object';

export default class RouteComponentsPaymentIntegrationSbIntegrationComponent extends Component {
  @service modalWindow;
  @service store;
  @service notifications;
  @service partner;
  @service router;

  @tracked menuItems = A([]);

  constructor() {
    super(...arguments);
    this.getPaymentIntegrations.perform().then((integrations) => {
      integrations.forEach((integration) => {
        let obj = {};
        obj.name = integration.title
        obj.definer = 'payment_integration'
        obj.linkTo = {path: 'payment.integration.settings', model: integration.id}
        this.menuItems.pushObject(obj)
      })
      return integrations[0]
    })
  }

  @action
  selectIntegration(event) {
    this.router.transitionTo('payment.integration.settings', {integration_id: +event});
  }

  get integrations() {
    return this.store.peekAll('payment-integration');
  }

  @(task(function* () {
    return yield this.store.query('payment-integration', {})
  }))getPaymentIntegrations
}
