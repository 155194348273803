import AuthenticatedRoute from '../../authenticated';
import {inject as service} from '@ember/service';

export default class TournamentsTournamentsCreateRoute extends AuthenticatedRoute {
  @service('tournament') tournamentService;

  definer = 'tournaments_tournaments';

  activate() {
    this.partner.showPartnerSelect = true;
    this.partner.optionSystem = false;
    this.partner.showIsBonusSwitcher = false;
    this.partner.showIsEur = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = false;
    this.partner.optionSystem = false;
    this.partner.showIsBonusSwitcher = false;
    this.partner.showIsEur = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  model() {
    return this.store.createRecord('tournament', {
      prizes: this.tournamentService.createPrizes(20),
      gameIds: [],
    });
  }
}
