import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isObject}}\n  <ul>\n    {{#each-in this.jsonData as |key value|}}\n      <li><strong>{{key}}:</strong> {{#if (or (typeof value \"object\") (typeof value \"array\"))}}\n        <GenericComponents::JsonFormatter::SbJsonFormatter @data={{value}} />\n      {{else}}\n        {{value}}\n      {{/if}}</li>\n    {{/each-in}}\n  </ul>\n{{else if this.isArray}}\n  <ul>\n    {{#each this.jsonData as |value|}}\n      <li>{{#if (or (typeof value \"object\") (typeof value \"array\"))}}\n        <GenericComponents::JsonFormatter::SbJsonFormatter @data={{value}} />\n      {{else}}\n        {{value}}\n      {{/if}}</li>\n    {{/each}}\n  </ul>\n{{else}}\n  {{this.args.data}}\n{{/if}}\n", {"contents":"{{#if this.isObject}}\n  <ul>\n    {{#each-in this.jsonData as |key value|}}\n      <li><strong>{{key}}:</strong> {{#if (or (typeof value \"object\") (typeof value \"array\"))}}\n        <GenericComponents::JsonFormatter::SbJsonFormatter @data={{value}} />\n      {{else}}\n        {{value}}\n      {{/if}}</li>\n    {{/each-in}}\n  </ul>\n{{else if this.isArray}}\n  <ul>\n    {{#each this.jsonData as |value|}}\n      <li>{{#if (or (typeof value \"object\") (typeof value \"array\"))}}\n        <GenericComponents::JsonFormatter::SbJsonFormatter @data={{value}} />\n      {{else}}\n        {{value}}\n      {{/if}}</li>\n    {{/each}}\n  </ul>\n{{else}}\n  {{this.args.data}}\n{{/if}}\n","moduleName":"backoffice/components/generic-components/json-formatter/sb-json-formatter.hbs","parseOptions":{"srcName":"backoffice/components/generic-components/json-formatter/sb-json-formatter.hbs"}});
import Component from '@glimmer/component';
import { computed } from '@ember/object';

export default class SbJsonFormatterComponent extends Component {
  @computed('args.data')
  get jsonData() {
    try {
      return JSON.parse(this.args.data);
    } catch (e) {
      return this.args.data || '';
    }
  }

  get isObject() {
    return this.jsonData && typeof this.jsonData === 'object' && !Array.isArray(this.jsonData);
  }

  get isArray() {
    return Array.isArray(this.jsonData);
  }
}
