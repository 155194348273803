import ApplicationAdapter from './application';

export default class CodeInjectionAdapter extends ApplicationAdapter {
  urlForQuery(){
    return this.host + '/codeInjection/code-injections'
  }

  urlForCreateRecord(){
    return this.host + '/codeInjection/code-injections'
  }

  urlForUpdateRecord(id) {
    return this.host + `/codeInjection/code-injections/${id}`;
  }
}
