import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class SegmentActions extends Component {
  @service store;
  @service modalWindow;
  @service partner;
  @service currentUser;
  @service ajax;
  @service notifications;
  @service intl;

  @action
  duplicateSegment(segment) {
    this.modalWindow.open(
      'segments/duplicate/sb-duplicate',
      {
        segment,
        title: 'Duplicate to another Partner',
        onTrigger: this.args.getSegmentsTask.perform,
      },
      'bonus-duplicate-partner',
    );
  }

  @action
  updateSegment(segment) {
    this.modalWindow.open('segments/update-segment/sb-update-segment', {
      segment: segment,
      title: 'Edit Segment',
      onTrigger: this.args.getSegmentsTask.perform,
    });
  }

  @action
  copySegment(segmentId) {
    this.copyTask.perform(segmentId);
  }

  @action
  exportPlayers(segmentId) {
    this.exportPlayersTask.perform(segmentId);
  }
  @action
  uploadPlayers(segment_id ,event) {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.uploadPlayersTask.perform(segment_id, file);
    }
  }

  @action
  toggleCheckedStatus(segment, e) {
    e.stopPropagation();
    if (this.updateStatusTask.isIdle) {
      this.updateStatusTask.perform(segment);
    }
  }

  @task(function* (segmentId) {
    yield this.ajax
      .post('/segment/action-duplicate', { data: { segment_id: segmentId } })
      .then(() => {
        this.args.getSegmentsTask.perform();
        this.notifications.success(this.intl.t('Successfully copied'), {
          autoClear: true,
        });
      })
      .catch(({ payload }) => {
        if (payload.errors) {
          payload.errors.forEach((error) => {
            this.notifications.error(error, {
              autoClear: true,
            });
          });
        }
      });
  })
  copyTask;

  @task(function* (segmentId) {
    yield this.ajax
      .request(`segment/action-export-players/${segmentId}`)
      .then((response) => {
        let blob = new Blob([response.data], { type: 'text/csv' });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${response.fileName}`;
        link.click();
      })
      .catch(({ payload }) => {
        if (payload.errors) {
          payload.errors.forEach((error) => {
            this.notifications.error(error, {
              autoClear: true,
            });
          });
        }
      });
  })
  exportPlayersTask;

  @task(function* (segment_ids) {
    yield this.ajax.post(`segment/action-export-segments`, { data: { segment_ids } }).then((response) => {
      let blob = new Blob([response.data], { type: 'text/csv' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${response.fileName}`;
      link.click();
    }).catch(({ payload }) => {
      if (payload.errors) {
        payload.errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true,
          });
        });
      }
    });
  })
  exportSegmentsTask;

  @(task(function* (segmentId, file) {
    let formData = new FormData();
    formData.append('file', file);
    yield this.ajax
      .post(`/segment/action-import-players/${segmentId}`, {
        data: formData,
        enctype: 'multipart/form-data',
        cache: false,
        contentType: false,
        processData: false
      })
      .then((res) => {
        let message = this.intl.t('Document uploaded successfully.');
        if(res.unsuccessful_player_ids.length) {
          message += `
          ${this.intl.t('Players with ids')} ${res.unsuccessful_player_ids.join(', ')} ${this.intl.t('were not uploaded')}`;
        }
        this.notifications.success(message, {
          autoClear: true
        })
      })
      .catch(({ payload }) => {
        if (payload.errors) {
          payload.errors.forEach((error) => {
            this.notifications.error(error, {
              autoClear: true,
            });
          });
        }
      });
  })
    .maxConcurrency(3)
    .enqueue())
  uploadPlayersTask;

  @task(function* (segment) {
    yield this.ajax.post('/settings/segment-status', { data: { id: +segment.id, status: +!segment.status } });
    segment.status = +!segment.status;
  })
  updateStatusTask;
}
