import AuthenticatedRoute from '../../authenticated';

export default class RetailCashboxesDetailsRoute extends AuthenticatedRoute {
  definer = 'retail_cashboxes'

  model(params) {
    return params
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }
}
