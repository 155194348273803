import Controller from '@ember/controller';

export default class RiskManagementPlayersProfileBonusesController extends Controller {
  queryParams = [
    'status',
    'bonus_code',
  ];

  status;
  bonus_code;
}
