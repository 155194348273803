import AuthenticatedRoute from '../authenticated';

export default class SettingsMarketingRoute extends AuthenticatedRoute {
  definer = 'settings_marketing'

  activate() {
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerCurrencySelect = true;
  }
}
