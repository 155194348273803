import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"main-info-container reports\">\n  {{#if this.menuItems}}\n    <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n  {{/if}}\n</div>\n{{yield}}", {"contents":"<div class=\"main-info-container reports\">\n  {{#if this.menuItems}}\n    <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n  {{/if}}\n</div>\n{{yield}}","moduleName":"backoffice/components/route-components/retail/reports/sb-reports.hbs","parseOptions":{"srcName":"backoffice/components/route-components/retail/reports/sb-reports.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service'

export default class RouteComponentsRetailReportsSbReportsComponent extends Component {
  @service store;

  menuItems = [
    {
      name: 'Remaining Credits',
      linkTo: {
        path: 'retail.reports.remaining-credits'
      },
      definer: 'retail_reports_key_remaining_credits'
    },
    {
      name: 'Sold Credits',
      linkTo: {
        path: 'retail.reports.sold-credits'
      },
      definer: 'retail_reports_key_sold_credits'
    }
  ];
}
