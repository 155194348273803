import ApplicationAdapter from './application';

export default class MailchimpTemplateAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/marketing/mailchimp'
  }

  urlForUpdateRecord(id) {
    return this.host + `/marketing/mailchimp/${id}`;
  }

  urlForCreateRecord() {
    return this.host + `/marketing/mailchimp`;
  }

  urlForDeleteRecord(id) {
    return this.host + `/marketing/mailchimp/${id}`;
  }
}
