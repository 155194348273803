import ApplicationAdapter from './application';

export default class PartnerAdapter extends ApplicationAdapter {
  urlForCreateRecord() {
    return this.host + '/create-partner';
  }
  urlForUpdateRecord(id) {
    return this.host + `/partners/${id}`;
  }
}
