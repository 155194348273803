import Helper from '@ember/component/helper';

export default class convertEventName extends Helper {
  compute([event, game]) {
    let item = event.localEvent;
    if (item && item.get('name')) {
      return item.get('name').replace('()-()', event.get('invariant'))
        .replace('()', event.get('invariant'))
        .replace('^1^', game.get('team1_name') || game.get('team1_name_en'))
        .replace('^2^', game.get('team2_name') || game.get('team2_name_en'));
    }
    return '';
  }
}
