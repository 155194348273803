import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='duplicate-list-container'>\n  <LayoutComponents::FieldBox::SbFieldBox @label={{t 'New Bonus Code'}}>\n    <Input\n      type='text'\n      @value={{this.duplicatedBonusCode}}\n    />\n  </LayoutComponents::FieldBox::SbFieldBox>\n\n  <button\n    class=\"btn btn-basic btn-full mt-4 {{if this.duplicateTask.isRunning 'loading'}}\"\n    type=\"button\"\n    disabled={{if this.duplicateTask.isRunning 'disabled'}}\n    onclick={{perform this.duplicateTask}}\n  >\n    {{t 'Duplicate'}}\n  </button>\n</div>\n{{yield}}\n", {"contents":"<div class='duplicate-list-container'>\n  <LayoutComponents::FieldBox::SbFieldBox @label={{t 'New Bonus Code'}}>\n    <Input\n      type='text'\n      @value={{this.duplicatedBonusCode}}\n    />\n  </LayoutComponents::FieldBox::SbFieldBox>\n\n  <button\n    class=\"btn btn-basic btn-full mt-4 {{if this.duplicateTask.isRunning 'loading'}}\"\n    type=\"button\"\n    disabled={{if this.duplicateTask.isRunning 'disabled'}}\n    onclick={{perform this.duplicateTask}}\n  >\n    {{t 'Duplicate'}}\n  </button>\n</div>\n{{yield}}\n","moduleName":"backoffice/components/modals/bonuses/duplicate/sb-duplicate.hbs","parseOptions":{"srcName":"backoffice/components/modals/bonuses/duplicate/sb-duplicate.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency';
import {tracked} from '@glimmer/tracking';

export default class ModalsBonusesDuplicateSbDuplicateComponent extends Component {
  @service ajax;
  @service modalWindow;
  @service intl;
  @service notifications;

  @tracked duplicatedBonusCode = `${this.modalWindow.data.bonusCode}Duplicate`

  @task
  *duplicateTask() {
    let data = {
      bonus_id: this.modalWindow.data.bonusId,
      bonus_code: this.duplicatedBonusCode
    };

    yield this.ajax.post('/bonusTemplates/bonuses/bonus-duplicate', {data})
      .then(() => {
        this.modalWindow.data?.onTrigger();
        this.notifications.success(this.intl.t('Successfully Duplicated'), {
          autoClear: true,
        });
        this.modalWindow.closeModal();
      })
      .catch(({payload}) => {
        if (payload && payload.errors) {
          payload.errors.forEach((error) => {
            this.notifications.error(error, {
              autoClear: true,
            });
          });
        }
      });
  }
}
