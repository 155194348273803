import Model, {attr, belongsTo} from '@ember-data/model';

export default class SportsKpiModel extends Model {
  @attr partner_id;
  @attr player_id;
  @attr product;
  @attr bets_amount;
  @attr win_amount;
  @attr lose_amount;
  @attr ggr;
  @attr percent;
  @attr your_profit;

  @belongsTo('partner', { async: false, inverse: null }) partner;
  @belongsTo('currency', { async: false, inverse: null }) currency;
  // @belongsTo('player', { async: false, inverse: null }) player
}
