import Controller from '@ember/controller';
import moment from 'moment';

export default class RiskManagementPlayersProfileCasinoBetHistoryController extends Controller {
  queryParams = [
    'end',
    'page',
    'start',
    'perPage',
    'type_id',
    'game_id',
    'status_id',
    'sort_name',
    'isFreeSpin',
    'sort_type',
    'search_val',
  ];

  perPage = 10;
  search_val;
  status_id;
  isFreeSpin = 0;
  sort_name;
  sort_type;
  page = 1;
  game_id;
  type_id;
  start = moment().startOf('day').unix();
  end = moment().endOf('day').unix();
}
