import {tracked} from '@glimmer/tracking';
import {action, computed, set} from '@ember/object';
import {inject as service} from '@ember/service';
import MainPartnerSelect from "../../abstract-components/main-partner-select";

export default class Players extends MainPartnerSelect {
  @service navigation;
  @service modalWindow;
  @service notifications;

  @tracked baseRoute = this.router.currentRouteName
  @tracked btnContentBox = false;
  @tracked searchBalanceBox = false;
  @tracked searchDepositAmountBox = false;
  @tracked playerFilters = null;
  @tracked monthIncome = false;
  @tracked players = null;
  @tracked search_by = this.searchKeys.find(key => this.args.params[key]) || 'email';
  @tracked deposit_search_by = this.depositSearchKeys.find(key => this.args.params[key]) || 'deposit_amount';

  products = [{title: 'Sport'}, {title: 'Casino'}];

  @computed('filters.income_by_bets_from')
  get income_by_bets_from() {
    return this.filters.income_by_bets_from;
  }

  set income_by_bets_from(value) {
    set(this, `filters.income_by_bets_from`, value || undefined);
  }

  @computed('filters.income_by_bets_to')
  get income_by_bets_to() {
    return this.filters.income_by_bets_to;
  }

  set income_by_bets_to(value) {
    set(this, `filters.income_by_bets_to`, value || undefined);
  }

  @computed('filters.balance_from')
  get searchBalanceFrom() {
    return this.filters.balance_from;
  }

  set searchBalanceFrom(search) {
    set(this, `filters.balance_from`, search || undefined);
    this.setTimeOut();
  }

  @computed('filters.balance_to')
  get searchBalanceTo() {
    return this.filters.balance_to;
  }

  set searchBalanceTo(search) {
    set(this, `filters.balance_to`, search || undefined);
    this.setTimeOut();
  }

  @computed('currencies', 'filters.currency_id', 'partner.currencies')
  get selectedCurrencyCode() {
    if (this.currencies && this.partner.currencies) {
      let currencies = this.partner.currencies.find(i => i.id === this.filters.currency_id);
      if (currencies) {
        return currencies.get('code');
      }
    }
    return null;
  }

  @computed('filters.{email,id,name,phone,username}', 'search_by')
  get searchByKey() {
    return this.filters[this.search_by];
  }

  set searchByKey(search) {
    set(this, `filters.${this.search_by}`, search || undefined);
    this.setTimeOut();
  }

  @computed('deposit_search_by', 'filters.{deposit_amount_from,deposit_amount_to,deposit_quantity_from,deposit_quantity_to,last_deposit_date_from,last_deposit_date_to}')
  get depositSearchByKey() {
    return this.filters[this.deposit_search_by];
  }

  set depositSearchByKey(search) {
    set(this, `filters.${this.deposit_search_by}`, search || undefined);
    this.setTimeOut();
  }

  @computed('filters.deposit_amount_from')
  get searchDepositAmountFrom() {
    return this.filters.deposit_amount_from;
  }

  set searchDepositAmountFrom(search) {
    set(this, `filters.deposit_amount_from`, search || undefined);
    this.setTimeOut();
  }

  @computed('filters.deposit_quantity_from')
  get searchDepositQuantityFrom() {
    return this.filters.deposit_quantity_from;
  }

  set searchDepositQuantityFrom(search) {
    set(this, `filters.deposit_quantity_from`, search || undefined);
    this.setTimeOut();
  }

  @computed('filters.deposit_quantity_to')
  get searchDepositQuantityTo() {
    return this.filters.deposit_quantity_to;
  }

  set searchDepositQuantityTo(search) {
    set(this, `filters.deposit_quantity_to`, search || undefined);
    this.setTimeOut();
  }

  @computed('filters.deposit_amount_to')
  get searchDepositAmountTo() {
    return this.filters.deposit_amount_to;
  }

  set searchDepositAmountTo(search) {
    set(this, `filters.deposit_amount_to`, search || undefined);
    this.setTimeOut();
  }

  @action
  selectIncomeByBetsProduct(value) {
    if (!value) value = undefined;
    set(this.filters, 'income_by_bets_product', value)
  }

  @action
  selectBalanceProduct(value) {
    if (!value) value = undefined;
    set(this.filters, 'balance_product', value);
    this.setTimeOut();
  }

  @action
  saveIncome(attr) {
    let from = this[`${attr}_from`];
    let to = this[`${attr}_to`];
    if (from !== undefined && to !== undefined && from !== '' && to !== '') {
      set(this, `filters.${attr}_from`, from);
      set(this, `filters.${attr}_to`, to);
      this.setPage(1);
    } else {
      set(this, `filters.${attr}_from`, undefined);
      set(this, `filters.${attr}_to`, undefined);
      this.setPage(1);
    }
    this.income = false;
    this.monthIncome = false;
  }

  @action
  setEmptyInput(val) {
    set(this, `filters.${val}`, undefined);
    this.setTimeOut();
  }

  @action
  selectSearchBy(val) {
    this.searchKeys.forEach(searchKey => set(this, `filters.${searchKey.key}`, undefined));
    this.search_by = val;
    this.clearSearchByKey();
  }

  @action
  selectDepositSearchBy(val) {
    set(this, `filters.deposit_amount_from`, undefined);
    set(this, `filters.deposit_amount_to`, undefined);
    set(this, `filters.deposit_quantity_from`, undefined);
    set(this, `filters.deposit_quantity_to`, undefined);
    this.depositSearchKeys.forEach(key => set(this, `filters.${key}`, undefined));
    this.setTimeOut();
    this.deposit_search_by = val;
  }

  @action
  toggleAction(val) {
    set(this, `${val}`, !this[val]);
  }

  @action
  OutsideAction(value) {
    this[`${value}`] = false;
  }

  @action
  ClearInput(prop) {
    set(this, 'filters.' + prop, undefined);
    this.setTimeOut();
  }

  @action
  applyCalendarIncomeByBets(event, dateRangePicker) {
    if(dateRangePicker) {
      set(this.filters, 'income_by_bets_start', dateRangePicker.startDate.unix());
      set(this.filters, 'income_by_bets_end', dateRangePicker.endDate.unix());
    }
    this.setTimeOut();
  }

  @action
  applyCalendarLastDepositDate(event, dateRangePicker) {
    if(dateRangePicker) {
      set(this.filters, 'last_deposit_date_from', dateRangePicker.startDate.unix());
      set(this.filters, 'last_deposit_date_to', dateRangePicker.endDate.unix());
    }
    this.setTimeOut();
  }

  @action
  applyCalendarLastLoginDate(event, dateRangePicker) {
    if(dateRangePicker) {
      set(this.filters, 'last_login_from', dateRangePicker.startDate.unix());
      set(this.filters, 'last_login_to', dateRangePicker.endDate.unix());
      this.setTimeOut();
    }
  }

  @action
  applyCalendarRegistrationDate(event, dateRangePicker) {
    if(dateRangePicker) {
      set(this.filters, 'registration_date_from', dateRangePicker.startDate.unix());
      set(this.filters, 'registration_date_to', dateRangePicker.endDate.unix());
      this.setTimeOut();
    }
  }

  @action
  cancelCalendarLastLoginDate() {
    set(this.filters, 'last_login_from', undefined);
    set(this.filters, 'last_login_to', undefined);
    this.setTimeOut();
  }

  @action
  cancelCalendarRegistrationDate() {
    set(this.filters, 'registration_date_from', undefined);
    set(this.filters, 'registration_date_to', undefined);
    this.setTimeOut();
  }

  @action
  cancelCalendarLastDepositDate() {
    set(this.filters, 'last_deposit_date_from', undefined);
    set(this.filters, 'last_deposit_date_to', undefined);
    this.setTimeOut();
  }

  @action
  performSearchByKey() {
    this.searchByKey = document.querySelector('.customSearchTerm').value
  }

  @action
  clearSearchByKey() {
    if (!document.querySelector('.customSearchTerm').value && ! this.searchByKey) {
      return;
    }

    document.querySelector('.customSearchTerm').value = '';
    this.searchByKey = '';
  }

}
