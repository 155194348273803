import Helper from '@ember/component/helper';
import {inject as service} from "@ember/service"

export default class MenuLinker extends Helper {
  @service navigation;
  @service globalVars;
  @service store;

  compute([definer, id]) {
    let linkTo = ''
    this.navigation.mapping.forEach(map => {
      if (map.haveManyLink && definer === map.definer) {
        let child = this.store.peekAll('site-content').filter(i => i.type === this.globalVars.siteContentTypes.subMenuContent).find(i => i.parent_id === parseInt(id));
        let childMap = this.navigation[definer].find(i => i.definer === definer);
        if (child) {
          let childOfChildMap = this.navigation[definer].find(i => i.definer === child.definer)
          if (childOfChildMap) {
            childMap = childOfChildMap;
          }
        }
        if(childMap) {
          linkTo = childMap.linkTo;
        }
      }
      if (map.definer === definer && !map.haveManyLink) {
        linkTo = map.linkTo;
      }
    });
    return linkTo
  }
}
