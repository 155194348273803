export const roundDown = (amount, rates, currency_id) => {
  let rate = rates.map(rItem => {
    if (+rItem['id'] === currency_id) {
      return rItem['rates'];
    }
  }).find(item => item);
  let result = (rate * amount).toFixed(3).toString();
  let resultString = result.split('.');
  if (resultString[0].length > 2) {
    resultString = resultString[0];
    let result = '';
    for (let i = 0; i < resultString.length; i++) {
      if (i > 1) {
        result += '0';
      } else {
        result += resultString[i];
      }
    }
    return parseFloat(parseInt(result).toFixed(3));
  } else if (resultString[0].length === 2) {
    let secondNum = +resultString[0][1] >= 5 ? 5 : 0;
    return parseFloat(parseInt(resultString[0][0] + secondNum).toFixed(3));
  } else if (resultString[0].length === 1 && +resultString[0] === 0) {
    return 1;
  }
  return parseFloat(parseInt(resultString[0]).toFixed(3));
}
