import ApplicationAdapter from './application';

export default class InterkassaAccountAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/payment/settings/accounts'
  }

  urlForQueryRecord() {
    return this.host + '/payment/settings/accounts'
  }

  urlForCreateRecord() {
    return this.host + '/payment/settings/accounts'
  }

  urlForUpdateRecord(id) {
    return this.host + `/payment/settings/accounts/${id}`;
  }

  urlForDeleteRecord(id) {
    return this.host + `/payment/settings/accounts/${id}`;
  }
}
