import Model, {attr, belongsTo} from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

export default class SportsbookTemplateLeagueModel extends Model {
  @attr('number') sportsbook_template_id;
  @attr('string') sport_id;
  @attr('string') league_id;
  @attr('boolean') dis_active;
  @attr('boolean') is_top;
  @attr('boolean') is_popular;
  @attr('number', {defaultValue: 9999999}) top_order;
  @attr('number', {defaultValue: 9999999}) popular_order;

  @belongsTo('local-league', { async: false, inverse: null }) localLeague; 

  @tracked name = this.get('localLeague.name');

  order = 9999999;
}
