import AuthenticatedRoute from "../../../authenticated";

export default class AffiliatesClientsProfileStatsProfileRoute extends AuthenticatedRoute {
  definer = 'affiliates_clients_stats_profile'

  queryParams = {
    partner_id: {
      refreshModel: true
    },
    dateMode: {
      refreshModel: true
    },
    graph_start: {
      refreshModel: true
    },
    graph_end: {
      refreshModel: true
    }
  };

  model(params) {
    return {...this.paramsFor('affiliates.clients.profile'), ...params};
  }
}
