import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\">\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Currencies'}}</span>\n    <PowerSelectMultiple\n            @searchEnabled={{true}}\n            @searchField=\"code\"\n            @options={{this.currencies}}\n            @selected={{this.selectedCurrencies}}\n            @placeholder=\"\"\n            @onChange={{this.selectCurrencies}} as |currency|>\n      {{currency.code}}\n    </PowerSelectMultiple>\n  </div>\n\n  {{#if this.saveTask.isIdle}}\n  \t<button class=\"btn btn-full\" type=\"button\" {{action this.save}}>{{t 'Save'}}</button>\n  {{else}}\n  \t<button class=\"btn btn-full loading\" type=\"button\"></button>\n  {{/if}}\n</div>\n", {"contents":"<div class=\"add-new-managers-fields\">\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Currencies'}}</span>\n    <PowerSelectMultiple\n            @searchEnabled={{true}}\n            @searchField=\"code\"\n            @options={{this.currencies}}\n            @selected={{this.selectedCurrencies}}\n            @placeholder=\"\"\n            @onChange={{this.selectCurrencies}} as |currency|>\n      {{currency.code}}\n    </PowerSelectMultiple>\n  </div>\n\n  {{#if this.saveTask.isIdle}}\n  \t<button class=\"btn btn-full\" type=\"button\" {{action this.save}}>{{t 'Save'}}</button>\n  {{else}}\n  \t<button class=\"btn btn-full loading\" type=\"button\"></button>\n  {{/if}}\n</div>\n","moduleName":"backoffice/components/modals/casino/settings/change-currency-category/sb-change-currency-category.hbs","parseOptions":{"srcName":"backoffice/components/modals/casino/settings/change-currency-category/sb-change-currency-category.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';

export default class ModalsCasinoSettingsChangeCurrencyCategorySbChangeCurrencyCategoryComponent extends Component {
  @service store;
  @service ajax;
  @service notifications;
  @service modalWindow;
  @service intl;

  @tracked currencies = this.store.peekAll('currency');
  @tracked selectedCurrencies = [];

  @action
  selectCurrencies(currencies) {
    this.selectedCurrencies = currencies;
  }

  @action
  save() {
    this.saveTask.perform()
  }

  @(task(function*() {
    const { category_ids, type } = this.modalWindow.data;

    yield this.ajax.post(`/casino/categories-currency`, {
      data: {
        currency_ids: this.selectedCurrencies.map(i => i.id),
        category_ids,
        type
      }
    }).then(() => {
      const categories = this.store.peekAll('casino-template-category').filter(i => i.checked);
      if (type === 'add') {
        categories.forEach(category => category.currencies.pushObjects(this.selectedCurrencies))
      } else {
        categories.forEach(category => category.currencies.removeObjects(this.selectedCurrencies))
      }

      this.modalWindow.closeModal();
      this.notifications.success(this.intl.t('Successfully Changed'), {
        autoClear: true
      });
    }).catch(({ payload }) => {
      let errors = payload.errors;
      if (errors) {
        errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true
          });
        })
      }
    });
  })) saveTask
}
