import Service from '@ember/service';
import {tracked} from "@glimmer/tracking";

export default class FileService extends Service {
  @tracked isDownloading = false;
  @tracked downloadProgress = 0;

  validateExtension(file, extenstion) {
    const extension = this.getFileExtension(file);
    return extension.toLowerCase() === extenstion.toLowerCase();
  }

  getFileExtension(file) {
    return file.name.split('.').pop();
  }

  generateCsvDownloadLink(content) {
    const blob = new Blob([content], { type: 'text/csv' });
    return URL.createObjectURL(blob);
  }
}
