import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class PartnerSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    accounts: {embedded: 'always'},
    sportsKpis: {embedded: 'always'},
    partnerConfig: {embedded: 'always'},
    mailConfig: {embedded: 'always'},
    languages: {embedded: 'always'},
    configLanguages: {embedded: 'always'},
    partnerDomainManager: {embedded: 'always'},
    group: {embedded: 'always'},
  }
}
