import {action, set, computed} from '@ember/object';
import MainFilter from "./main-filter";
import {task} from 'ember-concurrency';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import ScrollBooster from "scrollbooster";

export default class MainDashboard extends MainFilter {
  @service notifications;
  @service intl;

  @tracked ggrData;
  @tracked statsGraph = [];
  @tracked statsItems;
  @tracked currentBalance = {}
  @tracked expandedData = {};
  @tracked isExpanded = 0;

  DASHBOARD_INFO_TYPES = {
    currentBalance: 'current_balance'
  }

  get baseGraphTask() {
    return this.dashboardGraphTask;
  }

  get baseTask() {
    this.expandedData = {};
    this.isExpanded = 0;
    return this.ggrDataTask;
  }

  @computed('dashboardInfoTask.isIdle', 'dashboardInfoTask.performCount')
  get dashboardInfoTaskLoaded() {
    return this.dashboardInfoTask.isIdle && this.dashboardInfoTask.performCount >= 1;
  }

  extractCurrentBalance() {
    this.statsItems.forEach((statsItem) => {
      if(statsItem.name === this.DASHBOARD_INFO_TYPES.currentBalance) {
        return this.currentBalance = {...statsItem}
      }
    });
  }

  @action
  applyGraphCalendar(event, dateRangePicker) {
    set(this.graphFilters, 'graph_start', dateRangePicker.startDate.unix());
    set(this.graphFilters, 'graph_end', dateRangePicker.endDate.unix());
    this.router.transitionTo(this.baseRoute, {
      queryParams: this.graphFilters
    })
    this.expandedData = {};
    this.isExpanded = 0;
    return this.baseGraphTask.perform();
  }

  @action
  selectDateMode(element) {
    set(this.graphFilters, 'dateMode', element)
    this.router.transitionTo(this.baseRoute, {
      queryParams: this.graphFilters
    })
    return this.baseGraphTask.perform()
  }

  @action
  registerListener(element) {
    new ScrollBooster({
      viewport: element,
      content: element.querySelector('.scroll-content'),
      scrollMode: 'native',
      direction: 'horizontal',
      pointerDownPreventDefault: false,
    });
  }

  @(task(function* (params = false) {
    let data = {...params};
    this.graph_player_id !== undefined && this.graph_player_id ? data.player_id = this.graph_player_id : data.partner_id = parseInt(this.partner.selected_id);
    // this.graph_player_id ? data.currency_id = this.player.mainAccount.currency_id : data.currency_id = parseInt(this.partner.currency_id);
    yield this.ajax.request(`/get-${this.mainUrl}-dashboard`, {data: data}).then((retData) => {
      this.statsItems = retData;
      this.extractCurrentBalance();
    });
  }))dashboardInfoTask

  @(task(function* () {
    yield this.ajax.request(`/get-${this.mainUrl}-ggr`, {data: this.filters}).then((data) => {
      this.ggrData = data[this.dataAttribute]
      this.ggrData.meta = data.meta;
    }).catch((payload) => {
      let errors = payload.errors;
      if (errors) {
        errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true
          });
        })
      }
    });
  }))ggrDataTask

  @(task(function* () {
    return yield this.ajax.request(`/get-${this.mainUrl}-graph-data`, {data: this.graphFilters}).then((data) => {
      set(this.graphTitle, 'price', data['ggrAmount'])
      set(this.graphTitle, 'currency_symbol', data['currency_symbol'])
      return this.statsGraph = data;
    });
  }))dashboardGraphTask
}
