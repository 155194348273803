import ApplicationAdapter from './application';

export default class LocalLeagueAdapter extends ApplicationAdapter {
  urlForQuery(params) {
    if(params.is_bonus) {
      return this.host + '/bonusTemplates/bonuses/local-league';
    }
    if(params.is_dashboard) {
      return this.host + '/get-dashboard-local-leagues';
    }
    return this.host + '/get-local-league'
  }
}
