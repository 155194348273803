import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{yield}}", {"contents":"<GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{yield}}","moduleName":"backoffice/components/route-components/retail/administrators/details/sb-details.hbs","parseOptions":{"srcName":"backoffice/components/route-components/retail/administrators/details/sb-details.hbs"}});
import Component from '@glimmer/component';

export default class RouteComponentsRetailAdministratorsDetailsSbDetailsComponent extends Component {
  get menuItems() {
    return [
      {
        name: 'Details',
        definer: 'retail_administrators_key_details',
        linkTo: {
          path: 'retail.administrators.administrator.details',
        }
      },
      {
        name: 'Managers',
        definer: 'retail_managers',
        linkTo: {
          path: 'retail.administrators.administrator.managers'
        }
      },
      {
        name: 'Cashiers',
        definer: 'retail_cashiers',
        linkTo: {
          path: 'retail.administrators.administrator.cashiers'
        }
      },
      {
        name: 'Transaction History',
        definer: 'retail_administrators_key_transaction_history',
        linkTo: {
          path: 'retail.administrators.administrator.transaction-history'
        }
      }
    ];
  }
}
