import Model, {attr} from '@ember-data/model';

export default class MailTemplateModel extends Model {
	@attr('number') partner_id;
	@attr('string') key;
	@attr('string') title;
	@attr('string') message;
	@attr('string') message_title;
	@attr('image') image_url;
}
