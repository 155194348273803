import Controller from '@ember/controller';

export default class RiskManagementLimitsMarketsController extends Controller {
  queryParams = [
    'page',
    'perPage',
    'search'
  ];

  page = 1;
  perPage = 10;
  search;
}
