import Component from '@glimmer/component';
import {action} from '@ember/object';
import {tracked} from '@glimmer/tracking';
import {task} from 'ember-concurrency';
import {inject as service} from '@ember/service'
import {next} from '@ember/runloop';
import moment from 'moment';

export default class MainBanner extends Component {
  @service store;
  @service partner;

  @tracked viewType = '';
  @tracked isNew = false;
  @tracked selectedBanner = null;
  @tracked language_id;
  @tracked darkMode = 1;

  bannerUsageType = {
    sports: 'SPORTS',
    e_sports: 'E_SPORTS',
    right_section: 'RIGHT_SECTION',
    home_main: 'HOME_MAIN',
    home_categories: 'HOME_CATEGORIES',
    casino: 'CASINO',
    payment: 'PAYMENT',
    home_single: 'HOME_SINGLE',
    registration: 'REGISTRATION'
  }

  builderComponentNames = {
    SPORTS: 'route-components/sportsbook/banners/banner-build/sb-banner-builder',
    E_SPORTS: 'route-components/sportsbook/banners/banner-build/sb-banner-builder',
    RIGHT_SECTION: 'route-components/sportsbook/banners/banner-right-section-build/sb-banner-right-section-build',
    HOME_MAIN: 'route-components/sportsbook/banners/banner-right-section-build/sb-banner-right-section-build',
    HOME_CATEGORIES: 'route-components/sportsbook/banners/banner-right-section-build/sb-banner-right-section-build',
    CASINO: 'route-components/cms/banners/banner-casino/sb-banner-casino',
    PAYMENT: 'route-components/payment/banners/banner-build/sb-banner-build',
    HOME_SINGLE: 'route-components/sportsbook/banners/banner-right-section-build/sb-banner-right-section-build',
    REGISTRATION: 'route-components/sportsbook/banners/registration/sb-registration',
  }

  multyLanguages = {
    SPORTS: true,
    E_SPORTS: true,
    RIGHT_SECTION: true,
    HOME_MAIN: true,
    HOME_CATEGORIES: true,
    CASINO: true,
    PAYMENT: false,
    HOME_SINGLE: false,
    REGISTRATION: false,
  }

  get builderComponentName() {
    return this.builderComponentNames[this.usage_type];
  }

  get isMultyLanguages() {
    return this.multyLanguages[this.usage_type];
  }

  get languages() {
    return this.partner.selectedPartner?.languages;
  }

  get isSingle() {
    return this.usage_type === this.bannerUsageType.home_single;
  }

  get isRegistration() {
    return this.usage_type === this.bannerUsageType.registration;
  }

  get banners() {
    const usage_type = this.store.peekAll('banner-usage-type').find(i => i.alias === this.usage_type);
    if (!usage_type) return [];

    return this.store.peekAll(`${this.url}`).filter(v =>
      v.partner_id === this.partner.selected_id && v.is_dark === this.darkMode &&
      v.language_id === (this.isMultyLanguages ? this.language_id : null) &&
      v.usage_type_id == usage_type.id).slice().sort((a,b) => a.order - b.order);
  }

  @action
  setViewType(type) {
    this.viewType = type;
  }

  @action
  setDeviceType(type) {
    this.deviceType = type;
  }

  @action
  createNewBanner() {
    this.selectedBanner = this.store.createRecord(`${this.url}`, {
      language_id: this.isMultyLanguages ? this.language_id : null,
      usage_type_id: this.store.peekAll('banner-usage-type').find(i => i.alias === this.usage_type) && this.store.peekAll('banner-usage-type').find(i => i.alias === this.usage_type).id,
      banners_type: 30,
      is_dark: this.darkMode,
      expiration_date: moment().endOf('week').unix()
    });
  }

  @action
  setBanner(banner) {
    this.selectedBanner = null;
    next(() => {
      this.selectedBanner = banner;
    })
  }

  @action
  reorderItems(groupModel, itemModels) {
    itemModels.forEach((item, order) => {
      item.order = order;
      if (item.hasDirtyAttributes) {
        item.save();
      }
    });
  }

  @action
  toggleStyleMode() {
    this.darkMode = this.darkMode?0:1;
    if (this.darkMode) {
      document.documentElement.setAttribute("data-theme", 'dark');
    } else {
      document.documentElement.setAttribute("data-theme", 'light');
    }

    this.bannerTask.perform();
  }

  @action
  setLanguage(language_id) {
    this.language_id = language_id;
    this.bannerTask.perform();
  }

  @(task(function* () {
    yield this.store.query(`${this.url}`, {
      partner_id: this.partner.selected_id,
      language_id: this.isMultyLanguages ? this.language_id : undefined,
      usage_type: this.usage_type,
      include: 'link'
    })
      .then(banners => {
      this.selectedBanner = banners.filter(i => i.is_dark === this.darkMode).slice().sort((a,b) => a.order - b.order)[0];
      this.selectedBanner = banners.filter(i => i.is_dark === this.darkMode).slice().sort((a,b) => a.order - b.order)[0];
      if (!this.selectedBanner && (this.isSingle || this.isRegistration)) {
        this.createNewBanner();
      }
    })
  }))bannerTask
}
