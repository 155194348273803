import Model, {attr} from '@ember-data/model';

export default class LocalLeagueModel extends Model {
  @attr league_id;
  @attr sport_id;
  @attr short_league_id;
  @attr region_id;
  @attr name;
  @attr name_en;
}
