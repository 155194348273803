import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

export default class SegmentRuleModel extends Model {
  @attr('number', { defaultValue: null }) parent_id;
  @attr('number', { defaultValue: null }) segment_id;
  @attr('string', { defaultValue: null }) combinator;
  @attr('number', { defaultValue: null }) is_group;
  @attr('number', { defaultValue: null }) rule_data_id;
  @belongsTo('segment-rule-data', { async: false, inverse: null }) data;
  @hasMany('segment-rule', { async: true, inverse: 'parent' }) rules;
  @belongsTo('segment-rule', { async: false, inverse: 'rules' }) parent;

  @tracked isFirst = false;

  get isValidRule() {
    if(!this.isParentDeleted) {
      if(this.is_group && !this.children.length) {
        return false;
      }
      if(!this.is_group && !this.data?.value) {
        return false;
      }
    }
    return true;
  }

  get children() {
    return this.rules.filter((rule) => !rule.isDeleted);
  }

  get isParentDeleted() {
    return this.isDeleted || (!this.parent && !this.isFirst) || this.parent?.isParentDeleted;
  }

  get patterns() {
    let value = this.data?.value;
    if(this.data?.operationType.isDateRange) {
      value = this.data.valueDateRangeForPattern;
    }

    const pattern = this.data ? [
      `<code>${this.data?.condition?.name || '[]'}</code>`,
      `<code>${this.data?.operation?.value || '[]'}</code>`,
      `<code>${this.data?.item?.name || value || '[]'}</code>`
    ].join(' ') : '';

    const childPatterns = this.children.map((rule) => rule.patterns.trim()).join(' ');

    return [
      pattern,
      !this.is_group && this.combinator ? `<code>${this.combinator}</code>` : '',
      this.is_group ? (this.isFirst ? `${childPatterns || ''}` : `(${childPatterns || ''})`) : '',
      this.is_group ? `${this.combinator ? `<code>${this.combinator}</code>` : ''}` : '',
    ].join(' ');

    // return `${pattern} ${!this.is_group && this.combinator ? this.combinator : ''} ${this.is_group ? `(${childPatterns || ''}) ${this.combinator || ''}` : ''}`;
  }

  toJSON(hasChildren = false) {
    return {
      id: this.id,
      parent_id: this.parent_id,
      segment_id: this.segment_id,
      combinator: this.combinator,
      is_group: this.is_group,
      rule_data_id: this.rule_data_id,
      data: this.data?.toJSON() || null,
      ...(hasChildren ? { rules: this.rules.map((rule) => rule.toJSON(true)) } : {}),
    };
  }
}
