import Controller from '@ember/controller';

export default class RetailCashboxesIndexController extends Controller {
  queryParams = [
    'page',
    'perPage',
    'partner_id',
    'manager_id',
    'administrator_id',
    'search_value',
    'currency_id',
    'sort_name',
    'sort_type',
    'is_active'
  ];

  page = 1;
  perPage = 10;
  partner_id;
  manager_id;
  administrator_id;
  search_value;
  currency_id;
  sort_name;
  sort_type;
  is_active = 1;
}
