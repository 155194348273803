import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.menuItems}}\n  <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{/if}}\n{{yield}}\n", {"contents":"{{#if this.menuItems}}\n  <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{/if}}\n{{yield}}\n","moduleName":"backoffice/components/route-components/settings/users-roles/sb-users-roles.hbs","parseOptions":{"srcName":"backoffice/components/route-components/settings/users-roles/sb-users-roles.hbs"}});
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking'

export default class RouteComponentsSettingsUsersRolesSbUsersRolesComponent extends Component {
  @tracked menuItems = [
    {
      name: 'Users ',
      definer: 'settings_users_roles_key_users',
      linkTo: {
        path: 'settings.users-roles.users'
      },
    },
    {
      name: 'Roles',
      definer: 'settings_users_roles_key_roles',
      linkTo: {
        path: 'settings.users-roles.roles'
      }
    }
  ];
}
