import Model, {attr, belongsTo, hasMany} from '@ember-data/model';

export default class BetSystemModel extends Model {
  @attr parent_id;
  @attr type_id;
  @attr player_id;
  @attr status_id;
  @attr currency_id;
  @attr system_option;
  @attr stake;
  @attr win_amount;
  @attr calculate_date;
  @attr created_at;

  @belongsTo('player', { async: false, inverse: null }) player;
  @belongsTo('bet-type', { async: false, inverse: null }) betType;
  @belongsTo('currency', { async: false, inverse: null }) currency;
  @belongsTo('bet-status', { async: false, inverse: null }) betStatus;
  @hasMany('bet-event', { async: false, inverse: null }) betEvents;

  get isSystem() {
    return this.type_id == 3
  }

  get isSingle() {
    return this.type_id == 1
  }

  get isCashOut() {
    return this.status_id == 6
  }

  get isLost() {
    return this.status_id == 3
  }

  get totalOdd() {
    let totalOdd = 1;
    this.betEvents.forEach(event => {
      if (event.status_id !== 5) {
        totalOdd = totalOdd * event.odd;
      }
    });
    return totalOdd.toFixed(1);
  }
}
