import AuthenticatedRoute from "../authenticated";
import {inject as service} from '@ember/service';

export default class PaymentIntegrationRoute extends AuthenticatedRoute {
  @service partner;

  definer = 'payment_integration';

  model(model) {
    return model
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }
}
