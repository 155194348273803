import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{yield}}", {"contents":"<GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{yield}}","moduleName":"backoffice/components/route-components/cms/pages/sb-pages.hbs","parseOptions":{"srcName":"backoffice/components/route-components/cms/pages/sb-pages.hbs"}});
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';

export default class RouteComponentsCmsPagesSbPagesComponent extends Component {
  @tracked menuItems = [
    {
      name: 'Manual',
      definer: 'cms_pages',
      linkTo: {
        path: 'cms.pages.manual',
      }
    },
    {
      name: 'Static',
      definer: 'cms_pages',
      linkTo: {
        path: 'cms.pages.static',
      }
    }
  ];
}
