import AuthenticatedRoute from "../authenticated";

export default class BonusesWheelShopRoute extends AuthenticatedRoute {
  definer = 'bonuses_wheel_shop'
  activate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = true;
  }
}
