import ApplicationAdapter from './application';

export default class CountryAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/get-countries';
  }

  urlForFindAll() {
    return this.host + '/get-countries';
  }
}
