import Controller from '@ember/controller';
import moment from 'moment';

export default class StatsReportsByDepositWithdrawController extends Controller {
  queryParams = [
    'currency_ids',
    'partner_ids',
    'group_ids',
    'cashbox_id',
    'perPage',
    'payment_integration_id',
    'start',
    'page',
    'end',
    'administrator_id',
    'manager_id',
    'by',
    'payment_sphere',
  ];

  currency_ids;
  group_ids;
  perPage = 100;
  page = 1;
  by = 'currency';
  payment_sphere = 'online';
  partner_ids;
  cashbox_id;
  payment_integration_id;
  administrator_id;
  manager_id;
  start = moment().startOf('month').unix();
  end = moment().endOf('day').unix();
}
