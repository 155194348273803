import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.delete.isRunning}}\n    <button class=\"btn btn-full\" type=\"button\">\n        <GenericComponents::Loading::SbLoading/>\n    </button>\n{{else}} \n    <button class=\"btn btn-full\" type=\"button\" {{on 'click' (perform this.delete)}}>{{t 'Yes!'}}</button>\n{{/if}} ", {"contents":"{{#if this.delete.isRunning}}\n    <button class=\"btn btn-full\" type=\"button\">\n        <GenericComponents::Loading::SbLoading/>\n    </button>\n{{else}} \n    <button class=\"btn btn-full\" type=\"button\" {{on 'click' (perform this.delete)}}>{{t 'Yes!'}}</button>\n{{/if}} ","moduleName":"backoffice/components/modals/bonuses/wheel-templates/delete-wheel-template/sb-delete-wheel-template.hbs","parseOptions":{"srcName":"backoffice/components/modals/bonuses/wheel-templates/delete-wheel-template/sb-delete-wheel-template.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { filter } from '@ember/object/computed';

export default class ModalsBonusesWheelTemplatesDeleteWheelTemplateSbDeleteWheelTemplateComponent extends Component {
    @service store;
    @service modalWindow;
    @service eventTrigger;

    @tracked wheel = this.modalWindow.data.wheel;
    @tracked sectors = this.store.peekAll('wheel-sector');

    @filter('sectors', function(sector) {
        if (sector.wheel_template_id == this.wheel.id){
            return sector; 
        } 
    }) removeSectors;

    @(task(function* () {
        yield this.wheel.destroyRecord();
        this.removeSectors.map((sector)=> {
            this.store.unloadRecord(sector);
        }); 
        
        this.eventTrigger.trigger('delete-wheel')

    })) delete;
}
