import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"row-item-wrapper {{if this.showContent \"show\"}}\">\n  <div class=\"row-item\">\n    <div class=\"column1\">\n      <span>{{this.playerName}}</span>\n    </div>\n    <div class=\"column2\">\n      <span>{{@transaction.amount}}</span>\n    </div>\n      <div class=\"column3\">\n        <span class=\"text-green\">{{@transaction.account_type}}</span>\n      </div>\n    <div class=\"column4\">\n      <span>{{to-uppercase @transaction.documentType.title '_'}}</span>\n    </div>\n    <div class=\"column5\">\n      <span>{{@transaction.currency.code}}</span>\n\n    </div>\n    <div class=\"column6\">\n      <span>{{moment-format (unix @transaction.created_at) 'MMM DD HH:mm'}}</span>\n    </div>\n  </div>\n</div>\n{{yield}}", {"contents":"<div class=\"row-item-wrapper {{if this.showContent \"show\"}}\">\n  <div class=\"row-item\">\n    <div class=\"column1\">\n      <span>{{this.playerName}}</span>\n    </div>\n    <div class=\"column2\">\n      <span>{{@transaction.amount}}</span>\n    </div>\n      <div class=\"column3\">\n        <span class=\"text-green\">{{@transaction.account_type}}</span>\n      </div>\n    <div class=\"column4\">\n      <span>{{to-uppercase @transaction.documentType.title '_'}}</span>\n    </div>\n    <div class=\"column5\">\n      <span>{{@transaction.currency.code}}</span>\n\n    </div>\n    <div class=\"column6\">\n      <span>{{moment-format (unix @transaction.created_at) 'MMM DD HH:mm'}}</span>\n    </div>\n  </div>\n</div>\n{{yield}}","moduleName":"backoffice/components/route-components/affiliates/clients/profile/transaction/transaction-table/transaction-table-content/sb-transaction-table-content.hbs","parseOptions":{"srcName":"backoffice/components/route-components/affiliates/clients/profile/transaction/transaction-table/transaction-table-content/sb-transaction-table-content.hbs"}});
import Component from '@glimmer/component';

export default class RouteComponentsAffiliatesClientsProfileTransactionTransactionTableTransactionTableContentSbTransactionTableContentComponent extends Component {
  get playerName() {
    let player = this.args.transaction.player;
    return player.get('fullName') !== ' ' ? player.get('fullName') : (player.get('username') ? player.get('username') : player.get('id'));
  }
}
