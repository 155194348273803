import Model, {attr, belongsTo} from '@ember-data/model';

export default class FilteringPlayerModel extends Model {
  @attr title;
  @attr filters;
  @attr created_at;
  @attr updated_at;

  @belongsTo('user', { async: false, inverse: null }) user;
}
