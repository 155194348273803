import Model, {attr, belongsTo} from '@ember-data/model';

export default class PromotionByLanguageModel extends Model {
  @attr promotion_id;
  @attr language_id;
  @attr description;
  @attr body;
  @attr('image') image;
  @attr url;
  @attr created_at;

  @belongsTo('language', {async: false, inverse: null}) language;
}
