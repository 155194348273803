import {helper} from '@ember/component/helper';

export default helper(function loop([min, max, step]) {
  let numbers = [];

  let i;
  for (i = min; i <= max; i += step) {
    numbers.push(i);
  }

  return numbers;
});
