import Model, {attr, belongsTo} from '@ember-data/model';

export default class CashierDepositPlayerModel extends Model {
  @attr player_id;
  @attr cashbox_id;
  @attr('number') account_id;
  @attr('number') currency_id;
  @attr limit;
  @attr created_at;
  @attr updated_at;
  @attr created_by;
  @attr updated_by;

  @attr retail;

  @attr('string', {defaultValue: 'main'}) walletType;

  @belongsTo('player', { async: false, inverse: null }) player;
  @belongsTo('cashbox', { async: false, inverse: null }) cashbox;
  @belongsTo('user', { async: false, inverse: null }) cashier;
}

