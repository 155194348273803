import AuthenticatedRoute from "../../authenticated";
import {inject as service} from "@ember/service";

export default class PaymentSettingTemplateRoute extends AuthenticatedRoute {
  @service store
  definer = 'payment_setting';

  model() {
    this.store.unloadAll('payment-template-category');
    this.store.unloadAll('payment-template-deposit-method');
    this.store.unloadAll('payment-template-withdraw-method');

    let template_id = this.paramsFor('payment/setting/template').template_id;

    return this.store.query('payment-template-category', {template_id})
  }
}
