import EmberObject from '@ember/object';
import {buildValidations, validator} from "ember-cp-validations";
import {tracked} from '@glimmer/tracking';

const prizeValidations = buildValidations({
  'reward': {
    description: 'Prize',
    validators: [
      validator('presence', true),
      validator('number', {
        integer: true,
        positive: true,
        allowString: true,
        message: 'Number must be an integer'
      }),
    ],
  }
});

export default class PrizeObject extends EmberObject.extend(prizeValidations) {
  @tracked reward = '';
  @tracked place = '';
}
