import ApplicationAdapter from './application';

export default class NavigationGroupAdapter extends ApplicationAdapter {
  urlForCreateRecord() {
    return this.host + '/navigations/navigation-groups';
  }

  urlForUpdateRecord(id) {
    return this.host + '/navigations/navigation-groups/update-group/' + id;
  }

  urlForDeleteRecord(id) {
    return this.host + `/navigations/navigation-groups/${id}`;
  }
}
