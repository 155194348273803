import Controller from '@ember/controller';
import {inject as service} from '@ember/service';

export default class RetailTransactionsController extends Controller {
  @service router;

  queryParams = [
    'page',
    'perPage',
    'partner_id',
    'currency_id',
    'administrator_id',
    'sort_name',
    'sort_type',
    'cashier_id',
    'manager_id',
    'filterOption',
  ];

  page = 1;
  perPage = 10;
  currency_id;
  administrator_id;
  partner_id;
  sort_name;
  sort_type;
  cashier_id;
  manager_id;
  filterOption = this.router.currentRouteName === 'retail.wallets.transactions' ? undefined : 'fromMe';
}
