import ApplicationAdapter from './application';

export default class SignupTypeAdapter extends ApplicationAdapter {
  urlForFindAll() {
    return this.host + '/settings/partner-configs/signup-types'
  }

  urlForQueryRecord() {
    return this.host + '/settings/partner-configs/signup-types'
  }
}
