import ApplicationAdapter from './application';

export default class CasinoBannerAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/casinoBanners/banners'
  }

  urlForCreateRecord() {
    return this.host + '/casinoBanners/banners'
  }

  urlForDeleteRecord(id) {
    return this.host + `/casinoBanners/banners/${id}`;
  }

  urlForUpdateRecord(id) {
    return this.host + `/casinoBanners/banners/${id}`;
  }
}
