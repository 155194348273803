import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class CasinoBetSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    player: {embedded: 'always'},
    casinoGameList: {embedded: 'always'},
    casinoGameProvider: {embedded: 'always'},
    currency: {embedded: 'always'}
  };
}
