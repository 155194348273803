import ApplicationAdapter from './application';

export default class PaymentIntegrationAdapter extends ApplicationAdapter {
  urlForQuery(param) {
    if (param.for_report) {
      return this.host + '/reports/payment-integration'
    }
    if (param.for_affiliate) {
      return this.host + '/affiliate/payment-integration'
    }
    return this.host + '/payment/integrations'
  }

  urlForFindAll(modelName, params) {
    if (params.adapterOptions.transaction_history) {
      return this.host + '/transactions/payment-integrations'
    }
    return this.host + '/payment-integrations'
  }
}
