import Service from "@ember/service";
import {tracked} from "@glimmer/tracking";
import {computed, action} from "@ember/object";

export default class FileUploadService extends Service {
  @tracked testParam = 'This is a test param showing that this service is working.';
  @tracked files = [];
  @tracked file = null;

  @computed('file')
  get singleFile() {
    return this.file;
  }

  @action
  addFile(file) {
    this.files = [...this.files, file];
  }

  addSingleFile(file) {
    this.file = file;
  }

  @action
  removeFile(file) {
    this.files = this.files.filter(f => f !== file);
  }
}
