import Model, {attr,belongsTo,hasMany} from '@ember-data/model';

export default class WheelSectorModel extends Model {
  @attr() wheel_template_id;
  @attr('string') background_color;
  @attr('string') color;
  @attr('string') title;
  @attr('string') icon;
  @attr('string') percent;
  @attr() win_message;
  @attr() bonus_id;
  @attr() coin_amount;
  @attr('number', {defaultValue: 1}) wheel_sector_type_id;
  @attr('string') title_or_icon;

  @belongsTo('bonus', { async: false, inverse: null }) bonus;
  @hasMany('wheel-sector-currency', { async: false, inverse: null }) wheelSectorCurrencies;
}
