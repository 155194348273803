import Model, {attr, hasMany,belongsTo} from '@ember-data/model';

export default class RoleModel extends Model {
  @attr partner_id;
  @attr title;
  @attr status;
  @attr role_order;
  @attr role_type_id;
  @attr created_at;
  @attr created_by;

  @belongsTo('role-type', { async: false, inverse: null }) roleType;
  // @hasMany('users', { async: false, inverse: null }) users;
  @hasMany('role-permission', { async: false, inverse: null }) rolePermissions;
}
