import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"notification-config-content\">\n  <div class=\"field-box fields-group\">\n    <span class=\"label\">{{t 'Percent'}}</span>\n    <div class=\"input-box\">\n      <input type=\"number\" onchange={{action this.changeValue 'limits_percent'}} value=\"{{@userNotificationConfig.limits_percent}}\">\n    </div>\n  </div>\n</div>\n{{yield}}", {"contents":"<div class=\"notification-config-content\">\n  <div class=\"field-box fields-group\">\n    <span class=\"label\">{{t 'Percent'}}</span>\n    <div class=\"input-box\">\n      <input type=\"number\" onchange={{action this.changeValue 'limits_percent'}} value=\"{{@userNotificationConfig.limits_percent}}\">\n    </div>\n  </div>\n</div>\n{{yield}}","moduleName":"backoffice/components/route-components/settings/admin-panel/limits/sb-limits.hbs","parseOptions":{"srcName":"backoffice/components/route-components/settings/admin-panel/limits/sb-limits.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class RouteComponentsSettingsAdminPanelLimitsSbLimitsComponent extends Component {
	@action
	changeValue(key) {
		this.args.userNotificationConfig[key] = parseInt(event.target.value);
		this.args.update()
	}
}
