import AuthenticatedRoute from '../../authenticated';

export default class SettingsMarketingSiteMessagesRoute extends AuthenticatedRoute {
  definer = 'settings_marketing_site_messages'

  activate() {
    this.partner.showPartnerSelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
  }
}
