import AuthenticatedRoute from '../../../authenticated';

export default class RiskManagementPlayersProfileTransactionRoute extends AuthenticatedRoute {
  definer = 'r_m_players_key_transaction'

  queryParams = {
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    sort_name: {
      refreshModel: true
    },
    sort_type: {
      refreshModel: true
    },
    type_id: {
      refreshModel: true
    },
    account_type: {
      refreshModel: true
    },
    amount: {
      refreshModel: true
    },
    start: {
      refreshModel: true
    },
    end: {
      refreshModel: true
    }
  };

  model(params) {
    params.player_id = this.paramsFor('risk-management.players.profile').player_id;
    return params
  }

  activate() {
    this.partner.showCurrentPlayerCurrencies = false;
    this.partner.showPartnerCurrencySelect = false;
    this.partner.showIsEur = false;
  }

  deactivate() {
    this.partner.showCurrentPlayerCurrencies = false;
    this.partner.showPartnerCurrencySelect = false;
    this.partner.showIsEur = false;
  }
}
