import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.menuItems}}\n  <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{/if}}\n<div class=\"main-info-container settings-partners\">\n  <div class=\"top-section between-xs row\">\n    <button\n      class=\"btn small\"\n      style=\"float: right\"\n      onclick={{action this.openModal}}\n    >\n      {{t 'Add Group'}}\n    </button>\n  </div>\n  <div class=\"table-content\">\n    {{#if this.getGroupTask.isIdle}}\n      <RouteComponents::Settings::Partners::Groups::GroupsTable::SbGroupsTable\n        @groups={{this.groups}}\n      />\n    {{else}}\n      <GenericComponents::Loading::SbLoading/>\n    {{/if}}\n  </div>\n</div>\n{{yield}}", {"contents":"{{#if this.menuItems}}\n  <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{/if}}\n<div class=\"main-info-container settings-partners\">\n  <div class=\"top-section between-xs row\">\n    <button\n      class=\"btn small\"\n      style=\"float: right\"\n      onclick={{action this.openModal}}\n    >\n      {{t 'Add Group'}}\n    </button>\n  </div>\n  <div class=\"table-content\">\n    {{#if this.getGroupTask.isIdle}}\n      <RouteComponents::Settings::Partners::Groups::GroupsTable::SbGroupsTable\n        @groups={{this.groups}}\n      />\n    {{else}}\n      <GenericComponents::Loading::SbLoading/>\n    {{/if}}\n  </div>\n</div>\n{{yield}}","moduleName":"backoffice/components/route-components/settings/partners/groups/sb-group.hbs","parseOptions":{"srcName":"backoffice/components/route-components/settings/partners/groups/sb-group.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {task} from 'ember-concurrency';
import {action} from '@ember/object';

export default class RouteComponentsSettingsPartnersGroupsSbGroupComponent extends Component {
  @service store;
  @service modalWindow;
  @service eventTrigger;

  @tracked groups;
  @tracked newGroup = this.store.createRecord('group');

  @tracked menuItems = [
    {
      name: 'Partners',
      definer: 'settings_partners',
      linkTo: {
        path: 'settings.partners',
      },
    },
    {
      name: 'Groups',
      definer: 'settings_partners',
      linkTo: {
        path: 'settings.partners.groups',
      },
    },
  ];

  groupModalData = {title: 'Add group'};

  constructor() {
    super(...arguments);

    this.getGroupTask.perform();

    this.eventTrigger.on('create-group', (group) => {
      this.groups.pushObject(group);
    });

    this.eventTrigger.on('delete-group', (group) => {
      this.groups = this.groups.filter(function (el) {
        return el.id != group.id;
      });
    });
  }

  @action
  openModal() {
    const component = 'settings/partner/group/create/add-group';
    this.modalWindow.open(component, this.groupModalData);
  }

  @task(function* () {
    let groups = yield this.store.query('group', {});
    this.groups = groups.slice();
  })
  getGroupTask;
}
