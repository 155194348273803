import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\" {{did-insert this.onDidInsert}}>\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Group ID'}}</span>\n    <Input type=\"text\" @value={{this.group.external_group_id}}/>\n  </div>\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Group title'}}</span>\n    <Input type=\"text\" @value={{this.group.title}}/>\n  </div>\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Group Description'}}</span>\n    <Input type=\"text\" @value={{this.group.description}}/>\n  </div>\n  {{#unless this.createGroupTask.isRunning}}\n    <button class=\"btn btn-basic btn-full\" disabled={{false}} {{action 'saveGroup'}}>{{t 'Save'}}</button>\n  {{else}}\n    <button class=\"btn btn-basic btn-full loading dark\"></button>\n  {{/unless}}\n</div>\n{{yield}}", {"contents":"<div class=\"add-new-managers-fields\" {{did-insert this.onDidInsert}}>\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Group ID'}}</span>\n    <Input type=\"text\" @value={{this.group.external_group_id}}/>\n  </div>\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Group title'}}</span>\n    <Input type=\"text\" @value={{this.group.title}}/>\n  </div>\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Group Description'}}</span>\n    <Input type=\"text\" @value={{this.group.description}}/>\n  </div>\n  {{#unless this.createGroupTask.isRunning}}\n    <button class=\"btn btn-basic btn-full\" disabled={{false}} {{action 'saveGroup'}}>{{t 'Save'}}</button>\n  {{else}}\n    <button class=\"btn btn-basic btn-full loading dark\"></button>\n  {{/unless}}\n</div>\n{{yield}}","moduleName":"backoffice/components/modals/settings/partner/group/create/add-group.hbs","parseOptions":{"srcName":"backoffice/components/modals/settings/partner/group/create/add-group.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';

export default class ModalsSettingsPartnerGroupCreateAddGroupComponent extends Component {
  @service store;
  @service modalWindow;
  @service notifications;
  @service intl;
  @service eventTrigger;

  @tracked group;

  @action
  onDidInsert() {
    this.group = this.store.createRecord('group');
  }

  @action
  saveGroup() {
    this.createGroupTask.perform();
  }

  @task(function* () {
    if (!this.group.title) {
      this.notifications.error(this.intl.t('Group title is required'), {
        autoClear: true,
      });
      return;
    }

    yield this.group
      .save()
      .then((group) => {
        this.modalWindow.closeModal();
        this.notifications.success(this.intl.t('Group create successfully'), {
          autoClear: true,
        });
        this.eventTrigger.trigger('create-group', group);
      })
      .catch((response) => {
        this.notifications.error(response, {
          autoClear: true,
        });
      });
  })
  createGroupTask;
}
