import {inject as service} from '@ember/service';
import AjaxServiceBase from 'ember-ajax/services/ajax';
import {computed} from '@ember/object';
import {storageFor} from 'ember-local-storage';

export default class AjaxService extends AjaxServiceBase {
  @service session;
  @service globalVars;
  @service partner;

  @storageFor('language') lngParams;
  @storageFor('partner') partnerStorage;

  host = this.globalVars.url.admin_api;

  contentType = 'application/json; charset=utf-8';

  isSuccess(status, headers, payload) {
    let isSuccess = super.isSuccess(...arguments);
    if (isSuccess && payload.status && payload) {
      return super.isSuccess(payload.status);
    }
    return isSuccess;
  }

  @computed('lngParams.currentLanguage', 'partnerStorage.isEurOn', 'session.data.authenticated.access_token', 'session.isAuthenticated')
  get headers() {
    let headers = {};
    headers['language'] = this.lngParams.get('currentLanguage');
    headers['Time-zone'] = Intl.DateTimeFormat().resolvedOptions().timeZone ?? "Asia/Yerevan";
    headers['In-Eur'] = this.partnerStorage.get('isEurOn');
    if (this.session.isAuthenticated) {
      headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;
    }
    headers['ip'] = window.ip
    return headers;
  }

  requestRawText(url, options) {
    options = options || {};
    options.headers = this.headers;
    options.dataType = 'text';

    return this.raw(url, options);
  }
}
