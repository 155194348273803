import AuthenticatedRoute from '../authenticated';

export default class CasinoFreeSpinsUsageRoute extends AuthenticatedRoute {
  definer = 'casino_free_spin'

  queryParams = {
    page: {refreshModel: true},
    perPage: {refreshModel: true},
    status: {refreshModel: true},
    game_code: {refreshModel: true},
    player_id: {refreshModel: true},
    start: {refreshModel: true},
    end: {refreshModel: true},
    sort_name: {refreshModel: true},
    sort_type: {refreshModel: true},
  };

  activate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
    this.partner.showIsEur = false;
    this.partner.toggleIsEur(false);
    this.partner.allCurrencyOption = true;
    this.partner.showIsBonusSwitcher = false;
  }

  deactivate() {
    this.partner.allCurrencyOption = false;
    this.partner.showIsEur = true;
    this.partner.showPartnerCurrencySelect = false;
    this.partner.showPartnerSelect = false;
    this.partner.showIsBonusSwitcher = false;
  }

  model(params) {
    return params
  }
}
