import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"field-wrapper\">\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Partners'}}</span>\n    <div class=\"select-box\">\n      <select name=\"\" id=\"\" onchange={{action this.selectPartnerToDuplicate value=\"target.value\"}}>\n        {{#each this.partners as |partner|}}\n          <option value=\"{{partner.id}}\">{{partner.title}}</option>\n        {{/each}}\n      </select>\n    </div>\n  </div>\n  <button class=\"btn btn-full\" type=\"button\" {{on 'click' this.partnerToDuplicate}}>{{t 'Save'}}</button>\n</div>\n{{yield}}", {"contents":"<div class=\"field-wrapper\">\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Partners'}}</span>\n    <div class=\"select-box\">\n      <select name=\"\" id=\"\" onchange={{action this.selectPartnerToDuplicate value=\"target.value\"}}>\n        {{#each this.partners as |partner|}}\n          <option value=\"{{partner.id}}\">{{partner.title}}</option>\n        {{/each}}\n      </select>\n    </div>\n  </div>\n  <button class=\"btn btn-full\" type=\"button\" {{on 'click' this.partnerToDuplicate}}>{{t 'Save'}}</button>\n</div>\n{{yield}}","moduleName":"backoffice/components/modals/right-section-banner/duplicate.hbs","parseOptions":{"srcName":"backoffice/components/modals/right-section-banner/duplicate.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service'
import {action} from '@ember/object';
import {tracked} from '@glimmer/tracking';
import {storageFor} from 'ember-local-storage';

export default class ModalsRightSectionBannerDuplicateComponent extends Component {
  @service modalWindow;
  @service store;
  @service partner;
  @service intl;
  @service notifications;
  @tracked selectedPartner;
  @storageFor('partner') partnerStorage

  get partners() {
    return this.partner.all.filter(item => parseInt(item.id) !== parseInt(this.partnerStorage.get('selected')));
  }

  @action
  selectPartnerToDuplicate(partner_id) {
    this.selectedPartner = partner_id;
  }

  @action
  partnerToDuplicate() {

    let duplicateBanner = this.store.createRecord('right-section-banner', {})
    let oldBanner = this.modalWindow.data.banner;
    duplicateBanner.title = oldBanner.title;
    duplicateBanner.image = oldBanner.image;
    duplicateBanner.status = oldBanner.status;
    duplicateBanner.link = oldBanner.link;
    duplicateBanner.partner_id = this.selectedPartner;
    duplicateBanner.save().then(() => {
      this.notifications.success(this.intl.t('Dublicate to another Partner'), {
        autoClear: true
      });
      this.modalWindow.closeModal()
    }).catch(payload => {
      payload.errors.forEach((error) => {
        this.notifications.error(error, {
          autoClear: true
        });
      })
    })
  }
}
