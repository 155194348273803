import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';

export default class PaymentBannersRoute extends Route {
  @service partner;

  definer = 'payment_banners';

  activate() {
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerCurrencySelect = true;
  }
}
