import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class PaymentMethodSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    provider: {embedded: 'always'},
    method: {embedded: 'always'},
    merchant_configuration: {embedded: 'always'}
  }

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    let globalIdCounter = 1;

    const updatedData = payload.flatMap((item) => {
      return item.merchant_configuration.map((merchant) => {
        const {merchant_id, ...rest} = merchant;

        const updatedSegments = merchant.tags.map((segment, index) => {
          return {id: index + 1, ...segment};
        });

        const updatedCountries = merchant.countries.map((country, index) => {
          return {id: index + 1, ...country};
        });

        return {
          id: globalIdCounter++,
          merchant_id: merchant_id,
          partner_name: merchant.merchant.name,
          partner_token: merchant.merchant.token,
          provider: item.provider,
          method: item.method,
          type: item.type,
          ...rest,
          active: item.active && merchant.active,
          activeTemplate: item.active,
          merchantActive: merchant.active,
          tags: updatedSegments,
          countries: updatedCountries,
          configuration: merchant.configuration
        };
      });
    });

    const newPayload = { paymentMethods: updatedData };

    return super.normalizeResponse(store, primaryModelClass, newPayload, id, requestType);
  }
}
