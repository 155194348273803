import Model, {attr, hasMany} from '@ember-data/model';
import {buildValidations, validator} from 'ember-cp-validations';
import {computed} from '@ember/object';
import {reads} from '@ember/object/computed';
import {tracked} from '@glimmer/tracking';

const Validations = buildValidations({
  value: {
    description: 'From Value',
    validators: [
      validator('presence', true)
    ],
  },
  until_value: {
    description: 'To Value',
    validators: [
      validator('presence', true),
      validator('number', {gt: computed('model.value', function () {
          return this.model.value;
        })})
    ],
  },
  percent: {
    description: 'Percent',
    validators: [
      validator('presence', true),
      validator('number', {gt: 0, message: 'percentage or #of spins must be greater than 0'})
    ],
    disabled: reads('model.free_spin')
  },
  free_spin: {
    description: 'Free Spin',
    validators: [
      validator('presence', true),
      validator('number', {gt: 0, message: '#of spins or percentage must be greater than 0'})
    ],
    disabled: reads('model.percent')
  }
});
export default class BonusProgressiveModel extends Model.extend(Validations) {
  @attr('number') parent_id;
  @attr('number') bonus_id;
  @attr('number') value;
  @attr('number') until_value;
  @attr('number') free_spin;
  @attr('number', { defaultValue: 0 }) percent;
  @attr('number') created_at;
  @attr('number') updated_at;

  @tracked errorMessage = undefined;
  @tracked untilErrorMessage = undefined;

  @hasMany('bonus-progressive-currency', {async: false, inverse: null}) bonusProgressiveCurrencies
}
