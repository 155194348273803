import Controller from '@ember/controller';

export default class SettingsSegmentIndexController extends Controller {
  queryParams = ['name', 'status', 'frequency_type', 'for_payment', 'type'];

  name = '';
  status = 1;
  frequency_type = 0;
  for_payment = undefined;
  type = 0;
}
