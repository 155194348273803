import Model, {attr, belongsTo, hasMany} from '@ember-data/model';

export default class AdminDocumentModel extends Model {
  @attr amount
  @attr currency_id
  @attr type_id
  @attr cashbox_id
  @attr user_id
  @attr user_balance
  @attr db_type
  @attr account_type
  @attr operation_id
  @attr created_at
  @attr updated_at

  @belongsTo('admin-document-type', { async: false, inverse: null }) adminDocumentType;
  @belongsTo('user', { async: false, inverse: null }) user;
  @belongsTo('admin-deposit', { async: false, inverse: null }) adminDeposit;
  @belongsTo('currency', { async: false, inverse: null }) currency;
  @hasMany('admin-transaction', { async: false, inverse: null }) adminTransactions;

}
