import Model, {attr,belongsTo} from '@ember-data/model';

export default class AffiliateSettingModel extends Model {
  @attr('number') user_id
  @attr('number') parent_id
  @attr('number') currency_id
  @attr percent
  @attr balance
  @attr calculate_date

  @belongsTo('user', { async: false, inverse: null }) user
  @belongsTo('currency', { async: false, inverse: null }) currency
}
