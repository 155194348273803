import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class={{this.className}}>\n  <select\n    {{did-insert this.initialize}}\n    onchange={{this.onChange}}\n    disabled={{if this.disabled 'disabled'}}\n    size={{@size}}\n    multiple={{@multiple}}\n  >\n    {{yield}}\n\n    {{#each this.localOptions as |localOption|}}\n      <option value={{localOption.value}}>\n        {{localOption.title}}\n      </option>\n    {{/each}}\n  </select>\n\n  {{#unless (or @size @multiple)}}\n    <i class=\"sb-icon sb-triangledown\"></i>\n  {{/unless}}\n</div>\n", {"contents":"<div class={{this.className}}>\n  <select\n    {{did-insert this.initialize}}\n    onchange={{this.onChange}}\n    disabled={{if this.disabled 'disabled'}}\n    size={{@size}}\n    multiple={{@multiple}}\n  >\n    {{yield}}\n\n    {{#each this.localOptions as |localOption|}}\n      <option value={{localOption.value}}>\n        {{localOption.title}}\n      </option>\n    {{/each}}\n  </select>\n\n  {{#unless (or @size @multiple)}}\n    <i class=\"sb-icon sb-triangledown\"></i>\n  {{/unless}}\n</div>\n","moduleName":"backoffice/components/generic-components/select/sb-select.hbs","parseOptions":{"srcName":"backoffice/components/generic-components/select/sb-select.hbs"}});
import Component from '@glimmer/component';
import {action, computed} from '@ember/object';
import {tracked} from '@glimmer/tracking';
import {next} from '@ember/runloop';

export default class GenericComponentsSelectSbSelectComponent extends Component {
  mainCssClass = 'select-box'

  @tracked selectEl = null

  get className() {
    let classes = [this.mainCssClass];

    if (this.args.className) {
      classes.push(this.args.className)
    }

    if (this.args.loading) {
      classes.push(`${this.mainCssClass}_loading`)
    }

    if (this.args.disabled) {
      classes.push(`${this.mainCssClass}_disabled`)
    }

    if (this.args.multiple) {
      classes.push(`${this.mainCssClass}_multiple`)
    }

    if (this.args.size) {
      classes.push(`${this.mainCssClass}_with_size`)
    }

    return classes.join(' ')
  }

  get disabled() {
    return this.args.disabled || this.args.loading;
  }

  @computed('args.options', 'args.selected.length', 'selectEl.options')
  get localOptions() {
    if (this.selectEl) {
      next(() => {
        Array
          .from(this.selectEl.options)
          .forEach(option => {
            if (typeof this.args.selected === 'string') {
              option.selected = option.value === this.args.selected;
            } else if (typeof this.args.selected === 'number') {
              option.selected = `${option.value}` === `${this.args.selected}`;
            } else if (Array.isArray(this.args.selected)) {
              option.selected = this.args.selected.length
                ? this.args.selected.some(item => `${item}` === `${option.value}`)
                : option.value === ''
            }
          });
      })
    }

    return this.args.options
  }

  @action
  initialize(element) {
    this.selectEl = element
  }

  @action
  onChange(event) {
    if (typeof this.args.onChange === 'function' && !this.args.multiple) {
      this.args.onChange(
        event,
        event.target.value === 'null'
          ? null
          : event.target.value,
      );
    } else if (typeof this.args.onChange === 'function' && this.args.multiple) {
      let value = Array
          .from(event.target.options)
          .filter(option => option.selected)
          .map(option => option.value)

      if (value.includes('')) {
        value = []
      }

      this.args.onChange(event, value)
    }
  }
}
