import Controller from '@ember/controller';

export default class RetailWalletsTransactionsController extends Controller {
  queryParams = [
    'page',
    'perPage',
    'partner_id',
    'sort_name',
    'sort_type',
  ];

  page = 1;
  perPage = 10;
  partner_id;
  sort_name = 'id';
  sort_type = 3;
}
