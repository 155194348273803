import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\">\n  <form onsubmit={{action \"update\"}} class=\"form-inline pull-right\">\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Title'}}</span>\n      <Input type=\"text\" @value={{this.bonus.title}}/>\n    </div>\n    {{#unless this.updateTask.isRunning}}\n      <button class=\"btn btn-basic btn-full\" type=\"submit\">{{t 'Edit'}}</button>\n    {{else}}\n      <button class=\"btn btn-basic btn-full loading dark\" type=\"submit\"></button>\n    {{/unless}}\n  </form>\n</div>\n{{yield}}", {"contents":"<div class=\"add-new-managers-fields\">\n  <form onsubmit={{action \"update\"}} class=\"form-inline pull-right\">\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Title'}}</span>\n      <Input type=\"text\" @value={{this.bonus.title}}/>\n    </div>\n    {{#unless this.updateTask.isRunning}}\n      <button class=\"btn btn-basic btn-full\" type=\"submit\">{{t 'Edit'}}</button>\n    {{else}}\n      <button class=\"btn btn-basic btn-full loading dark\" type=\"submit\"></button>\n    {{/unless}}\n  </form>\n</div>\n{{yield}}","moduleName":"backoffice/components/modals/bonuses/update-bonus/sb-update-bonus.hbs","parseOptions":{"srcName":"backoffice/components/modals/bonuses/update-bonus/sb-update-bonus.hbs"}});
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking'
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency';

export default class ModalsBonusesUpdateBonusSbUpdateBonusComponent extends Component {
  @service modalWindow;
  @service bonusService;
  @service partner;
  @service store;

  @tracked bonus = this.modalWindow.data.bonus;
  @tracked title;

  constructor() {
    super(...arguments);
    this.modalWindow.afterCloseModel = this.bonus
  }

  @action
  update(e) {
    e.preventDefault();
    e.stopPropagation();
    this.updateTask.perform();
  }

  @(task(function* () {
    yield this.bonus.save().then(() => {
      this.modalWindow.closeModal()
    });
  }))updateTask
}
