import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class CashboxSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    manager: {embedded: 'always'},
    adminAccounts: {embedded: 'always'},
    freezeAndMainAccounts: {embedded: 'always'},
    mainAdminAccounts: {embedded: 'always'},
    
    // manager: { deserialize: 'records' },
    // adminAccounts: { deserialize: 'records' },
    // freezeAndMainAccounts: { deserialize: 'records' },
    // mainAdminAccounts: { deserialize: 'records' }
  };
}
