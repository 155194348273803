import ApplicationAdapter from './application';

export default class MessagesTemplateAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/marketing/site-messages'
  }

  urlForCreateRecord() {
    return this.host + '/marketing/site-messages';
  }

  urlForUpdateRecord(id) {
    return this.host + `/marketing/site-messages/${id}`;
  }

  urlForDeleteRecord(id) {
    return this.host + `/marketing/site-messages/${id}`;
  }
}
