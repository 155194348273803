import AuthenticatedRoute from '../authenticated';

export default class CmsLogoStylesRoute extends AuthenticatedRoute {
  definer = 'cms_logo_styles'

  activate() {
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerCurrencySelect = true;
  }
}
