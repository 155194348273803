import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class UserNotifyService extends Service {
  @service globalVars;
  @service currentUser;
  @service store;

  @tracked mainWithdrawRequestsCount = 0;
  @tracked adminWithdrawRequestsCount = 0;

  @tracked userNotificationConfig = this.store.peekRecord('user-notification-config', this.currentUser.user.userNotificationConfig.get('id'));

  get documentsCount() {
    return this.store.peekAll('notification-document').length;
  }
  get adminDepositsCount() {
    return this.store.peekAll('notification-admin-deposit').length;
  }

  get notificationCount() {
    return this.documentsCount + this.adminDepositsCount + this.mainWithdrawRequestsCount + this.adminWithdrawRequestsCount;
  }

  reload() {
    this.store.unloadAll('notification-withdraw-request');
    this.store.unloadAll('notification-document');
    this.store.unloadAll('notification-admin-deposits');

    this.store.findAll('notification-document');
    this.store.findAll('notification-admin-deposit');
  }
}
