import RESTSerializer, { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

export default class CasinoIntegrationSettingSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    integration: { embedded: 'always' },
  };

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (requestType === 'queryRecord') {
      if(payload['CasinoIntegrationSettings']) {
        payload['CasinoIntegrationSetting'] = payload['CasinoIntegrationSettings']?.[0] || null;
        delete payload['CasinoIntegrationSettings'];
      }

      if(payload['casino-integration-settings']) {
        payload['casino-integration-setting'] = payload['casino-integration-settings']?.[0] || null;
        delete payload['casino-integration-settings'];
      }
    }

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
