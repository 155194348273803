import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\">\n  <form onsubmit={{action \"createWallet\"}} class=\"form-inline pull-right\">\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Currencies'}}</span>\n      <div class=\"select-box\">\n        <select name=\"\" id=\"\" onchange={{action this.selectCurrency value=\"target.value\"}}>\n          {{#each this.currencies as |currency|}}\n            <option value=\"{{currency.id}}\">{{currency.name}}</option>\n          {{/each}}\n        </select>\n        <i class=\"sb-icon sb-triangledown\"></i>\n      </div>\n    </div>\n    {{#unless this.createAccountTask.isRunning}}\n      <button class=\"btn btn-basic btn-full\" disabled={{false}} type=\"submit\">{{t 'Save'}}</button>\n    {{else}}\n      <button class=\"btn btn-basic btn-full loading dark\" type=\"submit\"></button>\n    {{/unless}}\n  </form>\n</div>\n{{yield}}", {"contents":"<div class=\"add-new-managers-fields\">\n  <form onsubmit={{action \"createWallet\"}} class=\"form-inline pull-right\">\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Currencies'}}</span>\n      <div class=\"select-box\">\n        <select name=\"\" id=\"\" onchange={{action this.selectCurrency value=\"target.value\"}}>\n          {{#each this.currencies as |currency|}}\n            <option value=\"{{currency.id}}\">{{currency.name}}</option>\n          {{/each}}\n        </select>\n        <i class=\"sb-icon sb-triangledown\"></i>\n      </div>\n    </div>\n    {{#unless this.createAccountTask.isRunning}}\n      <button class=\"btn btn-basic btn-full\" disabled={{false}} type=\"submit\">{{t 'Save'}}</button>\n    {{else}}\n      <button class=\"btn btn-basic btn-full loading dark\" type=\"submit\"></button>\n    {{/unless}}\n  </form>\n</div>\n{{yield}}","moduleName":"backoffice/components/modals/retail/wallets/add-wallet.hbs","parseOptions":{"srcName":"backoffice/components/modals/retail/wallets/add-wallet.hbs"}});
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {task} from "ember-concurrency";
import {action} from "@ember/object";

export default class ModalsRetailWalletsAddWalletComponent extends Component {
  @service store;
  @service modalWindow;
  @service notifications;

  @tracked currencies = this.modalWindow.data.currencies;
  @tracked selectedCurrency = this.modalWindow.data.currencies[0].id;

  @action
  createWallet(e) {
    e.preventDefault();
    this.createAccountTask.perform();
    this.selectedCurrency = this.currencies[0].id
  }

  @action
  selectCurrency(val) {
    this.selectedCurrency = val
  }

  @(task(function* () {
    let currentUser = this.store.peekRecord('user', this.modalWindow.data.user_id);
    let account  = this.store.createRecord('admin-account');
    account.balance = '0.00';
    account.owner_id = currentUser.id;
    account.currency_id = this.selectedCurrency;
    account.currency = this.store.peekRecord('currency', this.selectedCurrency)
    account[this.modalWindow.data.decider] = true;
    yield account.save().then(() => {
      currentUser.freezeAndMainAccounts.pushObject(account)
      this.modalWindow.closeModal();
      this.notifications.success('Wallet Create Successfully', {
        autoClear: true
      });
    })
  })) createAccountTask;

}
