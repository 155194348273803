import Controller from '@ember/controller';
import moment from "moment";

export default class RetailReportsSoldCreditsController extends Controller {
  queryParams = [
    'system_user_id',
    'currency_ids',
    'partner_ids',
    'perPage',
    'page',
    'start',
    'end',
    'by',
  ];

  system_user_id;
  currency_ids;
  perPage = 100;
  page = 1;
  by = 'currency';
  partner_ids;
  start = moment().startOf('month').unix();
  end = moment().endOf('day').unix();
}
