import Service, {inject as service} from '@ember/service';
import {task} from "ember-concurrency";

export default class ThemeService extends Service {
  @service partner;
  @service store;

  stylePresets = {
    light: [
      {title: '--tournament-font-color', key: '--tournament-font-color', color: '#000000'},
      {title: '--tournament-table-stripe-color', key: '--tournament-table-stripe-color', color: '#EBEBEB'},
      {title: '--tournament-first-place-color', key: '--tournament-first-place-color', color: '#baa460'},
      {title: '--tournament-second-place-color', key: '--tournament-second-place-color', color: '#8988a5'},
      {title: '--tournament-third-place-color', key: '--tournament-third-place-color', color: '#cc886d'},
      {title: '--website-font-color', key: '--website-font-color', color: '#000000'},
      {title: '--website-table-stripe-color', key: '--website-table-stripe-color', color: '#EBEBEB'},
    ],
    dark: [
      {title: '--tournament-font-color', key: '--tournament-font-color', color: '#FFFFFF'},
      {title: '--tournament-table-stripe-color', key: '--tournament-table-stripe-color', color: '#1D1D1D'},
      {title: '--tournament-first-place-color', key: '--tournament-first-place-color', color: '#f7e6aa'},
      {title: '--tournament-second-place-color', key: '--tournament-second-place-color', color: '#c1bfd9'},
      {title: '--tournament-third-place-color', key: '--tournament-third-place-color', color: '#f3d4c2'},
      {title: '--website-font-color', key: '--website-font-color', color: '#FFFFFF'},
      {title: '--website-table-stripe-color', key: '--website-table-stripe-color', color: '#1D1D1D'}
    ],
  };

  @task(function* () {
    return yield this.store
      .queryRecord('partner-style', {
        active_menu: 'dark',
        partner_id: this.partner.selected_id
      })
      .catch(({ payload }) => {
        if (payload && payload.errors) {
          payload.errors.forEach((error) => {
            this.notifications.error(error, {
              autoClear: true,
            });
          });
        }
      });
  })
  getPartnerStylesTask;
}
