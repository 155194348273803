import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class BonusSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    bonusProgressives: {embedded: 'always'},
    bonusBenefit: {embedded: 'always'},
    bonusAction: {embedded: 'always'},
    groups: {embedded: 'always'},
    partners: {embedded: 'always'},
    includeSegments: {embedded: 'always'},
    excludeSegments: {embedded: 'always'},
    bonusConditionCurrencies: {embedded: 'always'},
    depositMethods: {embedded: 'always'}
  };
}
