import Model,{attr,belongsTo} from '@ember-data/model';

export default class CasinoWithdrawRequestModel extends Model {
  @attr player_id
  @attr amount
  @attr cashbox_id
  @attr comment
  @attr manager_approval_id
  @attr system_user_approval_id
  @attr withdraw_info
  @attr status
  @attr internal_value
  @attr internal_name
  @attr currency_id
  @attr payment_method_id
  @attr type
  @attr created_at
  @attr updated_at
  @attr approved_date
  @attr integration_id

  @belongsTo('player', { async: false, inverse: null }) player
  @belongsTo('casino-withdraw', { async: false, inverse: null }) withdraw
  @belongsTo('payment-integration', { async: false, inverse: null }) integration

  get getAccountType(){
    return 'Casino'
  }
}
