import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{yield}}", {"contents":"<GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{yield}}","moduleName":"backoffice/components/route-components/retail/cashiers/details/sb-details.hbs","parseOptions":{"srcName":"backoffice/components/route-components/retail/cashiers/details/sb-details.hbs"}});
import Component from '@glimmer/component';

export default class RouteComponentsRetailCashiersDetailsSbDetailsComponent extends Component {
  get menuItems() {
    return [
      {
        name: 'Details',
        definer: 'retail_cashiers_key_details',
        linkTo: {
          path: 'retail.cashiers.cashier.details',
        }
      },
      {
        name: 'Transaction History',
        definer: 'retail_cashiers_key_transaction_history',
        linkTo: {
          path: 'retail.cashiers.cashier.transaction-history'
        }
      }
    ];
  }
}
