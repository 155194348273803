import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';

export default class AffiliateBannerModel extends Model {
  @service globalVars;

  @attr title;
  @attr subtitle;
  @attr button_title;
  @attr background_color;
  @attr affiliate_link_id;
  @attr('image') image;
  @attr status;
  @attr('boolean') is_dark;
  @attr width;
  @attr height;
  @attr('number', { defaultValue: 10 }) type;

  modelName = 'affiliate-banner';

  get iframe() {
    return `<iframe src=${this.globalVars.url.site_api}/affiliate-banner/${this.id} scrolling="no" width=${this.width} height=${this.height} frameborder="0"></iframe>`;
  }
}
