import ApplicationAdapter from './application';

export default class SportLimitAdapter extends ApplicationAdapter {
  urlForCreateRecord() {
    return this.host + '/limits/sport-limits'
  }

  urlForUpdateRecord($id) {
    return this.host + '/limits/sport-limits/' + $id
  }

  urlForDeleteRecord(id) {
    return this.host + `/limits/sport-limits/${id}`;
  }
}
