import ApplicationAdapter from './application';

export default class MarketLimitAdapter extends ApplicationAdapter {
  urlForCreateRecord() {
    return this.host + '/limits/market-limits'
  }

  urlForUpdateRecord($id) {
    return this.host + '/limits/market-limits/' + $id
  }

  urlForDeleteRecord(id) {
    return this.host + `/limits/market-limits/${id}`;
  }
}
