import Model, {attr} from '@ember-data/model';

export default class InterkassaPurseModel extends Model {
  @attr type;
  @attr purse_id;
  @attr name;
  @attr status;
  @attr balance;
  @attr frozen;
  @attr turnover;
  @attr payment_cashbox_id;
  @attr integration_id;
}
