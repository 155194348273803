import Model, { attr } from '@ember-data/model';
import { htmlSafe } from '@ember/string';

export default class NotificationLimitModel extends Model {
  @attr('number') created_at;
  @attr('number') currency_id;
  @attr('number') expiration_date;
  @attr('number') local_game_id;
  @attr('number') local_league_id;
  @attr('number') market_id;
  @attr('number') partner_id;
  @attr('number') percent;
  @attr('number') sport_id;
  @attr('number') total_bet_amount_limit;
  @attr('number') total_limit_remnant;
  @attr('string') type;
  @attr('number') updated_at;
  @attr('boolean') is_deleted;
  @attr() sport;
  @attr() league;
  @attr() game;

  get percentMinus() {
    return htmlSafe(`width: calc(${100 - this.percent}% - 5px);`);
  }

  get percentPlus() {
    return htmlSafe(`width: ${this.percent}%;`);
  }

  get currentAmount() {
    return this.total_bet_amount_limit - this.total_limit_remnant;
  }
}
