import Model, {attr} from '@ember-data/model';

export default class CurrencyCourseModel extends Model {
  @attr base_currency_id;
  @attr currency_id;
  @attr code;
  @attr time;
  @attr value;
  @attr('boolean') is_manual;
  @attr created_at;
  @attr updated_at;
}
