import AuthenticatedRoute from '../../authenticated';

export default class AffiliatesClientsIndexRoute extends AuthenticatedRoute {
  definer = 'affiliates_clients'

  queryParams = {
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    partner_id: {
      refreshModel: true
    },
    id: {
      refreshModel: true
    },
    name: {
      refreshModel: true
    },
    email: {
      refreshModel: true
    },
    phone: {
      refreshModel: true
    },
    currency_id: {
      refreshModel: true
    },
    affiliate_user_id: {
      refreshModel: true
    },
    affiliate: {
      refreshModel: false
    },
    sort_name: {
      refreshModel: true
    },
    sort_type: {
      refreshModel: true
    },
    sub_affiliate_user_id: {
      refreshModel: true
    },
    total_income_product: {
      refreshModel: true
    },
    income_by_bets_start: {
      refreshModel: true
    },
    income_by_bets_end: {
      refreshModel: true
    },
    income_by_currency_id: {
      refreshModel: true
    },
    balance_product: {
      refreshModel: true
    }
  };

  model(params) {
    return params
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }
}
