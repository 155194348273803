import ApplicationAdapter from './application';

export default class MailTemplateAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/partners/mail-templates'
  }

  urlForUpdateRecord(id) {
    return this.host + `/partners/mail-templates/${id}`;
  }
}
