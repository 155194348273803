import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class PaymentWithdrawMethodSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    paymentCurrency: {embedded: 'always'},
    integrationSetting: {embedded: 'always'},
    paymentIntegrationSetting: {embedded: 'always'},
    peculiarAttributes: {embedded: 'always'},
  }
}
