import {tracked} from '@glimmer/tracking';
import {action, set} from '@ember/object';
import {task} from 'ember-concurrency'
import MainFilter from "../main-filter";

export default class MainPlayerSearch extends MainFilter {
  @tracked players = null;
  @tracked selectedPlayer = null;

  @action
  searchPlayer(val) {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(() => {
      this.playerTask.perform(val);
    }, 300)
  }

  @action
  selectPlayer(data) {
    this.selectedPlayer = data;
    if (data) {
      set(this.filters, 'player_id', data.id)
    } else {
      set(this.filters, `player_id`, undefined);
    }
    this.setPage(1)
  }

  @(task(function* (val) {
    yield this.ajax.request(`/get-risk-management-players`, {
      data: {
        searchVal: val,
        partner_id: this.filters.partner_id,
        currency_id: this.filters.currency_id,
        include: 'profile,accountMain',
      }
    }).then(requestData => {
      this.players = requestData['players'];
    });
  }))playerTask

}
