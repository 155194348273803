import ApplicationAdapter from './application';

export default class UserAdapter extends ApplicationAdapter {
  urlForQueryRecord() {
    return this.host + '/users/me';
  }

  urlForQuery(param) {
    if (param.retail_reports) {
      return this.host + '/retail/remaining-credits/get-administrators';
    }
    if (param.retail_cashboxes_get_administrators) {
      return this.host + '/retail/cashboxes/get-administrators';
    }
    if (param.retail_cashboxes_get_managers) {
      return this.host + '/retail/cashboxes/get-managers';
    }
    if (param.retail_administrators_get_managers) {
      return this.host + '/retail/administrators/get-managers';
    }
    if (param.retail_administrators) {
      return this.host + '/retail/administrators/get';
    }
    if (param.retail_administrators_get_managers) {
      return this.host + '/retail/administrators/get-managers';
    }
    if (param.retail_administrators_get_cashiers) {
      return this.host + '/retail/administrators/get-cashiers';
    }
    if (param.retail_managers) {
      return this.host + '/retail/managers/get';
    }
    if (param.retail_managers_get_administrators) {
      return this.host + '/retail/managers/get-administrators';
    }
    if (param.retail_managers_get_cashiers) {
      return this.host + '/retail/managers/get-cashiers';
    }
    if (param.retail_cashiers) {
      return this.host + '/retail/cashiers/get';
    }
    if (param.retail_cashiers_get_managers) {
      return this.host + '/retail/cashiers/get-managers';
    }
    if (param.retail_cashiers_get_administrators) {
      return this.host + '/retail/cashiers/get-administrators';
    }
    if (param.sub_affiliate) {
      return this.host + '/sub-affiliate/sub-affiliate-users';
    }
    if (param.sub_affiliate_get_affiliate) {
      return this.host + '/sub-affiliate/affiliate-users';
    }
    if (param.is_affiliate) {
      return this.host + '/affiliate/link/affiliate-users'
    }
    if (param.affiliate) {
      return this.host + '/affiliate/affiliate-users'
    }
    if (param.is_affiliate_client) {
      return this.host + '/affiliate/client/affiliate-users'
    }
    if (param.affiliate_on_players) {
      return this.host + '/players-actions-many/affiliate-users'
    }
    if (param.is_affiliate_dashboard) {
      return this.host + '/affiliate/dashboard/affiliate-users'
    }
    if (param.is_affiliate_transactions) {
      return this.host + '/affiliate/transactions/affiliate-users'
    }
    return this.host + '/get-users';
  }

  urlForCreateRecord(model, params) {
    if (params.__attributes.sub_affiliate) {
      return this.host + '/sub-affiliate/create';
    } else if (params.__attributes.affiliate) {
      return this.host + '/affiliate/create';
    } else if (params.__attributes.retail_administrators) {
      return this.host + '/retail/administrators/create';
    } else if (params.__attributes.retail_managers_on_administrator) {
      return this.host + '/retail/administrators/create-manager';
    } else if (params.__attributes.retail_cashiers_on_administrator) {
      return this.host + '/retail/administrators/create-cashier';
    } else if (params.__attributes.retail_managers) {
      return this.host + '/retail/managers/create';
    } else if (params.__attributes.retail_cashiers_on_manager) {
      return this.host + '/retail/managers/create-cashier';
    } else if (params.__attributes.retail_cashiers) {
      return this.host + '/retail/cashiers/create';
    }
    return this.host + '/create-user';
  }

  urlForUpdateRecord(id, model, params) {
    if (params.__attributes.retail_administrators) {
      return this.host + `/retail/administrators/update/${id}`;
    }
    if (params.__attributes.retail_managers) {
      return this.host + `/retail/managers/update/${id}`;
    }
    if (params.__attributes.retail_cashiers) {
      return this.host + `/retail/cashiers/update/${id}`;
    }
    if (params.__attributes.affiliate) {
      return this.host + `/affiliate/update/${id}`
    }
    if (params.__attributes.sub_affiliate) {
      return this.host + `/sub-affiliate/update/${id}`
    }
    return this.host + `/users/${id}`;
  }

  urlForDeleteRecord(id, model, params) {
    if (params.__attributes.retail_administrators) {
      return this.host + `/retail/administrators/delete/${id}`;
    }
    if (params.__attributes.retail_managers) {
      return this.host + `/retail/managers/delete/${id}`;
    }
    if (params.__attributes.retail_cashiers) {
      return this.host + `/retail/cashiers/delete/${id}`;
    }
    return this.host + `/users/${id}`;
  }
}
