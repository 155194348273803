import Service, {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {task} from 'ember-concurrency';

export default class MaxBetService extends Service {
  @service partner;
  @service store;
  @service notifications;

  @tracked selectedGroupId;
  @tracked currencyGroups;
  @tracked navMenuItems = [];

  @task
  * getCurrencyGroupsTask() {
    return yield this.store.query('max-bet-currency-group', {
      partner_id: this.partner.selectedId,
    })
      .then((currencyGroups) => {
        this.currencyGroups = currencyGroups;
        this.navMenuItems = [];
        currencyGroups.forEach((group) => {
          let obj = {};
          obj.name = group.desc;
          obj.definer = 'settings_max_bet_configuration';
          obj.linkTo = {path: 'settings.max-bet.setting', model: group.id};
          this.navMenuItems.push(obj);
        });
      })
      .catch(payload => {
        if (payload.errors) {
          payload.errors.forEach((error) => {
            this.notifications.error(error, {
              autoClear: true,
            });
          });
        }
      });
  }
}
