import Service, {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {task} from "ember-concurrency";
import RSVP from 'rsvp';
import {storageFor} from 'ember-local-storage';

export default class CurrentUserService extends Service {
  @service store;
  @service partner;
  @service session;
  @service groupReport;

  @tracked user = null;

  @storageFor('partner') partnerStorage;

  roleTypes = {
    administrator: 'Administrator',
    manager: 'Manager',
    cashier: 'Cashier',
    affiliate: 'Affiliate',
    sub_affiliate: 'Sub Affiliate',
    default: 'Default',
    system: 'System',
    groupAdmin: 'Group Admin'
  };

  accountOwners = {
    user: 'user',
    player: 'player'
  }

  accountFreeze = 'freeze';
  accountCashDesk = 'cashDesk';
  accountMain = 'main';

  load() {
    if (this.session.isAuthenticated) {
      return this.actionMe.perform();
    } else {
      return RSVP.resolve();
    }
  }

  get isFilledUp() {
    return this.session.isAuthenticated && this.user;
  }

  roleDefined(definer) {
    return !!(this.store.peekAll('site-content').find(i => i.definer === definer));
  }

  @(task(function* () {
    try {
      this.user = yield this.store.queryRecord('user', {include: 'mainAdminAccounts.currency,mainAdminAccounts.adminAccountType,role.roleType,rolePermissions.siteContent,siteContentFavorites,affiliateSetting.affiliatePlayers,affiliateLinks.affiliateBanners,affiliateLinks.bonus,userNotificationConfig'}).catch();
      let partner_id = this.user.partner_id;
      let partners = yield this.store.query('partner', {include: 'accounts.accountType,accounts.currency,partnerConfig.signupTypes,mailConfig,languages,configLanguages,partnerDomainManager,group'}).catch();
      partners.find(i => i.id === this.user.partner_id);
      if (parseInt(this.user.partner_id) !== 0) {
        this.partner.setSelected(partner_id);
        this.partner.setSelectedCurrency(this.partner.all[0].get('accounts')[0].currency_id);
      }
      if(!this.groupReport.getToken()) {
        yield this.groupReport.getIframeTokenTask.perform()
      }
    } catch (err) {
      this.groupReport.removeToken()
      this.session.invalidate();
    }


  })) actionMe;
}
