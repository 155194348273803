import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\">\n  {{#unless this.generateTask.isRunning}}\n    <button class=\"btn btn-basic btn-full\" disabled={{false}} {{on 'click' (perform this.generateTask)}} >{{t 'Yes'}}</button>\n  {{else}}\n    <button class=\"btn btn-basic btn-full loading dark\"></button>\n  {{/unless}}\n</div>\n{{yield}}", {"contents":"<div class=\"add-new-managers-fields\">\n  {{#unless this.generateTask.isRunning}}\n    <button class=\"btn btn-basic btn-full\" disabled={{false}} {{on 'click' (perform this.generateTask)}} >{{t 'Yes'}}</button>\n  {{else}}\n    <button class=\"btn btn-basic btn-full loading dark\"></button>\n  {{/unless}}\n</div>\n{{yield}}","moduleName":"backoffice/components/modals/settings/partner/generate-all/sb-generate-all.hbs","parseOptions":{"srcName":"backoffice/components/modals/settings/partner/generate-all/sb-generate-all.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {task} from "ember-concurrency";

export default class ModalsSettingsPartnerGenerateAllSbGenerateAllComponent extends Component {
  @service modalWindow;
  @service notifications;
  @service intl;
  @service ajax;

  @(task(function* () {
    yield this.ajax.post('generate-all-partners', {data:{}}).then(()=>{
      this.modalWindow.closeModal()
    });
  }))generateTask

}
