import Route from '@ember/routing/route';

export default class StatsReportsByBetsRoute extends Route {
  definer = 'stats_reports_key_by_partner'

  queryParams = {
    partner_id: {
      refreshModel: true
    },
    currency_id: {
      refreshModel: true
    },
    group_ids: {
      refreshModel: true
    },
    partner_ids: {
      refreshModel: true
    },
    currency_ids: {
      refreshModel: true
    },
    start: {
      refreshModel: true
    },
    end: {
      refreshModel: true
    }
  };

  model(params) {
    return params
  }
}
