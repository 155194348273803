import ApplicationAdapter from './application';

export default class GlobalLimitAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/limits/global-limit'
  }

  urlForCreateRecord() {
    return this.host + '/limits/global-limits'
  }

  urlForUpdateRecord($id) {
    return this.host + '/limits/global-limits/' + $id
  }

  urlForDeleteRecord($id) {
    return this.host + '/limits/global-limits/' + $id
  }
}
