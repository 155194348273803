import Controller from '@ember/controller';

export default class CasinoSettingsTemplateGamesController extends Controller {
  queryParams = [
    'page',
    'perPage',
    'type',
    'template_category_id',
    'template_sub_category_id',
    'template_provider_id',
    'game_code',
    'integration_id',
    'name'
  ];

  page = 1;
  perPage = 10;
  type;
  template_category_id;
  template_sub_category_id;
  template_provider_id;
  game_code;
  integration_id;
  name;
}
