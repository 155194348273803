import ApplicationAdapter from './application';

export default class BonusAdapter extends ApplicationAdapter {
  urlForQuery(params) {
    if(params.is_dashboard){
      return this.host + '/bonusDashboard/bonuses'
    }
    if(params.is_players_action){
      return this.host + '/players-actions-many/partner-bonuses'
    }
    if(params.is_player_action){
      return this.host + '/bonus-template/list'
    }
    if(params.is_wheel){
      return this.host + '/wheel-sector/get-bonuses'
    }
    return this.host + '/bonusTemplates/bonuses/get'
  }
  urlForFindRecord(id) {
    return this.host + '/bonuses/' + id
  }

  urlForCreateRecord(model,params) {
    if(params.__attributes.isLogic){
      return this.host + `/bonusTemplates/bonuses/bonus-logics`;
    }
    return this.host + '/bonusTemplates/bonuses'
  }

  urlForDeleteRecord(id) {
    return this.host + `/bonusTemplates/bonuses/${id}`;
  }

  urlForUpdateRecord(id,model,params) {
    if(params.__attributes.isLogic){
      return this.host + `/bonusTemplates/bonuses/bonus-logics/${id}`;
    }
    return this.host + `/bonusTemplates/bonuses/${id}`;
  }
}
