import Model, {attr} from '@ember-data/model';

export default class PaymentPeculiarAttributeModel extends Model {
  @attr('number') integration_setting_id;
  @attr('number') withdraw_method_id;
  @attr attribute;
  @attr placeholder;
  @attr('string', {defaultValue: 'text'}) type;
  @attr('boolean', {defaultValue: true}) is_required;
  @attr regex;
  @attr created_by;
  @attr updated_by;
  @attr created_at;
}
