import Model, { attr } from '@ember-data/model';

export default class PartnerLinkModel extends Model {
	@attr('number') partner_id;
	@attr('string') route_name;
	@attr('json-parse') models;
	@attr('json-parse') query;
	@attr('boolean') is_external_link;
	@attr('string') external_url;
}
