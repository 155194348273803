import ApplicationAdapter from './application';

export default class AffiliateLinkAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/affiliate/links'
  }

  urlForCreateRecord() {
    return this.host + '/affiliate/links';
  }

  urlForUpdateRecord(id) {
    return this.host + `/affiliate/links/${id}`;
  }
}
