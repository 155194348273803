import Controller from '@ember/controller';
import moment from 'moment';

export default class CasinoDashboardController extends Controller {
  queryParams = [
    'page',
    'perPage',
    'dateMode',
    'graph_start',
    'graph_end',
    'start',
    'integration_id',
    'provider_id',
    'game_id',
    'end',
    'sort_name',
    'sort_type',
  ];

  page = 1;
  perPage = 10;
  dateMode = 'day';
  integration_id = 0;
  provider_id = 0;
  game_id = 0;
  sort_name;
  sort_type;
  graph_start = moment().startOf('month').unix();
  graph_end = moment().endOf('day').unix();
  start = moment().startOf('month').unix();
  end = moment().endOf('day').unix();
}
