import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<LayoutComponents::Table::SbTable\n  @isEmpty={{eq @data.length 0}}\n  @isLoading={{@isLoading}}\n  @titles={{this.tableTitles}}\n  @className=\"table_sticky-header\"\n>\n  {{#each @data as |rowData|}}\n    <RouteComponents::Bonuses::BonusTemplates::BonusTemplatesTable::BonusTemplatesTableItem::SbBonusTemplatesTableItem\n      @data={{rowData}}\n      @deleteById={{@deleteById}}\n      @duplicateById={{@duplicateById}}\n      @showAmount={{@showAmount}}\n      @showConfirmation={{@showConfirmation}}\n      @showSegments={{@showSegments}}\n    />\n  {{/each}}\n</LayoutComponents::Table::SbTable>\n{{yield}}\n", {"contents":"<LayoutComponents::Table::SbTable\n  @isEmpty={{eq @data.length 0}}\n  @isLoading={{@isLoading}}\n  @titles={{this.tableTitles}}\n  @className=\"table_sticky-header\"\n>\n  {{#each @data as |rowData|}}\n    <RouteComponents::Bonuses::BonusTemplates::BonusTemplatesTable::BonusTemplatesTableItem::SbBonusTemplatesTableItem\n      @data={{rowData}}\n      @deleteById={{@deleteById}}\n      @duplicateById={{@duplicateById}}\n      @showAmount={{@showAmount}}\n      @showConfirmation={{@showConfirmation}}\n      @showSegments={{@showSegments}}\n    />\n  {{/each}}\n</LayoutComponents::Table::SbTable>\n{{yield}}\n","moduleName":"backoffice/components/route-components/bonuses/bonus-templates/bonus-templates-table/sb-bonus-templates-table.hbs","parseOptions":{"srcName":"backoffice/components/route-components/bonuses/bonus-templates/bonus-templates-table/sb-bonus-templates-table.hbs"}});
import Component from '@glimmer/component';

export default class RouteComponentsBonusesBonusTemplatesBonusTemplatesTableSbBonusTemplatesTableComponent extends Component {
  tableTitles = [
    {title: 'Name', className: 'col-fixed'},
    'ID',
    'Status',
    'Trigger',
    'Benefit',
    'FS Bet',
    'Game',
    'Amount',
    'Max Amount',
    'Start',
    'End',
    'Exp',
    'Pending Exp',
    'Displayed',
    'Usage',
    'Segments',
    'Actions',
  ];
}
