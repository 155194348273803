import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.menuItems}}\n  <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{/if}}\n", {"contents":"{{#if this.menuItems}}\n  <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{/if}}\n","moduleName":"backoffice/components/route-components/sportsbook/settings/template/sb-template.hbs","parseOptions":{"srcName":"backoffice/components/route-components/sportsbook/settings/template/sb-template.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class RouteComponentsSportsbookSettingsTemplateSbTemplateComponent extends Component {
  @service router;

  get menuItems() {
    let template_id = this.router.currentRoute.parent.params.template_id

    return [{
      name: 'Order',
      definer: 'sportsbook_settings',
      linkTo: {
        path: 'sportsbook.settings.template.order',
        model: template_id
      }
    }, {
      name: 'Sports',
      definer: 'sportsbook_settings',
      linkTo: {
        path: 'sportsbook.settings.template.sports',
        model: template_id
      }
    }, {
      name: 'Leagues',
      definer: 'sportsbook_settings',
      linkTo: {
        path: 'sportsbook.settings.template.leagues',
        model: template_id
      }
    }, {
      name: 'Games',
      definer: 'sportsbook_settings',
      linkTo: {
        path: 'sportsbook.settings.template.games',
        model: template_id
      }
    }, {
      name: 'Markets',
      definer: 'sportsbook_settings',
      linkTo: {
        path: 'sportsbook.settings.template.markets',
        model: template_id
      }
    }]
  }
}
