import ApplicationAdapter from './application';

export default class CasinoTemplateCategoryAdapter extends ApplicationAdapter {
  urlForQuery(params) {
    if (params.partner_id) {
      return this.host + '/casino/category-partner'
    }

    if(params.is_bonus) {
      return this.host + '/bonusTemplates/bonuses/casino-categories';
    }

    if(params.wageringContribution) {
      return this.host + `/casino/categories-with-wc/${params.template_id}`;
    }

    if(params.withSubCategories) {
      return this.host + `/casino/categories-with-sub-categories`;
    }

    return this.host + '/casino/categories'
  }

  urlForCreateRecord() {
    return this.host + '/casino/categories'
  }

  urlForUpdateRecord($id) {
    return this.host + '/casino/categories/' + $id
  }

  urlForDeleteRecord($id) {
    return this.host + '/casino/categories/' + $id
  }
}
