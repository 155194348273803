import Model, {attr, belongsTo} from '@ember-data/model';
import {set} from '@ember/object';

export default class AccountModel extends Model {
  @attr() balance;
  @attr('number') type_id;
  @attr() currency_id;
  @attr() owner_id;
  @attr() created_at;
  @attr('boolean') active;
  @attr('boolean') is_main;
  @attr() updated_at;

  @attr() isAdministrator;
  @attr() isManager;
  @attr() isCashier;
  @attr('number') order;

  @belongsTo('accountType', {async: false, inverse: null}) accountType;
  @belongsTo('account-order', {async: false, inverse: null}) accountOrder;
  @belongsTo('players-config', {async: false, inverse: null}) playersConfig;
  @belongsTo('partner', {async: false, inverse: null}) partner;
  @belongsTo('player', {async: false, inverse: null}) player;
  @belongsTo('currency', {async: false, inverse: null}) currency;

  get orderBy() {
    if (this.accountOrder) {
      return this.accountOrder.get('order');
    }
    return null;
  }

  set orderBy(value) {
    if (this.accountOrder) {
      set(this.accountOrder, 'order', value);
    }
  }
}
