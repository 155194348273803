import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class={{this.classes}}\n  style={{html-safe this.style}}\n  onclick={{action this.show}}\n>\n  <img src={{or @imageUrl ''}}/>\n</div>\n", {"contents":"<div\n  class={{this.classes}}\n  style={{html-safe this.style}}\n  onclick={{action this.show}}\n>\n  <img src={{or @imageUrl ''}}/>\n</div>\n","moduleName":"backoffice/components/generic-components/file-manager-btn/sb-file-manager-btn.hbs","parseOptions":{"srcName":"backoffice/components/generic-components/file-manager-btn/sb-file-manager-btn.hbs"}});
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

export default class GenericComponentsFileManagerBtnSbFileManagerBtnComponent extends Component {
  @service modalWindow;

  className = 'file-manager-btn'

  get classes() {
    let classNames = this.className;

    if (this.args.disabled) {
      classNames = `${classNames} ${this.className}_disabled`;
    }

    return classNames;
  }

  get style() {
    let styles = [];

    if (this.args.width) {
      styles.push(`width: ${this.args.width}`)
    } else {
      styles.push('width: 80px')
    }

    if (this.args.height) {
      styles.push(`height: ${this.args.height}`)
    } else {
      styles.push('height: 80px')
    }

    if (this.args.ratio) {
      styles.push(`aspect-ratio: ${this.args.ratio}`)
    }

    return styles.join(';');
  }

  @action
  show() {
    if (this.args.disabled) return

    this.modalWindow.showFileManager({setImageUrl: this.args.setImageUrl});
  }
}
