import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';

export default class SettingsPartnersDomainManagerMainConfigRoute extends Route {
  @service store

  model() {
    let partner_id = this.paramsFor('settings.partners.domain-manager').partner_id;
    return this.store.queryRecord('partner-domain-manager', {partner_id});
  }
}
