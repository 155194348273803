import ApplicationAdapter from './application';

export default class PartnerMirrorAdapter extends ApplicationAdapter {
  urlForUpdateRecord(id) {
    return this.host + `/partners/partner-mirrors/${id}`;
  }

  urlForCreateRecord() {
    return this.host + `/partners/partner-mirrors`;
  }

  urlForQuery() {
    return this.host + `/partners/partner-mirrors`;
  }

  urlForDeleteRecord(id) {
    return this.host + `/partners/partner-mirrors/${id}`;
  }
}
