import ApplicationAdapter from './application';

export default class PaymentTemplateWithdrawMethodAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/paymentTemplate/withdraw-methods'
  }

  urlForUpdateRecord(id) {
    return this.host + `/paymentTemplate/withdraw-methods/${id}`;
  }

  urlForDeleteRecord(id) {
    return this.host + `/paymentTemplate/withdraw-methods/${id}`;
  }
}
