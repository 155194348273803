import ApplicationAdapter from './application';

export default class WheelSectorAdapter extends ApplicationAdapter {
    urlForQuery() {
		return this.host + '/wheel/wheel-sectors'
	}
		
	urlForFindAll() {
		return this.host + '/wheel/wheel-sectors';
	}

	urlForCreateRecord() {
		return this.host + '/wheel/wheel-sectors';
	}
		
	urlForUpdateRecord(id) {
		return this.host + '/wheel/wheel-sectors/' + id;
	}

	urlForDeleteRecord(id) {
		return this.host + `/wheel/wheel-sectors/${id}`;
	}
}
