import Helper from '@ember/component/helper';

export default class parseStatusFromEditLog extends Helper {
  compute([data, statusData, field, model]) {
    let regExp = "[0-9]";
    if (new RegExp("[10-90]{2}", "g").test(Object.keys(statusData)[0])) regExp = "[10-90]{2}"
    if (field === 'status_id' && model === 'Player') {
      statusData = {
        1: 'Newbie',
        2: 'Popan',
        3: 'Followers',
        4: 'Penny-Better',
        5: 'Bonus-Hunters',
        6: 'Value-Betters',
        7: 'Smallers',
        8: 'Investor',
        9: 'Trader',
        10: 'Privateer',
        11: 'Pro',
        12: 'Forklifts',
        13: 'Mathematicians',
        14: 'Ludoman',
        15: 'Risk',
        16: 'Sport',
      }
    }
    if (new RegExp("[1-6]{1,2}", "g").test(Object.keys(statusData)[0])) regExp = "[1-6]{1,2}"

    if (data.search('Pending Amount') < 0) {
      return data.replace(new RegExp(regExp, "g"), function (val) {
        return statusData[val];
      })
    }
    return data
  }
}
