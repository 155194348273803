import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<LayoutComponents::Table::SbTable\n  @isEmpty={{eq @data.length 0}}\n  @isLoading={{@isLoading}}\n  @titles={{this.tableTitles}}\n>\n  {{#each @data as |rowData|}}\n    <RouteComponents::RiskManagement::Players::Profile::BalanceChanges::BalanceChangesTable::BalanceChangesTableItem::SbBalanceChangesTableItem\n      @data={{rowData}}\n    />\n  {{/each}}\n</LayoutComponents::Table::SbTable>\n{{yield}}\n", {"contents":"<LayoutComponents::Table::SbTable\n  @isEmpty={{eq @data.length 0}}\n  @isLoading={{@isLoading}}\n  @titles={{this.tableTitles}}\n>\n  {{#each @data as |rowData|}}\n    <RouteComponents::RiskManagement::Players::Profile::BalanceChanges::BalanceChangesTable::BalanceChangesTableItem::SbBalanceChangesTableItem\n      @data={{rowData}}\n    />\n  {{/each}}\n</LayoutComponents::Table::SbTable>\n{{yield}}\n","moduleName":"backoffice/components/route-components/risk-management/players/profile/balance-changes/balance-changes-table/sb-balance-changes-table.hbs","parseOptions":{"srcName":"backoffice/components/route-components/risk-management/players/profile/balance-changes/balance-changes-table/sb-balance-changes-table.hbs"}});
import Component from "@glimmer/component";
import {computed} from '@ember/object';

export default class RouteComponentsRiskManagementPlayersProfileBalanceChangesBalanceChangesTableSbBalanceChangesTableComponent extends Component {

  tableTitles = [
    'Date',
    'TXN Reason',
    'Reason Cat.',
    'Game name',
    'Manufacturer',
    'Provider',
    'Change amount',
    'Bet amount',
    'Win amount',
    'Cash before',
    'Bonus before',
    'Total before',
    'Cash after',
    'Bonus after',
    'Total after',
    'Remaining wager',
    'Inner ID',
    'Outer ID',
  ];
}
