import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"p-4\">\n  <LayoutComponents::Table::SbTable\n    @titles={{this.titles}}\n    @isEmpty={{eq this.winnersTable.length 0}}\n  >\n    {{#each this.winnersTable as |winner|}}\n      <tr>\n        <td>{{winner.prize.place}}</td>\n        <td>{{winner.participant.player.name}}</td>\n        <td>{{winner.participant.contribution}}</td>\n        <td>{{winner.displayPrize}}</td>\n      </tr>\n    {{/each}}\n  </LayoutComponents::Table::SbTable>\n</div>\n", {"contents":"<div class=\"p-4\">\n  <LayoutComponents::Table::SbTable\n    @titles={{this.titles}}\n    @isEmpty={{eq this.winnersTable.length 0}}\n  >\n    {{#each this.winnersTable as |winner|}}\n      <tr>\n        <td>{{winner.prize.place}}</td>\n        <td>{{winner.participant.player.name}}</td>\n        <td>{{winner.participant.contribution}}</td>\n        <td>{{winner.displayPrize}}</td>\n      </tr>\n    {{/each}}\n  </LayoutComponents::Table::SbTable>\n</div>\n","moduleName":"backoffice/components/modals/tournaments/winners-table/sb-winners-table.hbs","parseOptions":{"srcName":"backoffice/components/modals/tournaments/winners-table/sb-winners-table.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {task} from 'ember-concurrency';

export default class ModalsBonusesBonusAmountSbBonusAmountComponent extends Component {
  @service ajax;
  @service modalWindow;
  @service notifications;
  @service template;
  @service('tournament') tournamentService;

  @tracked winnersList = []

  constructor() {
    super(...arguments);
    this.getTournamentWinnersTask.perform(this.modalWindow.data.tournamentId);
  }

  get titles() {
    return [
      '#',
      'Player',
      this.tournamentService.titlesTypes[this.modalWindow.data.tournamentType] || '',
      'Prize'
    ]
  }

  get winnersTable () {
    return this.winnersList
      .filter(item => item.participant)
      .map(winner => ({
        ...winner,
        displayPrize: this.template.withNumber(winner.prize?.reward)
      }))
  }

  @task
  * getTournamentWinnersTask(tournamentId) {
    return yield this.ajax.request(`/tournaments/${tournamentId}/winners`)
      .then((response) => {
        this.winnersList = response
      })
      .catch(({payload}) => {
        if (payload.errors) {
          payload.errors.forEach((error) => {
            this.notifications.error(error, {
              autoClear: true,
            });
          });
        }
      });
  }
}
