import {task} from 'ember-concurrency';
import {tracked} from '@glimmer/tracking';
import MainReports from "./main-reports";
import {action, set} from '@ember/object';

export default class MainReportsMultiFilter extends MainReports {
  withSumOfCredits = false;

  @tracked user = this.currentUser.user;

  @tracked currencyFilterConfig = {
    title: 'Currency',
    key: 'currency_ids',
    items: [],
    isMultiselect: true,
    visible: true,
    placeholder: 'All currencies are selected',
    selectedItems: typeof this.filters.currency_ids === 'object' && this.filters.currency_ids.length ? this.filters.currency_ids.map(id => this.store.peekRecord('currency', id)) :
      typeof this.filters.currency_ids === 'string' ? JSON.parse('[' + this.filters.currency_ids + ']').map(id => this.store.peekRecord('currency', id)) : [],
    getItems: this.getCurrencies
  }

  @tracked partnerFilterConfig = {
    title: 'Partner',
    key: 'partner_ids',
    isMultiselect: true,
    visible: this.currentUser.user.isWithoutPartner,
    placeholder: 'All partners are selected',
    selectedItems: typeof this.filters.partner_ids === 'object' ? this.filters.partner_ids.map(id => this.store.peekRecord('partner', id)) : typeof this.filters.partner_ids === 'string' ? JSON.parse('[' + this.filters.partner_ids + ']').map(id => this.store.peekRecord('partner', id)) : [],
    getItems: this.user.isWithoutPartner ? this.getPartners : false
  }

  @tracked groupFilterConfig = {
    title: 'Group',
    key: 'group_ids',
    isMultiselect: true,
    visible: this.currentUser.user.isWithoutPartner,
    placeholder: 'All groups are selected',
    selectedItems: typeof this.filters.group_ids === 'object' ? this.filters.group_ids.map(id => this.store.peekRecord('group', id)) : typeof this.filters.group_ids === 'string' ? JSON.parse('[' + this.filters.group_ids + ']').map(id => this.store.peekRecord('group', id)) : [],
    getItems: this.user.isWithoutPartner ? this.getGroups : false
  }

  byTypes = {
    currency: [this.currencyFilterConfig],
    partner: [this.currencyFilterConfig, this.groupFilterConfig, this.partnerFilterConfig],
  };

  @action
  setIds(config, items) {
    let key = config.key;
    set(this, `filters.${key}`, items.length ? items.map(i => i.id) : undefined);
    set(config, `selectedItems`, items);
    for (let i = this.byTypes[this.filters.by].findIndex(k => k.key === key) + 1; i < this.byTypes[this.filters.by].length; i++) {
      let r = this.byTypes[this.filters.by][i];
      set(this, `filters.${r.key}`, undefined);
      set(r, `selectedItems`, undefined);
      if (r.getItems) r.getItems.perform(r);
    }
    if (this.withSumOfCredits) this.getSumOfCredits()
    this.setPage(1);
  }

  @action
  setBy(type, withoutSetPage = false) {
    if (this.byTypes[type]) {
      this.byTypes[type].forEach(r => {
        if (r.getItems) r.getItems.perform(r);
      })
    }

    set(this, 'filters.by', type);

    for (let i = this.types.findIndex(k => k === type) + 1; i < this.types.length; i++) {
      set(this, `filters.${this.types[i]}_id`, undefined);
    }
    if (this.withSumOfCredits) this.getSumOfCredits()
    if (!withoutSetPage) this.setPage(1);
  }

  // eslint-disable-next-line
  @(task(function* (r) {
    set(r, 'items', this.store.peekAll('currency').slice());
  }))getCurrencies

  // eslint-disable-next-line
  @(task(function* (r) {
    set(r, 'items', this.partners.filter(partner => !this.filters.group_ids || (!this.filters.currency_ids ||
      partner.mainAccounts.map(i => +i.currency_id).find(id => this.currencyFilterConfig.selectedItems.map(item => +item.id).includes(id))
    ) && this.groupFilterConfig.selectedItems.map(item => +item.id).includes(+partner.group_id)));
  }))getPartners

  // eslint-disable-next-line
  @(task(function* (r) {
    set(r, 'items', this.groups);
  }))getGroups
}
