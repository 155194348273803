import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"content-item\">\n  <div class=\"column1\">{{@betEvent.type}}</div>\n  <div class=\"column2\">{{@betEvent.localGame.localSport.name}}</div>\n  <div class=\"column3\">{{@betEvent.localGame.localLeague.name}}</div>\n  <div class=\"column4\">\n    <i class=\"sb-icon sb-info\"></i>\n    <span>{{@betEvent.localGame.team1_name}} - {{@betEvent.localGame.team2_name}}</span>\n  </div>\n  <div class=\"column5\">{{@betEvent.localMarket.name}}</div>\n  <div class=\"column6\">{{@betEvent.localGame.team1_name}}</div>\n  <div class=\"column7\">{{@betEvent.odd}}</div>\n  <div class=\"column8 text-capitalize status-{{@betEvent.betStatus.title}}\">{{@betEvent.betStatus.title}}</div>\n</div>\n{{yield}}", {"contents":"<div class=\"content-item\">\n  <div class=\"column1\">{{@betEvent.type}}</div>\n  <div class=\"column2\">{{@betEvent.localGame.localSport.name}}</div>\n  <div class=\"column3\">{{@betEvent.localGame.localLeague.name}}</div>\n  <div class=\"column4\">\n    <i class=\"sb-icon sb-info\"></i>\n    <span>{{@betEvent.localGame.team1_name}} - {{@betEvent.localGame.team2_name}}</span>\n  </div>\n  <div class=\"column5\">{{@betEvent.localMarket.name}}</div>\n  <div class=\"column6\">{{@betEvent.localGame.team1_name}}</div>\n  <div class=\"column7\">{{@betEvent.odd}}</div>\n  <div class=\"column8 text-capitalize status-{{@betEvent.betStatus.title}}\">{{@betEvent.betStatus.title}}</div>\n</div>\n{{yield}}","moduleName":"backoffice/components/route-components/affiliates/clients/profile/bet-history/accordion-table/accordion-table-item/accordion-table-item-content/sb-accordion-table-item-content.hbs","parseOptions":{"srcName":"backoffice/components/route-components/affiliates/clients/profile/bet-history/accordion-table/accordion-table-item/accordion-table-item-content/sb-accordion-table-item-content.hbs"}});
import Component from '@glimmer/component';

export default class RouteComponentsAffiliatesClientsProfileBetHistoryAccordionTableAccordionTableItemAccordionTableItemContentSbAccordionTableItemContentComponent extends Component {
  get status() {
    if (this.args.contentItem.status) {
      if (this.args.contentItem.status === 'pending') {
        return 'text-pending';
      } else if (this.args.contentItem.status === 'lost') {
        return 'text-red'
      } else {
        return 'text-green'
      }
    }

    return '';
  }
}
