import ApplicationAdapter from './application';

export default class LocalGameAdapter extends ApplicationAdapter {
  urlForQuery(params) {
    if(params.is_bonus) {
      return this.host + '/bonusTemplates/bonuses/local-game';
    }
    if(params.is_dashboard) {
      return this.host + '/get-dashboard-local-games';
    }
    return this.host + '/get-local-games'
  }
}
