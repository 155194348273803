import AuthenticatedRoute from '../../../authenticated';
import {inject as service} from '@ember/service';

export default class RetailAdministratorsAdministratorCashiersRoute extends AuthenticatedRoute {
  @service navigation;

  definer = 'retail_administrators_key_cashiers';

  queryParams = {
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    id: {
      refreshModel: true
    },
    name: {
      refreshModel: true
    },
    sort_name: {
      refreshModel: true
    },
    sort_type: {
      refreshModel: true
    }
  };

  model(params) {
    return params;
  }
}
