import ApplicationAdapter from './application';
import {inject as service} from '@ember/service'

export default class LanguageAdapter extends ApplicationAdapter {
  @service globalVars
  host = this.globalVars.url.site_api

  urlForCreateRecord() {
    return this.globalVars.url.admin_api + '/create-language'
  }
}
