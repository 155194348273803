import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.menuItems}}\n  <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{/if}}\n\n{{yield}}", {"contents":"{{#if this.menuItems}}\n  <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{/if}}\n\n{{yield}}","moduleName":"backoffice/components/route-components/risk-management/odds-feed/sb-odds-feed.hbs","parseOptions":{"srcName":"backoffice/components/route-components/risk-management/odds-feed/sb-odds-feed.hbs"}});
import Component from '@glimmer/component';

export default class RouteComponentsRiskManagementOddsFeedSbOddsFeedComponent extends Component {

  menuItems = [
    {
      name: 'Sports',
      definer: 'risk_management_odds_feed',
      linkTo: {
        path: 'risk-management.odds-feed.sports',
      },
    },
    {
      name: 'Leagues',
      definer: 'risk_management_odds_feed',
      linkTo: {
        path: 'risk-management.odds-feed.leagues',
      },
    },
    {
      name: 'Markets',
      definer: 'risk_management_odds_feed',
      linkTo: {
        path: 'risk-management.odds-feed.markets',
      },
    },
    {
      name: 'Events',
      definer: 'risk_management_odds_feed',
      linkTo: {
        path: 'risk-management.odds-feed.events',
      },
    }
  ]
}
