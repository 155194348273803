import Service from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';
import {later} from '@ember/runloop';

export default class ModalWindowService extends Service {
  @tracked isVisible = false;
  @tracked activeComponentName = '';
  @tracked fade = false;
  @tracked className = '';
  @tracked calendar = false;
  @tracked data = {};
  @tracked afterCloseModel = null;
  @tracked isVisibleFileManager = false;
  @tracked isVisibleLinkManager = false;
  @tracked fileManagerConfig = null;
  @tracked linkManagerConfig = null;
  @tracked canCloseModal = true;

  constructor() {
    super(...arguments);

    // this.showLinkManager()
  }

  @action
  open(modalName, data = {}, className = '') {
    this.fade = true;
    this.isVisible = true;
    this.activeComponentName = modalName;
    this.data = data;
    this.className = className;
    document.body.classList.add('no-scroll');
  }

  @action
  accept(data) {
    this.data = {...this.data, ...data}
  }

  @action
  cancel() {
    this.data = {...this.data, isAccept: false, cancel: null, confirm: null , headerText : null}
  }

  showFileManager(fileManagerConfig) {
    this.fileManagerConfig = fileManagerConfig;
    this.isVisibleFileManager = true;
    this.fade = true;
  }

  showLinkManager(linkManagerConfig) {
    this.linkManagerConfig = linkManagerConfig;
    this.isVisibleLinkManager = true;
    this.fade = true;
  }

  @action
  closeModal() {
    this.fade = false;
    if (this.afterCloseModel) {
      this.afterCloseModel.rollbackAttributes();
    }
    document.body.classList.remove('no-scroll');
    later(this, function () {
      this.isVisible = false;
      this.activeComponentName = '';
      this.data = {};
      this.className = '';
    }, 200);
  }
}
