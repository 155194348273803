import AuthenticatedRoute from "../authenticated";
import {inject as service} from '@ember/service';

export default class PaymentSettingRoute extends AuthenticatedRoute {
  @service partner;
  @service store;

  definer = 'payment_setting';

  model() {
    return this.store.findAll('payment-template')
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }
}
