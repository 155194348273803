import AuthenticatedRoute from '../../authenticated';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AffiliatesClientsProfileRoute extends AuthenticatedRoute {
  @service store;
  @service navigation;

  @tracked link;

  definer = 'affiliates_clients';

  beforeModel(transition) {
    super.beforeModel(transition);
    this.link = transition.to.name;
  }

  model({ player_id }) {
    let parent = this.store.peekAll('site-content').find(i => i.definer === this.definer);
    let link = null;

    this.store
      .peekAll('site-content')
      .filter(i => i.parent_id === parseInt(parent.id))
      .forEach((item) => {
        let withLink = this.navigation.affiliates_clients_details.find((content) => {
          return content.linkTo === this.link;
        });
        let val = this.navigation.affiliates_clients_details.find(i => i.definer === item.definer);
        if (withLink) {
          link = withLink.linkTo;
        }
        if (!link && val) {
          link = val.linkTo;
        }
      });
    this.router.transitionTo(link);
    return player_id;
  }

  deactivate() {
    this.navigation.customHeaderTitle = null;
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }
}
