import ApplicationAdapter from './application';

export default class PromoAdapter extends ApplicationAdapter {
  urlForCreateRecord() {
    return this.host + '/partner-promo/promos'
  }

  urlForQuery() {
    return this.host + '/partner-promo/promos'
  }

  urlForDeleteRecord(id) {
    return this.host + `/partner-promo/promos/${id}`;
  }

  urlForUpdateRecord(id) {
    return this.host + `/partner-promo/promos/${id}`;
  }
}
