import AuthenticatedRoute from "../../../authenticated";

export default class CasinoSettingsTemplateProvidersRoute extends AuthenticatedRoute {
  definer = 'casino_settings_key_providers';
  queryParams = {
    name: {
      refreshModel: true
    },
    integration_id: {
      refreshModel: true
    }
  };
}
