import AuthenticatedRoute from '../authenticated';
import {inject as service} from '@ember/service';

export default class CasinoSettingsRoute extends AuthenticatedRoute {
  @service store;

  definer = 'casino_settings';

  model() {
    return this.store.findAll('casino-template')
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }
}
