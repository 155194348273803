import Service, { inject as service } from '@ember/service';

export default class TemplateService extends Service {
  @service math;

  withNumber(number) {
    if (this.math.isInt(number) && number !== null) {
      return parseInt(number);
    } else if (this.math.isFloat(number)) {
      return parseFloat(number);
    } else {
      return '';
    }
  }

  withRange(from, to, delimiter = '-') {
    return from && to
      ? `${from}${delimiter}${to}`
      : '';
  }

  withSign(value, sign) {
    return value
      ? `${value}${sign}`
      : '';
  }
}
