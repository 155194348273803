import AuthenticatedRoute from '../authenticated';

export default class SettingsSeoRoute extends AuthenticatedRoute {
  definer = 'settings_seo'

  activate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }
}
