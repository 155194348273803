import AuthenticatedRoute from '../../authenticated';

export default class BonusesBonusTemplatesSiteRoute extends AuthenticatedRoute {
  definer = 'bonuses_bonus_templates';

  activate() {
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerCurrencySelect = false;
  }
}
