import Model, {attr, hasMany} from '@ember-data/model';
import {tracked} from '@glimmer/tracking';
export default class PaymentTemplateCategoryModel extends Model {
  @attr template_id;
  @attr title;
  @attr url;
  @attr('string') icon_url;
  @attr('boolean') is_active;
  @attr('order', {defaultValue: 999}) order;

  @tracked methods;
  @tracked types;
  @tracked ordering_key = 'order';
  @tracked checked = false

  @hasMany('currency', { async: false, inverse: null }) currencies

  get name() {
    return this.title;
  }
}
