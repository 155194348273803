import Controller from '@ember/controller';
import moment from 'moment';

export default class RiskManagementPlayersProfileStatsProfileController extends Controller {
  queryParams = [
    'partner_id',
    'dateMode',
    'graph_start',
    'graph_end',
    'start',
    'end',
    'isBonus'
  ];

  partner_id;
  isBonus;
  dateMode = 'day';
  graph_start = moment().startOf('month').unix();
  graph_end = moment().endOf('day').unix();
  start = moment().startOf('month').unix();
  end = moment().endOf('day').unix();
}
