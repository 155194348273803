import Model, {attr, belongsTo} from '@ember-data/model';

export default class WheelBonusesCurrencyLimitModel extends Model {
    @attr() wheel_template_id;
    @attr() currency_id;
    @attr() limit;



    @belongsTo('currency', { async: false, inverse: null }) currency; 

}
