import Model, {attr} from '@ember-data/model';
import {buildValidations} from 'ember-cp-validations';

const Validations = buildValidations({
  wagering_percent: {
    description: 'Ranking',
    validators: [],
  }
});

export default class CasinoTemplateRankingGameModel extends Model.extend(Validations) {
  @attr categoryName
  @attr gameCode
  @attr gameName
  @attr providerName
  @attr integrationName
}
