import AuthenticatedRoute from '../../authenticated';

export default class RiskManagementBetHistorySportRoute extends AuthenticatedRoute {
  definer = 'r_m_bet_history_sport'

  queryParams = {
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    sort_name: {
      refreshModel: true
    },
    partner_id: {
      refreshModel: true
    },
    player_id: {
      refreshModel: true
    },
    currency_id: {
      refreshModel: true
    },
    sort_type: {
      refreshModel: true
    },
    type_id: {
      refreshModel: true
    },
    sport_id: {
      refreshModel: true
    },
    status_id: {
      refreshModel: true
    },
    is_bonus: {
      refreshModel: true
    },
    search_val: {
      refreshModel: true
    },
    start: {
      refreshModel: true
    },
    end: {
      refreshModel: true
    }
  };

  model(params) {
    return params
  }
}
