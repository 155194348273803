import Model,{attr, belongsTo} from '@ember-data/model';

export default class TransactionHistoryLogModel extends Model {
  @attr occurredAt;
  @attr('string') reason;
  @attr('string') category;
  @attr cashBalanceBefore;
  @attr cashBalanceAfter;
  @attr bonusBalanceBefore;
  @attr bonusBalanceAfter;
  @attr totalBalanceBefore;
  @attr totalBalanceAfter;
  @attr remainingWager;
  @attr gameName;
  @attr manufacturerName;
  @attr providerName;
  @attr betAmount;
  @attr winAmount;
  @attr innerId;
  @attr outerId;
}
