import AuthenticatedRoute from '../../authenticated';

export default class AffiliatesWalletsWithdrawRoute extends AuthenticatedRoute {
  definer = 'affiliates_wallets_key_withdraw'

  queryParams = {
    page: {refreshModel: true},
    perPage: {refreshModel: true},
    partner_id: {refreshModel: true},
    sort_name: {refreshModel: true}
  };

  model(params) {
    return params
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }
}
