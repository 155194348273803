import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class BetSystemSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    betEvents: {embedded: 'always'},
    player: {embedded: 'always'},
    betType: {embedded: 'always'},
    currency: {embedded: 'always'},
    betStatus: {embedded: 'always'}
  }
}
