import Controller from '@ember/controller';
import moment from "moment";

export default class RiskManagementPlayersProfileTransactionController extends Controller {
  queryParams = [
    'end',
    'page',
    'start',
    'perPage',
    'type_id',
    'sort_name',
    'account_type',
    'sort_type',
  ];

  perPage = 10;
  sort_name;
  sort_type;
  account_type = /*'All'*/'Main';
  page = 1;
  type_id;
  start = moment().startOf('day').unix();
  end = moment().endOf('day').unix();
}
