import Model, {attr, belongsTo} from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class SportsbookTemplateSportModel extends Model {
  @service store;

  @attr('number') sportsbook_template_id;
  @attr('string') sport_id;
  @attr('boolean') is_active;
  @attr('number', {defaultValue: 9999999}) order;
  @belongsTo('local-sport', { async: false, inverse: null }) localSport; 

  ordering_key = 'order';

  @tracked name = this.get('localSport.name');

  @tracked childs = [
    {
      id: 10, 
      name: 'Top',
      ordering_key: 'top_order', 
      childs: [],
      getChilds: () => this.getSportsbookTemplateLeagues({ is_top: 1 })
    },
    {
      id: 10, 
      name: 'Popular', 
      ordering_key: 'popular_order', 
      childs: [],
      getChilds: () => this.getSportsbookTemplateLeagues({ is_popular: 1 })
    }
  ]

  getSportsbookTemplateLeagues(params) {
    return this.store.query('sportsbook-template-league', { 
      sportsbook_template_id: this.sportsbook_template_id, 
      sport_id: this.sport_id,
      dis_active: 0,
      ...params 
    })
  }
}
