import AuthenticatedRoute from '../../authenticated';
import {inject as service} from '@ember/service';

export default class SettingsPartnersIndexRoute extends AuthenticatedRoute {
  definer = 'settings_partners'

  @service store;

  queryParams = {
    group_id: {
      refreshModel: true
    }
  };

  model(params) {
		this.store.query('group', {});
		this.store.query('partner', {include:'group', group_id: params.group_id});

    return params
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }
}
