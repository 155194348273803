import Controller from '@ember/controller';
import moment from 'moment';

export default class StatsDashboardController extends Controller {
  queryParams = [
    'page',
    'perPage',
    'dateMode',
    'graphMode',
    'graph_start',
    'graph_end',
    'product',
    'start',
    'end'
  ];

  page = 1;
  perPage = 10;
  dateMode = 'day';
  graphMode = 10;
  product = 'all';
  graph_start = moment().startOf('month').unix();
  graph_end = moment().endOf('day').unix();
  start = moment().startOf('month').unix();
  end = moment().endOf('day').unix();
}
