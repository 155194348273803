import Transform from '@ember-data/serializer/transform';

export default class OrderTransform extends Transform {
  deserialize(serialized) {
    return Number(serialized);
  }

  serialize(deserialized) {
    return deserialized;
  }
}
