import Model,{attr, belongsTo} from '@ember-data/model';

export default class AdminTransactionModel extends Model {
  @attr document_id;
  @attr from;
  @attr to;
  @attr account_id;
  @attr operation;
  @attr created_at;
  @attr amount;

  @belongsTo('admin-document', { async: false, inverse: null }) adminDocument
  @belongsTo('admin-account', { async: false, inverse: null }) adminAccount
}
