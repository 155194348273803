import Model, {attr, belongsTo} from '@ember-data/model';
import {tracked} from '@glimmer/tracking';

export default class PaymentDepositMethodModel extends Model {
  @attr service_id;
  @attr alias;
  @attr name;
  @attr description;
  @attr status;
  @attr min;
  @attr max;
  @attr fee_in;
  @attr fee_out;
  @attr payment_cashbox_id;
  @attr currency_id;
  @attr integration_setting_id;
  @attr('image') image;
  @belongsTo('payment-currency', { async: false, inverse: null }) paymentCurrency;
  @belongsTo('payment-integration-setting', { async: false, inverse: null }) paymentIntegrationSetting;

  @tracked checked = false;

  modelName = 'payment-deposit-method';
}
