import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"field-box\">\n  <span class=\"label\">{{@route.name}}</span>\n  <div class=\"select-box\">\n\n    <select name=\"\" id=\"\" onchange={{action this.select value='target.value'}}>\n    \t<option></option>\n      {{#each @route.childs as | child |}}\n        <option value={{child.name}}>{{child.name}}</option>\n      {{/each}}\n    </select>\n    <i class=\"sb-icon sb-triangledown\"></i>\n  </div>\n</div>\n\n{{#if this.isShowChilds}}\n\t<GenericComponents::LinkManager::ChunkLinkItem::SbChunkLinkItem @route={{this.route}} />\n{{/if}}", {"contents":"<div class=\"field-box\">\n  <span class=\"label\">{{@route.name}}</span>\n  <div class=\"select-box\">\n\n    <select name=\"\" id=\"\" onchange={{action this.select value='target.value'}}>\n    \t<option></option>\n      {{#each @route.childs as | child |}}\n        <option value={{child.name}}>{{child.name}}</option>\n      {{/each}}\n    </select>\n    <i class=\"sb-icon sb-triangledown\"></i>\n  </div>\n</div>\n\n{{#if this.isShowChilds}}\n\t<GenericComponents::LinkManager::ChunkLinkItem::SbChunkLinkItem @route={{this.route}} />\n{{/if}}","moduleName":"backoffice/components/generic-components/link-manager/chunk-link-item/sb-chunk-link-item.hbs","parseOptions":{"srcName":"backoffice/components/generic-components/link-manager/chunk-link-item/sb-chunk-link-item.hbs"}});
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';

export default class GenericComponentsLinkManagerChunkLinkItemSbChunkLinkItemComponent extends Component {
  @service eventTrigger;
  @tracked route = null;
  @tracked selectedRouteName = null;

  get isShowChilds() {
    return this.route && this.route.childs.length && this.args.route.childs.find(i => i.name === this.route.name);
  }

  @action
  select(routeName) {
    this.selectedRouteName = routeName;

    let oldRoute = this.route;
    this.route = this.args.route.childs.find(i => i.name === this.selectedRouteName);
    let position = (this.route && Number.isInteger(this.route.position)) ? this.route.position : oldRoute.position;
    this.eventTrigger.trigger('link-manager-change-link-chunk', position, this.route);
  }

  @tracked displayLabel;

  @action
  updateDisplay(sb) {
    if (sb.element.querySelector('option:checked')) {
      const label = sb.element.querySelector('option:checked').textContent.trim();

      this.displayLabel = label;
    }
  }
}
