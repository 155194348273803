import ApplicationAdapter from './application';

export default class AdminAccountAdapter extends ApplicationAdapter {
  urlForQuery(params) {
    if(params.retail){
      return this.host + '/retail/cashboxes/get-accounts';
    }
    return this.host + '/admin-accounts'
  }

  urlForCreateRecord(model, params) {
    if (params.__attributes.isManager) {
      return this.host + '/retail/managers/create-wallet';
    } else if (params.__attributes.isAdministrator) {
      return this.host + '/retail/administrators/create-wallet';
    } else if (params.__attributes.isCashier) {
      return this.host + '/retail/cashiers/create-wallet';
    }
    return this.host + '/create-admin-account';
  }
}
