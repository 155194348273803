import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"p-4 modal-bonus-amount\">\n  <LayoutComponents::Table::SbTable\n    @titles={{this.titles}}\n    @isEmpty={{eq this.bonusProgressives.length 0}}\n  >\n    {{#each this.bonusProgressives as |bonusProgressive|}}\n      <tr>\n        <td>{{bonusProgressive.range}}</td>\n        <td>{{bonusProgressive.percentage}}</td>\n        <td>{{bonusProgressive.fs}}</td>\n      </tr>\n    {{/each}}\n  </LayoutComponents::Table::SbTable>\n</div>\n{{yield}}\n", {"contents":"<div class=\"p-4 modal-bonus-amount\">\n  <LayoutComponents::Table::SbTable\n    @titles={{this.titles}}\n    @isEmpty={{eq this.bonusProgressives.length 0}}\n  >\n    {{#each this.bonusProgressives as |bonusProgressive|}}\n      <tr>\n        <td>{{bonusProgressive.range}}</td>\n        <td>{{bonusProgressive.percentage}}</td>\n        <td>{{bonusProgressive.fs}}</td>\n      </tr>\n    {{/each}}\n  </LayoutComponents::Table::SbTable>\n</div>\n{{yield}}\n","moduleName":"backoffice/components/modals/bonuses/bonus-amount/sb-bonus-amount.hbs","parseOptions":{"srcName":"backoffice/components/modals/bonuses/bonus-amount/sb-bonus-amount.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';

export default class ModalsBonusesBonusAmountSbBonusAmountComponent extends Component {
  @service modalWindow;
  @service template;

  titles = [
    'Range',
    'Percentage',
    'FS'
  ]

  get bonusProgressives() {
    return this.modalWindow.data.bonus?.progressives?.map(({amount, percentage, numberOfFreeSpins}) => ({
      range: `${this.template.withRange(this.template.withNumber(amount?.from), this.template.withNumber(amount?.to))}`,
      percentage: `${this.template.withSign(this.template.withNumber(percentage), '%')}`,
      fs: this.template.withNumber(numberOfFreeSpins) || ''
    })) || []
  }
}
