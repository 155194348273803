import Model, {attr,belongsTo} from '@ember-data/model';

export default class AutomaticSportKpiModel extends Model {
  @attr date
  @attr currency_id
  @attr sport_id
  @attr market_id
  @attr type_id
  @attr bets_count
  @attr win_count
  @attr lose_count
  @attr game_type
  @attr recalculated_count

  @belongsTo('sport', { async: false, inverse: null }) sport
  @belongsTo('market', { async: false, inverse: null }) market
  @belongsTo('automatic-sport-kpi-type', { async: false, inverse: null }) kpiType
}
