import Controller from '@ember/controller';
import {inject as service} from '@ember/service';
export default class MaxBetController extends Controller {
  @service maxBet;

  constructor() {
    super(...arguments);
    this.maxBet.getCurrencyGroupsTask.perform();
  }
}
