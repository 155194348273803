import ApplicationAdapter from './application';

export default class RightSectionBannerAdapter extends ApplicationAdapter {

	urlForQuery() {
    return this.host + '/sportsbook/right-section-banners'
  }

  urlForCreateRecord() {
    return this.host + '/sportsbook/right-section-banners'
  }

  urlForDeleteRecord(id) {
    return this.host + `/sportsbook/right-section-banners/${id}`;
  }

  urlForUpdateRecord(id) {
    return this.host + `/sportsbook/right-section-banners/${id}`;
  }
}
