import JSONAPIAdapter from '@ember-data/adapter/json-api';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { storageFor } from 'ember-local-storage';

export default class ApplicationAdapter extends JSONAPIAdapter {
  @service session;
  @service notifications;
  @service globalVars;

  @storageFor('language') lngParams;
  @storageFor('partner') partnerStorage;

  host = this.globalVars.url.admin_api;
  siteHost = this.globalVars.url.site_api;

  @computed('session.{data.authenticated.access_token,isAuthenticated}','partnerStorage.isEurOn', 'lngParams.currentLanguage')
  get headers() {
    let headers = {};
    headers['language'] = this.lngParams.get('currentLanguage');
    headers['Time-zone'] = Intl.DateTimeFormat().resolvedOptions().timeZone ?? "Asia/Yerevan";
    headers['Accept'] = 'application/json, text/javascript, */*; q=0.01';
    headers['Content-Type'] = 'application/json; charset=UTF-8';
    headers['In-Eur'] = this.partnerStorage.get('isEurOn');
    headers['ip'] = window.ip;
    if (this.session.isAuthenticated) {
      // OAuth 2
      headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;
    }

    return headers;
  }

  handleResponse(status, header, response) {
    if(status === 422) {
      response?.errors.forEach((error) => {
        this.notifications.error(error, {
          autoClear: true
        });
      })
    }

    return super.handleResponse(...arguments);
  }
}
