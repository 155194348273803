import AuthenticatedRoute from '../../../authenticated';

export default class PaymentPaymentMethodsPaymentMethodDetailConfigurationRoute extends AuthenticatedRoute {
  definer = 'payment_methods';

  model(params) {
    return {...this.paramsFor('payment.payment-methods.payment-method-detail'), ...params};
  }

  activate() {
    this.partner.showCurrentPlayerCurrencies = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showCurrentPlayerCurrencies = false;
    this.partner.showPartnerCurrencySelect = false;
  }
}
