import ApplicationAdapter from './application';

export default class PartnerSeoAdapter extends ApplicationAdapter {
  urlForUpdateRecord(id) {
    return this.host + `/settings-seo/seos/${id}`;
  }

  urlForCreateRecord() {
    return this.host + `/settings-seo/seos`;
  }

  urlForQuery() {
    return this.host + `/settings-seo/seos`;
  }

  urlForDeleteRecord(id) {
    return this.host + `/settings-seo/seos/${id}`;
  }
}
