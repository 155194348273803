import Controller from '@ember/controller';
import moment from "moment";

export default class AffiliatesTransactionsHistoryController extends Controller {
  queryParams = [
    'page',
    'perPage',
    'start',
    'end',
    'sort_name',
    'sort_type',
    'currency_id',
    'integration_id',
    'amount_range_start',
    'amount_range_end',
    'affiliate_id',
    'partner_id',
  ];

  page;
  perPage;
  start = moment().startOf('month').unix();;
  end = moment().endOf('day').unix();
  sort_name;
  affiliate_id;
  sort_type;
  currency_id;
  integration_id;
  amount_range_start;
  amount_range_end;
  partner_id;
}
