import AuthenticatedRoute from '../authenticated';

export default class SettingsSlackMessagesRoute extends AuthenticatedRoute {
  definer = 'settings_slack_messages'

  activate() {
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerCurrencySelect = true;
  }
}
