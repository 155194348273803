import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='add-new-managers-fields' {{did-insert this.onDidInsert}}>\n  <div class='field-box'>\n    <span class='label'>{{t 'Currencies'}}</span>\n    <div class='field-box'>\n      <div class='select-box'>\n        <select onchange={{action this.setCurrencyId}}>\n          <option></option>\n          {{#each this.currencies as |currency|}}\n            <option value='{{currency.id}}'>{{currency.code}}</option>\n          {{/each}}\n        </select>\n        <i class='sb-icon sb-triangledown'></i>\n      </div>\n    </div>\n  </div>\n\n  <div class='field-box'>\n    <Input type='number' @value={{this.model.value}} />\n  </div>\n\n  {{#if this.saveTask.isIdle}}\n    <button class='btn btn-full' type=\"button\" {{action this.save}}>{{t 'Save'}}</button>\n  {{else}}\n    <button class='btn btn-full loading' type=\"button\"></button>\n  {{/if}}\n</div>", {"contents":"<div class='add-new-managers-fields' {{did-insert this.onDidInsert}}>\n  <div class='field-box'>\n    <span class='label'>{{t 'Currencies'}}</span>\n    <div class='field-box'>\n      <div class='select-box'>\n        <select onchange={{action this.setCurrencyId}}>\n          <option></option>\n          {{#each this.currencies as |currency|}}\n            <option value='{{currency.id}}'>{{currency.code}}</option>\n          {{/each}}\n        </select>\n        <i class='sb-icon sb-triangledown'></i>\n      </div>\n    </div>\n  </div>\n\n  <div class='field-box'>\n    <Input type='number' @value={{this.model.value}} />\n  </div>\n\n  {{#if this.saveTask.isIdle}}\n    <button class='btn btn-full' type=\"button\" {{action this.save}}>{{t 'Save'}}</button>\n  {{else}}\n    <button class='btn btn-full loading' type=\"button\"></button>\n  {{/if}}\n</div>","moduleName":"backoffice/components/modals/payment/currency-courses/add/sb-add.hbs","parseOptions":{"srcName":"backoffice/components/modals/payment/currency-courses/add/sb-add.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';

export default class ModalsPaymentCurrencyCoursesAddSbAddComponent extends Component {
  @service store;
  @service modalWindow;
  @service notifications;
  @service eventTrigger;

  @tracked model = null;
  @tracked currencyCourses = [];

  get currencies() {
    return this.store.peekAll('currency').filter((currency) => {
      return !this.currencyCourses.find(i => i.currency_id === +currency.id) && currency.id != this.modalWindow.data.currency_id;
    });
  }

  @action
  onDidInsert() {
    this.currencyCourses = this.store.peekAll('currency-course').filter(i => i.base_currency_id === +this.modalWindow.data.currency_id);
    this.createNewRecord();
  }

  createNewRecord() {
    this.model = this.store.createRecord('currency-course', {
      base_currency_id: +this.modalWindow.data.currency_id,
      time: parseInt(Date.now() / 1000),
      is_manual: true,
      value: 0,
    });
  }

  @action
  setCurrencyId(event) {
    this.model.currency_id = +event.target.value;
    this.model.code = this.currencies.find(i => i.id === this.model.currency_id.toString()).code;
  }

  @action
  save() {
    this.saveTask.perform();
  }

  willDestroy() {
super.willDestroy(...arguments);
    this.model.deleteRecord();
  }

  @task(function* () {
    yield this.model
      .save()
      .then(() => {
        this.currencyCourses = this.store.peekAll('currency-course').filter(i => i.base_currency_id === +this.modalWindow.data.currency_id);
        this.createNewRecord();
        this.notifications.success('Successfully changed', {
          autoClear: true,
        });
        this.eventTrigger.trigger('update-list-currency-course');
      })
      .catch((payload) => {
        if (payload && payload.errors) {
          this.notifications.error(payload.errors, {
            autoClear: true,
          });
        }
      });
  })
  saveTask;
}
