import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='main-info-container payment-settings-templates' {{did-insert this.didInsert}}>\n  {{#if this.maxBet.getCurrencyGroupsTask.isIdle}}\n    <GenericComponents::BasicNavMenu::SbBasicNavMenu\n      @menuItems={{this.maxBet.navMenuItems}}\n      @select={{action this.selectConfig}}\n    />\n  {{/if}}\n</div>\n{{yield}}\n", {"contents":"<div class='main-info-container payment-settings-templates' {{did-insert this.didInsert}}>\n  {{#if this.maxBet.getCurrencyGroupsTask.isIdle}}\n    <GenericComponents::BasicNavMenu::SbBasicNavMenu\n      @menuItems={{this.maxBet.navMenuItems}}\n      @select={{action this.selectConfig}}\n    />\n  {{/if}}\n</div>\n{{yield}}\n","moduleName":"backoffice/components/route-components/settings/max-bet/sb-max-bet.hbs","parseOptions":{"srcName":"backoffice/components/route-components/settings/max-bet/sb-max-bet.hbs"}});
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency';
import {action, set} from '@ember/object';

export default class RouteComponentsSettingsMaxBetSbMaxBetComponent extends Component {
  @service partner;
  @service store;
  @service router;
  @service maxBet;

  @tracked firstCurrencyGroup;

  constructor() {
    super(...arguments);
    this.initPage();
  }

  @action
  didInsert() {
    this.setupObserver();
  }

  setupObserver() {
    if (!this.partner.selectedIdObserverIsSet) {
      // eslint-disable-next-line ember/no-observers
      this.partner.addObserver('selectedId', this, this.handlePartnerChange);
      set(this, 'partner.selectedIdObserverIsSet', true);
    }
  }

  handlePartnerChange() {
    this.maxBet.getCurrencyGroupsTask.perform();
    this.selectConfig(this.firstCurrencyGroup);
  }

  initPage() {
    this.getCurrencyGroups.perform()
      .then((currencyGroups) => {
        this.firstCurrencyGroup = currencyGroups[0].id;
        if (!this.router.currentRoute.params.currency_group_id) {
          this.selectConfig(currencyGroups[0].id);
        }
      });
  }

  @action
  selectConfig(newCurrencyGroupId) {
    this.router.transitionTo('settings.max-bet.setting', newCurrencyGroupId);
  }

  @task
  * getCurrencyGroups() {
    return yield this.store.query('max-bet-currency-group', {
      partner_id: this.partner.selectedId,
    });
  }
}
