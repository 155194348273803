import Model, {attr, belongsTo} from '@ember-data/model';

export default class PartnerStyleModel extends Model {
  @attr partner_id
  @attr logo_dark
  @attr favicon_dark
  @attr styles_dark
  @attr loading_dark
  @attr logo_light
  @attr favicon_light
  @attr loading_light
  @attr styles_light
  @attr theme_id
  @attr created_at
  @attr updated_at

  @belongsTo('theme', { async: false, inverse: null }) theme;

  get darkStyles() {
    return JSON.parse(this.styles_dark)
  }

  get lightStyles() {
    return JSON.parse(this.styles_light)
  }
}
