import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<MainComponents::Segment::UpdateSegmentContent @segment={{this.segment}} @updateTask={{this.updateTask}} @button={{t 'Update'}}/>\n", {"contents":"<MainComponents::Segment::UpdateSegmentContent @segment={{this.segment}} @updateTask={{this.updateTask}} @button={{t 'Update'}}/>\n","moduleName":"backoffice/components/modals/segments/update-segment/sb-update-segment.hbs","parseOptions":{"srcName":"backoffice/components/modals/segments/update-segment/sb-update-segment.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

export default class ModalsSegmentsUpdateSegmentSbUpdateSegmentComponent extends Component {
  @service modalWindow;
  @service notification;
  @service partner;
  @service store;

  @tracked segment = this.modalWindow.data.segment;
  @tracked name = this.segment.name;
  @tracked description = this.segment.description;
  @tracked frequency_type = this.segment.frequency_type;
  @tracked for_payment = +this.segment.for_payment;

  get isDisabled() {
    return !this.name.trim();
  }


  @task(function* (data) {
    this.segment.name = data.name;
    this.segment.description = data.description;
    this.segment.frequency_type = data.frequency_type;
    this.segment.for_payment = data.for_payment;
    yield this.segment.save().then(() => {
      this.modalWindow.data.onTrigger && this.modalWindow.data.onTrigger();
      this.modalWindow.closeModal();
    });
  })
  updateTask;
}
