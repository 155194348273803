import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {computed,set} from '@ember/object';
import {task} from 'ember-concurrency';
import MainDashboard from "./main-dashboard";
import {storageFor} from "ember-local-storage";

export default class ProfileMain extends MainDashboard {
  @service navigation;
  @service store;
  @service globalVars;

  @tracked player = null;
  @tracked initBlockStatus;
  @tracked playerKpiData = {};
  @tracked include = '';
  @tracked EUR = null;
  @tracked selectedCountryId;

  @storageFor('partner') partnerStorage;

  constructor() {
    super(...arguments);
    this.playerTask.perform().then(() => {
      if (this.runTaskFunctions) {
        this.runTaskFunctions();
      }
    })
  }

  getEurCurrency() {
    const currencies = this.store.peekAll('currency')
    this.EUR = currencies.filter(currency => {
      return currency.code === 'EUR';
    })
    this.EUR = this.EUR.length > 0 ? this.EUR[0] : null;
  }

  @computed('args.params.player_id', 'player', 'store')
  get playerContent() {
    return this.store.peekRecord('player', parseInt(this.args.params.player_id));
  }

  @(task(function* () {
    let data = {};
    data.include = this.include;
    data.id = this.args.params.player_id;
    data.currency_id = this.partnerStorage.get('currentPlayerCurrencyId');
    yield this.store.queryRecord('player', data).then((player) => {
      this.player = player;
      this.playerKpiTask.perform();
      this.getEurCurrency();
      this.initBlockStatus = player.is_blocked;
      let type = this.store.peekAll('account-type').filter(i => i.wallet === 'main').find(i => i.owner === 'player');
      if (!this.partnerStorage.get('currentPlayerCurrencyId') || (this.partnerStorage.get('currentPlayerCurrencyId') && !player.accounts.filter(i => i.type_id === parseInt(type.id)).find(i => i.currency_id === this.partnerStorage.get('currentPlayerCurrencyId').toString()))) {
        set(this.partnerStorage, 'currentPlayerCurrencyId', +player.accounts.filter(i => i.type_id === parseInt(type.id))[0].get('currency_id'))
      }
      this.navigation.customHeaderTitle = this.player.username
      this.navigation.playerVerified = this.player.verified
    }).catch(({payload}) => {
      this.isSuccessful = false;
      if (payload && payload.errors) {
        this.notifications.error(payload.errors, {
          autoClear: true
        })
      }
    });
  }))playerTask

  @task
  *playerKpiTask(){
    yield this.ajax.request(`${this.globalVars.url.admin_api}/players/${this.player.id}/kpis`).then(data => {
      this.playerKpiData = data;
    }).catch(({payload}) => {
      this.notifications.error('kpi failed to load', {
        autoClear: true
      })
    });
  }

  @computed('playerKpiTask.isIdle', 'playerKpiTask.performCount')
  get playerKpiTaskLoaded() {
    return this.playerKpiTask.isIdle && this.playerKpiTask.performCount > 0;
  }

}
