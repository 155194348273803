import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';

export default class AuthenticatedRoute extends Route {
  @service session;
  @service partner;
  @service router;
  @service currentUser;
  @service store;

  beforeModel() {
    if (!this.session.isAuthenticated) {
      this.router.transitionTo('login');
    }
    if (!this.currentUser.roleDefined(this.definer)) {
      this.session.invalidate();
      this.router.transitionTo('/');
    }
  }

}
