import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class BonusPlayerSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    promotionPlayer: {embedded: 'always'},
    playerFreeSpin: {embedded: 'always'},
    player: {embedded: 'always'},
    bonus: {embedded: 'always'},
    bonusAction: {embedded: 'always'},
    currency: {embedded: 'always'}
  };
}
