import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.affiliateLink}}\n<RouteComponents::Affiliates::LinksBanners::LinkBannerForm::SbLinkBannerForm\n        @setImageUrl={{this.setImageUrl}}\n        @affiliateLink={{this.affiliateLink}}\n        @linkBannerSave={{this.linkBannerSave}}\n        @selectBonus={{this.selectBonus}}\n        @bonuses={{this.bonuses}}\n        @setStatus={{this.setStatus}}\n        @addBanner={{this.addBanner}}\n        @deleteBanner={{this.deleteBanner}}\n/>\n{{/if}}", {"contents":"{{#if this.affiliateLink}}\n<RouteComponents::Affiliates::LinksBanners::LinkBannerForm::SbLinkBannerForm\n        @setImageUrl={{this.setImageUrl}}\n        @affiliateLink={{this.affiliateLink}}\n        @linkBannerSave={{this.linkBannerSave}}\n        @selectBonus={{this.selectBonus}}\n        @bonuses={{this.bonuses}}\n        @setStatus={{this.setStatus}}\n        @addBanner={{this.addBanner}}\n        @deleteBanner={{this.deleteBanner}}\n/>\n{{/if}}","moduleName":"backoffice/components/route-components/affiliates/links-banners/edit/sb-edit-link.hbs","parseOptions":{"srcName":"backoffice/components/route-components/affiliates/links-banners/edit/sb-edit-link.hbs"}});
import {tracked} from '@glimmer/tracking';
import {task} from 'ember-concurrency';
import AffiliateChanges from "../../../../abstract-components/affiliate-changes";

export default class RouteComponentsAffiliatesLinksBannersEditSbEditLinkComponent extends AffiliateChanges {
  @tracked affiliate_link_id = this.args.params.affiliate_link_id
  @tracked affiliateLink;

  constructor() {
    super(...arguments);
    if (this.currentUser.user.isWithoutPartner || this.currentUser.user.isDefault) {
      this.partnerId = this.partner.selected_id;
      this.getAffiliateLinkTask.perform().then(() => {
        this.getBonusesTask.perform(this.partner.selected_id);
      })
    } else {
      this.affiliateSettingId = this.currentUser.user.affiliateSetting.get('id')
      this.partnerId = this.currentUser.user.partner_id;
      this.getAffiliateLinkTask.perform().then(() => {
        this.getBonusesTask.perform(this.currentUser.user.partner_id);
      })
    }
  }

  @(task(function* () {
    yield this.store.query('affiliate-link', {
      id: parseInt(this.affiliate_link_id),
      include: 'affiliateBonus,affiliateBanners'
    }).then(affiliateLinks => {
      this.affiliateLink = affiliateLinks[0];
      if (!this.affiliateLink.affiliateBanners) {
        this.addBanner()
      }
    }).catch(({payload}) => {
      if (payload && payload.errors) {
        payload.errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true
          });
        })
      }
    });
  }))getAffiliateLinkTask

}
