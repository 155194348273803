import Model, { attr, belongsTo } from '@ember-data/model';

export default class PlayerFreeSpin extends Model {
  @attr('number') status;
  @attr('number') bonus_player_id;
  @attr('number') bet_value_per_spin;
  @attr('number') spin_count;
  @attr('number') spins_received;
  @attr('number') wager;
  @attr('number') win_amount;
  @attr('number') created_at;
  @attr('number') updated_at;

  @belongsTo('casino-game-list') casinoGameList

  get statusLabel(){
    if(this.status === 20){
      return 'Allocated';
    }
    if(this.status === 30){
      return 'Started';
    }
    if(this.status === 40){
      return 'Credited';
    }
  }
}
