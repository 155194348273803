import ApplicationAdapter from './application';

export default class SportsbookTemplateAdapter extends ApplicationAdapter {
  urlForFindAll() {
    return this.host + '/sportsbook/templates'
  }

  urlForCreateRecord(modelName, snapshot) {
    if(snapshot.record.duplicateFromTemplateId) {
      return this.host + `/sportsbook/templates/${snapshot.record.duplicateFromTemplateId}/duplicate`
    }

    return this.host + '/sportsbook/templates'
  }

  urlForUpdateRecord($id) {
    return this.host + '/sportsbook/templates/' + $id
  }

  urlForDeleteRecord($id) {
    return this.host + '/sportsbook/templates/' + $id
  }
}
