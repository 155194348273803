import Component from '@glimmer/component';
import {action, set} from '@ember/object';
import {tracked} from '@glimmer/tracking';
import {task} from 'ember-concurrency';
import {inject as service} from '@ember/service'

export default class AffiliateChanges extends Component {
  @service store;
  @service partner;
  @service currentUser;
  @service router;
  @service notifications;
  @service ajax;
  @service intl;

  @tracked affiliateUsers;
  @tracked affiliateLinks;
  @tracked bonuses;
  @tracked partnerId;
  @tracked desktopHost;
  @tracked selectedUser = null;

  @tracked deviceType = 'desktop';


  @action
  setStatus(affiliateLink) {
    if (typeof affiliateLink.status === 'undefined') {
      set(affiliateLink, 'status', true);
    } else {
      affiliateLink.status = !affiliateLink.status
    }
  }

  @action
  addBanner() {
    let affiliateBanner = this.store.createRecord('affiliate-banner')
    affiliateBanner.affiliate_link_id = this.affiliateLink.id;
    this.affiliateLink.affiliateBanners.pushObject(affiliateBanner)
  }

  @action
  deleteBanner(affiliateBanner) {
    this.affiliateLink.affiliateBanners.removeObject(affiliateBanner)
    affiliateBanner.unloadRecord()
  }

  @action
  selectBonus(bonusId) {
    this.affiliateLink.bonus_id = bonusId
  }

  @action
  linkBannerSave() {
    this.affiliateLink.save().then(() => {
        this.notifications.success(this.intl.t('Successfully Changed'), {
          autoClear: true
        })
      }
    ).catch((payload) => {
      if (payload.errors) {
        payload.errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true
          });
        })
      }
    });
  }

  @(task(function* (partnerId, create = false) {
    this.bonuses = yield this.store.query('affiliate-bonus', {partner_id: partnerId})
    if (create && this.bonuses[0] !== undefined) {
      this.affiliateLink.bonus_id = this.bonuses[0].id;
    }
  }))getBonusesTask

  @(task(function* () {
    yield this.store.query('user', {
      partner_id: this.partner.selected_id,
      currency_id: this.partner.currency_id,
      is_affiliate: true,
      include: 'mainAdminAccounts,role.roleType,affiliateLinks.affiliateBanners,affiliateLinks.bonus,affiliateSetting'
    }).then((users) => {
      if (users.length && !this.selectedUser) {
        this.selectedUser = users[0]
      }
      this.affiliateLinks = users.length ? this.selectedUser.affiliateLinks : null;
      if (this.affiliateLinks && this.affiliateLinks.length) {
        this.selectedLink = this.affiliateLinks[0]
      }
      this.affiliateUsers = users
    })
  }))getAffiliateUsersTask

  @(task(function* () {
    yield this.ajax.request(`/affiliate/affiliate-domain-manager`, {data: {partner_id: this.partner.selected_id}}).then((retData) => {
      this.desktopHost = retData['affiliate-partner-domain-managers'].desktop_host;
    });
  }))getAffiliateDomainManagerTask
}
