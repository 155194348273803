import ApplicationAdapter from './application';

export default class BonusPlayerAdapter extends ApplicationAdapter {
  urlForQuery(params) {
    if(params.is_free_spin){
      return this.host + '/casino/free-spin/bonus-players'
    }
    return this.host + '/players/players-bonuses'
  }
  urlForUpdateRecord(id) {
    return this.host + `/player-bonuses/${id}`
  }
}
