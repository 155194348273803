import AuthenticatedRoute from '../../authenticated';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class CasinoIntegrationSettingRoute extends AuthenticatedRoute {
  @service store;

  definer = 'casino_integration_setting';
  model(params) {
    return this.getCasinoIntegrationSettings.perform(params.integration_id).then((setting) => {
      if (!setting) {
        setting = this.store.createRecord('casino-integration-setting', {
          integration_id: params.integration_id,
          partner_id: this.partner.selected_id,
          currency_id: this.partner.currency_id,
          host: params.integration_id == 2 ? 'https://app.mangobet.org' : 'https://pi.njoybingo.com/game.do',
        });
      }

      return setting;
    });
  }

  @task(function* (integration_id) {
    this.store.unloadAll('casino-integration-setting');
    return yield this.store.queryRecord('casino-integration-setting', {
      id: integration_id,
      partner_id: this.partner.selected_id,
      currency_id: this.partner.currency_id,
      include: 'integration',
    });
  })
  getCasinoIntegrationSettings;

  activate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }

  deactivate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }
}
