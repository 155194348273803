import ApplicationAdapter from './application';

export default class LocalSportAdapter extends ApplicationAdapter {
  urlForQuery(params) {
    if(params.affiliate) {
      return this.host + '/affiliate/client/local-sport';
    }
    if(params.is_bonus) {
      return this.host + '/bonusTemplates/bonuses/local-sport';
    }
    if(params.is_dashboard) {
      return this.host + '/get-dashboard-local-sports';
    }
    return this.host + '/get-local-sports'
  }
}
