import Model, {attr, hasMany} from '@ember-data/model';
import {buildValidations, validator} from 'ember-cp-validations';
import {computed} from '@ember/object';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';


const Validations = buildValidations({
  free_spin_wager: {
    description: 'Wager multiplier - Free Spins',
    validators: [
      validator('presence', true)
    ],
  },
  free_spin: {
    description: 'Count Of Free Spins',
    validators: [
      validator('presence', true)
    ],
    disabled: computed('model.bonus_action_id', function () {
      return this.model.bonus_action_id === this.model.bonusService.actionDepositId
        || this.model.bonus_action_id === this.model.bonusService.actionManualId;
    })
  },
  bet_value_per_spin: {
    description: 'Bet value per spin, in cents',
    validators: [
      validator('presence', true),
      validator('float', {
        gt: 0
      })
    ],
  }
});
export default class BonusBenefitModel extends Model.extend(Validations) {
  @service bonusService;
  @attr('number') bonus_id;
  @attr('number') bet_value_per_spin;
  @attr('number') game_category_id;
  @attr('number', {defaultValue: 30}) free_spin_wager;
  @attr('string', {defaultValue: '[]'}) game_providers;
  @attr('number') casino_integration_id;
  @attr('number', {defaultValue: null}) free_spin;
  @attr('string', {defaultValue: '[]'}) game_ids;
  @tracked bonus_action_id;

  @hasMany('casino-game-provider', {async: false, inverse: null}) gameProviders;
  @hasMany('casino-game-list', {async: false, inverse: null}) games;
  @hasMany('bonus-benefit-currency', {async: false, inverse: null}) bonusBenefitCurrencies;
}
