import Helper from '@ember/component/helper';
import {inject as service} from '@ember/service'

export default class CamelToSnake extends Helper {
  @service currentUser;

  compute([text]) {
    let result = text.replace( /([A-Z])/g, " $1" );
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
}
