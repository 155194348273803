import Model, {attr, hasMany} from '@ember-data/model';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';

export default class CurrencyModel extends Model {
  @service store;

  @attr name
  @attr code
  @attr symbol
  @attr('boolean') is_crypto

  @hasMany('accounts', { async: false, inverse: null }) accounts
  @hasMany('bet', { async: false, inverse: null }) bet

  @tracked title = this.name

  get bonusLogicConditions() {
    return this.store.peekAll('bonus-logic-condition').filter(i => i.product_value === this.id);
  }
}
