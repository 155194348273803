import Model, {attr, belongsTo, hasMany} from '@ember-data/model';

export default class PromotionModel extends Model {
  @attr bonus_id;
  @attr status;
  @attr language_id;
  @attr('number', { defaultValue: 0 }) order;
  @attr updated_at;
  @attr updated_by;

  @belongsTo('bonus', { async: false, inverse: null }) bonus;
  @hasMany('promotion-by-language', { async: false, inverse: null }) promotionByLanguages;
  @belongsTo('promotion-by-language', { async: false, inverse: null }) promotionByLanguage;

  get bonusCode() {
    if (this.bonus && this.bonus.get('bonus_code')) {
      return this.bonus.get('bonus_code');
    }
    return null;
  }
  get title() {
    if (this.bonus && this.bonus.get('bonus_code')) {
      return this.bonus.get('bonus_code');
    }
    return null;
  }
}
