import ApplicationAdapter from './application';

export default class CashboxAdapter extends ApplicationAdapter {

  urlForCreateRecord(model, params) {
    if (params.__attributes.retail) {
      return this.host + '/retail/cashboxes/create'
    }
    return this.host + '/cashboxes'
  }

  urlForQuery(params) {
    if (params.retail) {
      return this.host + '/retail/cashboxes/get'
    }
    return this.host + '/cashboxes'
  }

  urlForQueryRecord(params) {
    if (params.retail) {
      return this.host + '/retail/cashboxes/get'
    }
    return this.host + '/cashboxes'
  }

  urlForUpdateRecord(id, model, params) {
    if (params.__attributes.retail) {
      return this.host + `/retail/cashboxes/update/${id}`
    }
    return this.host + '/cashboxes/' + id
  }

  urlForDeleteRecord(id, model, params) {
    if (params.__attributes.retail) {
      return this.host + `/retail/cashboxes/delete/${id}`
    }
    return this.host + `/cashboxes/${id}`;
  }
}
