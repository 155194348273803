import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {Application} from "ember";

export default class WssClient extends Service {
  @service websockets;
  @service globalVars;
  @service store;
  @service session;
  @service intl;
  @service router;
  @service currentUser;

  @tracked socket = null;
  @tracked connection = false;
  @tracked marketNames = [];
  @tracked eventNames = [];
  @tracked additionalGameNames = [];

  @tracked callbackFunctions = {};

  availableLanguages = ['en', 'es', 'ro', 'ru', 'tr', 'hi', 'pt'];

  unavailableLanguages = ['es', 'ph', 'he'];

  connect() {
    if (this.connection) return;

    return this.startConnection();
  }

  startConnection() {
    return new Promise((resolve) => {
      this.socket = this.websockets.socketFor(`${this.globalVars.url.socket_notify_ws}`);
      //this.socket = this.websockets.socketFor(`wss://bet2.77dev.io/ws`);

      this.socket.on('open', () => {
        this.connection = true;
        console.log('Connected to WebSocket server');

        resolve();
      });

      this.socket.on('message', (message) => {
        const data = JSON.parse(message.data);
        switch (data.type) {
          case 'cacheReset':
            window.localStorage.clear();
            window.location.reload();
            break;
        }
      });

      this.socket.on('close', () => {
        this.connection = false;

        setTimeout(() => {
          this.socket.reconnect();
        }, 1000);
      });
    });
  }
}
