import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class SportsbookSettingsTemplateRoute extends Route {
  @service store;

  model() {}

  deactivate() {
    this.store.unloadAll('sportsbook-template-sport');
    this.store.unloadAll('sportsbook-template-league');
    this.store.unloadAll('sportsbook-template-market');
  }
}
