import AuthenticatedRoute from '../authenticated';

export default class RiskManagementBetsRoute extends AuthenticatedRoute {
  definer = 'risk_management_bets'

  queryParams = {
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    sort_name: {
      refreshModel: true
    },
    sort_type: {
      refreshModel: true
    },
    sport_id: {
      refreshModel: true
    },
    game_type: {
      refreshModel: true
    },
    bet_id: {
      refreshModel: true
    },
    grouped_ids: {
      refreshModel: true
    },
    match_name: {
      refreshModel: true
    },
    feed_type: {
      refreshModel: true
    },
    status_id: {
      refreshModel: true
    },
    is_calculated: {
      refreshModel: true
    },
    start: {
      refreshModel: true
    },
    end: {
      refreshModel: true
    }
  };

  model(params) {
    return params;
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }
}
