import {action, set} from '@ember/object';
import MainFilter from "./main-filter";
import {task} from 'ember-concurrency';
import {tracked} from '@glimmer/tracking';
import _ from 'lodash'

export default class MainPartnerSelect extends MainFilter {
  @tracked currencies;

  @action
  selectPartner(val) {
    set(this, 'filters.partner_id', parseInt(val) || undefined);
    if (parseInt(val)) {
      this.currencies = null;
      // this.store.unloadAll('currency');
      this.getCurrenciesTask.perform().then(() => {
        this.setPage(1)
      })
    }
    if (!parseInt(val)) {
      this.currencies = this.store.peekAll('currency')
      set(this, 'filters.currency_id', this.currencies[0].id)
      this.setPage(1)
    }
  }

  loadPartners(groups) {
    return this.partner.getPartnersByGroups(groups);
  }

  @action
  selectCurrency(val) {
    set(this, 'filters.currency_id', parseInt(val) || undefined);
    this.setPage(1)
  }

  get partners() {
    return this.partner.all;
  }

  get groups() {
    return this.partner.groups;
  }

  @(task(function* (withSelect = true) {
    if (parseInt(this.filters.partner_id)) {
      yield this.ajax.request('/partner-currencies', {data: {partner_id: this.filters.partner_id}}).then((data) => {
        this.currencies = data['currencies'] || [];
        this.store.pushPayload('currency', data)
      }).catch(({payload}) => {
        if (payload.errors) {
          payload.errors.forEach((error) => {
            this.notifications.error(error, {
              autoClear: true
            });
          })
        }
      });
    } else {
      this.currencies = this.store.peekAll('currency');
    }

    if (this.filters.currency_id) {
      let currency = this.currencies.filter(i => i.id === String(this.filters.currency_id));
      if (!currency.length) {
        set(this, 'filters.currency_id', withSelect?_.get(this.currencies, 'firstObject.id'):undefined)
      }
    } else {
      set(this, 'filters.currency_id', withSelect?_.get(this.currencies, 'firstObject.id'):undefined)
      if(!this.filters.income_by_currency_id) {
        set(this, 'filters.income_by_currency_id', _.get(this.currencies, 'firstObject.id'))
      }
    }

  }))getCurrenciesTask
}
