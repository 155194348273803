import Model, {attr, belongsTo} from '@ember-data/model';

export default class PaymentIntegrationSettingModel extends Model {
  @attr partner_id
  @attr integration_id
  @attr url
  @attr back_office_host
  @attr status
  @attr('json-parse') additional_information
  @attr() additional_currency

  @attr('boolean', {defaultValue: false}) isWallettec;
  @attr('boolean', {defaultValue: false}) isPayop;
  @attr('boolean', {defaultValue: false}) isFreekassa;
  @attr('boolean', {defaultValue: false}) isAmlNode;
  @attr('boolean', {defaultValue: false}) isPeculiar;

  @belongsTo('payment-integration', { async: false, inverse: null }) integration;
}
