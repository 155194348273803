import Model, { attr } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

export default class CasinoIntegrationModel extends Model {
  @attr title;
  @attr url;

  @tracked isGB_Games = this.title === 'GB-games'
  @tracked isMakeIt = this.title === 'MakeIt'
  @tracked isWAC = this.title === 'WAC'
  @tracked isInbet = this.title === 'Inbet'
  @tracked isRpls = this.title === 'Rpls'
  @tracked isGoldenrace = this.title === 'GOLDENRACE'
  @tracked isMarkor = this.title === 'MARKOR'
}
