import Model, {attr, belongsTo} from '@ember-data/model';
import {inject as service} from '@ember/service';

export default class AdminDepositModel extends Model {
  @attr from_user_id;
  @attr user_id;
  @attr amount;
  @attr currency_id;
  @attr partner_id;
  @attr status;
  @attr created_at;
  @attr updated_at;
  @attr created_by;
  @attr updated_by;

  @belongsTo('user', { async: false, inverse: null }) fromUser;
  @belongsTo('user', { async: false, inverse: null }) user;
  @belongsTo('currency', { async: false, inverse: null }) currency;
  @belongsTo('partner', { async: false, inverse: null }) partner;

  @service currentUser;

  get statusTitle() {
    return this.status === 10 ? 'Pending' : this.status === 20 ? 'Seen' : 'Canceled';
  }

  get canReturnDeposit() {
    return parseInt(this.status) === 10;
  }

  get canApproveDeposit() {
    return parseInt(this.currentUser.user.id) === parseInt(this.user_id) && parseInt(this.status) === 10;
  }
}
