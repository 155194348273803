import AuthenticatedRoute from '../authenticated';

export default class CasinoPlayersBulkCreditRoute extends AuthenticatedRoute {
  definer = 'casino_players_bulk_credit';

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
    this.partner.showIsEur = false;
  }
}
