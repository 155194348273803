import {inject as service} from '@ember/service';
import AuthenticatedRoute from "../../../authenticated";

export default class CasinoSettingsTemplateRankingRoute extends AuthenticatedRoute {
  definer = 'casino_settings_key_ranking';

  @service store;

  model() {
  }
}
