import {validator} from 'ember-cp-validations';
import {computed} from '@ember/object';

export default {
  title: {
    validators: [
      validator('presence', {
        presence: true,
        message: 'Partner name can’t be empty',
      }),
      validator('presence', {
        presence: computed('model.title', function() {
          return !this.model._partnersNames.includes(this.model.title);
        }),
        message: 'Partner name already exists',
      }),
      validator('length', {
        min: 2,
      }),
    ],
  },
  shortName: {
    validators: [
      validator('presence', {
        presence: true,
        message: 'Partner name can’t be empty',
      }),
      validator('format', {
        regex: /^[a-zA-Z0-9\s]+$/g,
        message: 'Invalid values, please use letters and digits.',
      }),
      validator('length', {
        min: 2,
        max: 5,
      }),
    ],
  },
};
