import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class AdminDocumentSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    adminDocumentType: {embedded: 'always'},
    user: {embedded: 'always'},
    adminDeposit: {embedded: 'always'},
    adminTransactions: {embedded: 'always'},
    currency: {embedded: 'always'}
  };
}
