import Controller from '@ember/controller';

export default class SettingsTranslationsController extends Controller {
  queryParams = ['page', 'language_id', 'category', 'search'];
  page = 1;
  language_id = 'en';
  category = '';
  search = '';
  isTranslated = 0;
}
