import Model, {attr, belongsTo, hasMany} from '@ember-data/model';
import {inject as service} from '@ember/service';
import {storageFor} from "ember-local-storage";
import {computed} from '@ember/object';

export default class BetModel extends Model {
  @storageFor('partner') partnerStorage
  @service store;

  @attr parent_id;
  @attr type_id;
  @attr player_id;
  @attr status_id;
  @attr currency_id;
  @attr system_option;
  @attr total_odds;
  @attr stake;
  @attr stake_in_eur;
  @attr total_return;
  @attr total_return_in_eur;
  @attr calculate_date;
  @attr created_at;

  @belongsTo('player', {async: false, inverse: null}) player;
  @belongsTo('bet-type', {async: false, inverse: null}) betType;
  @belongsTo('currency', {async: false, inverse: null}) currency;
  @belongsTo('bet-status', {async: false, inverse: null}) betStatus;
  @hasMany('bet-event', {async: false, inverse: null}) betEvents;

  get isSystem() {
    return this.type_id == 3
  }

  @computed('partnerStorage.isEurOn', 'stake', 'stake_in_eur')
  get stakeAmount() {
    return this.partnerStorage.get('isEurOn') ? this.stake_in_eur : this.stake;
  }

  @computed('partnerStorage.isEurOn', 'total_return_in_eur', 'total_return')
  get returnAmount() {
    return this.partnerStorage.get('isEurOn') ? this.total_return_in_eur : this.total_return;
  }

  get isSingle() {
    return this.type_id == 1
  }

  get isCashOut() {
    return this.status_id == 6
  }

  get isLost() {
    return this.status_id == 3
  }

  get childBets() {
    return this.store.peekAll('bet-system').filter(betSystem => betSystem.parent_id == this.id);
  }

  @computed('childBets', 'isSystem', 'partnerStorage.isEurOn', 'total_return', 'total_return_in_eur')
  get totalReturn() {
    let sum = 0;
    if (!this.isSystem) {
      sum = this.getTotalReturn(this);
    } else {
      this.childBets.forEach(childBet => {
        sum += this.getTotalReturn(childBet);
      })
    }

    return sum.toFixed(2);
  }

  getTotalReturn(bet) {
    let totalOdd = 1;

    if (bet.isLost) return 0;
    if (bet.isCashOut) return this.partnerStorage.get('isEurOn') ? +this.total_return_in_eur : +this.total_return;

    bet.betEvents.forEach(event => {
      if (!event.isReturn) {
        totalOdd = totalOdd * event.odd;
      }
    });

    return totalOdd * (this.partnerStorage.get('isEurOn') ? this.stake_in_eur : this.stake);
  }

  get totalOdd() {
    let totalOdd = 1;
    this.betEvents.forEach(event => {
      if (event.status_id !== 5) {
        totalOdd = totalOdd * event.odd;
      }
    });
    return totalOdd.toFixed(1);
  }
}
