import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\" {{did-insert (fn this.onDidInsert)}}>\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Title'}}</span>\n    <Input type=\"text\" @value={{this.navigationGroup.title}} />\n  </div>\n  {{#if this.navigationGroup.isSaving}}\n  \t<button class=\"btn btn-full loading\"></button>\n  {{else}}\n  \t<button class=\"btn btn-full\" {{action this.save}}>{{t 'Save'}}</button>\n  {{/if}}\n</div>\n{{yield}}\n", {"contents":"<div class=\"add-new-managers-fields\" {{did-insert (fn this.onDidInsert)}}>\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Title'}}</span>\n    <Input type=\"text\" @value={{this.navigationGroup.title}} />\n  </div>\n  {{#if this.navigationGroup.isSaving}}\n  \t<button class=\"btn btn-full loading\"></button>\n  {{else}}\n  \t<button class=\"btn btn-full\" {{action this.save}}>{{t 'Save'}}</button>\n  {{/if}}\n</div>\n{{yield}}\n","moduleName":"backoffice/components/modals/settings/navigations/new-group/sb-new-group.hbs","parseOptions":{"srcName":"backoffice/components/modals/settings/navigations/new-group/sb-new-group.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class ModalsSettingsNavigationsNewGroupSbNewGroupComponent extends Component {
  @service store;
  @service modalWindow;
  @service partner;

  @tracked navigationGroup;

  @action
  onDidInsert() {
    this.navigationGroup = this.store.createRecord('navigation-group', {
      position: this.modalWindow.data.position,
      partner_id: this.partner.selected_id,
    });
  }

  @action
  save() {
    this.navigationGroup.save().then(() => {
      this.modalWindow.closeModal();
    });
  }
}
