import RESTSerializer from '@ember-data/serializer/rest';

export default RESTSerializer.extend({

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    let json = JSON.stringify(payload);

    json = json.replace(/"SI":/g, '"sport_id":')
      .replace(/"RI":/g, '"region_id":')
      .replace(/"LI":/g, '"league_id":')
      .replace(/"GI":/g, '"game_id":')
      .replace(/"T1N":/g, '"team_1_name":')
      .replace(/"T2N":/g, '"team_2_name":')
      .replace(/"T1I":/g, '"team_1_id":')
      .replace(/"T2I":/g, '"team_2_id":')
      .replace(/"S":/g, '"game_start":')
      .replace(/"FT":/g, '"feedType":')
      .replace(/"F":/g, '"is_finished":')
      .replace(/"AG":/g, '"additional_games":')
      .replace(/"EI":/g, '"event_id":')
      .replace(/"_G":/g, '"game":')
      .replace(/"P":/g, '"invariant":')
      .replace(/"B":/g, '"is_blocked":')
      .replace(/"MI":/g, '"market_id":')
      .replace(/"OD":/g, '"odd":')
      .replace(/"LUT":/g, '"last_update_time":')
      .replace(/"_L":/g, '"league":')
      .replace(/"_R":/g, '"region":')
      .replace(/"_S":/g, '"sport":')
      .replace(/"_E":/g, '"events":')
      .replace(/"_SAS":/g, '"score_and_stat":')
      .replace(/"AL":/g, '"alias":')
      .replace(/"TOP":/g, '"is_top":')
      .replace(/"GC1":/g, '"live_games_count":')
      .replace(/"GC2":/g, '"line_games_count":')
      .replace(/"N":/g, '"name":')
      .replace(/"RC":/g, '"region_code":')
      .replace(/"NE":/g, '"name_en":')
      .replace(/"GC3":/g, '"to_day_games_count":');

    payload = JSON.parse(json);

    let result = {
      searchGames: payload.games,
      searchLeagues: payload.leagues,
      searchSports: payload.sports,
    }

    return this._super(store, primaryModelClass, result, id, requestType);
  }
})
