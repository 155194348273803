import AuthenticatedRoute from '../authenticated';

export default class SettingsTranslationsRoute extends AuthenticatedRoute {
  definer = 'settings_translations'

  queryParams = {
    page: {
      refreshModel: true
    },
    language_id: {
      refreshModel: true
    },
    category: {
      refreshModel: true
    },
    search: {
      refreshModel: true
    },
    isTranslated: {
      refreshModel: true
    }
  };

  model(params) {
    return params
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }
}
