import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.notifications.length}}\n  <div class='notification-item'>\n    <div class='title-section' onclick={{this.toggleItem}}>\n      <div class='name'>\n        <i class='sb-icon sb-triangleright'></i>\n        <span class='text-ellipsis'>Documents</span>\n      </div>\n\n      {{#if this.notifications.length}}\n        <div class='count'>{{this.notifications.length}}</div>\n      {{/if}}\n    </div>\n\n    <ul class='items documents'>\n      {{#each this.notifications as |notification|}}\n        <li>\n          <div class='item-wrapper'>\n            <GenericComponents::PlayerDocument::SbPlayerDocument @doc={{notification}} />\n          </div>\n        </li>\n      {{/each}}\n    </ul>\n  </div>\n{{/if}}", {"contents":"{{#if this.notifications.length}}\n  <div class='notification-item'>\n    <div class='title-section' onclick={{this.toggleItem}}>\n      <div class='name'>\n        <i class='sb-icon sb-triangleright'></i>\n        <span class='text-ellipsis'>Documents</span>\n      </div>\n\n      {{#if this.notifications.length}}\n        <div class='count'>{{this.notifications.length}}</div>\n      {{/if}}\n    </div>\n\n    <ul class='items documents'>\n      {{#each this.notifications as |notification|}}\n        <li>\n          <div class='item-wrapper'>\n            <GenericComponents::PlayerDocument::SbPlayerDocument @doc={{notification}} />\n          </div>\n        </li>\n      {{/each}}\n    </ul>\n  </div>\n{{/if}}","moduleName":"backoffice/components/main-components/notifications/documents/sb-documents.hbs","parseOptions":{"srcName":"backoffice/components/main-components/notifications/documents/sb-documents.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';

export default class MainComponentsNotificationsDocumentsSbDocumentsComponent extends Component {
  @service store;
  @service ajax;

  @tracked notifications = this.store.peekAll('notification-document');
  @tracked showContent = false;

  @action
  toggleSwitcher(notification) {
    notification.status = !notification.status;
    this.ajax.patch(`/player-documents/${notification.document_id}`, {
      data: {
        playerDocument: {
          id: notification.document_id,
          status: notification.status
        }
      }
    }).then(() => {
      let playerDocument = this.store.peekRecord('player-document', notification.document_id);
      if (playerDocument) {
        playerDocument.status = notification.status;
      }
    })
  }

  @action
  toggleItem(e) {
    let element = e.target.closest(".notification-item");
    let childElement = element.querySelector(".items");

    if (childElement.style.maxHeight) {
      childElement.style.maxHeight = null;
    } else {
      childElement.style.maxHeight = `${childElement.scrollHeight}px`;
    }

    this.showContent = !this.showContent;

    if (this.showContent) {
      e.target.closest('.notification-item').classList.add('show');
    } else {
      e.target.closest('.notification-item').classList.remove('show');
    }
  }
}
