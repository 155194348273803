import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"p-4\">\n  <div>\n    {{t 'Reason for archiving bonus'}} {{this.modalWindow.data.bonus.bonus.bonus_code}}\n  </div>\n\n  <div class='field-box mt-4'>\n    <Input\n      type='text'\n      @value={{this.reasonText}}\n    />\n  </div>\n\n  <button\n    class=\"btn btn-basic btn-full mt-4 {{if this.saveReason.isRunning 'loading'}}\"\n    type=\"button\"\n    disabled=\"{{if this.saveReason.isRunning 'disabled'}}\"\n    onclick={{perform this.saveReason}}\n  >\n    {{t 'OK'}}\n  </button>\n</div>\n", {"contents":"<div class=\"p-4\">\n  <div>\n    {{t 'Reason for archiving bonus'}} {{this.modalWindow.data.bonus.bonus.bonus_code}}\n  </div>\n\n  <div class='field-box mt-4'>\n    <Input\n      type='text'\n      @value={{this.reasonText}}\n    />\n  </div>\n\n  <button\n    class=\"btn btn-basic btn-full mt-4 {{if this.saveReason.isRunning 'loading'}}\"\n    type=\"button\"\n    disabled=\"{{if this.saveReason.isRunning 'disabled'}}\"\n    onclick={{perform this.saveReason}}\n  >\n    {{t 'OK'}}\n  </button>\n</div>\n","moduleName":"backoffice/components/modals/risk-management/players/bonus-status/sb-bonus-status.hbs","parseOptions":{"srcName":"backoffice/components/modals/risk-management/players/bonus-status/sb-bonus-status.hbs"}});
import {inject as service} from '@ember/service';
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {task} from 'ember-concurrency';

export default class ModalsRiskManagementPlayersBonusStatusSbBonusStatusComponent extends Component {
  @service modalWindow;
  @service notifications;

  @tracked reasonText = 'Manual by Admin';

  @task
  *saveReason() {
    if (!this.reasonText) {
      this.notifications.error('Reason can not be empty', {
        autoClear: true,
      });
      return;
    }

    this.modalWindow.data.bonus.reasonText = this.reasonText

    yield this.modalWindow.data.onSave.perform(this.modalWindow.data.bonus);
    this.modalWindow.closeModal();
  }
}
