import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class DocumentSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    documentType: {embedded: 'always'},
    player: {embedded: 'always'},
    transactions: {embedded: 'always'},
    manualActionUser: {embedded: 'always'},
    currency: {embedded: 'always'}
  };
}
