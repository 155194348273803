import Model, {attr, hasMany, belongsTo} from '@ember-data/model';
import {buildValidations, validator} from 'ember-cp-validations';
import {tracked} from '@glimmer/tracking';
import {computed} from '@ember/object';

const Validations = buildValidations({
  wagering_percent: {
    description: 'Wagering Percent',
    validators: [
      validator('number', {
        gt: 0, lte: 100,
        message: computed('model.wagering_percent', function () {
          if (this.model.wagering_percent > 100) {
            return 'Must be less than 100';
          }
          return 'Must be greater than 0';
        })
      })
    ],
  }
});

export default class CasinoTemplateSubCategoryModel extends Model.extend(Validations) {
  @attr template_category_id;
  @attr template_id;
  @attr title;
  @attr is_active;
  @attr('number') wagering_percent;

  @attr url;
  @attr order;
  @attr icon_url;
  @attr created_at;
  @attr updated_at;

  @tracked providers;
  @tracked types;
  @tracked checked = false

  @belongsTo('casino-template-category', {async: false, inverse: null}) casinoTemplateCategory
  @hasMany('partner-config', {async: false, inverse: null}) partnerConfigs

}
