import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.menuItems}}\n  <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{/if}}\n{{yield}}", {"contents":"{{#if this.menuItems}}\n  <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{/if}}\n{{yield}}","moduleName":"backoffice/components/route-components/cms/navigations/sb-navigations.hbs","parseOptions":{"srcName":"backoffice/components/route-components/cms/navigations/sb-navigations.hbs"}});
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';

export default class RouteComponentsCmsNavigationsSbNavigationsComponent extends Component {
  @tracked menuItems = [
    {
      name: 'Header',
      definer: 'cms_navigation',
      linkTo: {
        path: 'cms.navigations.header',
      }
    },
    {
      name: 'Footer',
      definer: 'cms_navigation',
      linkTo: {
        path: 'cms.navigations.footer',
      }
    },
    {
      name: 'Mobile Footer',
      definer: 'cms_navigation',
      linkTo: {
        path: 'cms.navigations.mobile-footer',
      }
    }
  ];
}
