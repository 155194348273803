import {inject as service} from '@ember/service';
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action, set, computed} from '@ember/object';
import {task} from "ember-concurrency";

export default class MainWithdrawForm extends Component {
  @service store
  @service notifications
  @service intl;
  @service router;
  @service globalVars;

  @tracked currency = this.args.mainAccount.currency.get('code');

  @tracked amountErrorMessage = false;

  @tracked errors = false;

  cryptoRegExps = {
    "mBTC": "^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$",
    "mBCH": "((bitcoincash|bchreg|bchtest):)?(q|p)[a-z0-9]{41}",
    "mETH": "0x[a-fA-F0-9]{40}",
    "mLTC": "[LM3][a-km-zA-HJ-NP-Z1-9]{26,33}",
    "DOGE": "D{1}[5-9A-HJ-NP-U]{1}[1-9A-HJ-NP-Za-km-z]{32}",
    "DASH": "X[1-9A-HJ-NP-Za-km-z]{33}",
    "XMR": "4[0-9AB][1-9A-HJ-NP-Za-km-z]{93}",
    "NEO": "A[0-9a-zA-Z]{33}",
    "XRP": "r[0-9a-zA-Z]{33}"
  }

  indianPymnts = [
    'indbanking_ingz_transfer_inr', 'indupi_ingz_transfer_inr', 'inps_cpaytrz_transferBank_inr', 'inps_cpaytrz_transferUpi_inr'
  ]

  @action
  setAdditionalProperty(permission, event) {
    if (!this.args.withdrawRequestModel.additional_data) {
      this.args.withdrawRequestModel.additional_data = {};
    }
    set(this.args.withdrawRequestModel.additional_data, permission.al, event.target.value)
    set(permission, 'isValid', this.validate(permission.re, event.target.value));
  }

  get amount() {
    if (!this.args.mainAccount.get('currency.is_crypto')) {
      return this.args.withdrawRequestModel.converted_amount;
    }
    return this.args.withdrawRequestModel.amount
  }

  set amount(val) {
    if (!this.args.mainAccount.get('currency.is_crypto')) {
      set(this.args.withdrawRequestModel, 'converted_amount', val);
      set(this.args.withdrawRequestModel, 'converted_currency_id', this.args.currency_id);
      val = val / this.args.currentCourseValue
    }
    set(this.args.withdrawRequestModel, 'amount', val);
  }

  @computed('args.permissions.@each.isValid')
  get inrPymntError() {
    return !this.args.permissions.find((permission) => !permission.isValid);
  }

  @action
  withdraw() {
    this.args.withdrawRequestModel.payment_method_id = +this.args.activeWithdrawMethod.get('id');
    this.args.withdrawRequestModel.integration_id = +this.args.activeWithdrawMethod.get('integrationSetting').get('integration_id')
    if (this.validateAmount()) {
      this.save();
    }
  }

  save() {
    this.createWithdrawRequestTask.perform().then(() => {
      set(this.args.activeWithdrawMethod, 'max', this.args.activeWithdrawMethod.max - this.amount)
      set(this.args.withdrawRequestModel, 'amount', 0)
      set(this.args.withdrawRequestModel, 'converted_amount', 0)

    })
  }

  get errorPermissionMessage() {
    let message = '';
    let errors = false;
    if (this.args.withdrawRequestModel.get('internal_value') != '' && (!this.args.withdrawRequestModel.get('internal_value') || !this.validateCrypto(this.cryptoRegExps[this.currency], this.args.withdrawRequestModel.get('internal_value')))) {
      message = this.intl.t('Address is invalid.');
      errors = true;
    }
    return {
      message: message,
      errors: errors
    };
  }

  @(task(function* () {
    yield this.args.withdrawRequestModel.save().then(() => {
      this.notifications.success('Process Successfully Executed', {
        autoClear: true
      });
      this.router.transitionTo('affiliates.transactions.withdraw-request')
    }).catch((response) => {
      if (response && response.errors) {
        response.errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true
          });
        })
      }
    });
  }))createWithdrawRequestTask

  get amountError() {
    let message = '';
    let errors = false;
    let min = parseFloat(this.min);
    let max = parseFloat(this.max);
    if ((this.amount != '') && (!this.amount || parseFloat(this.amount) <= 0)) {
      message = this.intl.t('Amount must be greater then 0.')
      errors = true;
    } else if (this.amount != '' && parseFloat(this.amount) < min) {
      message = this.intl.t('The minimum withdraw amount is') + ' ' + min;
      errors = true;
    } else if (this.amount != '' && (max > 0.00 && parseFloat(this.amount) > max)) {
      message = this.intl.t('The maximum withdraw amount is') + ' ' + max;
      errors = true;
    }

    return {
      message: message,
      errors: errors
    };
  }

  validateAmount() {
    this.amountErrorMessage = !this.amount;
    return !!this.amount
  }

  validate(regex, internalName) {
    regex = regex.slice(1);
    regex = regex.slice(0, regex.length - 1);
    let _regex = new RegExp(regex);
    return _regex.test(internalName);
  }

  validateCrypto(regex, internalName) {
    let _regex = new RegExp(regex);
    return _regex.test(internalName);
  }

  get min() {
    return this.args.activeWithdrawMethod.get('min') || 0;
  }

  get max() {
    if (this.args.activeWithdrawMethod.get('max') && this.args.activeWithdrawMethod.get('max').toString().search("`") >= 0) {
      return this.args.activeWithdrawMethod.get('max').replaceAll('`', '');
    }
    return this.args.activeWithdrawMethod.get('max');
  }

  willDestroy() {
super.willDestroy(...arguments);
    if (this.args.withdrawRequestModel.isNew) {
      this.args.withdrawRequestModel.deleteRecord()
    }
  }

}
