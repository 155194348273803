import Model, {attr, hasMany} from '@ember-data/model';
import {tracked} from '@glimmer/tracking'

export default class SportModel extends Model {
  @attr name

  @hasMany('region', { async: false, inverse: null }) regions

  @tracked order = null;
  @tracked title = this.name;

  modelName = 'sport'
}
