import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div {{did-insert this.registerListener}}\n  class=\"table-wrapper scroll-viewport native-scroll-box x-scroll {{if this.media.isDesktop 'medium' 'small'}}\"\n>\n  {{!--<span class=\"export-tooltip-btn\">--}}\n  {{!--<i class=\"sb-icon sb-details\"></i>--}}\n  {{!--<div class=\"tooltip-container\">--}}\n  {{!--<div class=\"item\"></div>--}}\n  {{!--<div class=\"item\"></div>--}}\n  {{!--</div>--}}\n  {{!--</span>--}}\n\n\n  <table class=\"content scroll-content\">\n    <thead>\n      <tr class=\"table-head\">\n        {{#each @tableTitles as |columnName index|}}\n            <th class=\"column\">{{columnName}}</th>\n        {{/each}}\n      </tr>\n    </thead>\n\n    <tbody>\n      {{yield}}\n    </tbody>\n  </table>\n</div>", {"contents":"<div {{did-insert this.registerListener}}\n  class=\"table-wrapper scroll-viewport native-scroll-box x-scroll {{if this.media.isDesktop 'medium' 'small'}}\"\n>\n  {{!--<span class=\"export-tooltip-btn\">--}}\n  {{!--<i class=\"sb-icon sb-details\"></i>--}}\n  {{!--<div class=\"tooltip-container\">--}}\n  {{!--<div class=\"item\"></div>--}}\n  {{!--<div class=\"item\"></div>--}}\n  {{!--</div>--}}\n  {{!--</span>--}}\n\n\n  <table class=\"content scroll-content\">\n    <thead>\n      <tr class=\"table-head\">\n        {{#each @tableTitles as |columnName index|}}\n            <th class=\"column\">{{columnName}}</th>\n        {{/each}}\n      </tr>\n    </thead>\n\n    <tbody>\n      {{yield}}\n    </tbody>\n  </table>\n</div>","moduleName":"backoffice/components/generic-components/basic-table/sb-basic-table.hbs","parseOptions":{"srcName":"backoffice/components/generic-components/basic-table/sb-basic-table.hbs"}});
import Component from '@glimmer/component';
import ScrollBooster from 'scrollbooster';
import {action} from '@ember/object';

export default class GenericComponentsBasicTableSbBasicTableComponent extends Component {
  @action
  registerListener(element) {
    new ScrollBooster({
      viewport: element,
      content: element.querySelector('.scroll-content'),
      scrollMode: 'native', // use CSS 'transform' property
      pointerMode: 'all',
      pointerDownPreventDefault: false,
      direction: 'all',
    });
  }
}
