import AuthenticatedRoute from '../../../authenticated';

export default class AffiliatesClientsProfileBetHistoryRoute extends AuthenticatedRoute {
  definer = 'affiliates_clients_bet_history'

  queryParams = {
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    sort_name: {
      refreshModel: true
    },
    sort_type: {
      refreshModel: true
    },
    type_id: {
      refreshModel: true
    },
    sport_id: {
      refreshModel: true
    },
    status_id: {
      refreshModel: true
    },
    search_val: {
      refreshModel: true
    },
    start: {
      refreshModel: true
    },
    end: {
      refreshModel: true
    }
  };

  model(params) {
    params.player_id = this.paramsFor('affiliates.clients.profile').player_id;
    return params
  }

  activate() {
    this.partner.showIsBonusSwitcher = true;
  }

  deactivate() {
    this.partner.showIsBonusSwitcher = false;
  }
}
