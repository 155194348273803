import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='field-box'>\n  <span class='label'>{{t 'Bet value per spin, in cents'}}</span>\n  <ValidatedInput @type='number'\n                  @step=\"0.01\"\n                  @isCustomValue={{true}}\n                  @customValue={{this.bet_value_per_spin}} @className=\"''\" @model={{@benefitCurrency}}\n                  @disabled={{this.disabled}}\n                  @showValidation={{@showValidation}}\n                  @valuePath='bet_value_per_spin'/>\n</div>", {"contents":"<div class='field-box'>\n  <span class='label'>{{t 'Bet value per spin, in cents'}}</span>\n  <ValidatedInput @type='number'\n                  @step=\"0.01\"\n                  @isCustomValue={{true}}\n                  @customValue={{this.bet_value_per_spin}} @className=\"''\" @model={{@benefitCurrency}}\n                  @disabled={{this.disabled}}\n                  @showValidation={{@showValidation}}\n                  @valuePath='bet_value_per_spin'/>\n</div>","moduleName":"backoffice/components/route-components/bonuses/bonus-templates/bonus-wizard/components/step-4/benefit/sb-benefit.hbs","parseOptions":{"srcName":"backoffice/components/route-components/bonuses/bonus-templates/bonus-wizard/components/step-4/benefit/sb-benefit.hbs"}});
import Component from '@glimmer/component';
import {roundDown} from 'backoffice/components/abstract-components/main-currency-exchange';

export default class RouteComponentsBonusesBonusTemplatesBonusWizardComponentsStep4BenefitSbBenefitComponent extends Component {
  get bet_value_per_spin(){
    return this.args.benefitCurrency.bet_value_per_spin;
  }

  set bet_value_per_spin(value){
    if (this.args.activeCurrency === 3) {
      this.args.bonus.bonusBenefit.bonusBenefitCurrencies.forEach(item => {
        if(!item.isDeleted) {
          item.bet_value_per_spin = roundDown(value, this.args.rates, +item.currency_id)
        }
      })
    }
    this.args.benefitCurrency.bet_value_per_spin = value;
  }
  get disabled() {
    return this.args.cantEdit
  }
}
