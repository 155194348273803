import Route from '@ember/routing/route';

export default class StatsReportsAutomaticSportKpiRoute extends Route {
  definer = 'stats_reports_key_automatic_sport_kpi'

  queryParams = {
    currency_ids: {
      refreshModel: true
    },
    start: {
      refreshModel: true
    },
    end: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    type_id: {
      refreshModel: true
    },
    sport_id: {
      refreshModel: true
    },
    market_id: {
      refreshModel: true
    },
    game_type: {
      refreshModel: true
    }
  };

  model(params) {
    if(params.currency_ids) {
      params.currency_ids = params.currency_ids.split(',');
    }
    return params
  }
}
