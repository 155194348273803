import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class AdminWithdrawRequestSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    user: {embedded: 'always'},
    currency: {embedded: 'always'},
    withdraw: {embedded: 'always'},
    paymentMethod: {embedded: 'always'}
  }
}
