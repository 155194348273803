import ApplicationAdapter from './application';

export default class DobetSettingAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/sportsbook/dobet-settings'
  }

  urlForCreateRecord() {
    return this.host + '/sportsbook/dobet-settings'
  }

  urlForUpdateRecord($id) {
    return this.host + '/sportsbook/dobet-settings/' + $id
  }

  urlForDeleteRecord($id) {
    return this.host + '/sportsbook/dobet-settings/' + $id
  }
}
