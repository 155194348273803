import AuthenticatedRoute from "../../../authenticated";

export default class PaymentSettingsIndexRoute extends AuthenticatedRoute {
  definer = 'payment_integration';

  model() {
    return this.paramsFor('payment/integration/settings').integration_id
  }

}
