import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\">\n  <form onsubmit={{action \"addSettings\"}} class=\"form-inline pull-right\">\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Percent'}} %</span>\n      <Input type=\"text\" @value={{this.affiliateSetting.percent}}/>\n    </div>\n      <button class=\"btn btn-basic btn-full\" disabled={{false}} type=\"submit\">{{t 'Save'}}</button>\n  </form>\n</div>\n{{yield}}", {"contents":"<div class=\"add-new-managers-fields\">\n  <form onsubmit={{action \"addSettings\"}} class=\"form-inline pull-right\">\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Percent'}} %</span>\n      <Input type=\"text\" @value={{this.affiliateSetting.percent}}/>\n    </div>\n      <button class=\"btn btn-basic btn-full\" disabled={{false}} type=\"submit\">{{t 'Save'}}</button>\n  </form>\n</div>\n{{yield}}","moduleName":"backoffice/components/modals/affiliates/settings/sb-add-settings.hbs","parseOptions":{"srcName":"backoffice/components/modals/affiliates/settings/sb-add-settings.hbs"}});
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import {isEmpty} from '@ember/utils';

export default class ModalsAffiliatesSettingsSbAddSettingsComponent extends Component {
  @service notifications
  @service modalWindow;
  @service intl;
  @service store;

  @tracked affiliateSetting

  constructor() {
    super(...arguments);
    let user = this.modalWindow.data.model
    if (!isEmpty(user.affiliateSetting.get('id'))) {
      this.affiliateSetting = this.store.peekRecord('affiliate-setting', user.affiliateSetting.get('id'));
    } else {
      this.affiliateSetting = this.store.createRecord('affiliate-setting');
      this.affiliateSetting.user_id = user.id;
    }
  }


  @action
  addSettings(e) {
    e.preventDefault()
    this.affiliateSetting.save().then(() => {
      this.notifications.success(this.intl.t('settings successfully added'), {
        autoClear: true
      });
      this.modalWindow.closeModal()
    }).catch(payload => {
      payload.errors.forEach((error) => {
        this.notifications.error(error, {
          autoClear: true
        });
      })
    });
  }


}
