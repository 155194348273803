import Model, {attr, belongsTo} from '@ember-data/model';

export default class WheelSectorCurrencyModel extends Model {
  @attr currency_id;
  @attr wheel_sector_id;
  @attr amount;
  @attr created_at;

  @belongsTo('currency', { async: false, inverse: null }) currency;
}
