import Model, {attr, belongsTo} from '@ember-data/model';

export default class AdminWithdrawRequestModel extends Model {
  @attr user_id
  @attr amount
  @attr cashbox_id
  @attr integration_id
  @attr comment
  @attr manager_approval_id
  @attr system_user_approval_id
  @attr converted_amount
  @attr converted_currency_id
  @attr withdraw_info
  @attr status
  @attr additional_data
  @attr internal_value
  @attr internal_name
  @attr currency_id
  @attr payment_method_id
  @attr type
  @attr created_at
  @attr updated_at
  @attr approved_date

  @belongsTo('user', { async: false, inverse: null }) user
  @belongsTo('admin-withdraw', { async: false, inverse: null }) withdraw
  @belongsTo('currency', { async: false, inverse: null }) currency
  @belongsTo('payment-withdraw-method', { async: false, inverse: null }) paymentMethod

  get getAccountType() {
    return 'Admin'
  }

  get currentStatus() {
    switch (parseInt(this.status)) {
      case 10:
        return 'In Review'
      case 20:
        return 'Approved'
      case 30:
        return 'Declined'
      case 40:
        return 'Canceled'
      case 50:
        return 'Paid'
    }
    return null;
  }
}
