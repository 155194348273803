import Model, {attr} from '@ember-data/model';

export default class GameLimitModel extends Model {
  @attr sport_id;
  @attr league_id;
  @attr local_game_id;
  @attr partner_id;
  @attr currency_id;
  @attr expiration_date;
  @attr total_limit_remnant;
  @attr total_bet_amount_limit;
  @attr created_at;
}
