import Controller from '@ember/controller';

export default class RiskManagementLimitsSportsLeaguesController extends Controller {
  queryParams = [
    'page',
    'perPage',
    'gamePage',
    'gamePerPage',
    'leaguePage',
    'leaguePerPage',
  ];

  page = 1;
  perPage = 10;
  leaguePage = 1;
  leaguePerPage = 10;
  gamePage = 1;
  gamePerPage = 10;
}
