import Model, {attr, belongsTo, hasMany} from '@ember-data/model';
import {tracked} from '@glimmer/tracking';

export default class CasinoTemplateProviderModel extends Model {
  @attr casino_game_providers_id
  @attr('boolean') is_active
  @attr('boolean') is_top
  @attr name
  @attr('order', {defaultValue: 999}) order
  @attr template_category_id
  @attr template_id
  @attr integration_id
  @attr games
  @attr('string') view_type
  @attr('string') image_url;
  @attr segments_to_include;
  @attr segments_to_exclude;

  @belongsTo('casino-integration', { async: false, inverse: null }) integration
  @hasMany('currency', { async: false, inverse: null }) currencies
  @hasMany('casino-template-game-list', { async: false, inverse: null }) casinoTemplateGameLists
  @tracked title = this.name
  @tracked selectedAll = false
  @tracked checked = false
  @tracked show = true
  @tracked templateGameLists;
  modelName = 'casino-template-provider'
}
