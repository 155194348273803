import ApplicationAdapter from './application';

export default class CasinoGameProviderAdapter extends ApplicationAdapter {
  urlForQuery(params) {
    if(params.is_bonus) {
      return this.host + '/bonusTemplates/bonuses/casino-game-provider';
    }
    if (params.isCasino) {
      return this.host + '/casinoDashboard/game-providers'
    }
    return this.host + '/casino-game-providers'
  }
}
