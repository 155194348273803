import AuthenticatedRoute from '../../authenticated';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';

export default class RetailWalletsIndexRoute extends AuthenticatedRoute {
  @service store;
  @service navigation;

  @tracked link;

  definer = 'retail_wallets'

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }

}
