import Model, {attr} from '@ember-data/model';

export default class DobetSettingModel extends Model {
	@attr('number') from;
	@attr('number') to;
	@attr('number') sportsbook_template_id;
	@attr('number') parent_id;
	@attr('number') bet_accepted_time;

	get isValidSaveBtn() {
		return Number.isInteger(parseInt(this.bet_accepted_time)) && Number.isInteger(parseInt(this.from)) && Number.isInteger(parseInt(this.to))
	}
}
