import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"content-item\">\n  <div class=\"column1\">{{or @casinoFreeSpinBet.external_transaction_id @casinoFreeSpinBet.id}}</div>\n  <div class=\"column2\" style=\"display: block !important;\">\n      <span>{{moment-format (unix @casinoFreeSpinBet.created_at) 'DD/MM/YY'}} {{moment-format (unix @casinoFreeSpinBet.created_at) 'HH:mm'}}</span>\n  </div>\n  <div class=\"column3\">{{@casinoFreeSpinBet.casinoGameList.name}}</div>\n  <div class=\"column4\">{{@symbol}} {{number-delimiter @casinoFreeSpinBet.bet}}</div>\n  <div class=\"column5\">{{@symbol}} {{number-delimiter @casinoFreeSpinBet.win}}</div>\n</div>\n{{yield}}", {"contents":"<div class=\"content-item\">\n  <div class=\"column1\">{{or @casinoFreeSpinBet.external_transaction_id @casinoFreeSpinBet.id}}</div>\n  <div class=\"column2\" style=\"display: block !important;\">\n      <span>{{moment-format (unix @casinoFreeSpinBet.created_at) 'DD/MM/YY'}} {{moment-format (unix @casinoFreeSpinBet.created_at) 'HH:mm'}}</span>\n  </div>\n  <div class=\"column3\">{{@casinoFreeSpinBet.casinoGameList.name}}</div>\n  <div class=\"column4\">{{@symbol}} {{number-delimiter @casinoFreeSpinBet.bet}}</div>\n  <div class=\"column5\">{{@symbol}} {{number-delimiter @casinoFreeSpinBet.win}}</div>\n</div>\n{{yield}}","moduleName":"backoffice/components/route-components/casino/free-spin/free-spin-accordion/free-spin-accordion-item/free-spin-accordion-item-content/sb-free-spin-accordion-item-content.hbs","parseOptions":{"srcName":"backoffice/components/route-components/casino/free-spin/free-spin-accordion/free-spin-accordion-item/free-spin-accordion-item-content/sb-free-spin-accordion-item-content.hbs"}});
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {task} from 'ember-concurrency'
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';

export default class RouteComponentsCasinoFreeSpinFreeSpinAccordionFreeSpinAccordionItemFreeSpinAccordionItemContentSbFreeSpinAccordionItemContentComponent extends Component {
  @service ajax;
  @service store;
  @service notifications;

  @action
  returnBetEvent() {
    let data = {};
    data.is_calculated = this.args.is_calculated
    data.local_game_id = this.args.event.local_game_id;
    if (!this.args.grouping) {
      data.bet_event_id = this.args.event.id
    } else {
      data.event_id = this.args.event.event_id;
      data.market_id = this.args.event.market_id;
      data.invariant = this.args.event.invariant;
      data.type = this.args.event.type;
      data.id = this.args.event.id;
    }
    this.returnEventTask.perform(data)
  }

  @(task(function* (data) {
    let url = 'de-calculate-bet-event';
    if (this.args.grouping) {
      url = 'de-calculate-grouped';
    }
    yield this.ajax.post(`/${url}`, {data}).then(() => {
      this.store.peekRecord('bet-event', this.args.event.id).unloadRecord();
      let betEvents = this.store.peekAll('bet-event').filter(i => i.local_game_id === parseInt(this.args.event.local_game_id));
      if (betEvents.length === 0) {
        this.store.peekRecord('local-game', this.args.event.local_game_id).unloadRecord();
      }
    }).catch(({payload}) => {
      if (payload && payload.errors) {
        payload.errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true
          });
        })
      }
    })
  }))returnEventTask
}
