import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\">\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Role Name'}}</span>\n    <Input type=\"text\" @value={{this.title}}/>\n  </div>\n  {{#if this.duplicateTask.isIdle}}\n  \t<button {{on 'click' this.duplicate}} class=\"btn btn-basic btn-full\">{{t 'Save'}}</button>\n  {{else}}\n  \t<button class=\"btn btn-basic btn-full loading\"></button>\n  {{/if}}\n</div>\n{{yield}}", {"contents":"<div class=\"add-new-managers-fields\">\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Role Name'}}</span>\n    <Input type=\"text\" @value={{this.title}}/>\n  </div>\n  {{#if this.duplicateTask.isIdle}}\n  \t<button {{on 'click' this.duplicate}} class=\"btn btn-basic btn-full\">{{t 'Save'}}</button>\n  {{else}}\n  \t<button class=\"btn btn-basic btn-full loading\"></button>\n  {{/if}}\n</div>\n{{yield}}","moduleName":"backoffice/components/modals/settings/role/actions/duplicate/duplicate-role.hbs","parseOptions":{"srcName":"backoffice/components/modals/settings/role/actions/duplicate/duplicate-role.hbs"}});
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {action} from "@ember/object";
import {task} from 'ember-concurrency'

export default class ModalsSettingsRoleActionsDuplicateDuplicateRoleComponent extends Component {
  @service ajax;
  @service store;
  @service modalWindow;
  @service notifications;

  @tracked title;

  get titleValue() {
    return this.title
  }

  set titleValue(val) {
    this.title = val
  }

  @action
  duplicate() {
    this.duplicateTask.perform();
  }

  @(task(function* () {
    let data = {};
    data.title = this.title;
    data.role_id = this.modalWindow.data.role.id;
    data.include = 'rolePermissions';
    yield this.ajax.post(`/duplicate-role`, {data}).then((role) => {
      this.store.pushPayload('role', role)
      this.modalWindow.closeModal();
    }).catch((payload) => {
      if (payload.errors) {
        this.notifications.error(payload.errors, {
          autoClear: true
        })
      }
    })
  }))duplicateTask
}
