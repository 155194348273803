import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class UserSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    role: {embedded: 'always'},
    parent: {embedded: 'always'},
    partner: {embedded: 'always'},
    cashbox: {embedded: 'always'},
    adminAccounts: {embedded: 'always'},
    mainAdminAccounts: {embedded: 'always'},
    freezeAndMainAccounts: {embedded: 'always'},
    rolePermissions: {embedded: 'always'},
    filteringPlayers: {embedded: 'always'},
    siteContentFavorites: {embedded: 'always'},
    affiliateSetting: {embedded: 'always'},
    affiliateLinks: {embedded: 'always'},
    userNotificationConfig: {embedded: 'always'},
    groupAdminPartners: {embedded: 'always'}
  }
}
