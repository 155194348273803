import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class LocalGameSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    localSport: {embedded: 'always'},
    localLeague: {embedded: 'always'},
    betEvents: {embedded: 'always'},
    localAdditionalGame: {embedded: 'always'}
  }
}
