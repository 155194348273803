import Controller from '@ember/controller';
import moment from 'moment';

export default class RiskManagementBetsController extends Controller {
  queryParams = [
    'page',
    'perPage',
    'sort_name',
    'sort_type',
    'sport_id',
    'game_type',
    'match_name',
    'bet_id',
    'feed_type',
    'status_id',
    'is_calculated',
    'start',
    'end',
  ];

  page = 1;
  perPage = 10;
  bet_id;
  sort_name;
  sort_type;
  sport_id;
  feed_type;
  game_type = 'main';
  match_name;
  status_id = 0;
  is_calculated = 0;
  start = moment().startOf('day').unix();
  end = moment().endOf('day').unix();
}
