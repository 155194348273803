import {tracked} from '@glimmer/tracking';
import {action, set} from '@ember/object';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency'
import MainPlayerSearch from "./main-player-search";

export default class MainBetHistory extends MainPlayerSearch {
  @service navigation;
  @service modalWindow;
  @service notifications;

  @tracked baseRoute = this.router.currentRouteName;
  @tracked betHistories = null;
  @tracked statuses = null;
  @tracked sports = null;
  @tracked types = null;

  mainFilters = {
    end: this.args.params.end,
    page: this.args.params.page,
    start: this.args.params.start,
    perPage: this.args.params.perPage,
    type_id: this.args.params.type_id,
    sport_id: this.args.params.sport_id,
    player_id: this.args.params.player_id,
    status_id: this.args.params.status_id,
    sort_name: this.args.params.sort_name,
    sort_type: this.args.params.sort_type,
    search_val: this.args.params.search_val,
    is_bonus: this.args.params.is_bonus,
    include: 'currency,betEvents.betStatus,betEvents.localGame.localAdditionalGame,betEvents.localGame.localLeague,betEvents.localGame.localSport,betType,betStatus'
  }

  get baseTask() {
    return this.getBetHistories;
  }

  get searchVal() {
    return this.filters.search_val
  }

  set searchVal(val) {
    set(this, 'filters.search_val', val);
    this.setTimeOut();
  }

  @action
  changeTypes(arg) {
    set(this.filters, 'type_id', arg.target.value);
    this.setPage(1);
  }

  @action
  toggleSwitcher() {
    set(this.filters, 'is_bonus', this.filters.is_bonus ? 0 : 1);
    this.setPage(1);
  }

  @action
  selectSport(val) {
    set(this.filters, 'sport_id', val);
    this.setPage(1);
  }

  @action
  changeStatuses(arg) {
    set(this.filters, 'status_id', arg.target.value);
    this.setPage(1);
  }

  @(task(function* () {
    this.statuses = yield this.store.query('bet-status', {}).catch(({payload}) => {
      if (payload && payload.errors) {
        this.notifications.error(payload.errors, {
          autoClear: true
        })
      }
    });
    this.types = yield this.store.query('bet-type', {}).catch(({payload}) => {
      if (payload && payload.errors) {
        this.notifications.error(payload.errors, {
          autoClear: true
        })
      }
    });
    this.sports = yield this.store.query('local-sport', {}).catch(({payload}) => {
      if (payload && payload.errors) {
        this.notifications.error(payload.errors, {
          autoClear: true
        })
      }
    });
  })) getBetParamsTask;

  @(task(function* () {
    this.betHistories = yield this.store.query('bet', this.filters).catch(({payload}) => {
      if (payload && payload.errors) {
        this.notifications.error(payload.errors, {
          autoClear: true
        })
      }
    });
    let systemParams = {};
    if (this.betHistories) {
      let systemBets = this.betHistories.filter(bet => bet.type_id == 3);
      if (systemBets.length !== 0) {
        let ids = '';
        systemBets.forEach((systemBet) => {
          ids += systemBet.id + ',';
        });
        ids = ids.slice(0, ids.length - 1);
        systemParams.ids = ids;
        systemParams.player_id = this.filters.player_id;
        systemParams.include = this.filters.include;
        yield this.store.query('betSystem', systemParams).catch(({payload}) => {
          if (payload && payload.errors) {
            this.notifications.error(payload.errors, {
              autoClear: true
            })
          }
        });
      }
    }
  })) getBetHistories;

  @action
  returnBetEvent(betEvent) {
    let data = {};
    data.is_calculated = true
    data.local_game_id = betEvent.local_game_id;
    data.bet_event_id = betEvent.id
    this.returnEventTask.perform(data)
  }

  @action
  openModalEditLogs(id) {
    this.store.findRecord('bet-event', id, {
      include: 'editLogs.user'
    }).then(betEvent => {
      this.modalWindow.open('risk-management/edit-logs', {
        title: 'Edit Logs',
        haveLogs: true,
        editLogs: betEvent.editLogs,
        statusData: {1: 'Pending', 2: 'Win', 3: 'Lost', 4: 'Return', 5: 'Manual'},
        className: 'event-edit-logs'
      }, 'event-edit-logs')
    })
  }

  @(task(function* (data) {
    yield this.ajax.post(`/de-calculate-bet-event`, {data}).then(() => {
      this.baseTask.perform();
    }).catch(({payload}) => {
      if (payload.errors) {
        payload.errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true
          });
        })
      }
    })
  }))returnEventTask
}
