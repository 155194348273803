import IntlServiceBase from 'ember-intl/services/intl';
import {tracked} from '@glimmer/tracking';
import {task} from "ember-concurrency";
import {inject as service} from '@ember/service';
import {storageFor} from 'ember-local-storage';
import {action, set} from '@ember/object';

export default class IntlService extends IntlServiceBase {
  @service ajax;
  @service store;
  @service moment;
  @service globalVars;
  @service notifications;

  @tracked currentLanguage = 'en';
  @tracked languages;
  @tracked translate;
  @tracked missingMessages = [];

  @tracked eventNames = [];
  @tracked marketNames = [];
  @tracked additionalGameNames = [];

  @storageFor('language') lngParams;


  constructor() {
    super(...arguments);

    this.loadLanguages.perform().then(() => {
      this.getMarketAndEventNames.perform();
    });

    setTimeout(() => {
      this.missingLanguages();
    }, 1000);
  }

  @action
  setCurrentLanguage(lang) {
    set(this.lngParams, 'currentLanguage', lang);
    window.location.reload(true);
  }

  loadCurrentLanguage() {
    let currentLanguage = this.lngParams.get('currentLanguage');
    if (!currentLanguage) {
      currentLanguage = 'en';
    }
    this.currentLanguage = currentLanguage;
    this.moment.setLocale(currentLanguage);
    return fetch(`${window.GLOBAL_API.trans}/backoffice/${currentLanguage}.json`);
  }

  missingLanguages() {
    if (this.missingMessages.length > 0) {
      this.loadMissingLanguages.perform()
    }
  }

  lookupAst(key, localeName, options = {}) {
    key = key.trim();
    const localeNames = this._localeWithDefault(localeName);
    let translation;

    for (let i = 0; i < localeNames.length; i++) {
      translation = this._translationContainer.lookupAst(localeNames[i], key);
      if (translation !== undefined) {
        break;
      }
    }

    if (translation === undefined && options.resilient !== true && !this.missingMessages.includes(key)) {
      this._owner.resolveRegistration('util:intl/missing-message');
      this.missingMessages.push(key);

      return key;
      // return missingMessage.call(this, key, localeNames, options);
    }

    return translation?.length ? translation : key;
  }

  @(task(function* () {
    this.languages = yield this.store.findAll('language');
  })) loadLanguages;

  @(task(function* () {
    let data = {};
    data.messages = '';
    if (this.missingMessages) {
      data.messages = this.missingMessages;
    }
    yield this.ajax.post(`${this.globalVars.url.admin_api}/missing-admin-translation`, {data}).then(() => {
      this.message = 'translated success';
      this.notifications.success(this.t(this.message), {
        autoClear: true
      });
    }).catch(({payload}) => {
      let errors = payload.errors;
      if (errors) {
        errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true
          });
        })
      }
    });
  })) loadMissingLanguages;

  @(task(function* () {
    let results = yield Promise.all([
      fetch(`${window.GLOBAL_API.files}/jsonFiles-v2/${this.currentLanguage}/events.json`),
      fetch(`${window.GLOBAL_API.files}/jsonFiles-v2/${this.currentLanguage}/markets.json`),
      //fetch(`${window.GLOBAL_API.files}/jsonFiles/en/additional_games.json`)
    ])

    this.eventNames = yield results[0].json();
    this.marketNames = yield results[1].json();
    this.additionalGameNames = [];

  })) getMarketAndEventNames;
}
