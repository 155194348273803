import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{component (concat 'route-components/payment/integration/settings/' this.componentName)}}", {"contents":"{{component (concat 'route-components/payment/integration/settings/' this.componentName)}}","moduleName":"backoffice/components/route-components/payment/integration/settings/sb-settings.hbs","parseOptions":{"srcName":"backoffice/components/route-components/payment/integration/settings/sb-settings.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';

export default class RouteComponentsPaymentIntegrationSettingsSbSettingsComponent extends Component {
  @service router;

  get componentName() {
    switch (this.router.currentRoute.attributes) {
      case '1':
        return 'interkassa/sb-settings';
      case '2':
        return 'crypto-currency/sb-crypto-currency';
      case '3':
        return 'cash/sb-cash';
      case '4':
        return 'praxis/sb-praxis';
      case '5':
        return 'xrp-crypto/sb-xrp-settings';
      case '6':
        return 'ethereum-crypto/sb-ethereum-settings';
      case '7':
        return 'bitcoin-cash-crypto/sb-bitcoin-cash-settings';
      case '8':
        return 'dogecoin-crypto/sb-dogecoin-settings';
      case '9':
        return 'litecoin-crypto/sb-litecoin-settings';
      case '10':
        return 'flutterwave/sb-flutterwave';
      case '11':
        return 'wallettec/sb-wallettec';
      case '12':
        return 'mgurush/sb-mgurush';
      case '13':
        return 'payop/sb-payop';
      case '14':
        return 'paythrone/sb-paythrone';
      case '16':
        return 'astropay/sb-astropay';
      case '17':
        return 'pulpal/sb-pulpal-setting';
      case '18':
        return 'freekassa/sb-freekassa-setting';
      case '19':
        return 'aml-node/sb-aml-node-setting';
      case '20':
        return 'peculiar/sb-peculiar-menu';
      case '21':
        return 'buy-crypto/sb-buy-crypto';
      case '22':
        return 'cryptomus/sb-cryptomus';
      default:
        break;
    }
    return null;
  }
}
