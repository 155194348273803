import ApplicationAdapter from './application';

export default class PaymentTemplateCategoryAdapter extends ApplicationAdapter {
  urlForQuery(params) {
    if (params.partner_id) {
      return this.host + '/paymentTemplate/category-partner'
    }

    if(params.is_bonus) {
      return this.host + '/bonusTemplates/bonuses/payment-template-category';
    }

    return this.host + '/paymentTemplate/categories'
  }

  urlForCreateRecord() {
    return this.host + '/paymentTemplate/categories'
  }

  urlForUpdateRecord($id) {
    return this.host + '/paymentTemplate/categories/' + $id
  }

  urlForDeleteRecord($id) {
    return this.host + '/paymentTemplate/categories/' + $id
  }
}
