import Model, {attr, hasMany} from '@ember-data/model';

export default class SearchSportModel extends Model {
  @attr('string') alias;
  @attr('string') name;
  @attr('number') live_games_count;
  @attr('number') line_games_count;
  @attr() sport_id;
  @hasMany('search-league', { async: false, inverse: null }) leagues;
}



