import {action, set} from '@ember/object';
import ScrollBooster from "scrollbooster";
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency';
import MainFilter from "../main-filter";
import {later} from '@ember/runloop';

export default class RouteComponentsRiskManagementWithdrawRequestsAccordionTableSbAccordionTableComponent extends MainFilter {
  @service modalWindow;
  @service notifications;
  @service intl;
  @service userNotify;

  @tracked meta = null;
  @tracked withdrawRequests = [];
  @tracked isManager = null;
  @tracked isWithoutPartner = null;
  @tracked forNotify = false;

  @tracked filters = {...this.args.filters, type: this.args.withdrawRequestType.value};

  constructor() {
    super(...arguments);

    this.baseTask.perform();
    this.isManager = this.currentUser.user.isManager;
    this.isWithoutPartner = this.currentUser.user.isWithoutPartner;
  }

  get baseTask() {
    return this.withdrawTask
  }

  get notifyCountKey() {
    let key = '';
    switch (this.filters.type) {
      case 10:
        key = 'mainWithdrawRequestsCount';
        break;
      case 30:
        key = 'adminWithdrawRequestsCount';
        break;
    }

    return key;
  }

  getInclude() {
    let include = '';
    switch (this.filters.type) {
      case 10:
        include = 'player.accountMain.currency,partner,cashbox,systemUser.systemUserRole,manager.managerRole,editLogs.user,integration,currency';
        break;
      case 30:
        include = 'user.mainAdminAccounts.currency,withdraw,partner,cashbox,systemUser.systemUserRole,manager.managerRole,paymentMethod,integration,currency';
        break;
    }

    return include;
  }

  setTransition() {}

  @action
  registerListener(element) {
    new ScrollBooster({
      viewport: element,
      content: element.querySelector('.scroll-content'),
      scrollMode: 'native', // use CSS 'transform' property
      direction: 'horizontal',
      pointerDownPreventDefault: false,
    });
  }

  @action
  approve(withdrawRequest, approve) {
    this.ajax.post(`/risk-management/withdraw-approve`, {
      data: {
        id: withdrawRequest.id,
        approve: approve,
        type: parseInt(this.filters.type)
      }
    }).then((retData) => {
      set(withdrawRequest, 'system_user_approval_status', retData['withdraw-requests'].system_user_approval_status);
      set(withdrawRequest, 'system_user_approval_id', retData['withdraw-requests'].system_user_approval_id);
      set(withdrawRequest, 'status', retData['withdraw-requests'].status);

      this.notifications.success(this.intl.t('Successfully Changed'), {
        autoClear: true
      });

      later(() => {
        this.withdrawRequests.removeObject(withdrawRequest);
        if (this.forNotify) this.userNotify[this.notifyCountKey] = this.withdrawRequests.length;
      }, 2000)

    }).catch(({payload}) => {
      if (payload && payload.errors) {
        let errors = payload.errors;
        if (errors) {
          errors.forEach((error) => {
            this.notifications.error(error, {
              autoClear: true
            });
          })
        }
      }
    });
  }

  @(task(function* () {
    this.filters.include = this.getInclude();
    this.filters.forNotify = +this.forNotify;

    let host = this.filters.is_peculiar ? `/risk-management/manual-withdraw-tricks` : `/risk-management/withdraw-request`;

    yield this.ajax.request(host, {data: this.filters}).then((retData) => {
      switch (parseInt(this.filters.type)) {
        case 10:
          this.withdrawRequests = retData['withdraw-requests'] || [];
          break;
        case 30:
          this.withdrawRequests = retData['admin-withdraw-requests'] || [];
          break;
      }

      if (this.forNotify) this.userNotify[this.notifyCountKey] = this.withdrawRequests.length;
      this.meta = retData.meta
    });
  })) withdrawTask
}
