import Model, {attr, belongsTo} from '@ember-data/model';
import {tracked} from '@glimmer/tracking';

export default class BannerModel extends Model {
  @attr('number') partner_id;
  @attr('string') language_id;
  @attr('number') usage_type_id;
  @attr('string') image;
  @attr('string') title;
  @attr('string') subtitle;
  @attr('string') button_title;
  @attr('string') background_color;
  @attr('boolean') status;
  @attr('number') is_dark;
  @attr('number') expiration_date;
  @attr('number') order;
  @attr('number') type;
  @attr('number') link_id;
  @attr('boolean') img_full_screen;

  @belongsTo('partner-link', { async: false, inverse: null }) link;

  @tracked checked = false;

  modelName = 'banner';

  get style() {
    return 'height: 313px; background-color:' + this.background_color + ';' + (this.img_full_screen ? 'background-image:url(' + this.image +
      ');background-size: cover;background-repeat: no-repeat': '')

  }

  toJSON() {
    return {
      partner_id: this.partner_id,
      language_id: this.language_id,
      usage_type_id: this.usage_type_id,
      image: this.image,
      title: this.title,
      subtitle: this.subtitle,
      button_title: this.button_title,
      background_color: this.background_color,
      status: this.status,
      is_dark: this.is_dark,
      expiration_date: this.expiration_date,
      order: this.order,
      type: this.type,
      link_id: this.link_id,
      img_full_screen: this.img_full_screen
    }
  }

}
