import Model, { attr, belongsTo } from '@ember-data/model';

export default class NotificationDocumentModel extends Model {
  @attr player_document_type_id;
  @attr player_id;
  @attr('image') image;
  @attr('number') status;
  @attr created_at;
  @attr updated_at;

  @belongsTo('player', { async: false, inverse: null }) player;
  @belongsTo('player-document-type', { async: false, inverse: null }) playerDocumentType;

  get isLike() { return this.status === 10 }
  get isDislike() { return this.status === 20 }
}
