import Helper from '@ember/component/helper';

export default class ToFixed extends Helper {

  compute([value, fractionDigits = 2]) {
    if (value) {
      return parseFloat(value).toFixed(fractionDigits);
    }
  }
}
