import ApplicationAdapter from './application';

export default class WheelBonusesCurrencyLimitAdapter extends ApplicationAdapter {
    urlForQuery() {
		return this.host + '/wheel/wheel-bonuses-currency-limits'
	}
		
	urlForFindAll() {
		return this.host + '/wheel/wheel-bonuses-currency-limits';
	}

	urlForCreateRecord() {
		return this.host + '/wheel/wheel-bonuses-currency-limits';
	}
		
	urlForUpdateRecord(id) {
		return this.host + '/wheel/wheel-bonuses-currency-limits/' + id;
	}

	urlForDeleteRecord(id) {
		return this.host + `/wheel/wheel-bonuses-currency-limits/${id}`;
	}
}
