import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\">\n  <div class=\"field-box\">\n    <h3 class=\"label\">{{t 'Are you sure you want to edit contributions'}}?</h3>\n  </div>\n  <button {{on 'click' this.edit}} class=\"btn btn-basic btn-full\">{{t 'Yes'}}</button>\n</div>\n{{yield}}", {"contents":"<div class=\"add-new-managers-fields\">\n  <div class=\"field-box\">\n    <h3 class=\"label\">{{t 'Are you sure you want to edit contributions'}}?</h3>\n  </div>\n  <button {{on 'click' this.edit}} class=\"btn btn-basic btn-full\">{{t 'Yes'}}</button>\n</div>\n{{yield}}","moduleName":"backoffice/components/modals/casino/settings/wagering-contribution/edit/sb-edit.hbs","parseOptions":{"srcName":"backoffice/components/modals/casino/settings/wagering-contribution/edit/sb-edit.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {action} from "@ember/object";
import {task} from 'ember-concurrency'

export default class ModalsCasinoSettingsWageringContributionEditSbEditComponent extends Component {
  @service ajax;
  @service store;
  @service modalWindow;
  @service notifications;

  @action
  edit() {
    this.modalWindow.afterCloseModels = null;
    this.editTask.perform()
  }

  @(task(function* () {
    for (let record of this.modalWindow.data.records) {
      let changedAttributes = record.changedAttributes();
      if (Object.keys(changedAttributes).length && !record.isNew) {
        yield record.save().catch(({payload}) => {
          let errors = payload.errors;
          if (errors.length) {
            errors.forEach((error) => {
              this.notifications.error(error, {
                autoClear: true
              });
            })
          }
        });
      }
    }
    this.modalWindow.closeModal()
  }))editTask
}
