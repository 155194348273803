import Controller from '@ember/controller';

export default class RiskManagementOddsFeedLeaguesController extends Controller {
  queryParams = [
    'page',
    'perPage',
    'lng',
    'league_id',
    'sport_id',
    'name',
    'is_new',
  ];

  page = 1;
  perPage = 10;
  lng = 'en';
  league_id;
  sport_id;
  name;
  is_new = true;
}
