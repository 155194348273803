import Model,{attr,belongsTo} from '@ember-data/model';

export default class CasinoAccountModel extends Model {
  @attr('number') type_id;
  @attr owner_id;
  @attr balance;
  @attr currency_id;
  @attr version;
  @attr created_at;

  @belongsTo('currency', { async: false, inverse: null }) currency;
  @belongsTo('casino-account-type', { async: false, inverse: null }) casinoAccountType;
}
