import Model, {attr, hasMany} from '@ember-data/model';
import {tracked} from '@glimmer/tracking';

export default class SegmentModel extends Model {
  @attr('number') partner_id;
  @attr('string') name;
  @attr('string') description;
  @attr('number') last_sync;
  @attr('number') updated_at;
  @attr('number') status;
  @attr('number') type;
  @attr('boolean') for_payment;
  @attr('number') frequency_type;
  @attr('number', {defaultValue: 0}) playersCount;
  @attr('number', {defaultValue: 0}) rulesCount;

  @hasMany('segment-player') segmentPlayers;

  @tracked checked = false;

  get frequency() {
    switch (this.frequency_type) {
      case 10:
        return 'Real time';
      case 20:
        return 'Scheduled - Hourly';
      case 30:
        return 'Scheduled - Daily';
      case 40:
        return 'Never';
    }

    return '';
  }

  get canSync() {
    return this.frequency_type !== 40 && this.type !== 20;
  }

  get segmentType() {
    switch (this.type) {
      case 10:
        return 'Condition';
      case 20:
        return 'From list';
    }
    return '';
  }
}
