import Helper from '@ember/component/helper';
import {inject as service} from "@ember/service"

export default class StatusConverter extends Helper {
  @service globalVars;

  statusLabels = {
    active: 'Active',
    inactive: 'Inactive',
    blocked: 'Blocked',
  }

  compute([status, player = false]) {
    if (player) {
      switch (status) {
        case this.globalVars.playerStatuses.active:
          return this.statusLabels.active;
        case this.globalVars.playerStatuses.inactive:
          return this.statusLabels.inactive;
        case this.globalVars.playerStatuses.blocked:
          return this.statusLabels.blocked;
      }
    }
    return this.globalVars.statuses.active === status ? this.statusLabels.active : this.statusLabels.blocked;
  }
}
