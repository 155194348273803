import ApplicationAdapter from './application';

export default class InterkassaPurseAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/payment/settings/purses'
  }

  urlForCreateRecord() {
    return this.host + '/payment/settings/purses'
  }

  urlForUpdateRecord(id) {
    return this.host + `/payment/settings/purses/${id}`;
  }

  urlForDeleteRecord(id) {
    return this.host + `/payment/settings/purses/${id}`;
  }
}
