import Helper from '@ember/component/helper';

export default class ReadableText extends Helper {
  compute([data]) {
    if (typeof data === 'string') {
      let result = data.split('_').map(this.capitalizeWord).join(' ');
      return result;
    }
    return '';
  }

  capitalizeWord(word) {
    if (typeof word === 'string') {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    return '';
  }
}
