import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"field-wrapper\">\n  <button class=\"btn btn-danger\" type=\"button\" {{on 'click' this.deleteTemplate}}>{{t 'Delete'}}</button>\n  <button class=\"btn\" type=\"button\" {{on 'click' this.cancel}}>{{t 'Cancel'}}</button>\n</div>\n{{yield}}", {"contents":"<div class=\"field-wrapper\">\n  <button class=\"btn btn-danger\" type=\"button\" {{on 'click' this.deleteTemplate}}>{{t 'Delete'}}</button>\n  <button class=\"btn\" type=\"button\" {{on 'click' this.cancel}}>{{t 'Cancel'}}</button>\n</div>\n{{yield}}","moduleName":"backoffice/components/modals/marketing/mail-template/delete.hbs","parseOptions":{"srcName":"backoffice/components/modals/marketing/mail-template/delete.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {action} from '@ember/object';
import {task} from 'ember-concurrency';

export default class ModalsMarketingMailTemplateDeleteComponent extends Component {
  @service modalWindow;
  @service ajax;
  @service partner;

  constructor() {
    super(...arguments);
  }

  @(task(function* () {
    yield this.ajax.post(`/marketing/delete-mail-templates/${this.modalWindow.data.template.get('name')}`,{
      data: {
        partner_id: this.partner.selected_id
      }
    }).then((role) => {
      this.store.pushPayload('role', role)
      this.modalWindow.closeModal();
    })
  })) deleteAction;

  @action
  deleteTemplate() {
    this.deleteAction.perform()
  }

  @action
  cancel() {
    this.modalWindow.closeModal()
  }
}
