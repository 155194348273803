import Helper from '@ember/component/helper';

export default class PartnerStyleGetter extends Helper {

  compute([object, separator, value]) {
    if (object) {
      return object[`${separator}_${value}`];
    }
    return '';
  }
}
