import ApplicationAdapter from './application';

export default class GameLimitAdapter extends ApplicationAdapter {
  urlForCreateRecord() {
    return this.host + '/limits/game-limits'
  }

  urlForUpdateRecord($id) {
    return this.host + '/limits/game-limits/' + $id
  }

  urlForDeleteRecord(id) {
    return this.host + `/limits/game-limits/${id}`;
  }
}
