import Model, {attr} from '@ember-data/model';

export default class GlobalLimitTypeModel extends Model {
  @attr title
  @attr('boolean', {defaultValue: true}) with_currency

  get withPlusMinus() {
    return this.title === 'prematch_odds_trimming' || this.title === 'live_odds_trimming';
  }
}
