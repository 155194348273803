import AuthenticatedRoute from '../authenticated';

export default class StatsDashboardRoute extends AuthenticatedRoute {
  definer = 'stats_dashboard'

  queryParams = {
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    dateMode: {
      refreshModel: true
    },
    graphMode: {
      refreshModel: true
    },
    graph_start: {
      refreshModel: true
    },
    graph_end: {
      refreshModel: true
    },
    product: {
      refreshModel: true
    },
    start: {
      refreshModel: true
    },
    end: {
      refreshModel: true
    }
  };

  model(params) {
    return params
  }

  activate() {
    this.partner.showPartnerSelect = true;
    this.partner.optionSystem = true;
    this.partner.showIsBonusSwitcher = false;
    this.partner.showIsEur = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = false;
    this.partner.optionSystem = false;
    this.partner.showIsBonusSwitcher = false;
    this.partner.showIsEur = false;
    this.partner.showPartnerCurrencySelect = false;
  }
}
