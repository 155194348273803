import Controller from '@ember/controller';
import moment from 'moment';

export default class StatsReportsByProductController extends Controller {
  queryParams = [
    'currency_id',
    'integration_id',
    'start',
    'end'
  ];

  currency_id;
  integration_id = 0;
  start = moment().startOf('month').unix();
  end = moment().endOf('day').unix();
}
