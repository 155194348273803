import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\" {{did-insert this.onDidInsert}}>\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Currencies'}}</span>\n    <PowerSelectMultiple\n          @searchEnabled={{true}}\n          @searchField=\"name\"\n          @options={{this.currencies}}\n          @selected={{this.selectedCurrencies}}\n          @placeholder=\"\"\n          @onChange={{this.selectCurrency}} as |currency|>\n    {{currency.name}}\n  </PowerSelectMultiple>\n  </div>\n  {{#unless this.createAccountTask.isRunning}}\n    <button class=\"btn btn-basic btn-full\" disabled={{false}} {{on 'click' (perform this.createAccountTask)}} >{{t 'Save'}}</button>\n  {{else}}\n    <button class=\"btn btn-basic btn-full loading dark\"></button>\n  {{/unless}}\n</div>\n{{yield}}", {"contents":"<div class=\"add-new-managers-fields\" {{did-insert this.onDidInsert}}>\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Currencies'}}</span>\n    <PowerSelectMultiple\n          @searchEnabled={{true}}\n          @searchField=\"name\"\n          @options={{this.currencies}}\n          @selected={{this.selectedCurrencies}}\n          @placeholder=\"\"\n          @onChange={{this.selectCurrency}} as |currency|>\n    {{currency.name}}\n  </PowerSelectMultiple>\n  </div>\n  {{#unless this.createAccountTask.isRunning}}\n    <button class=\"btn btn-basic btn-full\" disabled={{false}} {{on 'click' (perform this.createAccountTask)}} >{{t 'Save'}}</button>\n  {{else}}\n    <button class=\"btn btn-basic btn-full loading dark\"></button>\n  {{/unless}}\n</div>\n{{yield}}","moduleName":"backoffice/components/modals/settings/partner/details/add-wallet.hbs","parseOptions":{"srcName":"backoffice/components/modals/settings/partner/details/add-wallet.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';

export default class ModalsSettingsRoleAddRoleComponent extends Component {
  @service store;
  @service modalWindow;
  @service notifications;
  @service intl;

  @tracked account;
  @tracked currencies;
  @tracked selectedCurrencies = [];

  @action
  onDidInsert() {
    this.getCurrenciesTask.perform();
  }

  @action
  selectCurrency(currency) {
    this.selectedCurrencies = currency;
  }

  @task(function* () {
    yield this.selectedCurrencies.forEach((currency) => {
      this.account = this.store.createRecord('account');
      this.account.owner_id = this.modalWindow.data.partner_id;
      this.account.currency_id = currency.id;

      this.account
        .save()
        .then(() => {
          this.modalWindow.closeModal();
          this.notifications.success(this.intl.t('Wallet Create Successfully'), {
            autoClear: true,
          });
        })
        .catch((payload) => {
          if (payload.errors) {
            payload.errors.forEach((error) => {
              this.notifications.error(error, {
                autoClear: true,
              });
            });
          }
        });
    });
  })
  createAccountTask;

  @task(function* () {
    yield this.store.query('currency', {}).then((currencies) => {
      let usedCurrencies = this.store.peekRecord('partner', this.modalWindow.data.partner_id)
        .mainAccounts.map((account) => this.store.peekRecord('currency', account.currency_id))
        .slice();
      this.currencies = currencies.slice().filter((x) => !usedCurrencies.includes(x));
    });
  })
  getCurrenciesTask;
}
