import Service, {inject as service} from '@ember/service';
import {task} from 'ember-concurrency';

export default class GameService extends Service {
  @service partner;
  @service store;

  @task
  * searchGamesWithParamsTask(params) {
    return yield this.store.query('casino-template-game-list', {
      ...params
    })
  }
}
