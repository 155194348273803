import Model, {attr, belongsTo,hasMany} from '@ember-data/model';

export default class CasinoDocumentModel extends Model {
  @attr amount
  @attr currency_id
  @attr type_id
  @attr cashbox_id
  @attr player_id
  @attr db_type
  @attr created_at
  @attr updated_at

  @belongsTo('document-type', { async: false, inverse: null }) documentType;
  @belongsTo('player', { async: false, inverse: null }) player;
  @belongsTo('currency', { async: false, inverse: null }) currency;
  @hasMany('transaction', { async: false, inverse: null }) transactions;

  get accountType(){
    return this.transactions[0].account.get('accountType').get('title');
  }
}
