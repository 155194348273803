import AuthenticatedRoute from '../../authenticated';

export default class SettingsSegmentIndexRoute extends AuthenticatedRoute {
  definer = 'settings_segment';

  queryParams = {
    name: {
      refreshModel: true,
    },
    status: {
      refreshModel: true,
    },
    type: {
      refreshModel: true,
    },
    frequency_type: {
      refreshModel: true,
    },
    for_payment: {
      refreshModel: true,
    },
  };

  model(params) {
    return params;
  }

  activate() {
    this.partner.showPartnerCurrencySelect = false;
    this.partner.showPartnerSelect = true;
  }

  deactivate() {
    this.partner.showPartnerCurrencySelect = true;
  }
}
