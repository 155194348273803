import Controller from '@ember/controller';

export default class BonusesBonusTemplatesController extends Controller {
  queryParams = ['bonus_step'];

  perPage = 10;
  page = 1;

  menuItems = [
    {
      name: 'Site bonuses',
      linkTo: {
        path: 'bonuses.bonus-templates.site',
      },
      definer: 'bonuses_bonus_templates',
    },
    {
      name: 'Affiliate bonuses',
      linkTo: {
        path: 'bonuses.bonus-templates.affiliate',
      },
      definer: 'bonuses_bonus_templates',
    },
  ];
}
