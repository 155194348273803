import AuthenticatedRoute from '../authenticated';

export default class AffiliatesDashboardRoute extends AuthenticatedRoute {
  definer = 'affiliates_dashboard'

  queryParams = {
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    dateMode: {
      refreshModel: true
    },
    graph_start: {
      refreshModel: true
    },
    graph_end: {
      refreshModel: true
    },
    product: {
      refreshModel: true
    },
    start: {
      refreshModel: true
    },
    end: {
      refreshModel: true
    }
  };

  model(params) {
    return params
  }

  activate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
    this.partner.showIsBonusSwitcher = false;
    this.partner.allCurrencyOption = true;
  }

  deactivate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
    this.partner.showIsBonusSwitcher = false;
    this.partner.allCurrencyOption = false;
  }
}
