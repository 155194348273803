import Model, {attr, belongsTo} from '@ember-data/model';

export default class PlayersConfigModel extends Model {
  @attr('number') player_id
  @attr('number') account_id
  @attr odd_prematch
  @attr odd_live
  @attr odd_minimum
  @attr odd_maximum
  @attr bid_prematch_second
  @attr bid_live_second
  @attr bid_tennis_second
  @attr bid_volleyball_second
  @attr bet_amount_minimum
  @attr bet_amount_maximum
  @attr('boolean') repeat_outcome
  @attr('boolean') repeat_bet_in_same_game
  @attr withdraw_minimum_limit
  @attr withdraw_maximum_limit
  @attr status_id
  @attr created_by
  @attr created_at
  @attr updated_at

  @belongsTo('player', { async: false, inverse: null }) player
  @belongsTo('players-status', { async: false, inverse: null }) playersStatus
}
