import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\">\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Group ID'}}</span>\n    <Input type=\"text\" @value={{this.group.external_group_id}}/>\n  </div>\n\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Title'}}</span>\n    <Input type=\"text\" @value={{this.group.title}}/>\n  </div>\n\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Description'}}</span>\n    <Input type=\"text\" @value={{this.group.description}}/>\n  </div>\n\n  {{#unless this.editGroupTask.isRunning}}\n    <button class=\"btn btn-basic btn-full\" {{action 'editGroup'}}>{{t 'Save'}}</button>\n  {{else}}\n    <button class=\"btn btn-basic btn-full loading dark\"></button>\n  {{/unless}}\n</div>\n{{yield}}", {"contents":"<div class=\"add-new-managers-fields\">\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Group ID'}}</span>\n    <Input type=\"text\" @value={{this.group.external_group_id}}/>\n  </div>\n\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Title'}}</span>\n    <Input type=\"text\" @value={{this.group.title}}/>\n  </div>\n\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Description'}}</span>\n    <Input type=\"text\" @value={{this.group.description}}/>\n  </div>\n\n  {{#unless this.editGroupTask.isRunning}}\n    <button class=\"btn btn-basic btn-full\" {{action 'editGroup'}}>{{t 'Save'}}</button>\n  {{else}}\n    <button class=\"btn btn-basic btn-full loading dark\"></button>\n  {{/unless}}\n</div>\n{{yield}}","moduleName":"backoffice/components/modals/settings/partner/group/edit/edit-group.hbs","parseOptions":{"srcName":"backoffice/components/modals/settings/partner/group/edit/edit-group.hbs"}});
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {action} from "@ember/object";
import {task} from "ember-concurrency";

export default class ModalsSettingsPartnerGroupEditEditGroupComponent extends Component {
  @service store;
  @service modalWindow;
  @service eventTrigger;
  @service notifications;

  @tracked group = this.modalWindow.data.group

  @action
  editGroup() {
    this.editGroupTask.perform()
  }

  @(task(function* () {
    yield this.group.save().then(() => {
      this.modalWindow.closeModal();
      this.notifications.success('Group change successfully', {
        autoClear: true
      });
    }).catch((response) => {
      let errors = response.errors;
      if (errors) {
        errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true,
            clearDuration: 3200
          });
        })
      }
    });
  })) editGroupTask;

}
