import Controller from '@ember/controller';
import {inject as service} from '@ember/service';

export default class SettingsUsersRolesUsersController extends Controller {
  @service currentUser;

  queryParams = [
    'page',
    'perPage',
  ];

  page = 1;
  perPage = 10;
}
