import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @className}}\n  <div class={{@className}}>\n    <i class=\"sb-icon sb-round-{{@className}}\"></i>\n  </div>\n{{/if}}\n{{#if @message}}\n  <span class=\"input-message-{{@className}}\">{{@message}}</span>\n{{/if}}\n{{#if (or @model @data)}}\n  {{#if peculiarData.error}}\n    <span class=\"input-message-error\">{{peculiarData.message}}</span>\n  {{else}}\n    <div class='success'>\n      <i class=\"sb-icon sb-round-success\"></i>\n    </div>\n  {{/if}}\n{{/if}}\n\n{{yield}}", {"contents":"{{#if @className}}\n  <div class={{@className}}>\n    <i class=\"sb-icon sb-round-{{@className}}\"></i>\n  </div>\n{{/if}}\n{{#if @message}}\n  <span class=\"input-message-{{@className}}\">{{@message}}</span>\n{{/if}}\n{{#if (or @model @data)}}\n  {{#if peculiarData.error}}\n    <span class=\"input-message-error\">{{peculiarData.message}}</span>\n  {{else}}\n    <div class='success'>\n      <i class=\"sb-icon sb-round-success\"></i>\n    </div>\n  {{/if}}\n{{/if}}\n\n{{yield}}","moduleName":"backoffice/components/generic-components/input-message/sb-input-message.hbs","parseOptions":{"srcName":"backoffice/components/generic-components/input-message/sb-input-message.hbs"}});
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {get} from '@ember/object';

export default class GenericComponentsFileManagerBtnSbInputMessageComponent extends Component {
  @tracked error = false;
  @tracked message = '';

  get peculiarData() {
    if (this.args.model && this.args.data) {
      let model = this.args.model;
      let data = model.attribute.split(' ');
      let attribute = model.attribute;
      if (data.length > 1) {
        attribute = data[0] + data[1].slice(0, 1).toUpperCase() + data[1].substring(1);
      }
      if (!get(this.args.data, attribute) && model.is_required) {
        return {
          message: model.attribute + ' ' + 'Is Required',
          error: true
        }
      }
      if (get(this.args.data, attribute) && model.regex) {
        let regex = model.regex;
        let internalName = get(this.args.data, attribute);
        let first = regex.charAt(0);
        let last = regex.charAt(regex.length - 1);
        if (first === '/' && last === '/') {
          regex = regex.slice(1, -1)
        }
        let _regex = new RegExp(regex);
        return {
          message: model.attribute + ' ' + 'Incorrect',
          error: !_regex.test(internalName)
        }
      }
    }
    return {message: '', error: false};
  }
}
