import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\">\n  <div class=\"field-box\">\n    <h3 class=\"label\">{{t 'Do you really want to delete?'}}</h3>\n  </div>\n  {{#unless this.destroyCashbox.isRunning}}\n    <button class=\"btn btn-basic btn-full\" type=\"button\" {{on 'click' this.delete}}>{{t 'Yes'}}</button>\n  {{else}}\n    <button class=\"btn btn-basic btn-full loading dark\" type=\"button\"></button>\n  {{/unless}}\n</div>\n{{yield}}", {"contents":"<div class=\"add-new-managers-fields\">\n  <div class=\"field-box\">\n    <h3 class=\"label\">{{t 'Do you really want to delete?'}}</h3>\n  </div>\n  {{#unless this.destroyCashbox.isRunning}}\n    <button class=\"btn btn-basic btn-full\" type=\"button\" {{on 'click' this.delete}}>{{t 'Yes'}}</button>\n  {{else}}\n    <button class=\"btn btn-basic btn-full loading dark\" type=\"button\"></button>\n  {{/unless}}\n</div>\n{{yield}}","moduleName":"backoffice/components/modals/retail/cashboxes/delete/delete-cashbox.hbs","parseOptions":{"srcName":"backoffice/components/modals/retail/cashboxes/delete/delete-cashbox.hbs"}});
import Component from '@glimmer/component';
import {task} from "ember-concurrency";
import {inject as service} from '@ember/service';
import {action} from "@ember/object";

export default class ModalsRetailCashboxesDeleteDeleteCashboxComponent extends Component {
  @service ajax;
  @service store;
  @service modalWindow;
  @service notifications;

  @action
  delete() {
    this.destroyCashbox.perform()
  }

  @(task(function* () {
    let cashbox = this.modalWindow.data.cashbox;
    cashbox.retail = true;
    yield cashbox.destroyRecord().then(() => {
      this.modalWindow.closeModal()
    }).catch((payload) => {
      if (payload && payload.errors) {
        payload.errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true,
            clearDuration: 3200
          });
        })
      }
    });
  }))destroyCashbox
}
