import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\">\n  <div class=\"field-box\">\n    <Textarea @value={{this.model.description}} cols=\"80\" rows=\"6\" />\n  </div>\n\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Duplicate all currency'}}</span>\n\n    <label class=\"on-off-button\" {{action this.toggleDuplicate}}>\n      <input type=\"checkbox\" checked={{this.isDuplicate}}>\n      <span></span>\n    </label>\n  </div>\n\n  {{#if this.saveTask.isIdle}}\n  \t<button class=\"btn btn-full\" type=\"button\" {{action this.save}}>{{t 'Save'}}</button>\n  {{else}}\n  \t<button class=\"btn btn-full loading\" type=\"button\"></button>\n  {{/if}}\n</div>\n", {"contents":"<div class=\"add-new-managers-fields\">\n  <div class=\"field-box\">\n    <Textarea @value={{this.model.description}} cols=\"80\" rows=\"6\" />\n  </div>\n\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Duplicate all currency'}}</span>\n\n    <label class=\"on-off-button\" {{action this.toggleDuplicate}}>\n      <input type=\"checkbox\" checked={{this.isDuplicate}}>\n      <span></span>\n    </label>\n  </div>\n\n  {{#if this.saveTask.isIdle}}\n  \t<button class=\"btn btn-full\" type=\"button\" {{action this.save}}>{{t 'Save'}}</button>\n  {{else}}\n  \t<button class=\"btn btn-full loading\" type=\"button\"></button>\n  {{/if}}\n</div>\n","moduleName":"backoffice/components/modals/payment/description/sb-description.hbs","parseOptions":{"srcName":"backoffice/components/modals/payment/description/sb-description.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';

export default class ModalsPaymentDescriptionSbDescriptionComponent extends Component {
  @service store;
  @service modalWindow;

  @tracked model = this.modalWindow.data.model;
  @tracked isDuplicate = false;

  @action
  save() {
    this.saveTask.perform()
  }

  @action
  toggleDuplicate() {
    this.isDuplicate = !this.isDuplicate;
  }

  willDestroy() {
super.willDestroy(...arguments);
    this.model.rollbackAttributes();
  }

  @(task(function*() {
    let models = [this.model];
    if (this.isDuplicate) {
      models = this.store.peekAll(this.model.modelName).filter(item => item.alias === this.model.alias && item.integration_setting_id == this.model.integration_setting_id);
    }

    let promises = models.map(model => { 
      model.description = this.model.description; 
      return model.save(); 
    });
    yield Promise.all(promises);
    this.modalWindow.closeModal();
  })) saveTask
}
