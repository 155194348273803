import Model, {attr, belongsTo} from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

export default class SportsbookTemplateMarketModel extends Model {
  @attr('number') sportsbook_template_id;
  @attr('string') market_id;
  @attr('boolean') is_active;
  @attr('number', {defaultValue: 9999999}) order;

  @belongsTo('local-market', { async: false, inverse: null }) localMarket; 

  @tracked name = this.get('localMarket.name');
}
