import ApplicationAdapter from './application';

export default class PaymentIntegrationSettingAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/payment/integration-settings'
  }
  urlForQueryRecord() {
    return this.host + '/payment/integration-settings'
  }

  urlForCreateRecord(model,params) {
    if(params.__attributes.isWallettec){
      return this.host + `/integration-setting/create-wallettec`;
    }
    if(params.__attributes.isPayop){
      return this.host + `/integration-setting/create-payop`;
    }
    if(params.__attributes.isFreekassa){
      return this.host + `/integration-setting/create-freekassa`;
    }
    if(params.__attributes.isAmlNode){
      return this.host + `/integration-setting/create-aml-node`;
    }
    if(params.__attributes.isCryptomus){
      return this.host + `/integration-setting/cryptomus`;
    }
    return this.host + '/payment/integration-settings'
  }

  urlForUpdateRecord(id,model,params) {
    if(params.__attributes.isWallettec){
      return this.host + `/integration-setting/update-wallettec-methods/${id}`;
    }
    if(params.__attributes.isPayop){
      return this.host + `/integration-setting/update-payop-methods/${id}`;
    }
    if(params.__attributes.isFreekassa){
      return this.host + `/integration-setting/freekassa-methods/${id}`;
    }
    if(params.__attributes.isAmlNode){
      return this.host + `/integration-setting/aml-node-methods/${id}`;
    }
    return this.host + `/payment/integration-settings/${id}`;
  }
}
