import Controller from '@ember/controller';
import moment from 'moment';

export default class StatsReportsByPartnerController extends Controller {
  queryParams = [
    'currency_id',
    'partner_id',
    'start',
    'end'
  ];

  currency_id;
  partner_id = 0;
  start = moment().startOf('month').unix();
  end = moment().endOf('day').unix();
}
