import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"body-section\">\n  <div class=\"filter-wrapper\">\n    {{#if this.playerFilters}}\n      {{#each this.playerFilters as |filter|}}\n        <div class=\"filter-item\">\n          <Input class=\"title text-ellipsis\" @value={{filter.title}}/>\n          <div class=\"btn-container\">\n            <button class=\"filter-btn rename\" type=\"button\" {{on 'click' (fn this.rename filter.id)}}>{{t 'Rename'}}</button>\n            <button class=\"filter-btn delete\" type=\"button\" {{on 'click' (fn this.delete filter.id)}}>{{t 'Delete'}}</button>\n          </div>\n        </div>\n      {{/each}}\n    {{/if}}\n  </div>\n</div>\n{{yield}}", {"contents":"<div class=\"body-section\">\n  <div class=\"filter-wrapper\">\n    {{#if this.playerFilters}}\n      {{#each this.playerFilters as |filter|}}\n        <div class=\"filter-item\">\n          <Input class=\"title text-ellipsis\" @value={{filter.title}}/>\n          <div class=\"btn-container\">\n            <button class=\"filter-btn rename\" type=\"button\" {{on 'click' (fn this.rename filter.id)}}>{{t 'Rename'}}</button>\n            <button class=\"filter-btn delete\" type=\"button\" {{on 'click' (fn this.delete filter.id)}}>{{t 'Delete'}}</button>\n          </div>\n        </div>\n      {{/each}}\n    {{/if}}\n  </div>\n</div>\n{{yield}}","moduleName":"backoffice/components/modals/risk-management/players/filter-templates/sb-filter-templates.hbs","parseOptions":{"srcName":"backoffice/components/modals/risk-management/players/filter-templates/sb-filter-templates.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';

export default class ModalsRiskManagementPlayersFilterTemplatesSbFilterTemplatesComponent extends Component {
  @service store
  @service modalWindow
  @service notifications

  @tracked playerFilters = null;

  constructor() {
    super(...arguments);
    this.playerFilters = this.store.peekAll('filtering-player');
  }

  @action
  rename(id) {
    let playerFilter = this.playerFilters.find(i => i.id === id);
    playerFilter.save().catch(({payload}) => {
      if (payload.errors) {
        payload.errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true
          });
        })
      }
    });
  }

  @action
  delete(id) {
    let playerFilter = this.playerFilters.find(i => i.id === id);
    playerFilter.destroyRecord().catch(({payload}) => {
      if (payload.errors) {
        payload.errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true
          });
        })
      }
    });
  }

}
