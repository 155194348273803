import Route from '@ember/routing/route';

export default class SportsbookSettingsTemplateMarketsRoute extends Route {
  queryParams = {
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    lng: {
      refreshModel: true
    },
    name: {
      refreshModel: true
    },
    market_id: {
      refreshModel: true
    }
  };

  model(params) {
    return params;
  }
}
