import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"main-info-container reports\">\n  {{#if this.menuItems}}\n    <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n  {{/if}}\n</div>\n{{yield}}", {"contents":"<div class=\"main-info-container reports\">\n  {{#if this.menuItems}}\n    <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n  {{/if}}\n</div>\n{{yield}}","moduleName":"backoffice/components/route-components/stats/reports/sb-reports.hbs","parseOptions":{"srcName":"backoffice/components/route-components/stats/reports/sb-reports.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service'

export default class RouteComponentsStatsReportsSbReportsComponent extends Component {
  @service store;

  menuItems = [
    {
      name: 'By Bets',
      linkTo: {
        path: 'stats.reports.by-bets'
      },
      definer: 'stats_reports_key_by_partner'
    },
    {
      name: 'By Deposit Withdraw',
      linkTo: {
        path: 'stats.reports.by-deposit-withdraw'
      },
      definer: 'stats_reports_key_retail_transactions'
    },
    {
      name: 'Bet Calculation Statistic',
      linkTo: {
        path: 'stats.reports.automatic-sport-kpi'
      },
      definer: 'stats_reports_key_automatic_sport_kpi'
    }
  ];
}
