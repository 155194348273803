import {tracked} from '@glimmer/tracking';
import {action, set} from '@ember/object';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency'
import MainPlayerSearch from "./main-player-search";

export default class MainCasinoBetHistory extends MainPlayerSearch {
  @service navigation;
  @service modalWindow;
  @service notifications;
  @service session;
  @service globalVars;

  @tracked baseRoute = this.router.currentRouteName;
  @tracked casinoBetHistories = null;

  mainFilters = {
    end: this.args.params.end,
    page: this.args.params.page,
    start: this.args.params.start,
    perPage: this.args.params.perPage,
    type_id: this.args.params.type_id,
    game_id: this.args.params.game_id,
    player_id: this.args.params.player_id,
    status_id: this.args.params.status_id,
    sort_name: this.args.params.sort_name,
    sort_type: this.args.params.sort_type,
    search_val: this.args.params.search_val,
    include: 'casinoGameList,casinoGameProvider'
  }

  get baseTask() {
    return this.getCasinoBetHistories;
  }

  get searchVal() {
    return this.filters.search_val
  }

  set searchVal(val) {
    set(this, 'filters.search_val', val);
    this.setTimeOut();
  }

  @action
  changeTypes(arg) {
    set(this.filters, 'type_id', arg.target.value);
    this.setPage(1);
  }


  @action
  changeStatuses(arg) {
    set(this.filters, 'status_id', arg.target.value);
    this.setPage(1);
  }


  @(task(function* () {
    this.casinoBetHistories = yield this.store.query('casino-bet', this.filters).catch((payload) => {
      if (payload && payload.errors) {
        this.notifications.error(payload.errors, {
          autoClear: true
        })
      }
    });
  }))getCasinoBetHistories;
}
