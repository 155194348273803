import Model, {attr, hasMany} from '@ember-data/model';

export default class InterkassaCashboxModel extends Model {
  @attr cashbox_id;
  @attr name;
  @attr invoice;
  @attr withdraw;
  @attr status;
  @attr payment_account_id;
  @attr integration_id;
  @hasMany('paymentCurrencies', { async: false, inverse: null }) paymentCurrencies;
}
