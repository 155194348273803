import Model,{attr} from '@ember-data/model';
import {buildValidations, validator} from 'ember-cp-validations';
import {computed} from '@ember/object';

const Validations = buildValidations({
  max_bonus: {
    description: 'Max Bonus',
    validators: [
      validator('presence', true),
      validator('number', {gt: 0})
    ],
    disabled: computed('model.benefits_type', 'model.bonus_action_id', function () {
      return (this.model.isDeposit || this.model.isManual) && this.model.isFS;
    })
  },
});
export default class BonusConditionCurrencyModel extends Model.extend(Validations) {
  @attr('number') currency_id;
  @attr('number') bonus_id;
  @attr('number') max_bonus;
  @attr('number') fixed_bonus;
}
