import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class GlobalVarsService extends Service {
  userTypes = {
    global: 'global',
    partner: 'partner',
  };

  statuses = {
    active: 10,
    blocked: 20,
  };

  playerStatuses = {
    blocked: 0,
    inactive: 10,
    active: 20,
  };

  siteContentTypes = {
    menu: 'menu',
    subMenu: 'sub_menu',
    subMenuContent: 'sub_menu_content',
  };

  betStatuses = {
    pending: 1,
    win: 2,
    lost: 3,
    return: 4,
    manual: 5,
    cashout: 6,
  };

  accountTypes = {
    bonus: 'bonus',
    main: 'main',
    cashDesk: 'cashDesk',
    freeze: 'freeze',
    paymentAccount: 'paymentAccount',
    freebet: 'freeBet',
    casinoBonus: 'casinoBonus'
  }

  playerBlockStatuses = {
    enabled: 'Enabled',
    disabled: 'Disabled',
    confirmation: 'Confirmation',
    disabledFraud: 'Disabled.fraud',
    disabledPermanentSelfExclude: 'Disabled.permanent_self_exclude',
    disabledReason: 'Disabled.reason',
    disabledChargeback: 'Disabled.chargeback'
  }
  playerBlockStatusesList = [
    {
      value: this.playerBlockStatuses.enabled,
      title: 'Enabled'
    },
    {
      value: this.playerBlockStatuses.disabled,
      title: 'Disabled'
    },
    {
      value: this.playerBlockStatuses.confirmation,
      title: 'Confirmation'
    },
    {
      value: this.playerBlockStatuses.disabledFraud,
      title: 'Disabled - Fraud'
    },
    {
      value: this.playerBlockStatuses.disabledPermanentSelfExclude,
      title: 'Disabled - Permanent Self exclude'
    },
    {
      value: this.playerBlockStatuses.disabledReason,
      title: 'Disabled - Reason'
    },
    {
      value: this.playerBlockStatuses.disabledChargeback,
      title: 'Disabled - Chargeback'
    },
  ]

  @tracked partnerConfigs = null;
  @tracked url = null;

  run() {
    this.partnerConfigs = window.partnerConfigs;
    this.setDomainManager();
  }

  setDomainManager() {
    this.url = {
      site_api: `${window.location.origin}/siteapi`,
      admin_api: `${window.location.origin}/adminapi`,
      casino_api: `${window.location.origin}/casinoapi`,
      payment_api: `${window.location.origin}/paymentapi`,
      odds_feed_api: `${window.location.origin}/ws`,
      socket_notify_ws: `wss://${window.location.host}/ws`,
    };
  }
}
