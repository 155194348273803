import MainFilter from "./main-filter";
import {task} from 'ember-concurrency';
import {tracked} from '@glimmer/tracking';
import {action, computed, set} from '@ember/object';
import {inject as service} from '@ember/service';
import {later} from '@ember/runloop';
import moment from 'moment';

export default class MainLimits extends MainFilter {
  @service modalWindow;
  @service notifications;
  @service intl;

  @tracked meta = null;
  @tracked model = null;
  @tracked filteredKpi = null;
  @tracked showContent = false;
  @tracked reConvertedVal = null;
  @tracked selectedCurrency = null;
  @tracked baseRoute = this.router.currentRouteName
  @tracked currencyVal = this.store.peekAll('currency-course')[0].get('value');
  @tracked total_bet_amount_limit = null;
  @tracked convertedVal = this.args.item ? this.args.item.total_bet_amount_limit : null;
  @tracked data = {
    partner_id: this.partner.selected_id,
    currency_id: 1,
    sport_id: this.args.sportId,
    league_id: this.args.leagueId ? this.args.leagueId : null,
    market_id: this.args.marketId ? this.args.marketId : null,
    local_game_id: this.args.item && this.args.item.local_game_id ? this.args.item.local_game_id : null,
    expiration_date: this.args.item && this.args.item.expiration_date ? this.args.item.expiration_date : this.minDate,
    total_bet_amount_limit: this.convertedVal,
    include: this.url + 'Limit'
  }

  minDate = moment().endOf('day').unix();

  get baseTask() {
    return this.getKpi;
  }

  @computed('args.item.total_limit_remnant', 'convertedVal', 'selectedCurrency', 'total_bet_amount_limit')
  get showError() {
    if (this.convertedVal) {
      return this.convertedVal < parseFloat(this.args.item.total_limit_remnant);
    }
    return false;
  }

  @action
  onInputTotalBetAmountLimit(val) {
    this.total_bet_amount_limit = val;
    this.convertedVal = (parseFloat(val) / parseFloat(this.currencyVal)).toFixed(2);
    set(this, 'data.total_bet_amount_limit', this.convertedVal)
  }

  @action
  setEmpty(attribute, attribute2 = null) {
    set(this, `${attribute}`, '');
    if (attribute2) set(this, `${attribute2}`, '');
    set(this, `data.total_bet_amount_limit`, '');
  }

  @action
  openLogs(model, name, field, id) {
    this.modalWindow.open('risk-management/limits/logs/sb-logs', {
      model: model,
      title: name,
      field: field,
      id_model: id,
    });
  }

  @action
  toggleItem(e) {
    let element = e.target.closest(".row-item-wrapper");
    let childElement = element.querySelector(".row-item-content");

    if (childElement.style.maxHeight && this.showContent) {
      childElement.style.maxHeight = `${childElement.scrollHeight}px`;
      later(this, function () {
        childElement.style.maxHeight = '0';

      }, 200)
    } else {
      childElement.style.maxHeight = `${childElement.scrollHeight}px`;

      later(this, function () {
        childElement.style.maxHeight = 'none';
      }, 200);
    }

    this.showContent = !this.showContent;
    if (this.showContent) {
      this.getKpi.perform();
    }
  }

  @action
  selectDate(date) {
    set(this.data, 'expiration_date', moment(date).unix())
  }

  @action
  saveLimit(decider) {
    this.saveLimitTask.perform(decider);
  }

  @action
  selectCurrency(val) {
    this.getCourse.perform(val, true)
  }

  @(task(function* (decider) {
    this.model = this.store.peekAll(`${decider}-limit`).find(i => (i.market ? "market_id" : "sport_id") === (i.market ? this.args.marketId : this.args.sportId));

    if (!this.model) {
      this.model = this.store.createRecord(`${decider}-limit`);
    }
    Object.keys(this.data).forEach(attrs => {
      this.model[attrs] = this.data[attrs];
    })
    if (this.model.total_bet_amount_limit === "" || this.model.total_bet_amount_limit === 0) {
      yield this.model.destroyRecord().then(() => {
        this.notifications.success(this.intl.t('Limit was removed'), {
          autoClear: true
        });
      }).catch((payload) => {
        if (payload.errors) {
          payload.errors.forEach((error) => {
            this.notifications.error(error, {
              autoClear: true
            });
          })
        }
      });
    } else {
      yield this.model.save().then(() => {
        this.notifications.success(this.intl.t('Successfully Changed'), {
          autoClear: true
        });
      }).catch(({payload}) => {
        if (payload.errors) {
          payload.errors.forEach((error) => {
            this.notifications.error(error, {
              autoClear: true
            });
          })
        }
      });
    }
  }))saveLimitTask

  @(task(function* (val, selected = false) {
    yield this.ajax.patch(`/get-course/${val}`, {data: {}}).then(course => {
      this.currencyVal = course['currency-courses'][0].value
      if (((this.args.item && this.args.item.total_bet_amount_limit !== null) || selected) && this.total_bet_amount_limit) {
        this.convertedVal = (this.total_bet_amount_limit / parseFloat(this.currencyVal)).toFixed(2)
        if (this.args.item) this.reConvertedVal = this.total_bet_amount_limit;
        set(this, 'data.total_bet_amount_limit', this.convertedVal)
      }
    })
  }))getCourse

  @(task(function* () {
    yield this.ajax.request(`/limits/sport-league-limits/${this.url}-kpi`, {
      data: {
        ...this.filters,
        include: `${this.url}Limit`,
        partner_id: this.partner.selected_id,
        currency_id: this.partner.currency_id,
      }
    }).then(filteredKpi => {
      this.meta = filteredKpi['meta'];
      this.filteredKpi = filteredKpi[Object.keys(filteredKpi)[0]];
      this.filteredKpi.forEach(item => {
        let data = {};
        data[`${this.url}Limit`] = item[`${this.url}Limit`];
        if (data[`${this.url}Limit`]) this.store.pushPayload(`${this.url}-limit`, data)
      })
    });
  }))getKpi
}
