import Model, {attr} from '@ember-data/model';

export default class PartnerDomainManagerModel extends Model {
  @attr partner_id;

  @attr api_domain

  @attr desktop_enabled;
  @attr desktop_host;
  @attr desktop_site_api;
  @attr desktop_admin_api;
  @attr desktop_odds_feed_api;
  @attr desktop_payment_api;
  @attr desktop_casino_api;

  @attr mobile_enabled;
  @attr mobile_host;
  @attr mobile_site_api;
  @attr mobile_admin_api;
  @attr mobile_odds_feed_api;
  @attr mobile_payment_api;
  @attr mobile_casino_api;

  @attr backoffice_enabled;
  @attr backoffice_host;
  @attr backoffice_site_api;
  @attr backoffice_admin_api;
  @attr backoffice_odds_feed_api;
  @attr backoffice_payment_api;
  @attr backoffice_casino_api;

  @attr cashbox_enabled;
  @attr cashbox_host;
  @attr cashbox_site_api;
  @attr cashbox_admin_api;
  @attr cashbox_odds_feed_api;
  @attr cashbox_payment_api;
  @attr cashbox_casino_api;

	@attr live_monitor_enabled;
	@attr live_monitor_host;
	@attr live_monitor_site_api;
	@attr live_monitor_admin_api;
	@attr live_monitor_odds_feed_api;
  @attr live_monitor_payment_api;
  @attr live_monitor_casino_api;
}
