import AuthenticatedRoute from '../authenticated';

export default class RetailAdministratorsAdministratorTransactionHistoryRoute extends AuthenticatedRoute {
  queryParams = {
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    start: {
      refreshModel: true
    },
    end: {
      refreshModel: true
    },
    type_id: {
      refreshModel: true
    },
    name: {
      refreshModel: true
    },
    currency_id: {
      refreshModel: true
    },
    url: {
      refreshModel: true
    },
    sort_name: {
      refreshModel: true
    },
    administrator: {
      refreshModel: true
    },
    manager: {
      refreshModel: true
    },
    cashier: {
      refreshModel: true
    },
    sort_type: {
      refreshModel: true
    }
  };

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }
}
