import Model, { attr } from '@ember-data/model';

export default class PageModel extends Model {
  @attr('string') title;
  @attr('string') url;
  @attr('string') content;
  @attr('number') partner_id;
  @attr('boolean') is_active;
  @attr('number', { defaultValue: false }) is_mobile;



  toJSON() {
    return {
      title: this.title,
      url: this.url,
      content: this.content,
      partner_id: this.partner_id,
      is_active: this.is_active,
      is_mobile: this.is_mobile
    }
  }
}
