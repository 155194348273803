import ApplicationAdapter from './application';

export default class SourceMessageAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/source-messages'
  }

  urlForCreateRecord() {
    return this.host + '/create-source-message';
  }

  urlForDeleteRecord(id) {
    return this.host + `/delete-source-message/${id}`;
  }

}
