import {inject as service} from '@ember/service';
import {action, computed, set} from '@ember/object';
import {task} from 'ember-concurrency'
import {tracked} from "@glimmer/tracking";
import MainFilter from "../main-filter";

export default class MainRetail extends MainFilter {
  @service modalWindow;
  @service navigation;
  @service notifications;
  @service eventTrigger;

  @tracked meta;
  @tracked baseRoute = this.router.currentRouteName
  @tracked currencies = null;
  @tracked selectedPartnerId = this.args.params.partner_id && this.currentUser.user.isWithoutPartner ? this.args.params.partner_id : this.currentUser.user.isWithoutPartner && !this.args.params.partner_id ? this.partner.all[0].id : this.currentUser.user.partner_id;
  //** TODO fix currency_id search
  @tracked selectedCurrencyId = this.args.params.currency_id && this.currentUser.user.isWithoutPartner ? this.args.params.currency_id : this.currentUser.user.isWithoutPartner && !this.args.params.currency_id ? this.partner.all[0].currency_id : this.currentUser.user.currency_id;

  decider = '';

  get partners() {
    return this.partner.all;
  }

  @action
  selectCurrency(value) {
    value = value === '' || parseInt(value) === 0 ? "" : value;
    set(this.filters, 'currency_id', value);
    this.setPage(1);
  }

  @computed('filters.name')
  get searchName() {
    return this.filters.name;
  }

  set searchName(search) {
    set(this, 'filters.name', search);
    this.setTimeOut();
  }

  @computed('filters.{id,name}')
  get searchId() {
    return this.filters.name;
  }

  set searchId(search) {
    set(this, 'filters.id', search);
    this.setTimeOut();
  }

  @(task(function* (user_id) {
    let data = {};
    data.partner_id = this.filters.partner_id;
    data.user_id = user_id;
    data[this.decider] = true;
    yield this.store.query('currency', data).then(currencies => {
      this.currencies = currencies;
    })
  }))getCurrencies
}
