import {inject as service} from '@ember/service';
import AuthenticatedRoute from "../../../authenticated";

export default class PaymentIntegrationSettingsDepositMethodRoute extends AuthenticatedRoute {
  definer = 'payment_integration';

  @service store;

  model() {
    return this.paramsFor('payment/integration/settings/deposit-method').deposit_method_integration_setting_id
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = true;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = false;
  }
}
