import Model,{attr} from '@ember-data/model';
import {tracked} from '@glimmer/tracking';
import {buildValidations, validator} from 'ember-cp-validations';
import {computed} from '@ember/object';

const Validations = buildValidations({
  value: {
    description: 'From Value',
    validators: [
      validator('presence', true)
    ],
  },
  until_value: {
    description: 'To Value',
    validators: [
      validator('presence', true),
      validator('number', {gt: computed('model.value', function () {
          return this.model.value;
        })})
    ],
  }
});

export default class BonusProgressiveCurrencyModel extends Model.extend(Validations) {
  @attr currency_id;
  @attr('number') bonus_progressive_id;
  @attr('number') value;
  @attr('number') until_value;

  @tracked currency_code;
  @tracked errorMessage = undefined;
  @tracked untilErrorMessage = undefined;
}
