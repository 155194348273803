import Model, {attr, hasMany} from '@ember-data/model';

export default class FirstDepositModel extends Model {
  @attr amount
  @attr amount_in_eur
  @attr created_at
  @attr currency_id
  @attr integration_id
  @attr is_manual
  @attr payment_method_id
  @attr player_id
  @attr updated_at

  @hasMany('player', {async: false, inverse: null}) player;
}
