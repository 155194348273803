import Model, {attr, belongsTo, hasMany} from '@ember-data/model';
import {buildValidations, validator} from 'ember-cp-validations';

const Validations = buildValidations({
  name: {
    description: 'Name',
    validators: [
      validator('presence', true),
    ],
  },
});

export default class BannerCasinoModel extends Model.extend(Validations) {
  @attr('string', {defaultValue: ''}) background;
  @attr('string', {defaultValue: ''}) image;
  @attr('boolean', {defaultValue: false}) is_active;
  @attr('number') link_id;
  @attr('string') name;
  @attr('number') partner_id;
  @attr('number') position;
  @attr('number') createdAt;
  @attr('number') updatedAt;

  @belongsTo('partner-link', { async: false, inverse: null }) link;

  @hasMany('banner-casino-languages', { async: false, inverse: null }) languages;
}
