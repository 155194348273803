import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>", {"contents":"<GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>","moduleName":"backoffice/components/route-components/risk-management/bet-history/sb-bet-history.hbs","parseOptions":{"srcName":"backoffice/components/route-components/risk-management/bet-history/sb-bet-history.hbs"}});
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';

export default class RouteComponentsRiskManagementBetHistorySbBetHistoryComponent extends Component {
  @service partner;

  @tracked menuItems = [
    {
      name: 'Casino',
      definer: 'r_m_bet_history_casino',
      linkTo: {
        path: 'risk-management.bet-history.casino'
      }
    }
  ];
}
