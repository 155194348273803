import Model, {attr, belongsTo} from '@ember-data/model';

export default class PromotionPlayerModel extends Model {
  @attr bonus_player_id;
  @attr player_id;
  @attr percent;
  @attr info;
  @attr title;
  @attr body;
  @attr status;
  @attr('image') image;
  @attr url;
  @attr expiration_date;
  @attr updated_at;
  @attr created_at;

  @belongsTo('bonus-player', { async: false, inverse: null }) bonusPlayer;
}
