import ApplicationAdapter from './application';

export default class AdminDepositAdapter extends ApplicationAdapter {
  urlForQuery(params) {
    if(params.retail){
      return this.host + '/retail/transactions/get';
    }
    return this.host + '/admin-deposits'
  }
}
