import Model, {attr, belongsTo} from '@ember-data/model';

export default class SocialConfigModel extends Model {
@attr('string') title
@attr('string') client_id
@attr('string') client_secret
@attr('boolean') is_active
@attr('number') partner_config_id
@attr('boolean') is_multicolored

@belongsTo('partner-config', { async: false, inverse: null }) partnerConfig
}
