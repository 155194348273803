import Controller from '@ember/controller';

export default class SportsbookSettingsTemplateMarketsController extends Controller {
  queryParams = [
    'page',
    'perPage',
    'lng',
    'market_id',
    'name',
  ];

  page = 1;
  perPage = 10;
  lng = 'en';
  market_id;
  name;
}
