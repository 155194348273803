import ApplicationAdapter from './application';

export default class AffiliateBannerAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/affiliate/banners'
  }

  urlForCreateRecord(){
    return this.host+'/affiliate/banners';
  }
}
