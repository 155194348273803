import ApplicationAdapter from './application';

export default class CashierDepositPlayerAdapter extends ApplicationAdapter {
  urlForCreateRecord(model, params) {
    if (params.__attributes.retail) {
      return this.host + '/players/cashier-deposit/create'
    }
    return this.host + '/cashier-deposit-players'
  }
}
