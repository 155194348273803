import Controller from '@ember/controller';
import {inject as service} from '@ember/service';
import moment from 'moment';

export default class RiskManagementPlayersIndexController extends Controller {
  @service currentUser;

  queryParams = [
    'page',
    'perPage',
    'partner_id',
    'group_ids',
    'id',
    'name',
    'username',
    'email',
    'phone',
    'deposit_quantity_from',
    'deposit_quantity_to',
    'last_deposit_date_from',
    'last_deposit_date_to',
    'last_login_from',
    'last_login_to',
    'registration_date_from',
    'registration_date_to',
    'total_income_from',
    'total_income_to',
    'currency_id',
    'income_by_bets_start',
    'income_by_bets_end',
    'income_by_currency_id',
    'income_by_bets_from',
    'income_by_bets_to',
    'income_by_bets_product',
    'sort_name',
    'sort_type',
    'this_month_income_product',
    'total_income_product',
    'balance_from',
    'deposit_amount_from',
    'deposit_amount_to',
    'balance_to',
    'balance_product',
    'segment_id',
    'active_bonus',
    'free_spins',
    'kyc'
  ];

  page = 1;
  perPage = 10;
  partner_id;
  group_ids;
  active_bonus = 0;
  free_spins = 0;
  id;
  name;
  username;
  email;
  phone;
  total_income_from;
  total_income_to;
  currency_id;
  deposit_quantity_from
  deposit_quantity_to
  last_login_from
  last_login_to
  last_deposit_date_to;
  last_deposit_date_from;
  registration_date_from;
  registration_date_to;
  income_by_bets_start = moment().startOf('month').unix();
  income_by_bets_end = moment().endOf('month').unix();
  income_by_currency_id;
  income_by_bets_from;
  income_by_bets_to;
  income_by_bets_product = 'Sport';
  total_income_product = 'Sport';
  sort_name;
  sort_type;
  balance_from;
  deposit_amount_from;
  deposit_amount_to;
  balance_to;
  balance_product = 'Sport';
  segment_id;
  kyc;
}
