import ApplicationAdapter from './application';

export default class BannerCasinoAdapter extends ApplicationAdapter {
  urlForCreateRecord() {
    return `${this.host}/cms/casino-banners`
  }

  urlForQuery() {
    return `${this.host}/cms/casino-banners`
  }

  urlForUpdateRecord(id) {
    return `${this.host}/cms/casino-banners/${id}`;
  }

  urlForDeleteRecord(id) {
    return `${this.host}/cms/casino-banners/${id}`;
  }
}
