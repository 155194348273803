import ApplicationAdapter from './application';

export default class WheelShopAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/wheel/wheel-shops'
  }

  urlForFindAll() {
    return this.host + '/wheel/wheel-shops';
  }

  urlForCreateRecord() {
    return this.host + '/wheel/wheel-shops';
  }

  urlForUpdateRecord(id) {
    return this.host + '/wheel/wheel-shops/' + id;
  }

  urlForDeleteRecord(id) {
    return this.host + `/wheel/wheel-shops/${id}`;
  }
}
