import AuthenticatedRoute from '../../authenticated';

export default class TournamentsTournamentsRoute extends AuthenticatedRoute {
  definer = 'tournaments_tournaments'

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.optionSystem = false;
    this.partner.showIsBonusSwitcher = false;
    this.partner.showIsEur = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = false;
    this.partner.optionSystem = false;
    this.partner.showIsBonusSwitcher = false;
    this.partner.showIsEur = false;
    this.partner.showPartnerCurrencySelect = false;
  }
}
