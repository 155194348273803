import Controller from '@ember/controller';

export default class SportsbookSettingsTemplateLeaguesController extends Controller {
  queryParams = [
    'page',
    'perPage',
    'lng',
    'sport_id',
    'league_id',
    'name',
  ];

  page = 1;
  perPage = 10;
  lng = 'en';
  sport_id;
  league_id;
  name;
}
