import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class PartnerStyleSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    theme: {embedded: 'always'}
  }

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (requestType === 'queryRecord') {
      payload['partner-style'] = payload['partner-styles']?.[0] || null;
      delete payload['partner-styles'];
    }

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
