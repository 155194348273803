import ApplicationAdapter from './application';

export default class AdminWithdrawRequestAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/affiliate/withdraw-request';
  }
  urlForCreateRecord() {
    return this.host + '/affiliate/create-withdraw-request';
  }
}
