import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\">\n  <div class=\"field-box\">\n    <h3 class=\"label\">{{t 'Do you really want to delete?'}}</h3>\n  </div>\n  <button {{on 'click' this.delete}} class=\"btn btn-basic btn-full\">{{t 'Yes'}}</button>\n</div>\n{{yield}}", {"contents":"<div class=\"add-new-managers-fields\">\n  <div class=\"field-box\">\n    <h3 class=\"label\">{{t 'Do you really want to delete?'}}</h3>\n  </div>\n  <button {{on 'click' this.delete}} class=\"btn btn-basic btn-full\">{{t 'Yes'}}</button>\n</div>\n{{yield}}","moduleName":"backoffice/components/modals/settings/partner/group/delete/delete-group.hbs","parseOptions":{"srcName":"backoffice/components/modals/settings/partner/group/delete/delete-group.hbs"}});
import Component from '@glimmer/component';
import {task} from "ember-concurrency";
import {inject as service} from '@ember/service';
import {action} from "@ember/object";

export default class ModalsSettingsPartnerGroupDeleteDeleteGroupComponent extends Component {
  @service ajax;
  @service store;
  @service modalWindow;
  @service notifications;
  @service eventTrigger;

  @action
  delete() {
    this.destroyGroup.perform()
  }

  @(task(function* () {
    let group = this.modalWindow.data.group;
    yield group.destroyRecord().then(() => {
      this.eventTrigger.trigger('delete-group', group)
      this.modalWindow.closeModal()
    }).catch((payload) => {
      group.rollbackAttributes()
      if (payload && payload.errors) {
        payload.errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true,
            clearDuration: 3200
          });
        })
      }
    });
  }))destroyGroup

}
