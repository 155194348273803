import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='add-new-managers-fields' {{did-insert this.onDidInsert}}>\n  <form onsubmit={{action 'createPartner'}} class='form-inline pull-right'>\n    <div class='field-box'>\n      <span class='label'>{{t 'Partner Name'}}</span>\n      <Input type='text' @value={{this.partner.title}} />\n    </div>\n    {{#unless this.createPartnerTask.isRunning}}\n      <button class='btn btn-basic btn-full' disabled={{false}} type=\"submit\">{{t 'Save'}}</button>\n    {{else}}\n      <button class='btn btn-basic btn-full loading dark' type=\"submit\"></button>\n    {{/unless}}\n  </form>\n</div>\n{{yield}}", {"contents":"<div class='add-new-managers-fields' {{did-insert this.onDidInsert}}>\n  <form onsubmit={{action 'createPartner'}} class='form-inline pull-right'>\n    <div class='field-box'>\n      <span class='label'>{{t 'Partner Name'}}</span>\n      <Input type='text' @value={{this.partner.title}} />\n    </div>\n    {{#unless this.createPartnerTask.isRunning}}\n      <button class='btn btn-basic btn-full' disabled={{false}} type=\"submit\">{{t 'Save'}}</button>\n    {{else}}\n      <button class='btn btn-basic btn-full loading dark' type=\"submit\"></button>\n    {{/unless}}\n  </form>\n</div>\n{{yield}}","moduleName":"backoffice/components/modals/settings/partner/add-partner.hbs","parseOptions":{"srcName":"backoffice/components/modals/settings/partner/add-partner.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';

export default class ModalsSettingsRoleAddRoleComponent extends Component {
  @service store;
  @service modalWindow;
  @service notifications;
  @service intl;

  @tracked partner;

  @action
  onDidInsert() {
    this.partner = this.store.createRecord('partner');
  }

  @action
  createPartner(e) {
    e.preventDefault();
    this.createPartnerTask.perform();
  }

  @task(function* () {
    try {
      yield this.partner.save();
      this.modalWindow.closeModal();
      this.notifications.success(this.intl.t('partner create successfully'), {
        autoClear: true,
      });
    } catch (response) {
      this.notifications.error(response, {
        autoClear: true,
      });
    }
  })
  createPartnerTask;
}
