import AuthenticatedRoute from '../authenticated';

export default class RiskManagementBetHistoryRoute extends AuthenticatedRoute {
  definer = 'risk_management_bet_history'

  activate() {
    this.partner.optionSystem = true;
    this.partner.showPartnerCurrencySelect = true;
    this.partner.allCurrencyOption = true;
    this.partner.showIsEur = true;
  }

  deactivate() {
    this.partner.optionSystem = false;
    this.partner.showPartnerCurrencySelect = false;
    this.partner.allCurrencyOption = false;
    this.partner.showIsEur = false;
  }
}
