import Model, { attr } from '@ember-data/model';

export default class PaymentBannerModel extends Model {
  @attr('number') partner_id;
  @attr('string') title;
  @attr('image') image;
  @attr('boolean') is_active;
  @attr('boolean') is_dark
  @attr('number') order;
}
