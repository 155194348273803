import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

const Phone = class BaseValidatorClass extends BaseValidator {
  @service ajax;
  @service globalVars;

  validate(value, options, model, attribute) {
    const href = 'validate-input';
    let data = {};
    data[attribute] = value;
    data.isNew = options.isNew;
    data.required = !options.allowBlank;

    if (value !== undefined) {
      return this.ajax
        .post(`${href}`, { data: data })
        .then(() => {
          return true;
        })
        .catch(({ payload }) => {
          let errors = payload.errors;
          if (errors) {
            return errors[0][0].length === 1 ? this.intl.t('Server Error') : errors[0][0];
          }
        });
    }
  }
};

Phone.reopenClass({
  /**
   * Define attribute specific dependent keys for your validator
   *
   * [
   *  `model.array.@each.${attribute}` --> Dependent is created on the model's context
   *  `${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
   * ]
   *
   * @param {String}  attribute   The attribute being evaluated
   * @param {Unknown} options     Options passed into your validator
   * @return {Array}
   */
  getDependentsFor() {
    return [];
  },
});

export default Phone;
