import {inject as service} from '@ember/service';
import AuthenticatedRoute from "../../../authenticated";

export default class CasinoSettingsTemplateCategoriesRoute extends AuthenticatedRoute {
  definer = 'casino_settings_key_categories';

  @service store;

  model() {
    let template_id = this.paramsFor('casino/settings/template').template_id;

    return this.store.query('casino-template-category', {template_id, include: 'currencies'})
  }
}
