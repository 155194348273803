import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\" {{did-insert (fn this.onDidInsert)}}>\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Title'}}</span>\n    <Input type=\"text\" @value={{this.navigation.title}} />\n  </div>\n\n  {{#if this.navigation.isSaving}}\n  \t<button class=\"btn btn-full loading\"></button>\n  {{else}}\n  \t<button class=\"btn btn-full\" {{action this.save}}>{{t 'Save'}}</button>\n  {{/if}}\n</div>\n", {"contents":"<div class=\"add-new-managers-fields\" {{did-insert (fn this.onDidInsert)}}>\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Title'}}</span>\n    <Input type=\"text\" @value={{this.navigation.title}} />\n  </div>\n\n  {{#if this.navigation.isSaving}}\n  \t<button class=\"btn btn-full loading\"></button>\n  {{else}}\n  \t<button class=\"btn btn-full\" {{action this.save}}>{{t 'Save'}}</button>\n  {{/if}}\n</div>\n","moduleName":"backoffice/components/modals/settings/navigations/new/sb-new.hbs","parseOptions":{"srcName":"backoffice/components/modals/settings/navigations/new/sb-new.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';

export default class ModalsSettingsNavigationsNewSbNewComponent extends Component {
  @service store;
  @service modalWindow;
  @service partner;

  @tracked navigation = null;

  @action
  onDidInsert() {
    this.navigation = this.store.createRecord('navigation');
    this.navigation.navigation_type_id = 1;
    this.navigation.navigation_group_id = this.modalWindow.data.group_id;
    this.navigation.partner_id = this.partner.selected_id;
  }

  @action
  save() {
    this.navigation.save().then(navigation => {
      this.store.peekRecord('navigation-group', navigation.navigation_group_id).navigations.pushObject(navigation);
      this.modalWindow.closeModal();
    })
  }
}
