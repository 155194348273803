import AuthenticatedRoute from '../../authenticated';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {storageFor} from "ember-local-storage";

export default class PaymentPaymentMethodsPaymentMethodDetailRoute extends AuthenticatedRoute {
  @service store;
  @service navigation;

  @storageFor('partner') partnerStorage;

  @tracked link;

  definer = 'payment_methods'

  beforeModel(transition) {
    super.beforeModel(transition);
    this.link = transition.to.name;
  }

  model(params) {

    return params;
  }

  deactivate() {
    this.navigation.customHeaderTitle = null;
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = false;
    this.partner.showCurrentPlayerCurrencies = false;
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
    this.partner.showCurrentPlayerCurrencies = false;
  }
}
