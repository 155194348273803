import ApplicationAdapter from './application';

export default class NotificationDocumentAdapter extends ApplicationAdapter {
  urlForFindAll() {
    return this.host + '/notification/player-documents';
  }

  urlForDeleteRecord(id) {
    return this.host + `/player-documents/${id}`;
  }
}
