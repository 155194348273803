import {action} from '@ember/object';
import {tracked} from '@glimmer/tracking';
import Component from '@glimmer/component';
import {task} from 'ember-concurrency'
import {inject as service} from '@ember/service';

export default class PaymentMethod extends Component {
  @service store;
  @service modalWindow;
  @service partner;
  @service notifications;
  @service intl;


  @tracked modelName;
  @tracked paymentMethods = [];
  @tracked interkassa = true;
  @tracked checkedAll = false;

  get checkedPaymentMethods() {
    return this.paymentMethods.filter(i => i.checked);
  }

  @action
  edit(method) {
    method.save();
  }

  @action
  setImageUrl(id, imageUrl) {
    let depositMethod = this.store.peekRecord(this.modelName, parseInt(id));
    depositMethod.image = imageUrl;
    depositMethod.save();
  }

  @action
  saveMethod(method) {
    method.save().then(() => {
          this.notifications.success(this.intl.t('Successfully Changed'), {
            autoClear: true
          })
        }
    ).catch((payload) => {
      if (payload.errors) {
        payload.errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true
          });
        })
      }
    });
  }

  @action
  saveAllMethod() {
    this.checkedPaymentMethods.forEach((paymentMethod)=>{
      this.saveMethod(paymentMethod)
    })
  }

  @action
  toggleChecked(paymentMethod) {
    paymentMethod.checked = !paymentMethod.checked;
  }

  @action
  toggleStatus(paymentMethod) {
    paymentMethod.status = !paymentMethod.status;
  }

  @action
  showDescription(model) {
    this.modalWindow.open(`payment/description/sb-description`, {
      title: `Description`,
      model
    });
  }

  @action
  checkAll() {
    this.checkedAll = !this.checkedAll;

    this.paymentMethods.forEach(paymentMethod => paymentMethod.checked = this.checkedAll);
  }

  @(task(function* () {
    let data
    if(this.interkassa){
       data = {cashbox_id: parseInt(this.args.params),currency_id:parseInt(this.partner.currency_id)};
    }else{
      data = {integration_setting_id:parseInt(this.args.params),currency_id:parseInt(this.partner.currency_id), include: 'paymentCurrency'};
    }
    yield this.store.query(this.modelName, data).then((data)=>{
      this.paymentMethods = data;
    }).catch((payload) => {
      if (payload.errors) {
        payload.errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true
          });
        })
      }else if(payload){
        this.notifications.error(payload, {
          autoClear: true
        });
      }
    });
  }))getMethod
}
