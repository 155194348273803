import Component from '@glimmer/component';
import {action} from '@ember/object';
import {tracked} from '@glimmer/tracking';
import {task} from 'ember-concurrency';
import {inject as service} from '@ember/service'
import {next} from '@ember/runloop';
import moment from 'moment';

export default class MainBanner extends Component {
  @service store;
  @service partner;

  @tracked selectedBanner = null;

  @tracked language_id;

  get languages() {
    return this.partner.selectedPartner.languages;
  }

  get banners() {
    return this.store.peekAll(`${this.url}`).filter(i => i.partner_id === this.partner.selected_id).slice().sort((a,b) => a.order - b.order).filter(i => i.language_id === this.language_id);
  }

  @action
  setLanguage(language_id) {
    this.language_id = language_id;
    this.bannerTask.perform();
  }

  @action
  createNewBanner() {
    this.selectedBanner = this.store.createRecord(`${this.url}`, {
      language_id: this.language_id,
      expiration_date: moment().endOf('week').unix()
    });
  }

  @action
  reorderItems(groupModel, itemModels, draggedModel) {
    itemModels.forEach((item, order) => {
      item.order = order
    });
    if (draggedModel.modelName === `${this.url}`) {
      this.store.pushPayload(`${this.url}`, itemModels);
      itemModels.forEach((item, order) => item.order = order);
    }
  }

  @action
  setBanner(banner) {
    this.selectedBanner = null;
    next(() => {
      this.selectedBanner = banner;
    })
  }


  @(task(function* () {
    yield this.store.query(`${this.url}`, {
      partner_id: this.partner.selected_id,
      language_id: this.language_id
    }).then(banners => {
      this.selectedBanner = banners.slice().sort((a,b) => a.order - b.order)[0];
    })
  }))bannerTask


}
