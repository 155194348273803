import ApplicationAdapter from './application';

export default class CasinoIntegrationAdapter extends ApplicationAdapter {
  urlForQuery(params) {
    if(params.isStats){
      return this.host + '/get-integrations-in-reports'
    }
    if(params.isCasino){
      return this.host + '/casinoDashboard/integrations'
    }
    if(params.is_bonus){
      return this.host + '/bonusTemplates/bonuses/casino-integrations';
    }
    return this.host + '/casinoIntegration/integrations'
  }
}
