import ApplicationAdapter from './application';

export default class LocalMarketAdapter extends ApplicationAdapter {
  urlForQuery(params) {
    if(params.is_bonus) {
      return this.host + '/bonusTemplates/bonuses/local-market';
    }
    if(params.is_dashboard) {
      return this.host + '/get-dashboard-local-markets';
    }
    return this.host + '/get-local-sports'
  }
}
