import Model, {attr} from '@ember-data/model';

export default class SearchGameModel extends Model {
  @attr('string') sport_id;
  @attr('string') region_id;
  @attr('string') league_id;
  @attr('string') game_id;
  @attr('string') feedType;
  @attr('string') team_1_name;
  @attr('string') team_2_name;
  @attr('string') team_1_id;
  @attr('string') team_2_id;
  @attr() game_start;
}
