import Model, {attr, belongsTo} from '@ember-data/model';
import {storageFor} from "ember-local-storage";
import { computed } from '@ember/object';

export default class CasinoBetModel extends Model {
  @storageFor('partner') partnerStorage

  @attr game_id
  @attr player_id
  @attr('number') bonus_player_id
  @attr external_round_id
  @attr('boolean') is_free_spin
  @attr external_transaction_id
  @attr external_refund_transaction_id
  @attr bet_type
  @attr provider_id
  @attr bet
  @attr bet_in_eur
  @attr win
  @attr win_in_eur
  @attr is_refund
  @attr created_at

  @belongsTo('casino-game-list', {async: false, inverse: null}) casinoGameList
  @belongsTo('casino-game-provider', {async: false, inverse: null}) casinoGameProvider
  @belongsTo('player', {async: false, inverse: null}) player
  @belongsTo('currency', {async: false, inverse: null}) currency
  @computed('partnerStorage.isEurOn', 'bet_in_eur', 'bet')

  get betAmount() {
    return this.partnerStorage.get('isEurOn') ? this.bet_in_eur : this.bet;
  }

  @computed('partnerStorage.isEurOn', 'win_in_eur', 'win')
  get winAmount() {
    return this.partnerStorage.get('isEurOn') ? this.win_in_eur : this.win;
  }
}
