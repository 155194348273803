import Component from '@glimmer/component';
import {inject as service} from '@ember/service'
import {action, set} from '@ember/object';
import {tracked} from '@glimmer/tracking';
import {task} from 'ember-concurrency';
import { next } from '@ember/runloop';

export default class MainBuilder extends Component {
  @service store;
  @service partner;
  @service notifications;

  @tracked deviceType = 'desktop';
  @tracked searchText;
  @tracked isSearch;
  @tracked linker;

  @action
  applyCalendar(event, dateRangePicker) {
    this.args.banner.expiration_date = dateRangePicker.endDate.unix();
  }

  @action
  setDeviceType(type) {
    this.deviceType = type;
  }

  @action
  setImageUrl(imageUrl) {
    this.args.banner.image = imageUrl;
  }

  @action
  saveBanner() {
    this.save.perform();
  }

  @action
  cancelChanges() {
    this.args.banner.rollbackAttributes();
  }

  @(task(function* () {
    this.args.banner.partner_id = this.partner.selected_id
    if (parseInt(this.args.banner.banners_type) === 10) {
      set(this.args, "banner.subtitle", null);
      set(this.args, "banner.button_title", null);
    }

    yield this.args.banner.save().then(() => {
      this.notifications.success('Successfully Changed', {
        autoClear: true
      });
    })
  }))save

  @action
  setViewType(type) {
    set(this.args, "banner.banners_type", type);
  }

  @action
  setLink(id) {
    this.args.banner.link_id = id;
    this.args.banner.link = null;
    next(() => {
      this.args.banner.link = this.store.peekRecord('partner-link', id);
    })
  }

  @action
  toggleImgFullScreen() {
    this.args.banner.img_full_screen = !this.args.banner.img_full_screen;
  }
}
