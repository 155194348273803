import ApplicationAdapter from './application';

export default class AffiliateSettingAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/affiliate/settings'
  }

  urlForCreateRecord(){
    return this.host+'/affiliate/settings';
  }
  urlForUpdateRecord(id){
    return this.host+`/affiliate/settings/${id}`;
  }
}
