import Route from '@ember/routing/route';

export default class AffiliatesClientsProfileTransactionRoute extends Route {
  definer = 'affiliates_clients_transaction'

  queryParams = {
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    sort_name: {
      refreshModel: true
    },
    sort_type: {
      refreshModel: true
    },
    type_id: {
      refreshModel: true
    },
    account_type: {
      refreshModel: true
    },
    amount: {
      refreshModel: true
    },
    currency_id: {
      refreshModel: true
    },
    start: {
      refreshModel: true
    },
    end: {
      refreshModel: true
    }
  };

  model(params) {
    params.player_id = parseInt(this.paramsFor('affiliates.clients.profile').player_id);
    return params
  }
}
