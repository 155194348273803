import ApplicationAdapter from './application';

export default class FilteringPlayerAdapter extends ApplicationAdapter {
  urlForCreateRecord() {
    return this.host + '/create-players-filter';
  }

  urlForQuery(param) {
    if(param.affiliate){
      return this.host + '/affiliate/client/player-filter';
    }
    return this.host + '/get-player-filters';
  }

  urlForDeleteRecord(id) {
    return this.host + `/delete-player-filter/${id}`;
  }

  urlForUpdateRecord(id) {
    return this.host + `/update-player-filter/${id}`;
  }
}
