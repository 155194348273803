import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("  {{#if this.menuItems}}\n    <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n  {{/if}}\n{{yield}}", {"contents":"  {{#if this.menuItems}}\n    <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n  {{/if}}\n{{yield}}","moduleName":"backoffice/components/route-components/risk-management/transactions/sb-transactions.hbs","parseOptions":{"srcName":"backoffice/components/route-components/risk-management/transactions/sb-transactions.hbs"}});
import Component from '@glimmer/component';

export default class RouteComponentsRiskManagementTransactionsSbTransactionsComponent extends Component {
  menuItems = [
    {
      name: 'Online',
      linkTo: {
        path: 'risk-management.transactions.online'
      },
      definer: 'r_m_transactions_key_online'
    }
  ];
}
