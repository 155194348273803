import AuthenticatedRoute from '../../authenticated';

export default class RiskManagementTransactionsRetailRoute extends AuthenticatedRoute {
  definer = 'r_m_transactions_key_retail'

  queryParams = {
    type_id: {
      refreshModel: true
    },
    player_id: {
      refreshModel: true
    },
    cashier_name: {
      refreshModel: true
    },
    group_ids: {
      refreshModel: true
    },
    partner_id: {
      refreshModel: true
    },
    transaction_id: {
      refreshModel: true
    },
    currency_id: {
      refreshModel: true
    },
    payment_deposit_method_id: {
      refreshModel: true
    },
    payment_withdraw_method_id: {
      refreshModel: true
    },
    start: {
      refreshModel: true
    },
    end: {
      refreshModel: true
    }
  };

  model(params) {
    return params;
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }
}
