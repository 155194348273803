import Model, {attr, belongsTo} from '@ember-data/model';
import {tracked} from '@glimmer/tracking'

export default class LeagueModel extends Model {
  @attr name
  @attr is_new

  @belongsTo('region', { async: false, inverse: null }) region
  @belongsTo('sport', { async: false, inverse: null }) sport

  @tracked order = null;
  @tracked region_id = this.region.id;
  @tracked sport_id = this.region.get('sport.id');
  @tracked title = this.name;
}
