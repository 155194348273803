import Controller from '@ember/controller';
import moment from "moment";

export default class AffiliatesTransactionsWithdrawRequestController extends Controller {
  queryParams = [
    'page',
    'perPage',
    'integration_id',
    'affiliate_id',
    'method_id',
    'currency_id',
    'start',
    'end',
    'sort_name',
  ];

  page;
  perPage;
  integration_id;
  affiliate_id;
  method_id;
  currency_id;
  sort_name;
  start = moment().startOf('month').unix();
  end = moment().endOf('day').unix();
}
