import ApplicationAdapter from './application';

export default class CasinoIntegrationSettingAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/casinoIntegration/settings'
  }

  urlForQueryRecord() {
    return this.host + '/casinoIntegration/settings'
  }

  urlForCreateRecord() {
    return this.host + '/casinoIntegration/settings'
  }

  urlForUpdateRecord(id) {
    return this.host + `/casinoIntegration/settings/${id}`
  }
}
