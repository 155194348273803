import AuthenticatedRoute from "../../authenticated";
import {inject as service} from '@ember/service';

export default class CasinoSettingsTemplateRoute extends AuthenticatedRoute {
  @service store;

  definer = 'casino_settings';

  model() {
    this.store.unloadAll('casino-template-category');
    this.store.unloadAll('casino-template-game-list');
    this.store.unloadAll('casino-template-provider');
    this.store.unloadAll('casino-template-type-label');
  }
}
