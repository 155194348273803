import AuthenticatedRoute from '../../../authenticated';

export default class RiskManagementPlayersProfileDocumentsRoute extends AuthenticatedRoute {
  definer = 'r_m_players_key_documents'

  model() {
    return this.paramsFor('risk-management.players.profile');
  }

  activate() {
    this.partner.showCurrentPlayerCurrencies = false;
    this.partner.showPartnerCurrencySelect = false;
    this.partner.showIsEur = false;
  }

  deactivate() {
    this.partner.showCurrentPlayerCurrencies = false;
    this.partner.showIsEur = false;
    this.partner.showPartnerCurrencySelect = false;
  }
}
