import ApplicationAdapter from './application';

export default class SportsbookTemplateSportAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/sportsbook/templates/sports'
  }

  urlForCreateRecord() {
    return this.host + '/sportsbook/templates/sports'
  }

  urlForUpdateRecord($id) {
    return this.host + '/sportsbook/templates/sports/' + $id
  }

  urlForDeleteRecord($id) {
    return this.host + '/sportsbook/templates/sports/' + $id
  }
}
 