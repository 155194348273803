import Model, {attr, belongsTo} from '@ember-data/model';

export default class GroupAdminPartnerModel extends Model {
  @attr user_id;
  @attr partner_id;
  @attr created_at;
  @attr updated_at;

  @belongsTo('partner') partner;
}
