import AuthenticatedRoute from '../../../authenticated';

export default class RiskManagementPlayersProfileEditLogsRoute extends AuthenticatedRoute {
  definer = 'r_m_players_key_edit_logs'

  queryParams = {
    start: {
      refreshModel: true
    },
    end: {
      refreshModel: true
    },
    user_id: {
      refreshModel: true
    }
  }

  model(params) {
    params.player_id = this.paramsFor('risk-management.players.profile').player_id;
    return params;
  }
}
