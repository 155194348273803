import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class PaymentTemplateWithdrawMethodSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    integration: {embedded: 'always'},
    currency: {embedded: 'always'},
    availableCountries: {embedded: 'always'},
    currencyExchanges: {embedded: 'always'},
    paymentTemplateCategory: {embedded: 'always'},

  }
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (payload['payment-template-withdraw-methods'] && Array.isArray(payload['payment-template-withdraw-methods'])) {
      payload['payment-template-withdraw-methods'].forEach(method => {
        if (method.availableCountries) {
          method.availableCountries.forEach(availableCountry => {
            availableCountry.id = `${availableCountry.template_deposit_method_id}-${availableCountry.country_id}`
          })
        }
        if (method.currencyExchanges) {
          method.currencyExchanges.forEach(currencyExchange => {
            currencyExchange.id = `${currencyExchange.payment_template_withdraw_method_id}-${currencyExchange.currency_id}`
          })
        }
      })
    }
    return this._normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
