import AuthenticatedRoute from "../../../authenticated";

export default class RiskManagementPlayersProfileCasinoBetHistoryRoute extends AuthenticatedRoute {
  definer = 'r_m_players_key_bet_history'

  queryParams = {
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    sort_name: {
      refreshModel: true
    },
    sort_type: {
      refreshModel: true
    },
    type_id: {
      refreshModel: true
    },
    game_id: {
      refreshModel: true
    },
    status_id: {
      refreshModel: true
    },
    search_val: {
      refreshModel: true
    },
    isFreeSpin: {
      refreshModel: true
    },
    start: {
      refreshModel: true
    },
    end: {
      refreshModel: true
    }
  };

  model(params) {
    params.player_id = this.paramsFor('risk-management.players.profile').player_id;
    return params
  }

  activate() {
    this.partner.showCurrentPlayerCurrencies = false;
    this.partner.showPartnerCurrencySelect = false;
    this.partner.showIsEur = false;
  }

  deactivate() {
    this.partner.showCurrentPlayerCurrencies = false;
    this.partner.showIsEur = false;
    this.partner.showPartnerCurrencySelect = true;
  }
}
