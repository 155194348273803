import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class BannerCasinoSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  entity = 'bannerCasino'
  attrs = {
    link: {embedded: 'always'},
    languages: {embedded: 'always'},
  };

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (requestType === 'createRecord') {
      payload = {
        [`${this.entity}s`]: { id: payload || null }
      };
    }

    if (requestType === 'query') {
      payload = {
        [`${this.entity}s`]: payload || []
      };
    }

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }

  serializeIntoHash(data, type, snapshot, options) {
    Object.assign(data, this.serialize(snapshot, options));
  }

  serialize() {
    let {link, createdAt, updatedAt, ...json} = super.serialize(...arguments);

    return json;
  }
}
