import AuthenticatedRoute from '../../authenticated';

export default class AffiliatesLinksBannersIndexRoute extends AuthenticatedRoute {
  definer = 'affiliates_links_banners'

  activate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }
}
