import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import moment from "moment";

export default class SegmentRuleDataModel extends Model {
  @service store;

  @attr('string') value;
  @attr('number') condition_id;
  @attr('number') operation_id;

  @tracked item = null;

  get valueDateRangeForPattern() {
    return `${moment(new Date(this.formatValueForDate.start * 1000)).format('Y/MM/DD')}-${moment(new Date(this.formatValueForDate.end * 1000)).format('Y/MM/DD')}`
  }

  get formatValueForDate() {
    if(this.value) {
      return {
        start: +(this.value.split('-')[0]),
        end: +(this.value.split('-')[1]),
      }
    }

    return {
      start: parseInt(Date.now() / 1000),
      end: parseInt(Date.now() / 1000),
    }
  }

  get condition() {
    return this.condition_id ? this.store.peekRecord('segment-condition', this.condition_id) : null;
  }

  get operation() {
    return this.operation_id ? this.store.peekRecord('segment-operation', this.operation_id) : null;
  }

  get operationType() {
    if (!this.condition) return null;
    return this.store.peekRecord('segment-operation-type', this.condition.type_id);
  }

  get defaultValue() {
    switch (this.operationType.name) {
      case 'Boolean':
        return 'Yes';
      case 'Date range':
        return `${parseInt(Date.now() / 1000)}-${parseInt(Date.now() / 1000)}`;
      default:
        return '';
    }
  }

  toJSON() {
    return {
      id: +this.id || null,
      value: this.value,
      condition_id: this.condition_id,
      operation_id: this.operation_id,
    };
  }
}
