import Helper from '@ember/component/helper';

export default class SortIcons extends Helper {

  compute([name, currentName, currentType]) {
    let result = '';
    if (name === currentName) {
      if (currentType === 4) {
        result = 'sb-arrowdown';
      } else {
        result = 'sb-arrowup';
      }
    }
    return result;
  }
}
