import ApplicationAdapter from './application';

export default class CasinoTemplateAdapter extends ApplicationAdapter {
  urlForFindAll() {
    return this.host + '/casino/templates'
  }

  urlForCreateRecord() {
    return this.host + '/casino/templates'
  }

  urlForUpdateRecord($id) {
    return this.host + '/casino/templates/' + $id
  }

  urlForDeleteRecord($id) {
    return this.host + '/casino/templates/' + $id
  }
}
