import ApplicationAdapter from './application';

export default class CasinoTemplateTypeLabelAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/casino/type-labels'
  }

  urlForCreateRecord() {
    return this.host + '/casino/type-labels'
  }

  urlForUpdateRecord($id) {
    return this.host + '/casino/type-labels/' + $id
  }

  urlForDeleteRecord($id) {
    return this.host + '/casino/type-labels/' + $id
  }
}
