import ApplicationAdapter from './application';

export default class PaymentDepositMethodAdapter extends ApplicationAdapter {
  urlForQuery(param) {
    if(param.transaction){
      return this.host + '/transactions/deposit-method'
    }
    if(param.is_bonus){
      return this.host + '/bonusTemplates/bonuses/deposit-methods'
    }
    return this.host + '/payment/settings/deposit-methods'
  }

  urlForUpdateRecord(id) {
    return this.host + `/payment/settings/deposit-methods/${id}`;
  }
}
