import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\">\n  <div class=\"field-box\">\n    <h3 class=\"label\">{{t 'Do you realy want to delete this bonus?'}}</h3>\n  </div>\n\n  <button\n    class=\"btn btn-basic btn-full {{if this.deleteTask.isRunning 'loading'}}\"\n    type=\"button\"\n    disabled={{if this.deleteTask.isRunning 'disabled'}}\n    onclick={{perform this.deleteTask}}\n  >\n    {{t 'Yes'}}\n  </button>\n</div>\n{{yield}}\n", {"contents":"<div class=\"add-new-managers-fields\">\n  <div class=\"field-box\">\n    <h3 class=\"label\">{{t 'Do you realy want to delete this bonus?'}}</h3>\n  </div>\n\n  <button\n    class=\"btn btn-basic btn-full {{if this.deleteTask.isRunning 'loading'}}\"\n    type=\"button\"\n    disabled={{if this.deleteTask.isRunning 'disabled'}}\n    onclick={{perform this.deleteTask}}\n  >\n    {{t 'Yes'}}\n  </button>\n</div>\n{{yield}}\n","moduleName":"backoffice/components/modals/bonuses/delete-bonus/sb-delete-bonus.hbs","parseOptions":{"srcName":"backoffice/components/modals/bonuses/delete-bonus/sb-delete-bonus.hbs"}});
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency'

export default class ModalsBonusesDeleteBonusSbDeleteBonusComponent extends Component {
  @service store;
  @service modalWindow;
  @service intl;
  @service notifications;

  constructor(owner, args) {
    super(owner, args);
  }

  @action
  cancel() {
    this.modalWindow.closeModal()
  }

  @task
  *deleteTask () {
    let bonusId = this.modalWindow.data.bonusId

    let bonus = yield this.store.findRecord('bonus', bonusId)
    yield bonus.destroyRecord().catch((payload) => {
      if (payload && payload.errors) {
        this.notifications.error(payload.errors, {
          autoClear: true
        })
      }
    }).then(() => {
      let bonusBenefit = this.store.peekAll('bonus-benefit').find(item => +item.bonus_id === +bonusId)
      if (bonusBenefit) {
        bonusBenefit.unloadRecord()
      }
      let bonusProgressives = this.store.peekAll('bonus-progressive').filter(item => +item.bonus_id === +bonusId);
      if (bonusProgressives.length) {
        bonusProgressives.forEach(bonusProgressive => {
          bonusProgressive.unloadRecord();
        })
      }

      this.modalWindow.data?.onTrigger();
      this.modalWindow.closeModal();
    })
  }
}
