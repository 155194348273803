import Model, {attr, belongsTo, hasMany} from '@ember-data/model';
import {buildValidations, validator} from 'ember-cp-validations';

const Validations = buildValidations(
  {
    username: {
      description: 'Username',
      validators: [
        validator('presence', true),
        validator('length', {
          min: 6,
          max: 15
        })
      ]
    },
    first_name: {
      description: 'First Name',
      validators: [
        validator('presence', true),
        validator('format', {regex: /^[a-zA-Z\s]+$/g, message: '{description} must be only letters'}),
        validator('length', {
          min: 2,
          max: 15
        })
      ]
    },
    last_name: {
      description: 'Last Name',
      validators: [
        validator('presence', true),
        validator('format', {regex: /^[a-zA-Z\s]+$/g, message: '{description} must be only letters'}),
        validator('length', {
          min: 2,
          max: 15
        })
      ]
    },
    password: {
      description: 'Password',
      validators: [
        validator('presence', true),
        validator('format', {
          regex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d].{7,}$/,
          message:
            '{description} must include minimum eight characters, at least one letter'
        }),
        validator('length', {
          isWarning: true,
          min: 8,
          max: 20,
          message: 'What kind of weak password is that?'
        })
      ]
    }
  }
);

export default class PlayerModel extends Model.extend(Validations) {
  @attr('number') profile_id
  @attr('number') partner_id
  @attr username
  @attr first_name
  @attr password;
  @attr last_name
  @attr auth_key
  @attr email
  @attr status_id
  @attr('boolean') verified
  @attr('boolean') is_active
  @attr('boolean') is_blocked
  @attr confirm_date
  @attr created_at
  @attr updated_at
  @attr affiliate_name
  @attr last_login
  @attr kyc_rejected
  @attr block_status
  @attr block_reason

  @attr('number') sportIncome
  @attr('number') casinoIncome
  @attr yourIncome
  @attr('number') spinCount

  @belongsTo('profile', { async: false, inverse: null }) profile;
  @belongsTo('first-deposit', { async: false, inverse: null }) firstDeposit;
  @belongsTo('partner', { async: false, inverse: null }) partner;
  @belongsTo('affiliate-setting', { async: false, inverse: null }) affiliateSetting;
  @belongsTo('bonus-player', { async: false, inverse: null }) activeBonusPlayer;
  @belongsTo('account', { async: false, inverse: null }) mainAccount;
  @hasMany('bonus-player', { async: false, inverse: null }) bonusPlayers;
  @hasMany('segment', { async: false, inverse: null }) segments;
  // @belongsTo('casino-account', { async: false, inverse: null }) casinoAccountMain;
  @hasMany('player-document', { async: false, inverse: null }) playerDocuments;
  @hasMany('account', { async: false, inverse: null }) accounts;
  // @hasMany('casino-account', { async: false, inverse: null }) casinoAccounts;
  @hasMany('player-message', { async: false, inverse: null }) playersMessages;
  @hasMany('player-ip-history', { async: false, inverse: null }) playerIpHistories;
  @hasMany('sports-kpi', { async: false, inverse: null }) sportsKpis;

  get fullName() {
    let first = this.first_name === null ? '' : this.first_name;
    let last = this.last_name === null ? '' : this.last_name;
    return first + ' ' + last;
  }

  get playersConfig() {
    return this.mainAccount.playersConfig
  }

  get bonusAccount() {
    let type = this.store.peekAll('account-type').filter(i => i.wallet === 'bonus').filter(i => i.owner === 'player');
    return this.accounts.filter(i => i.type_id === Number(type[0].id))[0]
  }


  // get casinoMainAccount() {
  //   let type = this.store.peekAll('casino-account-type').filter(i => i.wallet === 'casino').filter(i => i.owner === 'player');
  //   return this.casinoAccounts.filter(i => i.type_id === Number(type[0].id))[0]
  // }
  //
  // get casinoBonusAccount() {
  //   let type = this.store.peekAll('casino-account-type').filter(i => i.wallet === 'bonus').filter(i => i.owner === 'player');
  //   return this.casinoAccounts.filter(i => i.type_id === Number(type[0].id))[0]
  // }
}
