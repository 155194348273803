import AuthenticatedRoute from '../../authenticated';

export default class SettingsPartnersDetailsRoute extends AuthenticatedRoute {
  definer = 'settings_partners'

  model(params) {
    return params;
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }
}
