import Controller from '@ember/controller';

export default class RetailReportsRemainingCreditsController extends Controller {
  queryParams = [
    'currency_ids',
    'partner_ids',
    'cashbox_id',
    'perPage',
    'page',
    'administrator_id',
    'manager_id',
    'by',
  ];

  currency_ids;
  perPage = 100;
  page = 1;
  by = 'currency';
  partner_ids;
  cashbox_id;
  administrator_id;
  manager_id;
}
