import Model, {attr, belongsTo} from '@ember-data/model';
import {storageFor} from "ember-local-storage";
import {computed} from '@ember/object';

export default class WithdrawRequestModel extends Model {
  @storageFor('partner') partnerStorage

  @attr player_id
  @attr amount
  @attr amount_in_eur
  @attr cashbox_id
  @attr comment
  @attr manager_approval_id
  @attr system_user_approval_id
  @attr withdraw_info
  @attr status
  @attr internal_value
  @attr internal_name
  @attr integration_id
  @attr currency_id
  @attr payment_method_id
  @attr payment_method
  @attr type
  @attr created_at
  @attr updated_at
  @attr approved_date
  @attr('boolean') notification_off

  @belongsTo('player', {async: false, inverse: null}) player
  @belongsTo('withdraw', {async: false, inverse: null}) withdraw
  @belongsTo('payment-withdraw-method', {async: false, inverse: null}) paymentMethod
  @belongsTo('payment-integration', {async: false, inverse: null}) integration

  get getAccountType() {
    return 'Main'
  }

  get amountProcessed() {
    return this.partnerStorage.get('isEurOn') ? this.amount_in_eur : this.amount_in_eur;
  }
}
