import Model, {attr, belongsTo} from '@ember-data/model';
import {inject as service} from '@ember/service';
export default class BonusPlayerModel extends Model {
  @service bonusService;

  @attr('string') bonus_code;
  @attr('number') player_id;
  @attr('number') external_id;
  @attr('number') status;
  @attr('number') currency_id;
  @attr('number') bonus_action_id;
  @attr('number') display;
  @attr('number') deposit_amount;
  @attr('number') claimed_total_amount;
  @attr('number') complete_balance;
  @attr('number') played_balance;
  @attr('number') free_spin;
  @attr('number') payment_id;
  @attr('number') total_wager_amount;
  @attr('number') max_bonus_amount;
  @attr('number') fixed_bonus_amount;
  @attr('number') external_progressive_currency_id;
  @attr('number') created_at;
  @attr('string') reasonText;

  @belongsTo('player', { async: false, inverse: null }) player;
  @belongsTo('player-free-spin', { async: false, inverse: null }) playerFreeSpin;
  @belongsTo('bonus', { async: false, inverse: null }) bonus;
  @belongsTo('currency', { async: false, inverse: null }) currency;
  @belongsTo('bonus-action', { async: false, inverse: null }) bonusAction;
  @belongsTo('promotion-player', { async: false, inverse: null }) promotionPlayer;

  get isCash() {
    return this.bonus.benefits_type === this.bonusService.bonusBenefitsTypeCash;
  }

  get isStatusArchive() {
    return this.status === this.bonusService.bonusStatusArchive;
  }

  get isStatusComplete() {
    return this.status === this.bonusService.bonusStatusComplete;
  }
}
