import AuthenticatedRoute from '../../authenticated';

export default class AffiliatesTransactionsWithdrawRequestRoute extends AuthenticatedRoute {
  definer = 'affiliates_transactions_withdraw_request'

  queryParams = {
    page: {refreshModel: true},
    perPage: {refreshModel: true},
    integration_id: {refreshModel: true},
    affiliate_id: {refreshModel: true},
    method_id: {refreshModel: true},
    currency_id: {refreshModel: true},
    start: {refreshModel: true},
    end: {refreshModel: true},
    sort_name: {refreshModel: true}
  };

  model(params) {
    return params
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }
}
