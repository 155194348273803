import ApplicationAdapter from './application';

export default class AdminDocumentAdapter extends ApplicationAdapter {
  urlForQuery(param) {
    if(param.administrator){
      return this.host + '/retail/administrators/transaction-history'
    }
    if(param.manager){
      return this.host + '/retail/managers/transaction-history'
    }
    if(param.cashier){
      return this.host + '/retail/cashiers/transaction-history'
    }
    return this.host + '/admin-document'
  }
}
