import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\">\n  <form onsubmit={{action \"create\"}} class=\"form-inline pull-right\">\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Title'}}</span>\n      <Input type=\"text\" @value={{this.title}}/>\n    </div>\n    {{#unless this.createTask.isRunning}}\n      <button class=\"btn btn-basic btn-full\" type=\"submit\">{{t 'Save'}}</button>\n    {{else}}\n      <button class=\"btn btn-basic btn-full loading dark\" type=\"submit\"></button>\n    {{/unless}}\n  </form>\n</div>\n{{yield}}\n", {"contents":"<div class=\"add-new-managers-fields\">\n  <form onsubmit={{action \"create\"}} class=\"form-inline pull-right\">\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Title'}}</span>\n      <Input type=\"text\" @value={{this.title}}/>\n    </div>\n    {{#unless this.createTask.isRunning}}\n      <button class=\"btn btn-basic btn-full\" type=\"submit\">{{t 'Save'}}</button>\n    {{else}}\n      <button class=\"btn btn-basic btn-full loading dark\" type=\"submit\"></button>\n    {{/unless}}\n  </form>\n</div>\n{{yield}}\n","moduleName":"backoffice/components/modals/bonuses/create-bonus/sb-create-bonus.hbs","parseOptions":{"srcName":"backoffice/components/modals/bonuses/create-bonus/sb-create-bonus.hbs"}});
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking'
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency';

export default class ModalsBonusesCreateBonusSbCreateBonusComponent extends Component {
  @service modalWindow;
  @service bonusService;
  @service partner;
  @service store;

  @tracked bonus;
  @tracked title;

  @action
  create(e) {
    e.preventDefault();
    e.stopPropagation();
    this.bonus = this.store.createRecord(`bonus`);
    this.bonus.title = this.title;
    this.bonus.type = this.modalWindow.data.type;
    this.bonus.isLogic = false;
    this.bonus.status = this.bonusService.bonusStatusInactive;
    this.bonus.partner_id = this.partner.selected_id;
    this.bonus.usage_period = 7;
    this.createTask.perform();
  }

  @(task(function* () {
    yield this.bonus.save().then(() => {
      this.modalWindow.closeModal()
    }).catch();
  }))createTask
}
