import AuthenticatedRoute from "../../authenticated";

export default class StatsReportsByDepositWithdrawRoute extends AuthenticatedRoute {
  definer = 'stats_reports_key_retail_transactions'

  queryParams = {
    group_ids: {
      refreshModel: true
    },
    partner_ids: {
      refreshModel: true
    },
    cashbox_id: {
      refreshModel: true
    },
    payment_integration_id: {
      refreshModel: true
    },
    currency_ids: {
      refreshModel: true
    },
    start: {
      refreshModel: true
    },
    end: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    payment_sphere: {
      refreshModel: true
    },
    by: {
      refreshModel: true
    },
    administrator_id: {
      refreshModel: true
    },
    manager_id: {
      refreshModel: true
    }
  };
  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.optionSystem = false;
    this.partner.showIsBonusSwitcher = false;
    this.partner.showIsEur = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = false;
    this.partner.optionSystem = false;
    this.partner.showIsBonusSwitcher = false;
    this.partner.showIsEur = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  model(params) {
    if(params.currency_ids) {
      params.currency_ids = params.currency_ids.split(',');
    }
    if(params.partner_ids) {
      params.partner_ids = params.partner_ids.split(',');
    }
    return params
  }
}
