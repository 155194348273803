import ApplicationAdapter from './application';

export default class CurrencyAdapter extends ApplicationAdapter {
  urlForQuery(params) {
    if(params.retail_administrators) {
      return this.host + '/retail/administrators/get-currencies';
    }
    if(params.retail_administrators_by_current) {
      return this.host + '/retail/administrators/get-currencies-by-current';
    }
    if(params.retail_managers) {
      return this.host + '/retail/managers/get-currencies';
    }
    if(params.retail_managers_by_current) {
      return this.host + '/retail/managers/get-currencies-by-current';
    }
    if(params.retail_cashiers) {
      return this.host + '/retail/cashiers/get-currencies';
    }
    if(params.retail_cashiers_by_current) {
      return this.host + '/retail/cashiers/get-currencies-by-current';
    }
    return this.host + '/currencies';
  }
}
