import ApplicationAdapter from './application';

export default class BetEventAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/get-bet-events'
  }

  urlForFindRecord(id) {
    return this.host + '/get-bet-events/' + id
  }
}
