import BaseValidator from 'ember-cp-validations/validators/base';
import {inject as service} from '@ember/service';

const UniqueUsername = class BaseValidatorClass extends BaseValidator {
  @service ajax;
  @service globalVars;

  //TODO fix permissions for affiliate user
  validate(value, options, model, attribute) {
    let data = {}, href = 'validate-input';

    if (attribute === 'oldPassword') {
      href = 'validate-password'
    }

    data[attribute] = value;
    data.isNew = options.isNew;
    data.partner_id = parseInt(model.partner_id);

    if (!data.isNew && data.isNew !== undefined && attribute !== 'oldPassword') {
      href = 'validate-username';
      data.user_id = model.id
    }
    data.partner_id = model.partner_id
    if (attribute === 'bonus_code') {
      if(!options.isNew && value !== undefined){
        return this.ajax.patch(`${this.globalVars.url.admin_api}/validate-bonus-code/${model.id}`, {data}).then(() => {
          return true;
        }).catch(({payload}) => {
          let errors = payload.errors;
          if (errors) {
            return errors[0][0];
          }
        });
      }
      href = 'validate-bonus-code';
    }
    if (value !== undefined) {
      return this.ajax.post(`${this.globalVars.url.admin_api}/${href}`, {data}).then(() => {
        return true;
      }).catch(({payload}) => {
        let errors = payload.errors;
        if (errors) {
          return errors[0][0];
        }
      });
    }
  }
};

UniqueUsername.reopenClass({
  /**
   * Define attribute specific dependent keys for your validator
   *
   * [
   *  `model.array.@each.${attribute}` --> Dependent is created on the model's context
   *  `${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
   * ]
   *
   * @param {String}  attribute   The attribute being evaluated
   * @param {Unknown} options     Options passed into your validator
   * @return {Array}
   */
  getDependentsFor(/*attribute, options*/) {
    return [];
  }
});

export default UniqueUsername;
