import Model, {attr} from '@ember-data/model';

export default class WheelShopModel extends Model {
  @attr() title;
  @attr() disabled;
  @attr('image') image_url;
  @attr() count;
  @attr() price;
  @attr() wheel_template_id;
}
