import AuthenticatedRoute from '../authenticated';

export default class StatsReportsRoute extends AuthenticatedRoute {
  definer = 'stats_reports'

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }
}
