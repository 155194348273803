import AuthenticatedRoute from '../../../authenticated';

export default class RiskManagementPlayersProfileBonusesRoute extends AuthenticatedRoute {
  definer = 'r_m_players_key_bonuses'

  queryParams = {
    status: {
      refreshModel: true
    },
    bonus_code: {
      refreshModel: true
    }
  }

  model(params) {
    params.player_id = this.paramsFor('risk-management.players.profile').player_id;
    return params
  }
}
