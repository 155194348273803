import StorageObject from 'ember-local-storage/local/object';

const Storage = StorageObject.extend();

// Uncomment if you would like to set initialState
Storage.reopenClass({
  initialState() {
    return {selected: 0, isBonus: 0, isEurOn: 0};
  }
});

export default Storage;
