import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.menuItems}}\n  <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{/if}}\n{{yield}}", {"contents":"{{#if this.menuItems}}\n  <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{/if}}\n{{yield}}","moduleName":"backoffice/components/route-components/settings/code-injection/sb-code-injection.hbs","parseOptions":{"srcName":"backoffice/components/route-components/settings/code-injection/sb-code-injection.hbs"}});
import Component from '@glimmer/component';
import {tracked} from "@glimmer/tracking";

export default class RouteComponentsSettingsCodeInjectionSbCodeInjectionComponent extends Component {
  @tracked menuItems = [
    {
      name: 'Desktop ',
      definer: 'settings_code_injection',
      linkTo: {
        path: 'settings.code-injection.desktop'
      }
    },
    {
      name: 'Mobile',
      definer: 'settings_code_injection',
      linkTo: {
        path: 'settings.code-injection.mobile'
      }
    }
  ];
}
