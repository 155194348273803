import Model, {attr, belongsTo} from '@ember-data/model';

export default class PaymentCurrencyModel extends Model {
  @attr integration_id;
  @attr external_currency_id;
  @attr('number') currency_id;
  @attr code;

  @belongsTo('currency', { async: false, inverse: null }) currency
}
