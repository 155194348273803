import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class BetEventSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    betStatus: {embedded: 'always'},
    bet: {embedded: 'always'},
    localGame: {embedded: 'always'},
    localEvent: {embedded: 'always'},
    localMarket: {embedded: 'always'},
    editLogs: {embedded: 'always'}
  }
}

