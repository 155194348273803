import AuthenticatedRoute from "../../../authenticated";

export default class PaymentIntegrationSettingsWithdrawMethodRoute extends AuthenticatedRoute {
  definer = 'payment_integration';

  model() {
    return this.paramsFor('payment/integration/settings/withdraw-method').withdraw_method_integration_setting_id
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = true;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = false;
  }
}
