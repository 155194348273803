import AuthenticatedRoute from '../authenticated';

export default class AffiliatesSubAffiliatesRoute extends AuthenticatedRoute {
  definer = 'affiliates_sub_affiliates'

  queryParams = {
    affiliate_user_id: {
      refreshModel: false
    }
  }

  beforeModel(){
    if(this.currentUser.user.parent_id) {
      this.router.transitionTo('affiliates.dashboard');
    }
  }

  model(params) {
    return params
  }

  activate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }
}
