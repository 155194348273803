import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\">\n    <div class=\"field-box\">\n        <span class=\"label\">{{t 'New Template Title'}}</span>\n        <Input type=\"text\" @value={{this.newTemplateTitle}} />\n    </div>\n\n    {{#if this.save.isRunning}}\n        <button class=\"btn btn-full\" type=\"button\">\n        <GenericComponents::Loading::SbLoading/>\n        </button>\n    {{else}} \n        <button class=\"btn btn-full\" type=\"button\" {{on 'click' (perform this.save)}}>{{t 'Save'}}</button>\n    {{/if}} \n</div>\n", {"contents":"<div class=\"add-new-managers-fields\">\n    <div class=\"field-box\">\n        <span class=\"label\">{{t 'New Template Title'}}</span>\n        <Input type=\"text\" @value={{this.newTemplateTitle}} />\n    </div>\n\n    {{#if this.save.isRunning}}\n        <button class=\"btn btn-full\" type=\"button\">\n        <GenericComponents::Loading::SbLoading/>\n        </button>\n    {{else}} \n        <button class=\"btn btn-full\" type=\"button\" {{on 'click' (perform this.save)}}>{{t 'Save'}}</button>\n    {{/if}} \n</div>\n","moduleName":"backoffice/components/modals/bonuses/wheel-templates/dublicate-wheel-template/sb-dublicate-wheel-template.hbs","parseOptions":{"srcName":"backoffice/components/modals/bonuses/wheel-templates/dublicate-wheel-template/sb-dublicate-wheel-template.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {task} from 'ember-concurrency';

export default class ModalsBonusesWheelTemplatesDublicateWheelTemplateSbDublicateWheelTemplateComponent extends Component {
  @service modalWindow;
  @service ajax;
  @service store;
  @tracked newTemplateTitle = null;

  @tracked wheelTemplateId = this.modalWindow.data.wheelParams.id;

  @(task(function* () {
    let data = {
      template_id: this.wheelTemplateId,
      title: this.newTemplateTitle
    };

    let result = yield this.ajax.post('dublicate-template', {data});
    this.store.pushPayload(result);
    this.modalWindow.closeModal();

  })) save;
}
