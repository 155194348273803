import Controller from '@ember/controller';
import moment from 'moment';

export default class StatsReportsByBetsController extends Controller {
  queryParams = [
    'group_ids',
    'currency_ids',
    'partner_ids',
    'start',
    'by',
    'end'
  ];

  group_ids;
  currency_ids;
  partner_ids;
  by = 'currency';
  start = moment().startOf('month').unix();
  end = moment().endOf('day').unix();
}
