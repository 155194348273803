import ApplicationAdapter from './application';

export default class SocialConfigAdapter extends ApplicationAdapter {
  urlForFindAll() {
    return this.host + '/settings/partner-configs/social-configs'
  }

  urlForQuery() {
    return this.host + '/settings/partner-configs/social-configs'
  }

  urlForQueryRecord() {
    return this.host + '/settings/partner-configs/social-configs'
  }

  urlForCreateRecord() {
    return this.host + '/settings/partner-configs/social-config';
  }

  urlForUpdateRecord(id) {
    return this.host + `/settings/partner-configs/social-config/${id}`;
  }
}
