import Model, { attr, belongsTo } from '@ember-data/model';

export default class NotificationAdminDepositModel extends Model {
  @attr('number') user_id;
  @attr('number') currency_id;
  @attr('number') amount;
  @attr('number') status;
  @attr('number') created_at;

  @belongsTo('user', { async: false, inverse: null }) fromUser;
  @belongsTo('currency', { async: false, inverse: null }) currency;

  get isPending() { return this.status === 10 }
  get isLike() { return this.status === 20 }
  get isDislike() { return this.status === 30 }
}
