import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.menuItems}}\n  <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{/if}}\n\n{{yield}}", {"contents":"{{#if this.menuItems}}\n  <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{/if}}\n\n{{yield}}","moduleName":"backoffice/components/route-components/payment/setting/template/sb-template.hbs","parseOptions":{"srcName":"backoffice/components/route-components/payment/setting/template/sb-template.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';

export default class RouteComponentsPaymentSettingTemplateSbTemplateComponent extends Component {
  @service router;

  get menuItems() {
    let template_id = +this.router.currentRoute.parent.params.template_id

    return [
      {
        name: 'Order',
        definer: 'payment_settings_key_order',
        linkTo: {
          path: 'payment.setting.template.order',
          model: template_id
        },
      },
      {
        name: 'Categories',
        definer: 'payment_settings_key_categories',
        linkTo: {
          path: 'payment.setting.template.category',
          model: template_id
        }
      },
      {
        name: 'Withdraw Methods',
        definer: 'payment_settings_key_withdraw_methods',
        linkTo: {
          path: 'payment.setting.template.withdraw-method',
          model: template_id
        }
      },
      {
        name: 'Deposit Methods',
        definer: 'payment_settings_key_deposit_methods',
        linkTo: {
          path: 'payment.setting.template.deposit-method',
          model: template_id
        }
      },
    ];
  }
}
