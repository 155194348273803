import ApplicationAdapter from './application';

export default class PartnerStyleAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/partner-styles'
  }

  urlForUpdateRecord(id) {
    return this.host + `/partner-style/${id}`;
  }
}
