import ApplicationAdapter from './application';

export default class MailConfigAdapter extends ApplicationAdapter {
  urlForUpdateRecord(id) {
    return this.host + `/partners/mail-configs/${id}`;
  }

  urlForCreateRecord() {
    return this.host + `/partners/mail-configs`;
  }
}
