import ApplicationAdapter from './application';

export default class CurrencyCourseAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/paymentConverter/currency-courses';
  }

  urlForUpdateRecord(id) {
    return this.host + `/paymentConverter/currency-courses/${id}`;
  }

  urlForDeleteRecord(id) {
    return this.host + `/paymentConverter/currency-courses/${id}`;
  }

  urlForCreateRecord() {
    return this.host + `/paymentConverter/currency-courses`;
  }
}
