import Model, { attr } from '@ember-data/model';

export default class SegmentOperationModel extends Model {
  @attr('string') symbol;
  @attr('number') type_id;

  get value() {
    if (this.symbol === 'Yes/No') {
      return '=';
    }

    return this.symbol;
  }
}
