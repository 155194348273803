import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

export default class PaymentTemplateDepositMethodModel extends Model {
  @attr('string') name;
  @attr('string') alias;
  @attr('boolean') is_active;
  @attr('boolean') check_profile;
  @attr min;
  @attr max;
  @attr('number') currency_id;
  @attr('number') integration_id;
  @attr('number') template_category_id;
  @attr('image') image;
  @attr('order', { defaultValue: 999 }) order;

  @hasMany('payment-deposit-available-country', { async: false, inverse: null }) availableCountries;
  @hasMany('payment-deposit-currency-exchange', { async: false, inverse: null }) currencyExchanges;
  @belongsTo('payment-template-category', { async: false, inverse: null }) paymentTemplateCategory;

  @belongsTo('payment-currency', { async: false, inverse: null }) currency;

  @belongsTo('payment-integration', { async: false, inverse: null }) integration;
  @tracked checked = false;
  @tracked ordering_key = 'order';
  @tracked isLastSelected = false;
  @tracked selected_currency_ids = [];

  modelName = 'payment-template-deposit-method';

  get countryIds() {
    return this.availableCountries.map((item) => item.country_id.toString());
  }

  get currencyExchangeIds() {
    return this.currencyExchanges.map((item) => item.currency_id.toString());
  }
}
