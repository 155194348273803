import MainTransactionHistory from '../../main-transaction-history';

export default class RetailAdministratorsAdministratorTransactionHistoryRoute extends MainTransactionHistory {
  definer = 'retail_administrators_key_transaction_history'

  model(params) {
    params['user_id'] = this.paramsFor('retail.administrators.administrator')['administrator_id']
    return params
  }
}
