import ApplicationAdapter from './application';

export default class BetSystemAdapter extends ApplicationAdapter {
  urlForQuery(params) {
    if(params.affiliate) {
      return this.host + '/affiliate/client/bet-system';
    }
    return this.host + '/get-bet-system';
  }
}
