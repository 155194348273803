import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';

export default class PaymentDepositCurrencyExchangeModel extends Model {
  @service store;

  @attr payment_template_deposit_method_id;
  @attr currency_id;

  modelName = 'payment-deposit-currency-exchange';

  get code() {
    return this.store.peekRecord('currency', this.currency_id).code;
  }
}
