import Model, { attr } from '@ember-data/model';

export default class SegmentOperationTypeModel extends Model {
  @attr('string') name;

  get isValueFromList() {
    return this.name === 'Values from list';
  }
  get isNumeric() {
    return this.name === 'Numeric';
  }
  get isBoolean() {
    return this.name === 'Boolean';
  }
  get isDateRange() {
    return this.name === 'Date range';
  }
}
