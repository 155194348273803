import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"progress-bar-wrapper\" id={{@id}}>\n  <div class=\"progress-bar\">\n    <div class=\"bar {{if this.isComplete 'complete' ''}}\" style=\"width:{{this.progress}}%\"></div>\n  </div>\n  <p class=\"percent\">{{this.progress}}%</p>\n</div>\n", {"contents":"<div class=\"progress-bar-wrapper\" id={{@id}}>\n  <div class=\"progress-bar\">\n    <div class=\"bar {{if this.isComplete 'complete' ''}}\" style=\"width:{{this.progress}}%\"></div>\n  </div>\n  <p class=\"percent\">{{this.progress}}%</p>\n</div>\n","moduleName":"backoffice/components/generic-components/loading/progress-bar/sb-progress-bar.hbs","parseOptions":{"srcName":"backoffice/components/generic-components/loading/progress-bar/sb-progress-bar.hbs"}});
import Component from "@glimmer/component";
import {inject as service} from "@ember/service";
import {tracked} from "@glimmer/tracking";

export default class GenericComponentsLoadingProgressBarSbProgressBarComponent extends Component {
  @service math;

  @tracked complete = false;
  max = 100;
  min = 0;

  get isComplete() {
    return this.progress === this.max;
  }

  get progress() {
    return this.parseProgress(this.args.progress);
  }

  parseProgress(value) {
    let progress = this.math.round(value);
    return progress >= this.min && progress <= this.max
      ? progress
      : progress < this.min ? this.min : this.max;
  }
}
