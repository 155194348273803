import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\" {{did-insert this.onDidInsert}}>\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Title'}}</span>\n    <Input type=\"text\" @value={{this.mirror.title}} />\n  </div>\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Country code'}}</span>\n    <Input type=\"text\" @value={{this.mirror.country_code}} />\n  </div>\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Redirect url'}}</span>\n    <Input type=\"text\" @value={{this.mirror.redirect_url}} />\n  </div>\n\n  {{#if this.save.isRunning}}\n    <div class=\"btn btn-basic btn-full log-in-btn loading dark\"></div>\n  {{else}}\n    <button class=\"btn btn-full\" {{on 'click' (perform this.save)}}>\n      {{t 'Save'}}\n    </button>\n  {{/if}}\n</div>\n\n\n", {"contents":"<div class=\"add-new-managers-fields\" {{did-insert this.onDidInsert}}>\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Title'}}</span>\n    <Input type=\"text\" @value={{this.mirror.title}} />\n  </div>\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Country code'}}</span>\n    <Input type=\"text\" @value={{this.mirror.country_code}} />\n  </div>\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Redirect url'}}</span>\n    <Input type=\"text\" @value={{this.mirror.redirect_url}} />\n  </div>\n\n  {{#if this.save.isRunning}}\n    <div class=\"btn btn-basic btn-full log-in-btn loading dark\"></div>\n  {{else}}\n    <button class=\"btn btn-full\" {{on 'click' (perform this.save)}}>\n      {{t 'Save'}}\n    </button>\n  {{/if}}\n</div>\n\n\n","moduleName":"backoffice/components/modals/settings/partners/domain-manager/mirrors/create/sb-create.hbs","parseOptions":{"srcName":"backoffice/components/modals/settings/partners/domain-manager/mirrors/create/sb-create.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class ModalsSettingsPartnersDomainManagerMirrorsCreateSbCreateComponent extends Component {
  @service store;
  @service router;
  @service modalWindow;
  @service notifications;
  @service intl;

  @tracked mirror;

  @action
  onDidInsert() {
    this.mirror = this.store.createRecord('partner-mirror', { partner_id: this.router.currentRoute.parent.params.partner_id });
  }

  @task(function* () {
    yield this.mirror
      .save()
      .then(() => {
        this.notifications.success(this.intl.t('Mirror successfully added'), {
          autoClear: true,
        });
        this.modalWindow.closeModal();
      })
      .catch((payload) => {
        payload.errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true,
          });
        });
      });
  })
  save;
}
