import AuthenticatedRoute from '../../authenticated';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {storageFor} from "ember-local-storage";
import {set} from '@ember/object';

export default class RiskManagementPlayersProfileRoute extends AuthenticatedRoute {
  @service store;
  @service navigation;

  @storageFor('partner') partnerStorage;

  @tracked link;

  definer = 'risk_management_players'

  beforeModel(transition) {
    super.beforeModel(transition);
    this.link = transition.to.name;
  }

  model({player_id}) {
    let parent = this.store.peekAll('site-content').find(i => i.definer === this.definer);
    let link = null;
    this.store.peekAll('site-content').filter(i => i.parent_id === parseInt(parent.id)).forEach((item) => {
      let withLink = this.navigation.risk_management_players_details.find((content) => {
        return content.linkTo === this.link
      });
      let val = this.navigation.risk_management_players_details.find(i => i.definer === item.definer);
      if (withLink) {
        link = withLink.linkTo;
      }
      if (!link && val) {
        link = val.linkTo
      }
    })
    this.store.queryRecord('player',{ id:player_id, include: 'accounts.currency,accounts.accountType'}).then((player)=>{
      let type = this.store.peekAll('account-type').filter(i => i.wallet === 'main').find(i => i.owner === 'player');
      set(this.partnerStorage, 'currentPlayerCurrencyId', +player.accounts.filter(i => i.type_id === parseInt(type.id))[0].get('currency_id'))
    });
    if(link !== this.link) {
      this.router.transitionTo(link);
    }
    return player_id;
  }

  deactivate() {
    this.navigation.customHeaderTitle = null;
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = false;
    this.partner.showCurrentPlayerCurrencies = false;
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
    this.partner.showCurrentPlayerCurrencies = false;
  }
}
