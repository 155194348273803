import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';

export default class PaymentWithdrawCurrencyExchangeModel extends Model {
  @service store;

  @attr payment_template_withdraw_method_id;
  @attr currency_id;

  modelName = 'payment-withdraw-currency-exchange';

  get code() {
    return this.store.peekRecord('currency', this.currency_id).code;
  }
}
