import Controller from '@ember/controller';
import moment from 'moment';

export default class RiskManagementManualWithdrawTricksController extends Controller {
  queryParams = [
    'partner',
    'currency_id',
    'player_id',
    'transaction_id',
    'start',
    'end',
    'sort_type',
    'type',
    'amount_range_start',
    'amount_range_end',
    'sort_name',
    'page',
    'perPage'
  ];

  page = 1;
  perPage = 10;
  partner;
  currency_id;
  player_id;
  type;
  transaction_id;
  sort_type;
  amount_range_start;
  amount_range_end;
  sort_name;
  start = moment().subtract(7, 'days').unix();
  end = moment().endOf('day').unix();
}
