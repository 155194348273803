import Model, {attr, hasMany} from '@ember-data/model';

export default class WheelTemplateModel extends Model {
  @attr('number') partner_id;
  @attr('string', {defaultValue: 'inactive'}) status;
  @attr('string') wheel_title;
  @attr('string') out_border_color;
  @attr('string') border_color;
  @attr('string') btn_bg_color;
  @attr('string') btn_txt_color;
  @attr('string') use_period;
  @attr('string') sector_border_color;
  @attr('boolean') have_sector_border;
  @attr('string') out_dots_color;
  @attr('string') out_dots_shadow;
  @attr('boolean') show_verified_users;

  @hasMany('wheel-sector', { async: false, inverse: null }) wheelSectors;
  @hasMany('wheel-shop', { async: false, inverse: null }) wheelBonuses;
  @hasMany('wheel-bonuses-list', { async: false, inverse: null }) wheelBonusesList;
  @hasMany('wheel-page-info', { async: false, inverse: null }) wheelPageInfo;
  @hasMany('wheel-bonuses-currency-limit', { async: false, inverse: null }) currencyLimit;
}
