import Model, {attr} from '@ember-data/model';
import {tracked} from '@glimmer/tracking';

export default class CasinoTemplateTypeLabelModel extends Model {
  @attr title
  @attr label
  @attr image
  @attr expiration_date
  @attr template_id
  @attr('boolean') is_active
  @attr games
  @attr('order', {defaultValue: 999}) order

  @tracked selectedAll = false

  modelName = 'casino-template-type-label'
}
