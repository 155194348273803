import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"main-info-container limits-sports-leagues\">\n  <div class=\"table-content\">\n  \t{{#if this.baseTask.isIdle}}\n\t    <RouteComponents::RiskManagement::Limits::SportsLeagues::SportsLeaguesTable::SbSportsLeaguesTable\n\t            @filters={{@params}}\n\t            @setPage={{this.setPage}}\n\t            @setPerPage={{this.setPerPage}}\n\t            @filteredKpi={{this.filteredKpi}} />\n\t  {{else}}\n      <GenericComponents::Loading::SbLoading/>\n  \t{{/if}}\n  </div>\n</div>\n{{yield}}", {"contents":"<div class=\"main-info-container limits-sports-leagues\">\n  <div class=\"table-content\">\n  \t{{#if this.baseTask.isIdle}}\n\t    <RouteComponents::RiskManagement::Limits::SportsLeagues::SportsLeaguesTable::SbSportsLeaguesTable\n\t            @filters={{@params}}\n\t            @setPage={{this.setPage}}\n\t            @setPerPage={{this.setPerPage}}\n\t            @filteredKpi={{this.filteredKpi}} />\n\t  {{else}}\n      <GenericComponents::Loading::SbLoading/>\n  \t{{/if}}\n  </div>\n</div>\n{{yield}}","moduleName":"backoffice/components/route-components/risk-management/limits/sports-leagues/sb-sports-leagues.hbs","parseOptions":{"srcName":"backoffice/components/route-components/risk-management/limits/sports-leagues/sb-sports-leagues.hbs"}});
import MainLimits from "../../../../abstract-components/main-limits";
import {task} from 'ember-concurrency';

export default class RouteComponentsRiskManagementLimitsSportsLeaguesSbSportsLeaguesComponent extends MainLimits {
  url = 'sport';

  filters = {
    page: this.args.params.page,
    perPage: this.args.params.perPage
  }

  constructor() {
    super(...arguments);
    this.baseTask.perform();
    this.getCurrencyCourse.perform();
  }

  @(task(function* () {
    yield this.ajax.patch(`/get-course/${this.partner.currencies?.[0].get('id')}`, {}).then((course) => {
      this.selectedCurrency = this.partner.currencies?.[0].get('id')
      this.store.unloadAll('currency-course')
      let data = {currencyCourse: course?.['currency-courses']?.[0]}
      data.currencyCourse && this.store.pushPayload('currency-course', data);
    })
  }))getCurrencyCourse

}
