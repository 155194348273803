import Helper from '@ember/component/helper';

export default class CalculatePercent extends Helper {
  compute([arg1, arg2]) {
    if(arg2<=0){
      return '%';
    }
    return parseFloat((arg1 * 100) / arg2).toFixed(2) + "%";
  }
}
