import Controller from '@ember/controller';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';
import {inject as service} from '@ember/service'

export default class LoginController extends Controller {
  @tracked errorMessage;
  @service session;

  @action
  async authenticate() {
    let {identification, password} = this;
    try {
      await this.session.authenticate('authenticator:oauth2', identification, password);
    } catch (error) {
      this.errorMessage = error.error || error;
    }

    if (this.session.isAuthenticated) {
      // What to do with all this success?
    }
  }
}
