import Service, {inject as service} from '@ember/service';
import {task} from "ember-concurrency";

export default class TransactionService extends Service {
  @service store;
  @service notifications;

  hash = {
    documentType: 'document-type'
  }

  get types() {
    return this.store.peekAll('document-type');
  }

  queryTypes() {
    return this.getTypesTask.perform();
  }

  @task
  * getTypesTask() {
    return yield this.store.query(
      this.hash.documentType,
      {risk_management: true}
    )
      .catch((payload) => {
        if (payload && payload.errors) {
          if (typeof payload.errors === 'object') {
            payload.errors.forEach((error) => {
              this.notifications.error(error, {
                autoClear: true
              })
            })
          } else {
            this.notifications.error(payload.errors, {
              autoClear: true
            })
          }
        }
      })
  }
}
