import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"field-wrapper\">\n  <button class=\"btn btn-danger\" type=\"button\" {{on 'click' this.deleteTemplate}}>{{t 'Delete'}}</button>\n  <button class=\"btn\" type=\"button\" {{on 'click' this.cancel}}>{{t 'Cancel'}}</button>\n</div>\n{{yield}}", {"contents":"<div class=\"field-wrapper\">\n  <button class=\"btn btn-danger\" type=\"button\" {{on 'click' this.deleteTemplate}}>{{t 'Delete'}}</button>\n  <button class=\"btn\" type=\"button\" {{on 'click' this.cancel}}>{{t 'Cancel'}}</button>\n</div>\n{{yield}}","moduleName":"backoffice/components/modals/marketing/site-messages/delete.hbs","parseOptions":{"srcName":"backoffice/components/modals/marketing/site-messages/delete.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {action} from '@ember/object';
import {task} from 'ember-concurrency';

export default class ModalsMarketingSiteMessagesDeleteComponent extends Component {
  @service modalWindow;
  @service ajax;
  @service partner;

  constructor() {
    super(...arguments);
  }

  @(task(function* () {
    yield this.modalWindow.data.template.destroyRecord().then(() => {
      this.modalWindow.closeModal();
    })
  })) deleteAction;

  @action
  deleteTemplate() {
    this.deleteAction.perform()
  }

  @action
  cancel() {
    this.modalWindow.closeModal()
  }
}
