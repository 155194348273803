import Model, {attr} from '@ember-data/model';

export default class MailchimpTemplateModel extends Model {
  @attr('number') partner_id;
  @attr('string') code;
  @attr('string') created_at;
  @attr('string') from_email;
  @attr('string') from_name;
  @attr('string') name;
  @attr('string') publish_code;
  @attr('string') updated_at;
  @attr('string') slug;
  @attr('boolean') edit;
  @attr('string') subject;
  @attr('boolean') is_active;
  @attr labels;
}
