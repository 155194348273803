import AuthenticatedRoute from '../../authenticated';

export default class RetailManagersIndexRoute extends AuthenticatedRoute {
  definer = 'retail_managers'

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }

  queryParams = {
    page: {
      refreshModel: true
    },
    parent_id: {refreshModel: true},
    perPage: {refreshModel: true},
    partner_id: {refreshModel: true},
    id: {refreshModel: true},
    name: {refreshModel: true},
    currency_id: {refreshModel: true},
    sort_name: {refreshModel: true},
    sort_type: {refreshModel: true},
    is_active: {refreshModel: true}
  };

  model(params) {
    return params
  }
}
