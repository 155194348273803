import MainTransactionHistory from '../../main-transaction-history';

export default class RetailManagersManagerTransactionHistoryRoute extends MainTransactionHistory {
  definer = 'retail_managers_key_transaction_history'

  model(params) {
    params['user_id'] = this.paramsFor('retail.managers.manager')['manager_id']
    return params
  }
}
