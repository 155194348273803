import Controller from '@ember/controller';
import moment from 'moment';

export default class RiskManagementTransactionsOnlineController extends Controller {
  queryParams = [
    'player_id',
    'currency_id',
    'type_id',
    'transaction_id',
    'operation_id',
    'deposit_id',
    'gateway_reference',
    'group_ids',
    'partner_id',
    'start',
    'end',
    'sort_type',
    'account_type',
    'amount_range_start',
    'payment_deposit_method_id',
    'payment_withdraw_method_id',
    'amount_range_end',
    'sort_name',
    'integration_id',
    'page',
    'perPage'
  ];

  page = 1;
  perPage = 10;
  partner_id;
  group_ids;
  player_id;
  currency_id;
  integration_id;
  payment_deposit_method_id;
  payment_withdraw_method_id = undefined;
  type_id = 3;
  account_type = 'Main';
  transaction_id;
  operation_id;
  deposit_id;
  gateway_reference;
  game_type;
  amount_range_start;
  amount_range_end;
  sort_name = 'created_at';
  sort_type = 3;
  start = moment().startOf('day').unix();
  end = moment().endOf('day').unix();
}
