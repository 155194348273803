import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<BasicDropdown as |dd|>\n  <dd.Trigger> \n    <div class=\"dropdown-settings-btn {{@className}}\" {{on 'click' (fn this.hideChange dd)}}>\n      <i class=\"sb-icon {{@iconClass}}\"></i>\n    </div>\n  </dd.Trigger>\n  <dd.Content>\n    <div {{on 'click' (fn this.openClose dd)}}>\n      {{yield}}\n    </div>\n  </dd.Content>\n</BasicDropdown>", {"contents":"<BasicDropdown as |dd|>\n  <dd.Trigger> \n    <div class=\"dropdown-settings-btn {{@className}}\" {{on 'click' (fn this.hideChange dd)}}>\n      <i class=\"sb-icon {{@iconClass}}\"></i>\n    </div>\n  </dd.Trigger>\n  <dd.Content>\n    <div {{on 'click' (fn this.openClose dd)}}>\n      {{yield}}\n    </div>\n  </dd.Content>\n</BasicDropdown>","moduleName":"backoffice/components/generic-components/dropdown-item/sb-dropdown-item.hbs","parseOptions":{"srcName":"backoffice/components/generic-components/dropdown-item/sb-dropdown-item.hbs"}});
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {next} from '@ember/runloop';

export default class GenericComponentsDropdownItemSbDropdownItemComponent extends Component {

  @action
  openClose(dropdown) {
    next(dropdown, function () {
      dropdown.actions.close();
    });
  }

  @action
  hideChange(dropdown) {
    event.stopPropagation();
    dropdown.actions.open();
  }

}
