import Model, {attr, hasMany} from '@ember-data/model';

export default class CountryModel extends Model {
  @attr name;
  @attr alpha2;
  @attr alpha3;

  get code() {
    return this.alpha2
  }

  @hasMany('profiles', { async: false, inverse: null }) profiles;
}
