import ApplicationAdapter from './application';

export default class PaymentPeculiarAttributeAdapter extends ApplicationAdapter {
  urlForQuery(param) {
    if (param.for_affiliate) {
      return this.host + '/affiliate/wallets/peculiar-attributes'
    }
    return this.host + '/payment/integrations'
  }
}
