import JSONAPIAdapter from '@ember-data/adapter/json-api';
import {inject as service} from '@ember/service';
import {storageFor} from 'ember-local-storage';

export default class SportAdapter extends JSONAPIAdapter {
  @service globalVars;
  host = this.globalVars.url.odds_feed_api;

  @storageFor('language') lngParams;

  urlForFindAll() {
    return `${this.host}/sports-tree/${this.lngParams.get('currentLanguage')}`;
  }
}
