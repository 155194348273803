import AuthenticatedRoute from '../authenticated';

export default class RiskManagementWithdrawRequestsRoute extends AuthenticatedRoute {
  definer = 'risk_management_withdraw_requests'

  queryParams = {
    type: {
      refreshModel: true
    },
    status: {
      refreshModel: true
    },
    player_id: {
      refreshModel: true
    },
    partner_id: {
      refreshModel: true
    },
    group_ids: {
      refreshModel: true
    },
    transaction_id: {
      refreshModel: true
    },
    currency_id: {
      refreshModel: true
    },
    start: {
      refreshModel: true
    },
    end: {
      refreshModel: true
    },
    amount_range_start: {
      refreshModel: true
    },
    amount_range_end: {
      refreshModel: true
    }
  };

  model(params) {
    if(!params.status) {
      params.status = this.currentUser.user.isCashier ? 20 : 0;
    }
    return params;
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }
}
