import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div {{did-insert this.onDidInsert}}>\n  <RouteComponents::Affiliates::LinksBanners::LinkBannerForm::SbLinkBannerForm\n    @setImageUrl={{this.setImageUrl}}\n    @affiliateLink={{this.affiliateLink}}\n    @linkBannerSave={{this.linkBannerSave}}\n    @selectBonus={{this.selectBonus}}\n    @bonuses={{this.bonuses}}\n    @setStatus={{this.setStatus}}\n    @addBanner={{this.addBanner}}\n    @deleteBanner={{this.deleteBanner}}\n  />\n</div>", {"contents":"<div {{did-insert this.onDidInsert}}>\n  <RouteComponents::Affiliates::LinksBanners::LinkBannerForm::SbLinkBannerForm\n    @setImageUrl={{this.setImageUrl}}\n    @affiliateLink={{this.affiliateLink}}\n    @linkBannerSave={{this.linkBannerSave}}\n    @selectBonus={{this.selectBonus}}\n    @bonuses={{this.bonuses}}\n    @setStatus={{this.setStatus}}\n    @addBanner={{this.addBanner}}\n    @deleteBanner={{this.deleteBanner}}\n  />\n</div>","moduleName":"backoffice/components/route-components/affiliates/links-banners/create/sb-create-link.hbs","parseOptions":{"srcName":"backoffice/components/route-components/affiliates/links-banners/create/sb-create-link.hbs"}});
import AffiliateChanges from '../../../../abstract-components/affiliate-changes';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class RouteComponentsAffiliatesLinksBannersCreateSbCreateLinkComponent extends AffiliateChanges {
  @tracked affiliateLink;
  @tracked userId = this.args.params.user_id;

  @action
  onDidInsert() {
    this.affiliateLink = this.store.createRecord('affiliate-link', { user_id: parseInt(this.userId) });
    if (!this.currentUser.user.isAffiliate) {
      this.partnerId = this.partner.selected_id;
      this.getAffiliateDomainManagerTask.perform().then(() => {
        this.getAffiliateUsersTask.perform().then(() => {
          this.getBonusesTask.perform(this.partner.selected_id, true);
        });
      });
    } else {
      this.userId = this.currentUser.user.id;
      this.partnerId = this.currentUser.user.partner_id;
      this.getAffiliateDomainManagerTask.perform().then(() => {
        this.getBonusesTask.perform(this.currentUser.user.partner_id, true);
      });
    }
    this.addBanner();
  }
}
