import ApplicationAdapter from './application';

export default class SegmentAdapter extends ApplicationAdapter {
  urlForQuery(params) {
    if(params.is_bonus){
      return this.host+'/bonusTemplates/bonuses/segments';
    }
    return this.host + '/settings/segment'
  }
  urlForQueryRecord() {
    return this.host + '/get-segment'
  }

  urlForCreateRecord() {
    return this.host + '/settings/segment'
  }

  urlForUpdateRecord(id) {
    return this.host + `/settings/segment/${id}`
  }
}
