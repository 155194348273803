import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\">\n  <form onsubmit={{action \"save\"}} class=\"form-inline pull-right\">\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Percent'}} %</span>\n      <Input type=\"number\" @value={{this.affiliate.percent}}/>\n    </div>\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Full Name'}}</span>\n      <Input type=\"text\" @value={{this.affiliate.full_name}}/>\n    </div>\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Username'}}</span>\n      <Input type=\"text\" @value={{this.affiliate.username}}/>\n    </div>\n    <button class=\"btn btn-basic btn-full\" disabled={{false}} type=\"submit\">{{t 'Save'}}</button>\n  </form>\n</div>\n{{yield}}", {"contents":"<div class=\"add-new-managers-fields\">\n  <form onsubmit={{action \"save\"}} class=\"form-inline pull-right\">\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Percent'}} %</span>\n      <Input type=\"number\" @value={{this.affiliate.percent}}/>\n    </div>\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Full Name'}}</span>\n      <Input type=\"text\" @value={{this.affiliate.full_name}}/>\n    </div>\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Username'}}</span>\n      <Input type=\"text\" @value={{this.affiliate.username}}/>\n    </div>\n    <button class=\"btn btn-basic btn-full\" disabled={{false}} type=\"submit\">{{t 'Save'}}</button>\n  </form>\n</div>\n{{yield}}","moduleName":"backoffice/components/modals/affiliates/edit/edit-affiliate-user.hbs","parseOptions":{"srcName":"backoffice/components/modals/affiliates/edit/edit-affiliate-user.hbs"}});
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

export default class ModalsAffiliatesEditEditAffiliateUserComponent extends Component {
  @service notifications
  @service modalWindow;
  @service intl;
  @service store;

  @tracked affiliate = this.modalWindow.data.model;


  @action
  save(e) {
    e.preventDefault()
    this.affiliate.save().then(() => {
      this.notifications.success(this.intl.t('settings successfully changed'), {
        autoClear: true
      });
      this.modalWindow.closeModal()
    }).catch(payload => {
      if (payload && payload.errors) {
        payload.errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true
          });
        })
      }
    });
  }

}
