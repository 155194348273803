import Controller from '@ember/controller';
import moment from 'moment';

export default class StatsReportsAutomaticSportKpiController extends Controller {
  queryParams = [
    'type_id',
    'currency_ids',
    'perPage',
    'start',
    'page',
    'end',
    'market_id',
    'sport_id',
    'game_type',
  ];

  currency_ids;
  perPage = 10;
  page = 1;
  type_id = undefined;
  market_id = undefined;
  sport_id = undefined;
  game_type = undefined;
  start = moment().startOf('month').unix();
  end = moment().endOf('day').unix();
}
