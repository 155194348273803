import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\" {{did-insert this.onDidInsert}}>\n  <form onsubmit={{action this.save}} class=\"form-inline pull-right\">\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Host'}}</span>\n      <Input type=\"text\" @value={{this.mailConfig.host}}/>\n    </div>\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Username'}}</span>\n      <Input type=\"text\" @value={{this.mailConfig.username}}/>\n    </div>\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Password'}}</span>\n      <Input type=\"text\" @value={{this.mailConfig.password}}/>\n    </div>\n\n    <button class=\"btn btn-basic btn-full\">{{t 'Save'}}</button>\n  </form>\n</div>\n", {"contents":"<div class=\"add-new-managers-fields\" {{did-insert this.onDidInsert}}>\n  <form onsubmit={{action this.save}} class=\"form-inline pull-right\">\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Host'}}</span>\n      <Input type=\"text\" @value={{this.mailConfig.host}}/>\n    </div>\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Username'}}</span>\n      <Input type=\"text\" @value={{this.mailConfig.username}}/>\n    </div>\n    <div class=\"field-box\">\n      <span class=\"label\">{{t 'Password'}}</span>\n      <Input type=\"text\" @value={{this.mailConfig.password}}/>\n    </div>\n\n    <button class=\"btn btn-basic btn-full\">{{t 'Save'}}</button>\n  </form>\n</div>\n","moduleName":"backoffice/components/modals/settings/partner/mail/sb-mail.hbs","parseOptions":{"srcName":"backoffice/components/modals/settings/partner/mail/sb-mail.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';

export default class ModalsSettingsPartnerMailSbMailComponent extends Component {
  @service store;
  @service modalWindow;
  @service notifications;
  @service intl;

  @tracked mailConfig;

  @action
  onDidInsert() {
    let partnerId = this.modalWindow.data.partner_id;

    this.mailConfig = this.store.peekAll('mail-config').find(i => i.partner_id === +partnerId);
    if (!this.mailConfig) {
      this.mailConfig = this.store.createRecord('mail-config');
      this.mailConfig.partner_id = partnerId;
    }
  }

  @action
  save(e) {
    e.preventDefault();
    this.saveTask.perform();
  }

  @task(function* () {
    yield this.mailConfig
      .save()
      .then(() => {
        this.notifications.success(this.intl.t('Successfully saved'), {
          autoClear: true,
        });
        this.modalWindow.closeModal();
      })
      .catch((payload) => {
        if (payload && payload.errors) {
          payload.errors.forEach((error) => {
            this.notifications.error(error, {
              autoClear: true,
            });
          });
        }
      });
  })
  saveTask;
}
