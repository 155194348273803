import Component from '@glimmer/component';
import {action, set} from '@ember/object';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {later} from '@ember/runloop';

export default class ActionSelected extends Component {
  @service globalVars;
  @service modalWindow;
  @service notifications;

  @tracked activeMenu = false;

  @action
  rendComponent() {
    later(this, function () {
      this.activeMenu = true;
    });
  }

  @action
  setEmptyInput(val) {
    set(this, `${val}`, 0);
  }

  @action
  goBack(e) {
    if (typeof this.args.goBack === 'function') {
      this.args.goBack(e);
    }
  }
}
