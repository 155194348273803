import Model, {attr} from '@ember-data/model';
import {tracked} from '@glimmer/tracking';

export default class PaymentIntegrationModel extends Model {
  @attr title;

  @tracked isCash = this.title === 'cash';
  @tracked isPeculiar = this.title === 'peculiar';

  get isMgurush(){
    return this.title === 'mgurush';
  }
}
