import ApplicationAdapter from './application';

export default class BetTypeAdapter extends ApplicationAdapter {
  urlForQuery(params) {
    if(params.is_bonus) {
      return this.host + '/bonusTemplates/bonuses/bet-types';
    }
    if(params.affiliate) {
      return this.host + '/affiliate/client/bet-type';
    }
    return this.host + '/get-bet-types'
  }
}
