import AuthenticatedRoute from '../../authenticated';

export default class RiskManagementLimitsSportsLeaguesRoute extends AuthenticatedRoute {
  definer = 'risk_management_limits'

  queryParams = {
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    gamePage: {
      refreshModel: true
    },
    gamePerPage: {
      refreshModel: true
    },
    leaguePage: {
      refreshModel: true
    },
    leaguePerPage: {
      refreshModel: true
    }
  };

  model(params) {
    return params;
  }
}
