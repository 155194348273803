import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\" {{did-insert this.onDidInsert}}>\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Title'}}</span>\n    <Input type=\"text\" @value={{this.category.title}} />\n  </div>\n\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Url'}}</span>\n    <Input type=\"text\" @value={{this.category.url}} />\n  </div>\n  {{#if this.saveTask.isIdle}}\n  \t<button class=\"btn btn-full\" type=\"button\" {{action this.save}}>{{t 'Save'}}</button>\n  {{else}}\n  \t<button class=\"btn btn-full loading\" type=\"button\"></button>\n  {{/if}}\n</div>\n", {"contents":"<div class=\"add-new-managers-fields\" {{did-insert this.onDidInsert}}>\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Title'}}</span>\n    <Input type=\"text\" @value={{this.category.title}} />\n  </div>\n\n  <div class=\"field-box\">\n    <span class=\"label\">{{t 'Url'}}</span>\n    <Input type=\"text\" @value={{this.category.url}} />\n  </div>\n  {{#if this.saveTask.isIdle}}\n  \t<button class=\"btn btn-full\" type=\"button\" {{action this.save}}>{{t 'Save'}}</button>\n  {{else}}\n  \t<button class=\"btn btn-full loading\" type=\"button\"></button>\n  {{/if}}\n</div>\n","moduleName":"backoffice/components/modals/casino/settings/add-casino-category/sb-add-casino-category.hbs","parseOptions":{"srcName":"backoffice/components/modals/casino/settings/add-casino-category/sb-add-casino-category.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';

export default class ModalsCasinoSettingsAddCasinoCategorySbAddCasinoCategoryComponent extends Component {
  @service store;
  @service router;
  @service modalWindow;

  @tracked category = null;

  @action
  onDidInsert() {
    this.category = this.store.createRecord('casino-template-category', {
      template_id: this.router.currentRoute.parent.params.template_id,
    });
  }

  @action
  save() {
    this.saveTask.perform();
  }

  @task(function* () {
    yield this.category.save();
    this.modalWindow.closeModal();
  })
  saveTask;
}
