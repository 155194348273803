import Service from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';

export default class PaginationService extends Service {
  @tracked currentPage = 1;
  @tracked perPage = 10;
  @tracked totalCount = 0;

  @action
  setPage(currentPage) {
    this.currentPage = currentPage;
  }

  @action
  setTotalCount(totalCount) {
    this.totalCount = totalCount;
  }

  @action
  setPerPage(perPage) {
    this.perPage = perPage;
  }
}
