import RESTSerializer from '@ember-data/serializer/rest';

export default RESTSerializer.extend({

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    let json = JSON.stringify(payload);
    json = json.replace(/"AL":/g, '"alias":')
      .replace(/"TOP":/g, '"is_top":')
      .replace(/"LI":/g, '"league_id":')
      .replace(/"GC1":/g, '"live_games_count":')
      .replace(/"GC2":/g, '"line_games_count":')
      .replace(/"N":/g, '"name":')
      .replace(/"_R":/g, '"region":')
      .replace(/"RI":/g, '"region_id":')
      .replace(/"_S":/g, '"sport":')
      .replace(/"SI":/g, '"sport_id":')
      .replace(/"RC":/g, '"region_code":')
      .replace(/"NE":/g, '"name_en":');

    payload = JSON.parse(json);

    let result = {
      searchRegions: payload.regions,
      searchLeagues: payload.leagues,
      searchSports: payload.sports,
    }

    return this._super(store, primaryModelClass, result, id, requestType);
  }
})
