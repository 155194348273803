import Service from '@ember/service';
import {set} from '@ember/object'

export default class GroupByParentService extends Service {
  run(items) {
    let result = [];
    items.forEach(item => {
      let model = item.model
      let current = result.find(r => {
        return r.model === model;
      });
      if (!current) {
        current = {
          model,
          items: []
        }
        result.push(current);
      }
      if (item.operation !== null && item.operation.indexOf('|') > 0) {
        let regExp = "[0-9]";
        let statusData = {1:'Verified',0:'Unverified'}
        let operation = item.operation.split('|')[1];
        let title = item.operation.split('|')[0];
        if (new RegExp("[10-90]{2}", "g").test(operation)) regExp = "[10-90]{2}"
        if(title === 'Status Id ') {
          statusData = {
            1: 'Newbie',
            2: 'Popan',
            3: 'Followers',
            4: 'Penny-Better',
            5: 'Bonus-Hunters',
            6: 'Value-Betters',
            7: 'Smallers',
            8: 'Investor',
            9: 'Trader',
            10: 'Privateer',
            11: 'Pro',
            12: 'Forklifts',
            13: 'Mathematicians',
            14: 'Ludoman',
            15: 'Risk',
            16: 'Sport',
          }
          if (new RegExp("[1-6]{1,2}", "g").test(Object.keys(statusData)[0])) regExp = "[1-6]{1,2}"
          operation = operation.replace(new RegExp(regExp, "g"), function (val) {
            return statusData[val];
          })
        }
        set(item, 'title', title);
        set(item, 'property', operation);
      } else {
        set(item, 'title', item.operation);
      }
      current.items.push(item);
    })
    return result;
  }
}
