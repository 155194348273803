import Model, {attr, belongsTo} from '@ember-data/model';

export default class EditLogModel extends Model {
  @attr description
  @attr operation
  @attr action
  @attr model
  @attr id_model
  @attr field
  @attr player_id
  @attr created_at
  @attr created_by

  @belongsTo('user', { async: false, inverse: null }) user;
}
