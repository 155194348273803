import Model, {attr} from '@ember-data/model';

export default class PartnerSeoModel extends Model {
  @attr('number') partner_id;
  @attr('string') language_id;
  @attr('string') path;
  @attr('string') title;
  @attr('string') description;
  @attr('string') keywords;
  @attr('string') og_locale;
  @attr('string') og_type;
  @attr('string') og_title;
  @attr('string') og_url;
  @attr('string') og_description;
  @attr('string') og_image;
  @attr('string') og_site_name;
  @attr('string') og_image_width;
  @attr('string') og_image_height;
  @attr('string') og_image_alt;
  @attr('string') og_image_type;
  @attr('string') twitter_card;
  @attr('string') twitter_title;
  @attr('string') twitter_description;
  @attr('string') twitter_site;
  @attr('string') twitter_image;
}
