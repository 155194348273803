import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @bonus}}\n  <RouteComponents::Bonuses::BonusTemplates::BonusWizard::SbBonusWizard @isCreate={{false}} @bonus={{@bonus}}/>\n{{/if}}\n{{yield}}", {"contents":"{{#if @bonus}}\n  <RouteComponents::Bonuses::BonusTemplates::BonusWizard::SbBonusWizard @isCreate={{false}} @bonus={{@bonus}}/>\n{{/if}}\n{{yield}}","moduleName":"backoffice/components/route-components/bonuses/bonus-templates/edit/sb-edit.hbs","parseOptions":{"srcName":"backoffice/components/route-components/bonuses/bonus-templates/edit/sb-edit.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';

export default class RouteComponentsBonusesBonusTemplatesEditSbEditComponent extends Component {
  @service store;
  @service router;

  get bonus() {
    return this.store.peekRecord('bonus', this.router.currentRoute.attributes.bonus_id)
  }

}
