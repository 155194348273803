import ApplicationAdapter from './application';

export default class BannerAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/cms/banners'
  }

  urlForCreateRecord() {
    return this.host + '/cms/banners'
  }

  urlForDeleteRecord(id) {
    return this.host + `/cms/banners/${id}`;
  }

  urlForUpdateRecord(id) {
    return this.host + `/cms/banners/${id}`;
  }
}
