import Model,{attr} from '@ember-data/model';

export default class WithdrawModel extends Model {
  @attr user_id
  @attr status
  @attr description
  @attr withdraw_request_id
  @attr integration_id
  @attr currency_id
  @attr amount
}
