import Service, { inject as service } from '@ember/service';
import {TOURNAMENT_TYPES, TOURNAMENT_STATUSES} from '../models/tournament';
import PrizeObject from "../models/prize";
import { getOwner } from '@ember/application';

export default class TournamentService extends Service {
  @service intl;

  types = TOURNAMENT_TYPES
  statuses = TOURNAMENT_STATUSES

  titlesTypes = {
    [TOURNAMENT_TYPES.WAGER]: this.intl.t('Wager'),
    [TOURNAMENT_TYPES.SPINS]: this.intl.t('Spins'),
    [TOURNAMENT_TYPES.MAX_WIN]: this.intl.t('Max win')
  }

  titlesStatuses = {
    [TOURNAMENT_STATUSES.DRAFT]: this.intl.t('Draft'),
    [TOURNAMENT_STATUSES.LIVE]: this.intl.t('Live'),
    [TOURNAMENT_STATUSES.COMPLETED]: this.intl.t('Completed'),
    [TOURNAMENT_STATUSES.CREDITED]: this.intl.t('Credited')
  }

  optionsTypes = Object.values(this.types).map(type => ({
    value: type,
    title: this.titlesTypes[type],
  }));

  optionsStatuses = Object.values(this.statuses).map(type => ({
    value: type,
    title: this.titlesStatuses[type],
  }));

  createPrizes(length) {
    return Array.from({length}, (_value, index) =>
      (
        PrizeObject.create(
          getOwner(this).ownerInjection(),
          {place: index + 1, reward: ''}
        )
      )
    )
  }

  canEdit(status) {
    return status === this.statuses.DRAFT
  }
}
