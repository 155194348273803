import AuthenticatedRoute from '../../authenticated';

export default class RiskManagementLimitsMarketsRoute extends AuthenticatedRoute {
  definer = 'risk_management_limits'

  queryParams = {
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    search: {
      refreshModel: true
    }
  };

  model(params) {
    return params
  }
}
