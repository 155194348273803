import ApplicationAdapter from './application';

export default class RoleAdapter extends ApplicationAdapter {
  urlForQuery(param) {
    if(param.sub_affiliate){
      return this.host + '/affiliate/sub-affiliate/role'
    }
    else if(param.affiliate){
      return this.host + '/affiliate/role'
    }
    else if(param.retail_administrators){
      return this.host + '/retail/administrators/get-roles';
    }
    else if(param.retail_managers){
      return this.host + '/retail/managers/get-roles';
    }
    else if(param.retail_cashiers){
      return this.host + '/retail/cashiers/get-roles';
    }
    return this.host + '/get-roles'
  }

  urlForCreateRecord() {
    return this.host + '/create-role';
  }

  urlForDeleteRecord(id) {
    return this.host + `/delete-role/${id}`;
  }

  handleResponse(status, headers, payload, requestData) {

    if (status === 422) {
      return super.handleResponse(status, headers, JSON.parse(payload), requestData);
    }
    return super.handleResponse(status, headers, payload, requestData);
  }
}
