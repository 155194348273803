import Model, {attr, belongsTo} from '@ember-data/model';

export default class CasinoGameListModel extends Model {
  @attr game_code;
  @attr name;
  @attr free;
  @attr charged;
  @attr desktop;
  @attr mobile;
  @attr provider_id;
  @attr host;
  @attr integration_id;
  @attr('image') image_url;
  @attr product_id;
  @attr created_at;
  @attr updated_at;
  @attr('boolean') is_vpn;

  @belongsTo('casino-integration-setting', {async: false, inverse: null}) integrationSetting;
}
