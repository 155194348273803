import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{yield}}", {"contents":"<GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{yield}}","moduleName":"backoffice/components/route-components/risk-management/limits/sb-limits.hbs","parseOptions":{"srcName":"backoffice/components/route-components/risk-management/limits/sb-limits.hbs"}});
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';

export default class RouteComponentsRiskManagementLimitsSbLimitsComponent extends Component {
  @service partner;

  @tracked menuItems = [
    {
      name: 'Global',
      definer: 'risk_management_limits',
      linkTo: {
        path: 'risk-management.limits.global',
      }
    },
    {
      name: 'Sports & Leagues',
      definer: 'risk_management_limits',
      linkTo: {
        path: 'risk-management.limits.sports-leagues',
      }
    },
    {
      name: 'Markets',
      definer: 'risk_management_limits',
      linkTo: {
        path: 'risk-management.limits.markets',
      }
    }
  ];
}
