import ApplicationAdapter from './application';

export default class GroupAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/partners/groups';
  }

  urlForCreateRecord() {
    return this.host + '/partners/groups';
  }

  urlForUpdateRecord(id) {
    return this.host + `/partners/groups/${id}`;
  }

  urlForDeleteRecord(id) {
    return this.host + `/partners/groups/${id}`;
  }
}
