import OAuth2PasswordGrant from 'ember-simple-auth/authenticators/oauth2-password-grant';
import {inject as service} from '@ember/service';

export default class OAuth2 extends OAuth2PasswordGrant {
  @service globalVars;

  get serverTokenEndpoint() {
    return `${this.globalVars.url.admin_api}/admin-auth/login`;
  }
}
