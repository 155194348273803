import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{yield}}", {"contents":"<GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{yield}}","moduleName":"backoffice/components/route-components/payment/payment-methods/payment-method-detail/sb-payment-method-detail.hbs","parseOptions":{"srcName":"backoffice/components/route-components/payment/payment-methods/payment-method-detail/sb-payment-method-detail.hbs"}});
import Component from '@glimmer/component';

export default class RouteComponentsPaymentPaymentMethodsPaymentMethodDetailSbPaymentMethodDetailComponent extends Component {
  constructor() {
    super(...arguments);
  }

  get menuItems() {
    return [
      {
        name: 'Configuration',
        definer: 'payment_methods',
        linkTo: {
          path: 'payment.payment-methods.payment-method-detail.configuration'
        }
      }
    ];
  }
}
