import Model, {attr, belongsTo} from '@ember-data/model';

export default class PlayerIpHistoryModel extends Model {
  @attr('number') player_id
  @attr ip
  @attr status
  @attr('number') created_at

  @belongsTo('player', { async: false, inverse: null }) player
}
