import ApplicationAdapter from './application';

export default class CasinoBetAdapter extends ApplicationAdapter {
  urlForQuery(params) {
    if(params.is_free_spin) {
      return this.host + '/casino/free-spin-bets';
    }
    if(params.risk_management) {
      return this.host + '/risk-management/casino-bet-history';
    }
    return this.host + '/risk-management/player/casino-bet'
  }
}
