import ApplicationAdapter from './application';

export default class DocumentTypeAdapter extends ApplicationAdapter {
  urlForQuery(param) {
    if(param.affiliate){
      return this.host + '/affiliate/client/document-type'
    }
    if(param.risk_management){
      return this.host + '/risk-management/player/document-type'
    }
    return this.host + '/transactions/document-type'
  }
}
