import AuthenticatedRoute from '../authenticated';

export default class SportsbookDashboardRoute extends AuthenticatedRoute {
  definer = 'sportsbook_dashboard'

  queryParams = {
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    dateMode: {
      refreshModel: true
    },
    graph_start: {
      refreshModel: true
    },
    graph_end: {
      refreshModel: true
    },
    start: {
      refreshModel: true
    },
    end: {
      refreshModel: true
    },
    product: {
      refreshModel: true
    },
    sort_name: {
      refreshModel: true
    },
    sort_type: {
      refreshModel: true
    },
    type: {
      refreshModel: true
    },
    income_from: {
      refreshModel: true
    },
    income_to: {
      refreshModel: true
    },
    sport_id: {
      refreshModel: true
    },
    league_id: {
      refreshModel: true
    },
    local_game_id: {
      refreshModel: true
    },
    market_id: {
      refreshModel: true
    },
    income_type: {
      refreshModel: true
    },
    isBonus: {refreshModel: true}
  };

  model(params) {
    return params
  }

  activate() {
    this.partner.showPartnerSelect = true;
    this.partner.optionSystem = true;
    this.partner.showIsBonusSwitcher = true;
  }

  deactivate() {
    this.partner.showPartnerSelect = false;
    this.partner.optionSystem = false;
    this.partner.showIsBonusSwitcher = false;
  }
}
