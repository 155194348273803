import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class PlayerSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    profile: {embedded: 'always'},
    segments: {embedded: 'always'},
    firstDeposit: {embedded: 'always'},
    affiliateSetting: {embedded: 'always'},
    activeBonusPlayer: {embedded: 'always'},
    bonusPlayers: {embedded: 'always'},
    casinoAccountMain: {embedded: 'always'},
    // casinoAccounts: {embedded: 'always'},
    partner: {embedded: 'always'},
    accounts: {embedded: 'always'},
    mainAccount: {embedded: 'always'},
    sportsKpis: {embedded: 'always'},
    playerDocuments: {embedded: 'always'},
    playersMessages: {embedded: 'always'},
    playerIpHistories: {embedded: 'always'},
  }

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (requestType === 'queryRecord') {
      payload.player = payload.players?.[0] || null;
      delete payload.players;
    }

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
