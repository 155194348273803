import Model, {attr, belongsTo} from '@ember-data/model';

export default class PlayerMessageModel extends Model {
  @attr player_id
  @attr subject
  @attr message
  @attr is_read
  @attr created_at
  @attr updated_at

  @belongsTo('player', { async: false, inverse: null }) player
}
