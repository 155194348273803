import ApplicationAdapter from './application';

export default class PaymentWithdrawMethodAdapter extends ApplicationAdapter {
  urlForQueryRecord(params) {
    if (params.is_peculiar) {
      return this.host + '/payment-peculiar-method'
    }
    return this.host + '/payment/settings/withdraw-methods'
  }

  urlForFindRecord(id,params) {
    if (params.is_peculiar) {
      return this.host + '/payment/settings/withdraw-method/' + id
    }
  }

  urlForQuery(param) {
    if(param.transaction){
      return this.host + '/transactions/withdraw-method'
    }
    if(param.affiliateWalletsWithdraw){
      return this.host + '/affiliate/wallets/payment-methods'
    }
    if(param.is_peculiar){
      return this.host + '/payment-peculiar-methods'
    }
    return this.host + '/payment/settings/withdraw-methods'
  }

  urlForCreateRecord(model,params) {
    if (params.__attributes.is_peculiar) {
      return this.host + '/create-peculiar';
    }
    return this.host + '/payment/settings/withdraw-methods'
  }

  urlForUpdateRecord(id,model,params) {
    if(params.__attributes.is_peculiar){
      return this.host + `/update-peculiar/${id}`;
    }
    return this.host + `/payment/settings/withdraw-methods/${id}`;
  }

  urlForDeleteRecord(id) {
    return this.host + `/payment/settings/withdraw-methods/${id}`;
  }
}
