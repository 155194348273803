import RESTSerializer, {EmbeddedRecordsMixin} from '@ember-data/serializer/rest';

export default class RoleSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    roleType: {embedded: 'always'},
    rolePermissions: {embedded: 'always'}
  }

  normalizeResponse(store, primaryModelClass, payload) {
    if (payload.roles !== undefined && payload.roles.length > 0) {
      payload.roles.forEach(role => {
        delete role.users
      })
    }
    return this.normalizeQueryResponse(...arguments);
  }
}
