import ApplicationAdapter from './application';

export default class PaymentTemplateDepositMethodAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/paymentTemplate/deposit-methods'
  }

  urlForUpdateRecord(id) {
    return this.host + `/paymentTemplate/deposit-methods/${id}`;
  }

  urlForDeleteRecord(id) {
    return this.host + `/paymentTemplate/deposit-methods/${id}`;
  }
}
