import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';
import {task} from 'ember-concurrency'

export default class CryptoSetting extends Component {
  @service modalWindow;
  @service store;
  @service notifications;
  @service partner;
  @service ajax;
  @service router;

  @tracked cryptoCurrencySetting

  @tracked integration_id = +this.router.currentRoute.attributes;

  constructor() {
    super(...arguments);
    this.getSettings.perform().then((cryptoCurrencySetting) => {
      if (cryptoCurrencySetting) {
        this.cryptoCurrencySetting = cryptoCurrencySetting
      } else {
        this.create()
      }
    })
  }

  create() {
    this.cryptoCurrencySetting = this.store.createRecord('payment-integration-setting')
    this.cryptoCurrencySetting.additional_information = {};
    this.cryptoCurrencySetting.partner_id = this.partner.selected_id
    this.cryptoCurrencySetting.integration_id = this.integration_id
  }

  @action
  save() {
    this.cryptoCurrencySetting.save().then(() => {
        this.notifications.success('Successfully Changed', {
          autoClear: true
        })
      }
    ).catch((payload) => {
      if (payload.errors) {
        payload.errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true,
            clearDuration: 3200
          });
        })
      }
    });
  }

  @action
  updateDepositWndWithdrawMethods() {
    this.ajax.post(`/payment/integration-setting/external-update`, {
      data: {
        integration_setting_id: this.cryptoCurrencySetting.id
      }
    }).then(() => {
      this.notifications.success(this.intl.t('Successfully Changed'), {
        autoClear: true
      });
    }).catch(({payload}) => {
      let errors = payload.errors;
      if (errors) {
        errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true
          });
        })
      }
    });
  }

  @action
  delete(cryptoPaymentSetting) {
    cryptoPaymentSetting.destroyRecord();
  }


  @action
  setStatus(cryptoSettings) {
    cryptoSettings.status = !cryptoSettings.status;
  }

  @(task(function* () {
    return yield this.store.queryRecord('payment-integration-setting', {
      partner_id: this.partner.selected_id,
      integration_id: this.integration_id
    })
  }))getSettings
}
