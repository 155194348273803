import Model,{attr, belongsTo} from '@ember-data/model';

export default class TransactionModel extends Model {
 @attr document_id;
 @attr from;
 @attr to;
 @attr account_id;
 @attr operation;
 @attr created_at;
 @attr amount;

 @belongsTo('document', { async: false, inverse: null }) document
 @belongsTo('account', { async: false, inverse: null }) account
}
