import Model, {attr, belongsTo} from '@ember-data/model';

export default class GlobalLimitModel extends Model {
  @attr('number') partner_id;
  @attr('number') currency_id;
  @attr('number') type_id;
  @attr amount;

  @belongsTo('partner', { async: false, inverse: null }) partner;
  @belongsTo('currency', { async: false, inverse: null }) currency;
  @belongsTo('global-limit-type', { async: false, inverse: null }) globalLimitType;

  toJSON() {
    return {
      partner_id: this.partner_id,
      currency_id: this.currency_id,
      type_id: this.type_id,
      amount: this.amount,
      title: this.title
    }
  }
}
