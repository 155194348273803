import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';

export default class RetailAdministratorsIndexRoute extends Route {
  @service navigation;
  @service partner;

  definer = 'retail_administrators'

  queryParams = {
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    partner_id: {
      refreshModel: true
    },
    id: {
      refreshModel: true
    },
    name: {
      refreshModel: true
    },
    currency_id: {
      refreshModel: true
    },
    sort_name: {
      refreshModel: true
    },
    sort_type: {
      refreshModel: true
    },
    is_active: {
      refreshModel: true
    }
  };

  model(params) {
    return params
  }

  activate() {
    this.partner.showPartnerSelect = false;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }
}
