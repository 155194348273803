import Model, {attr, hasMany} from '@ember-data/model';
import {buildValidations, validator} from 'ember-cp-validations';
import {tracked} from '@glimmer/tracking';
import {computed} from '@ember/object';

const Validations = buildValidations({
  wagering_percent: {
    description: 'Wagering Percent',
    validators: [
      validator('number', {
        gt: 0, lte: 100,
        message: computed('model.wagering_percent', function () {
          if (this.model.wagering_percent > 100) {
            return 'Must be less than 101';
          }
          return 'Must be greater than 0';
        })
      })
    ],
  }
});
export default class CasinoTemplateCategoryModel extends Model.extend(Validations) {
  @attr template_id;
  @attr title;
  @attr url;
  @attr('string') icon_url;
  @attr('number') wagering_percent;
  @attr('boolean') is_active;
  @attr('boolean') is_top;
  @attr('order', {defaultValue: 999}) order;

  @tracked providers;
  @tracked types;
  @tracked checked = false
  @tracked show = true
  @tracked subCategories;

  @hasMany('currency', { async: false, inverse: null }) currencies
  @hasMany('partner-config', { async: false, inverse: null }) partnerConfigs
  @hasMany('casino-template-sub-category', { async: false, inverse: null }) casinoTemplateSubCategories

  get name() {
    return this.title;
  }
  modelName = 'casino-template-category'
}
