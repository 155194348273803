import Service from '@ember/service';

export default class PermissionsService extends Service {
  definers = {
    casino_settings_key_providers_update: 'casino_settings_key_providers_update',
    casino_settings_key_providers_delete: 'casino_settings_key_providers_delete',
    casino_settings_key_games_update: 'casino_settings_key_games_update',
    casino_settings_key_games_delete: 'casino_settings_key_games_delete'
  }
}
