import Model, {attr, hasMany, belongsTo} from '@ember-data/model';
import moment from 'moment';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {buildValidations, validator} from 'ember-cp-validations';
import {computed} from '@ember/object';
import {reads} from '@ember/object/computed';

export const TYPE_ENUM = {
  site: 10,
  affiliate: 20,
};

export const RECURRENCE_ENUM = {
  oneTime: 10,
  weekly: 20,
  monthly: 30,
};
export const WALLET_TYPE = {
  sport: 10,
  casino: 20
};
export const FORMULA_TYPE = {
  percent: 10,
  fix: 20
};

export const DISPLAY_ENUM = {
  show: 10,
  hidden: 20,
};

export const STATUS_ENUM = {
  inactive: 10,
  active: 20,
};

const Validations = buildValidations({
  bonus_code: {
    description: 'Bonus Code',
    validators: [
      validator('presence', true),
      validator('length', {
        min: 3,
        max: 30,
      }),
      validator('format', {
        regex: /^(?=.*[A-Za-z])[A-Za-z0-9!@#$%^&*()_+{}\[\]:;\"'\\|,<.>\/?`~-]+$/,
        message:
          '{description} must include at least one letter',
      }),
      validator('unique-fields', {
        showSuggestions: true,
        isNew: reads('model.isNew'),
      }),
    ],
  },
  wager: {
    description: 'Wager',
    validators: [validator('presence', true)],
  },
  expiration: {
    description: 'Expiration',
    validators: [
      validator('presence', true),
      validator('number', {gt: 0, lte: 365})
    ]
  },
  valid_from_date: {
    description: 'valid_from_date',
    validators: []
  },
  number_of_uses: {
    description: 'Number of claims',
    validators: [
      validator('presence', true),
      validator('number', {
        gt: 0,
        lte: computed('model.bonus_action_id', function() {
          if (this.model.isManualMarketing) {
            return 100;
          }

          return 10000;
        }),
      }),
    ],
  },
  max_bonus: {
    description: 'Max Bonus',
    validators: [
      validator('presence', true),
      validator('number', {gt: 0})
    ],
  },
  valid_until_date: {
    description: 'Until Date',
    validators: [
      validator('presence', true)
    ],
  }
});
export default class BonusModel extends Model.extend(Validations) {
  @service store;
  @service bonusService;

  @attr('number') bonus_action_id;
  @attr('boolean', {defaultValue: false}) cantEdit;
  @attr('number', {defaultValue: false}) created_at;
  @attr('number', {defaultValue: 1}) number_of_uses;
  @attr('number', {defaultValue: 7}) expiration;
  @attr('number', {defaultValue: 30}) wager;
  @attr('number', {defaultValue: moment().startOf('day').unix()}) valid_from_date;
  @attr('number', {defaultValue: moment().add('days', 7).unix()}) valid_until_date;
  @attr('string', {defaultValue: '00:00'}) valid_from_hour;
  @attr('string', {defaultValue: '23:59'}) valid_until_hour;
  @attr('number', {defaultValue: FORMULA_TYPE.percent}) balance_formula_type;
  @attr('number', {defaultValue: WALLET_TYPE.casino}) wallet_type;
  @attr('number', {defaultValue: TYPE_ENUM.site}) type;
  @attr('number', {defaultValue: RECURRENCE_ENUM.oneTime}) recurrence;
  @attr('number', {defaultValue: DISPLAY_ENUM.hidden}) display;
  @attr('number', {defaultValue: STATUS_ENUM.inactive}) status;
  @attr('string', {defaultValue: ''}) description;
  @attr('number') fixed_bonus;
  @attr('number') max_bonus;
  @attr('string') bonus_code;
  @attr('number') days_of_login;
  @attr('string') registration_type;
  @attr('string') weekday;
  @attr('string') payment_ids;
  @attr('string') segment_id_include;
  @attr('string') segment_id_exclude;
  @attr('string') group_ids;
  @attr('number', {defaultValue: 10}) benefits_type;

  @hasMany('partner', {async: false, inverse: null}) partners;
  @hasMany('group', {async: false, inverse: null}) groups;
  @hasMany('bonus-progressive', {async: false, inverse: null}) bonusProgressives;
  @hasMany('segment', {async: false, inverse: null}) includeSegments;
  @hasMany('segment', {async: false, inverse: null}) excludeSegments;
  @hasMany('bonus-condition-currency', {async: false, inverse: null}) bonusConditionCurrencies

  @belongsTo('bonus-benefit', {async: false, inverse: null}) bonusBenefit
  @belongsTo('bonus-action', {async: false, inverse: null}) bonusAction

  @tracked weekdays = this.initialWeekdays
  @tracked errors = [];
  @tracked customError = false;
  @tracked depositMethods = [];

  bonusActions = {
    manual: this.bonusService.actionManualId,
    deposit: this.bonusService.actionDepositId
  }

  get initialWeekdays() {
    return this.weekday && JSON.parse(this.weekday) ? JSON.parse(this.weekday).map(item => this.bonusService.weekdays[item]).filter(item => item) : [];
  }

  get isActive() {
    return this.status === STATUS_ENUM.active;
  }

  get isShow() {
    return this.display === DISPLAY_ENUM.show;
  }

  get typeTitle() {
    return this.getEnumTitles('type', TYPE_ENUM)
  }

  get walletTypeTitle() {
    return this.getEnumTitles('wallet_type', WALLET_TYPE)
  }

  get recurrenceTitle() {
    return this.getEnumTitles('recurrence', RECURRENCE_ENUM)
  }

  get formulaTypeTitle() {
    return this.getEnumTitles('balance_formula_type', FORMULA_TYPE)
  }

  getEnumTitles(attribute, enumValues) {
    if (this.get(attribute)) {
      let key;
      for (let prop in enumValues) {
        if (enumValues[prop] === this.get(attribute)) {
          key = prop;
          break;
        }
      }
      return key;
    }
    return '';
  }

  get isDisabled() {//todo remove
    return +this.bonus_action_id !== this.bonusService.actionDepositId;
  }

  get isDeposit() {
    return +this.bonus_action_id === this.bonusService.actionDepositId;
  }

  get isManual() {
    return +this.bonus_action_id === this.bonusService.actionManualId;
  }

  get isManualMarketing() {
    return +this.bonus_action_id === this.bonusService.actionManualMarketingId;
  }

  get isFS() {
    return +this.benefits_type === this.bonusService.bonusBenefitsTypeFS
  }

  get isBonusAndFS() {
    return +this.benefits_type === this.bonusService.bonusBenefitsTypeBonusAndFS
  }

  get isBonusMoney() {
    return +this.benefits_type === this.bonusService.bonusBenefitsTypeBonusMoney
  }

  get isCash() {
    return +this.benefits_type === this.bonusService.bonusBenefitsTypeCash
  }

  get hasManual() {
    return this.isManual ||
      this.isManualMarketing;
  }

  get hasFreeSpins() {
    return this.isFS ||
      this.isBonusAndFS;
  }

  get hasMoney() {
    return this.isBonusMoney ||
      this.isBonusAndFS ||
      this.isCash
  }

  rollback() {
    this.weekdays = this.initialWeekdays;

    this.bonusProgressives?.forEach(bonusProgressive => {
      bonusProgressive?.rollbackAttributes();

      bonusProgressive?.bonusProgressiveCurrencies?.forEach(bonusProgressiveCurrency => {
        bonusProgressiveCurrency?.rollbackAttributes();
      });
    });

    this.store.peekAll('bonus-progressive')
        .filter(item => item.isNew)
        .forEach(newBonusProgressive => {
          newBonusProgressive?.unloadRecord();
        });

    this.store.peekAll('bonus-progressive-currency')
        .filter(item => item.isNew)
        .forEach(newBonusProgressiveCurrency => {
          newBonusProgressiveCurrency?.unloadRecord();
        });

    this.store.peekAll('bonus-condition-currency')
        .filter(item => item.isNew)
        .forEach(newBonusConditionCurrency => {
          newBonusConditionCurrency?.unloadRecord();
        });

    this.store.peekAll('bonus-benefit-currency')
        .filter(item => item.isNew)
        .forEach(newBonusBenefitCurrency => {
          newBonusBenefitCurrency?.unloadRecord();
        });

    this.bonusBenefit?.rollbackAttributes();

    this.bonusBenefit?.bonusBenefitCurrencies?.forEach(bonusBenefitCurrency => {
      bonusBenefitCurrency?.rollbackAttributes();
    });

    this.bonusConditionCurrencies?.forEach(bonusConditionCurrency => {
      bonusConditionCurrency.rollbackAttributes();
    });

    this.rollbackAttributes();
  }
}
