import Controller from '@ember/controller';

export default class RetailAdministratorsAdministratorManagersController extends Controller {
  queryParams = [
    'page',
    'perPage',
    'id',
    'name',
    'sort_name',
    'sort_type',
  ];

  page = 1;
  perPage = 10;
  partner_id;
  id;
  name;
  currency_id;
  sort_name;
  sort_type;
}
