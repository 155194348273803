import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"wc-external-report\">\n  {{#if this.groupReport.getIframeTokenTask.isIdle}}\n  <GenericComponents::GrTransactionsIframe::SbGrTransactionsIframe\n    @token={{this.token}}\n    @tab={{'deposits'}}\n  >\n  </GenericComponents::GrTransactionsIframe::SbGrTransactionsIframe>\n  {{/if}}\n</div>\n", {"contents":"<div class=\"wc-external-report\">\n  {{#if this.groupReport.getIframeTokenTask.isIdle}}\n  <GenericComponents::GrTransactionsIframe::SbGrTransactionsIframe\n    @token={{this.token}}\n    @tab={{'deposits'}}\n  >\n  </GenericComponents::GrTransactionsIframe::SbGrTransactionsIframe>\n  {{/if}}\n</div>\n","moduleName":"backoffice/components/route-components/risk-management/deposits-gr/sb-deposits-gr.hbs","parseOptions":{"srcName":"backoffice/components/route-components/risk-management/deposits-gr/sb-deposits-gr.hbs"}});
import Component from '@glimmer/component';
import {tracked} from "@glimmer/tracking";
import {inject as service} from '@ember/service';

export default class RouteComponentsRiskManagementDepositsGrSbDepositsGr extends Component {
  @service groupReport;

  @tracked token;

  constructor() {
    super(...arguments);

    this.token = this.groupReport.getToken()
    if(!this.token) {
      this.getToken();
    }
  }

  getToken() {
    this.groupReport.getIframeTokenTask.perform(true)
      .then(data => {
        this.token = data.token;
      });
  }
}
