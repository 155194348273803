import ApplicationAdapter from './application';

export default class AdminTransactionAdapter extends ApplicationAdapter {
  urlForQuery(param) {
    if (param.retail) {
      return this.host + '/retail/transactions/get';
    }
    return this.host + '/admin-transactions';
  }
}
