import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"add-new-managers-fields\">\n  <div class=\"field-box\">\n    <h3 class=\"label\">{{t 'Do you really want to delete this role?'}}</h3>\n  </div>\n  <button {{on 'click' this.delete}} class=\"btn btn-basic btn-full\">{{t 'Yes'}}</button>\n</div>\n{{yield}}", {"contents":"<div class=\"add-new-managers-fields\">\n  <div class=\"field-box\">\n    <h3 class=\"label\">{{t 'Do you really want to delete this role?'}}</h3>\n  </div>\n  <button {{on 'click' this.delete}} class=\"btn btn-basic btn-full\">{{t 'Yes'}}</button>\n</div>\n{{yield}}","moduleName":"backoffice/components/modals/settings/role/actions/delete/delete-role.hbs","parseOptions":{"srcName":"backoffice/components/modals/settings/role/actions/delete/delete-role.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {action} from "@ember/object";
import {task} from 'ember-concurrency'

export default class ModalsSettingsRoleActionsDeleteDeleteRoleComponent extends Component {
  @service ajax;
  @service store;
  @service modalWindow;
  @service notifications;

  @action
  delete() {
    this.destroyRole.perform()
  }

  @(task(function* () {
    let role = this.store.peekRecord('role', this.modalWindow.data.role.id);
    yield role.destroyRecord().then(() => {
      this.modalWindow.closeModal()
    }).catch(({payload}) => {
      let errors = payload.errors;
      if (errors) {
        errors.forEach((error) => {
          this.notifications.error(error, {
            autoClear: true
          });
        })
      }
    });
  }))destroyRole

}
