import ApplicationAdapter from './application';

export default class CasinoTemplateCategoryAdapter extends ApplicationAdapter {
  urlForQuery(params) {
    if (params.partner_id) {
      return this.host + '/casino/sub-category-partner'
    }

    if(params.is_bonus) {
      return this.host + '/bonusTemplates/bonuses/sub-casino-categories';
    }

    if(params.wageringContribution) {
      return this.host + `/casino/sub-categories-with-wc/${params.template_id}`;
    }

    return this.host + '/casino/sub-categories'
  }

  urlForCreateRecord() {
    return this.host + '/casino/sub-categories'
  }

  urlForUpdateRecord($id) {
    return this.host + '/casino/sub-categories/' + $id
  }

  urlForDeleteRecord($id) {
    return this.host + '/casino/sub-categories/' + $id
  }
}
