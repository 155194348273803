import Service from '@ember/service';

export default class MathService extends Service {
  isInt(value) {
    const number = Number(value);

    return typeof number === 'number'
      && isFinite(number)
      && Number(number) % 1 === 0;
  }

  isFloat(value) {
    const number = Number(value);

    return typeof Number(number) === 'number'
      && isFinite(number)
      && Number(number) % 1 !== 0;
  }

  round(number) {
    return Math.round((number / 100) * 100);
  }

  randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
