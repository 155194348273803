import ApplicationAdapter from './application';

export default class WheelTemplateAdapter extends ApplicationAdapter {
  urlForQuery() {
    return this.host + '/wheel/wheel-templates'
  }

  urlForFindAll() {
    return this.host + '/wheel/wheel-templates';
  }

  urlForCreateRecord() {
    return this.host + '/wheel-template-create';
  }

  urlForUpdateRecord(id) {
    return this.host + '/wheel/wheel-templates/' + id;
  }

  urlForDeleteRecord(id) {
    return this.host + `/wheel/wheel-templates/${id}`;
  }
}
