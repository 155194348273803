import ApplicationAdapter from './application';

export default class CasinoTemplateGameListAdapter extends ApplicationAdapter {
  urlForQuery(params) {
    if (params.is_bonus) {
      return this.host + '/casino/game-list-search';
    }
    if (params.wageringContribution) {
      return this.host + `/casino/game-list-search`;
    }
    return this.host + '/casino/game-lists'
  }

  urlForCreateRecord() {
    return this.host + '/casino/game-lists'
  }

  urlForUpdateRecord($id) {
    return this.host + '/casino/game-lists/' + $id
  }

  urlForDeleteRecord($id) {
    return this.host + '/casino/game-lists/' + $id
  }
}
