import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='main-info-container casino-settings-templates'>\n  {{#if this.menuItems.length}}\n    {{#if this.menuItems}}\n      <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}} />\n    {{/if}}\n  {{else}}\n    <GenericComponents::Loading::SbLoading />\n  {{/if}}\n</div>\n{{yield}}", {"contents":"<div class='main-info-container casino-settings-templates'>\n  {{#if this.menuItems.length}}\n    {{#if this.menuItems}}\n      <GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}} />\n    {{/if}}\n  {{else}}\n    <GenericComponents::Loading::SbLoading />\n  {{/if}}\n</div>\n{{yield}}","moduleName":"backoffice/components/route-components/casino/integration/sb-integration.hbs","parseOptions":{"srcName":"backoffice/components/route-components/casino/integration/sb-integration.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';

export default class RouteComponentsCasinoIntegrationSbIntegrationComponent extends Component {
  @service store;

  @tracked menuItems = A([]);

  constructor() {
    super(...arguments);
    this.getCasinoIntegrations.perform().then((integrations) => {
      integrations.forEach((integration) => {
        let obj = {};
        obj.name = integration.title;
        obj.definer = 'casino_integration';
        obj.linkTo = { path: 'casino.integration.setting', model: integration.id };
        this.menuItems.pushObject(obj);
      });
    });
  }

  @task *getCasinoIntegrations() {
    return yield this.store.query('casino-integration', {});
  }
}
