import ApplicationAdapter from './application';

export default class DocumentAdapter extends ApplicationAdapter {
  urlForQuery(param) {
    if(param.affiliate){
      return this.host + '/affiliate/client/transaction'
    }
    return this.host + '/player/transaction'
  }
}
