import Model, {attr, belongsTo} from '@ember-data/model';

export default class NavigationModel extends Model {
  @attr partner_id;
  @attr('string') title;
  @attr('number') navigation_group_id;
  @attr('number') navigation_type_id;
  @attr('string') path;
  @attr('string') product;
  @attr('string') sub_product;
  @attr('string') view;
  @attr('boolean') is_active;
  @attr('string') models;
  @attr('order', {defaultValue: 0}) order;
  @attr('string') external_link;
  @attr('string') email;
  @attr('string') phone;
  @attr('string') icon_url;
  @attr('number') link_id;

  @belongsTo('partner-link', { async: false, inverse: null }) link;
  @belongsTo('navigation-group', { async: false, inverse: null }) navigationGroup;
}
